import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';

const LastDestinationSelect = ({ inputValue, clickHandler, destinations }) => {
    if (destinations.length === 0) {
        return <></>;
    }

    const filteredDestinations = inputValue.length
        ? destinations.filter(
            (elm) => (elm.name.toLowerCase().startsWith(inputValue.toLowerCase()))
        )
        : destinations;

    if (filteredDestinations.length === 0) {
        return <></>;
    }

    return (
        <div className="LastDestinationSelect">
            {filteredDestinations.map((destination) => (
                <button type="button" className="LastDestinationSelect__single" key={destination.id} onClick={() => { clickHandler(destination); }}>
                    <span className="LastDestinationSelect__single--name">{destination.name}</span>
                    <span className="LastDestinationSelect__single--details">
                        {destination.address_street}
                        {' '}
                        {destination.address_housenumber}
                        ,
                        {' '}
                        {destination.address_postcode}
                        {' '}
                        {destination.address_city}
                        ,
                        {' '}
                        {destination.address_country}
                    </span>
                </button>
            ))}
        </div>
    );
};

LastDestinationSelect.propTypes = {
    inputValue: PropTypes.string.isRequired,
    clickHandler: PropTypes.func.isRequired,
    destinations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
    const { lastDestinations } = state;
    const { destinations } = lastDestinations;

    return {
        destinations
    };
};

export default connect(mapStateToProps, null)(LastDestinationSelect);
