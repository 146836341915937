import React, {
    useCallback, useEffect, useMemo, useRef
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeChat, getOlderMessages, hideArrowButton } from 'data/currentChat/actions';
import SmallLoader from 'components/SmallLoader';

import './styles.scss';
import classNames from 'services/classNames';
import Error from 'components/Error';
import { API } from 'services';
import ChatAvatar from '../ChatAvatar';
import ChatFormOuter from '../ChatFormOuter';
import ChatMessage from '../ChatMessage';
import ChatArrow from '../ChatArrow';

const ChatSingle = ({
    isLoading, messages, data, close, fullScreen,
    older, hasOlder, olderLoading, olderError, hideArrow,
    receivers,
    email,
}) => {
    let lastUser = null;
    const lastCallTimeRef = useRef(Date.now());
    const newMessageRead = useRef(true);
    const dataId = data?.id;
    const listRef = useRef(null);


    // eslint-disable-next-line camelcase
    if (data?.last_message && data?.last_message.user?.id > 0) {
        lastUser = data.last_message.user.id;
    }

    const markAsRead = useCallback((force = false) => {
        if (!dataId) {
            return;
        }
        const now = Date.now();
        if ((now - lastCallTimeRef.current >= 5000 && !newMessageRead.current) || force) {
            lastCallTimeRef.current = now;
            newMessageRead.current = true;
            API.put(`/messages/chats/${dataId}?mark_as_read=1`)
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [dataId, newMessageRead, lastCallTimeRef]);

    const getOlder = useCallback(() => {
        if (dataId) {
            older(dataId, Math.floor(messages.length / 30) + 1);
        }
    }, [dataId, messages.length, older]);

    const getLastReadMessagesByUsers = useMemo(() => {
        const lastReadMap = new Map();

        receivers.forEach((receiver) => {
            const lastReadMessage = messages.find(
                (message) => new Date(receiver.last_seen_message_at) >= new Date(message.created_at)
            );

            if (lastReadMessage) {
                lastReadMap.set(receiver.id, lastReadMessage.id);
            }
        });

        return lastReadMap;
    }, [messages, receivers]);

    const handleScroll = useCallback(() => {
        if (listRef.current) {
            const { scrollHeight, clientHeight, scrollTop } = listRef.current;
            if (scrollHeight - clientHeight <= scrollTop) {
                hideArrow();
            }
            if (hasOlder && scrollTop === 0) {
                getOlder();
            }
            if (scrollHeight - clientHeight <= scrollTop + 10) {
                markAsRead();
            }
        }
    }, [hasOlder, hideArrow, getOlder, markAsRead]);

    const handleEvent = useCallback(() => {
        if (listRef.current) {
            const { scrollHeight, clientHeight, scrollTop } = listRef.current;
            if (scrollHeight - clientHeight <= scrollTop + 10) {
                markAsRead(true);
            } else {
                newMessageRead.current = false;
            }
        }
    }, [markAsRead]);

    const handleEventWithTimeout = useCallback((e) => {
        if (e?.detail !== dataId) {
            return;
        }
        setTimeout(() => handleEvent(e), 1000);
    }, [dataId, handleEvent]);

    useEffect(() => {
        markAsRead(true);
    }, [markAsRead]);

    useEffect(() => {
        const $elm = listRef.current;
        if ($elm) {
            $elm.addEventListener('scroll', handleScroll);
            return () => $elm.removeEventListener('scroll', handleScroll);
        }
        return undefined;
    }, [handleScroll]);

    useEffect(() => {
        document.addEventListener('ta-new-message', handleEventWithTimeout);
        return () => {
            document.removeEventListener('ta-new-message', handleEventWithTimeout);
        };
    }, [handleEvent, handleEventWithTimeout]);

    const title = useMemo(() => {
        if (receivers.length > 2 && data.passing) {
            return data.passing.passing_idstr;
        }
        return receivers
            .filter((receiver) => !receiver.is_you)
            .map((receiver) => (receiver.user ? `${receiver.user.name} ${receiver.user.surname}` : receiver.email))
            .join(', ');
    }, [data, receivers]);

    const list = useMemo(
        () => messages.map((message, index) => {
            const readers = [];

            getLastReadMessagesByUsers.forEach((lastReadMessageId, userId) => {
                if (lastReadMessageId === message.id) {
                    const reader = receivers.find((receiver) => receiver.id === userId);
                    if (reader && reader.user && email !== reader.user.email) {
                        readers.push(`${reader.user.name} ${reader.user.surname}`);
                    }
                }
            });

            const readByInfo = readers.join(', ');
            const displayReadByInfo = readByInfo && index !== messages.length - 1;
            return (
                <ChatMessage data={message} key={message.id} readBy={displayReadByInfo || 1 ? readByInfo : ''} />
            );
        }),
        [messages, getLastReadMessagesByUsers, receivers, email]
    );

    const goBack = useCallback((e) => {
        e.preventDefault();
        close();
    }, [close]);

    if (isLoading) {
        return (
            <div className={classNames('ChatSingle', fullScreen && 'full')}>
                <div className="Chat__loader">
                    <SmallLoader dark />
                </div>
            </div>
        );
    }

    if (!data) {
        return null;
    }

    return (
        <div className={classNames('ChatSingle', fullScreen && 'full')}>
            <div className="ChatSingle__header">
                <ChatAvatar receivers={data.receivers} withLink lastUser={lastUser} />
                <div className="ChatSingle__header--title">{title}</div>
                {!fullScreen && (
                    <a href="/messages" onClick={goBack} className="ChatSingle-back">
                        go back
                    </a>
                )}
            </div>
            <div className="ChatSingle__list" ref={listRef}>
                <div className="ChatSingle__list-inner">
                    {list}
                    {hasOlder && !olderLoading && <div className="ChatSingle__load"><Error errors={olderError} /></div>}
                    {hasOlder && olderLoading && <div className="ChatSingle__load with-loader"><SmallLoader dark /></div>}
                </div>
            </div>
            <ChatArrow />
            <ChatFormOuter id={data.id} />
        </div>
    );
};

ChatSingle.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.object).isRequired,
    close: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool,
    receivers: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.shape({
        id: PropTypes.number,
        uuid: PropTypes.string,
        company_id: PropTypes.number,
        passing_id: PropTypes.number,
        last_message_at: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        user_id: PropTypes.number,
        last_message_id: PropTypes.number,
        last_message: PropTypes.object,
        passing: PropTypes.object,
        receivers: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            chat_id: PropTypes.number,
            email: PropTypes.string,
            last_seen_message_at: PropTypes.string,
        })),
    }),
    older: PropTypes.func.isRequired,
    hasOlder: PropTypes.bool.isRequired,
    olderLoading: PropTypes.bool.isRequired,
    olderError: PropTypes.bool.isRequired,
    hideArrow: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
};

ChatSingle.defaultProps = {
    data: null,
    fullScreen: false,
};

const mapStateToProps = (state) => {
    const { currentChat, user } = state;
    let email = '';
    try {
        email = user.user.email;
    } catch (e) {
        email = '';
    }

    return {
        chatID: currentChat.chatID,
        messages: currentChat.messages,
        data: currentChat.data,
        receivers: currentChat.data && typeof currentChat.data.receivers
        && currentChat.data.receivers.length > 0 ? currentChat.data.receivers : [],
        isLoading: currentChat.isLoading,
        hasOlder: currentChat.hasOlder,
        olderLoading: currentChat.olderLoading,
        olderError: currentChat.olderError,
        email,
    };
};

const mapDispatchToProps = (dispatch) => ({
    close: () => dispatch(closeChat()),
    older: (i, p) => dispatch(getOlderMessages(i, p)),
    hideArrow: () => dispatch(hideArrowButton()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatSingle);
