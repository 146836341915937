import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'services';

import './style.scss';

import bg from './flags/bg.svg';
import cs from './flags/cs.svg';
import da from './flags/da.svg';
import de from './flags/de.svg';
import el from './flags/el.svg';
import en from './flags/en.svg';
import es from './flags/es.svg';
import et from './flags/et.svg';
import fi from './flags/fi.svg';
import fr from './flags/fr.svg';
import hr from './flags/hr.svg';
import hu from './flags/hu.svg';
import it from './flags/it.svg';
import lb from './flags/lb.svg';
import lt from './flags/lt.svg';
import lv from './flags/lv.svg';
import nl from './flags/nl.svg';
import pl from './flags/pl.svg';
import pt from './flags/pt.svg';
import ro from './flags/ro.svg';
import ru from './flags/ru.svg';
import sk from './flags/sk.svg';
import sl from './flags/sl.svg';
import sv from './flags/sv.svg';
import tr from './flags/tr.svg';
import uk from './flags/uk.svg';

const LanguageIcon = ({ code, className }) => {
    const icons = {
        bg,
        cs,
        da,
        de,
        el,
        en,
        es,
        et,
        fi,
        fr,
        hr,
        hu,
        it,
        lb,
        lt,
        lv,
        nl,
        pl,
        pt,
        ro,
        ru,
        sk,
        sl,
        sv,
        tr,
        uk,
    };

    if (Object.prototype.hasOwnProperty.call(icons, code)) {
        return (<img className={classNames('LanguageIcon', className)} src={icons[code]} alt={code} />);
    }

    return <></>;
};

LanguageIcon.propTypes = {
    code: PropTypes.string.isRequired,
    className: PropTypes.string,
};

LanguageIcon.defaultProps = {
    className: '',
};

export default LanguageIcon;
