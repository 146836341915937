import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { classNames, translations } from 'services';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import Yup from 'services/translatedYup';
import Button from 'components/Button';
import { useParams } from 'react-router-dom';
import { transportDraftAdr } from 'data/transportDraft/actions';

const TransportAdr = ({
    adr, setAdr,
}) => {
    const { id } = useParams();
    const edit = !!id;
    const [hasAdr, setHasAdr] = useState(adr.adr.length > 0);
    const [actions, setActions] = useState(false);
    const defaultValues = adr;

    const validationSchema = Yup.object().shape({
        adr: Yup.string().required(),
    });

    const handleRemove = () => {
        setAdr({
            un: '',
            class: '',
            group: '',
            adr: '',
        });
        setHasAdr(false);
    };

    const handleSubmit = (values) => {
        setAdr(values, edit);
    };

    const handleChange = (e) => {
        if (e.target.checked) {
            setHasAdr(true);
        } else {
            setHasAdr(false);
        }
    };

    if (adr.adr.length) {
        const adrValues = [];
        if (adr.adr?.length) {
            adrValues.push(adr.adr);
        }
        if (adr.un?.length) {
            adrValues.push(adr.un);
        }
        if (adr.class?.length) {
            adrValues.push(adr.class);
        }
        if (adr.group?.length) {
            adrValues.push(adr.group);
        }

        return (
            <div className="TransportAdr-loaded">
                <div className="TransportLoadSingle">
                    <div className="TransportLoadSingle__title">ADR</div>
                    <div className="TransportLoadSingle__values">{adrValues.join(', ')}</div>
                    <button
                        type="button"
                        className="TransportLoadSingle__actions"
                        onClick={() => {
                            setActions((prev) => !prev);
                        }}
                    >
                        actions
                    </button>
                    <div className={classNames('TransportLoadSingle__actions-list', actions && 'active')}>
                        <button
                            type="button"
                            className="TransportLoadSingle__actions-list--delete"
                            onClick={() => {
                                handleRemove();
                            }}
                        >
                            Usuń
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classNames('TransportAdr-outer', adr && 'active')}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="TransportAdr">
                <input type="checkbox" name="use_adr" className="TransportAdr__checkbox" onChange={handleChange} checked={hasAdr} />
                <span className="TransportAdr__span">ADR</span>
                <span className="TransportAdr__description">{translations('passing.details.adr.headline')}</span>
            </label>
            {hasAdr && (
                <Formik
                    initialValues={defaultValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {() => (
                        <Form className="TransportAdr__form">
                            <FormRow>
                                <FormField name="un" label={translations('cargo.un_number')} />
                                <FormField name="class" label={translations('cargo.class')} />
                            </FormRow>
                            <FormRow>
                                <FormField name="group" label={translations('cargo.packing_group')} />
                                <FormField name="adr" label="ADR" isRequired />
                            </FormRow>
                            <div className="TransportAdr__form--btns">
                                <Button type="submit" theme="outline-light">Dodaj</Button>
                                <Button
                                    type="button"
                                    theme="outline"
                                    onClick={() => {
                                        handleRemove();
                                    }}
                                >
                                    Anuluj
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

TransportAdr.propTypes = {
    adr: PropTypes.shape({
        un: PropTypes.string,
        class: PropTypes.string,
        group: PropTypes.string,
        adr: PropTypes.string,
    }).isRequired,
    setAdr: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const { adr } = transportDraft;

    return {
        adr,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setAdr: (values, isEdit = false) => dispatch(transportDraftAdr(values, isEdit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportAdr);
