import {
    LANGUAGES_REQUEST,
    LANGUAGES_LOADED,
    LANGUAGES_ERROR,
} from './constants';

export const initialGeneralState = {
    loading: false,
    error: false,
    languagesList: [],
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case LANGUAGES_REQUEST:
        newState.languagesList = [];
        newState.loading = true;
        newState.error = false;
        return newState;
    case LANGUAGES_LOADED:
        newState.languagesList = action.payload;
        newState.loading = false;
        newState.error = false;
        return newState;
    case LANGUAGES_ERROR:
        newState.languagesList = [];
        newState.loading = false;
        newState.error = action.payload;
        return newState;
    default:
        return state;
    }
};
