import React, { useMemo, useState } from 'react';
import Yup from 'services/translatedYup';
import PropTypes from 'prop-types';


import FormRow from 'components/FormRow';
import FormField from 'components/FormField';

import defaultTranslation from 'services/defaultTranslations';
import { NavLink } from 'react-router-dom';
import FormWrapper from '../FormWrapper';

const PasswordForm = ({ onSubmit, isLoading }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const validationSchema = useMemo(() => {
        const language = localStorage.getItem('language') ?? 'en';

        const translations = {
            validate_required: defaultTranslation('validate_required', language),
            validate_the_same: defaultTranslation('validate_the_same', language),
        };

        return Yup.object().shape({
            password: Yup.string().required(translations.validate_required).min(6),
            password_confirmation: Yup
                .string().required(translations.validate_required).min(6).oneOf([Yup.ref('password'), null], translations.validate_the_same),
        });
    }, []);
    const lang = localStorage.getItem('language') ?? 'en';
    const trans = {
        password: defaultTranslation('password', lang),
        password_confirmation: defaultTranslation('password_confirmation', lang),
        back_to: defaultTranslation('back_to', lang),
        sign_in_page: defaultTranslation('sign_in_page', lang),
    };

    return (
        <FormWrapper
            initialValues={{
                password: '',
                password_confirmation: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
            isLoading={isLoading}
        >
            {() => (
                <>
                    <FormRow>
                        <FormField
                            name="password"
                            type={passwordVisible ? 'text' : 'password'}
                            label={trans.password}
                        />
                        <button
                            className="ResetPassword__password-visibility"
                            type="button"
                            tabIndex="-1"
                            onClick={(() => setPasswordVisible(!passwordVisible))}
                        >
                            Show password
                        </button>
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="password_confirmation"
                            type={passwordVisible ? 'text' : 'password'}
                            label={trans.password_confirmation}
                        />
                    </FormRow>
                    <p className="Login__register">
                        {trans.back_to}
                        {' '}
                        <NavLink to="/" exact>{trans.sign_in_page}</NavLink>
                    </p>
                </>
            )}
        </FormWrapper>
    );
};

PasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default PasswordForm;
