import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Avatar from 'components/Avatar';
import { getUser } from 'data/user/actions';
import { connect } from 'react-redux';
import SettingsAvatar from '../SettingsAvatar';
import SettingsAccountForm from '../SettingsAccountForm';

const SettingsAccount = ({ avatar }) => (
    <div className="SettingsAccount">
        <div className="SettingsAccount__avatar">
            <div className="SettingsAccount__avatar--inner">
                <Avatar image={avatar} width={80} height={80} />
                <SettingsAvatar />
            </div>
        </div>
        <SettingsAccountForm />
    </div>
);

SettingsAccount.propTypes = {
    avatar: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        avatar: user.user.avatar.download_url,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCurrentUser: () => dispatch(getUser(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsAccount);
