import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { activateUser } from 'data/user/actions';
import { NavLink } from 'react-router-dom';
import defaultTranslation from 'services/defaultTranslations';
import SmallLoader from 'components/SmallLoader';

import './styles.scss';

const ActivateAccount = ({ activate, user, ...props }) => {
    const { isLoggedIn, loading } = user;
    const [isLoadingState, setIsLoadingState] = useState(true);

    useEffect(() => {
        const { match } = props;
        let timeout = null;

        if (!isLoggedIn && !loading) {
            timeout = setTimeout(() => {
                setIsLoadingState(false);
                activate(match.params.activateToken);
            }, 1000);
        }

        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, loading]);

    const { activationLoading, activationError } = user;
    const language = localStorage.getItem('language');

    const translations = {
        error_occurred: defaultTranslation('error_occurred', language),
        activation_token_broken: defaultTranslation('activation_token_broken', language),
        return_to_login: defaultTranslation('return_to_login', language),
        activation_success: defaultTranslation('activation_success', language),
    };

    if (activationLoading || isLoadingState) {
        return <div className="loading-wrapper"><SmallLoader dark /></div>;
    }

    const errorMessage = (
        <div className="ActivateAccount__error">
            <p>{translations.error_occurred}</p>
            <p>{translations.activation_token_broken}</p>
        </div>
    );
    const successMessage = <p>{translations.activation_success}</p>;

    const result = (
        <div>
            {activationError ? errorMessage : successMessage}
            <p className="ActivateAccount__go-back">
                <NavLink to="/" exact>{translations.return_to_login}</NavLink>
            </p>
        </div>
    );

    return (
        <div className="ActivateAccount">
            {activationLoading ? (
                <div className="loading-wrapper"><SmallLoader dark /></div>
            )
                : <div>{result}</div>}
        </div>
    );
};

ActivateAccount.propTypes = {
    activate: PropTypes.func.isRequired,
    user: PropTypes.shape({
        loading: PropTypes.bool,
        user: PropTypes.object,
        error: PropTypes.any,
        isLoggedIn: PropTypes.bool,
        activated: PropTypes.bool,
        activationError: PropTypes.any,
        activationLoading: PropTypes.bool,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            activateToken: PropTypes.string.isRequired
        })
    }).isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    activate: (token) => dispatch(activateUser(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
