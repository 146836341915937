import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    useFormikContext,
} from 'formik';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import { translations } from 'services';
import LastDestinationSelect from '../LastDestinationSelect';


const PointForm = ({ isPoint, countries, trigger }) => {
    const timeout = useRef(null);
    const [showDestinations, setShowDestinations] = useState(false);
    const {
        handleChange, handleBlur, setFieldTouched, setFieldValue, values,
    } = useFormikContext();
    const fieldPrefix = isPoint ? 'point' : 'company';
    const currentValues = isPoint ? values.point : values.company;

    const selectPredefinedDestination = (selectedCompany) => {
        if (selectedCompany) {
            [
                'name',
                'address_country_id',
                'address_postcode',
                'address_city',
                'address_street',
                'address_housenumber',
                'email',
                'phone',
                'lat',
                'lng',
                'additional_info',
            ].forEach((field) => {
                if (selectedCompany[field]) {
                    setFieldValue(`${fieldPrefix}.${field}`, selectedCompany[field]);
                    setTimeout(() => {
                        setFieldTouched(`${fieldPrefix}.${field}`, true);
                    }, 15);
                }
            });
            setShowDestinations(false);
        }
    };

    useEffect(() => {
        trigger();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDestinations]);

    return (
        <>
            <FormRow>
                <FormField
                    name={`${fieldPrefix}.name`}
                    label={translations('passing.name')}
                    isRequired
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    onFocus={() => {
                        clearTimeout(timeout.current);
                        setShowDestinations(true);
                    }}
                    onBlur={(e) => {
                        handleBlur(e);
                        setFieldTouched(`${fieldPrefix}.name`, false);
                        timeout.current = setTimeout(() => {
                            setShowDestinations(false);
                            setFieldTouched(`${fieldPrefix}.name`, true);
                        }, 500);
                    }}
                    autoComplete="nope"
                />
                {showDestinations && (
                    <LastDestinationSelect
                        inputValue={currentValues.name}
                        clickHandler={selectPredefinedDestination}
                    />
                )}
                <FormField
                    name={`${fieldPrefix}.email`}
                    label={translations('passing.email')}
                    isRequired
                    isEmails
                />
            </FormRow>
            <FormRow>
                <FormField
                    name={`${fieldPrefix}.phone`}
                    label={translations('front.passing.add.phone')}
                    isPhone
                />
                <FormField
                    name={`${fieldPrefix}.address_country_id`}
                    isSelect={countries}
                    label={translations('passing.country')}
                    isRequired
                    withValue
                />
            </FormRow>
            <FormRow className="half with-postcode">
                <FormField
                    name={`${fieldPrefix}.address_postcode`}
                    label={translations('passing.postcode')}
                    isRequired
                />
                <FormField
                    name={`${fieldPrefix}.address_city`}
                    label={translations('passing.city')}
                    isRequired
                />
            </FormRow>
            <FormRow className="half">
                <FormField
                    name={`${fieldPrefix}.address_street`}
                    label={translations('passing.street')}
                    isRequired
                />
                <FormField
                    name={`${fieldPrefix}.address_housenumber`}
                    label={translations('front.passing.add.house')}
                />
            </FormRow>
            {!isPoint && (
                <div className="TransportDestinationForm__info">
                    <FormRow>
                        <FormField
                            type="textarea"
                            name={`${fieldPrefix}.additional_info`}
                            label={translations('front.passing.add.instructions')}
                        />
                    </FormRow>
                </div>
            )}
        </>
    );
};

PointForm.propTypes = {
    isPoint: PropTypes.bool,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    trigger: PropTypes.func.isRequired,
};

PointForm.defaultProps = {
    isPoint: false,
};


const mapStateToProps = (state) => {
    const { countries } = state;

    return {
        countries: countries.countries,
    };
};

export default connect(mapStateToProps, null)(PointForm);
