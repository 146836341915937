import React, { useState } from 'react';
import { Field, useFormikContext, useField } from 'formik';
import PropTypes from 'prop-types';
import 'react-multi-email/dist/style.css';


import { classNames } from 'services';

import ErrorMessage from 'components/ErrorMessage';

import { ReactMultiEmail } from 'react-multi-email';
import Icon from './components/Icon';

import './styles.scss';
import DatePickerField from './components/DatepickerField';
import SelectField from './components/SelectField';
import PhoneField from './components/PhoneField';

const FormField = ({
    icon,
    label,
    name,
    isRequired,
    isSelect,
    isAsyncSelect,
    isPhone,
    isEmails,
    isDateTimePicker,
    copy,
    isCreatable,
    withValue,
    forceValue,
    ...props
}) => {
    const { errors, touched, setFieldValue } = useFormikContext();
    const [formikField] = useField(name);
    let error = errors[name];
    let touch = touched[name];

    if (name.includes('.')) {
        const splitedName = name.split('.');
        if (splitedName.length === 2) {
            if (errors[splitedName[0]] && errors[splitedName[0]][splitedName[1]]) {
                error = errors[splitedName[0]][splitedName[1]];
            }
            if (touched[splitedName[0]] && touched[splitedName[0]][splitedName[1]]) {
                touch = touched[splitedName[0]][splitedName[1]];
            }
        }
    }

    const hasError = error && touch;
    const [hasValue, setHasValue] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);

    const handleKeyUp = (e) => {
        if (e && e.target && e.target.value) {
            setHasValue(true);
        } else {
            setHasValue(false);
        }
    };

    let fieldRender = '';
    if (isPhone) {
        fieldRender = (<PhoneField name={name} {...props} />);
    } else if (isSelect) {
        fieldRender = (
            <SelectField
                options={isSelect}
                name={name}
                className="form-field__field"
                {...props}
                isAsyncCreatable={isAsyncSelect}
                isCreatable={isCreatable}
                withValue={withValue}
                forceValue={forceValue}
            />
        );
    } else if (isDateTimePicker) {
        fieldRender = (
            <DatePickerField
                name={name}
                time
                className="form-field__field"
            />
        );
    } else if (isEmails) {
        const emails = formikField.value && formikField.value.length > 0 ? formikField.value.split(',') : [];
        fieldRender = (
            <ReactMultiEmail
                emails={emails}
                className="form-field__field form-field__field--emails"
                onChange={(_emails) => {
                    setFieldValue(name, _emails.join(','));
                }}
                getLabel={(email, index, removeEmail) => (
                    <div data-tag="" key={index}>
                        <div data-tag-item>{email}</div>
                        {/* eslint-disable-next-line max-len */}
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                            ×
                        </span>
                    </div>
                )}
            />
        );
    } else {
        fieldRender = (
            <Field
                onKeyUp={(e) => handleKeyUp(e)}
                onBlur={(e) => {
                    formikField.onBlur(e);
                    setHasFocus(false);
                }}
                onFocus={() => {
                    setHasFocus(true);
                }}
                className="form-field__field"
                name={name}
                id={`field-${name}`}
                component={props.type === 'textarea' ? 'textarea' : 'input'}
                {...props}
            />
        );
    }

    return (
        <div
            className={classNames('form-field', icon && 'form-field--with-icon', hasError && 'form-field--error', label && 'has-label', hasValue && 'has-value', hasFocus && 'has-focus')}
        >
            {icon && (
                <Icon className="form-field__icon" icon={icon} />
            )}
            {label && (
                <div className="form-field__label">
                    <span className="form-field__label--copy" title={label}>{label}</span>
                    {isRequired && <span className="form-field__label--required" />}
                </div>
            )}
            {
                fieldRender
            }
            {
                copy.length > 0 ? (
                    <label
                        htmlFor={`field-${name}`}
                        className="form-field__field-copy"
                    >
                        {copy}
                    </label>
                ) : ''
            }
            {hasError && <ErrorMessage error={error} />}
        </div>
    );
};

FormField.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    isEmails: PropTypes.bool,
    copy: PropTypes.string,
    isSelect: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.array
    ]),
    isAsyncSelect: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func
    ]),
    isDateTimePicker: PropTypes.bool,
    isPhone: PropTypes.bool,
    isCreatable: PropTypes.bool,
    withValue: PropTypes.bool,
    forceValue: PropTypes.bool,
};

FormField.defaultProps = {
    icon: null,
    label: null,
    error: null,
    isRequired: false,
    isSelect: false,
    isDateTimePicker: false,
    copy: '',
    isAsyncSelect: false,
    isPhone: false,
    isEmails: false,
    isCreatable: false,
    withValue: false,
    forceValue: false,
};

export default FormField;
