import {
    CONFIRMATION_SHOW, CONFIRMATION_HIDE
} from './constants';

export function confirmationShow(payload) {
    return { type: CONFIRMATION_SHOW, payload };
}

export function confirmationHide() {
    return { type: CONFIRMATION_HIDE };
}

export function confirm(title, description, callback, isError = false) {
    return (dispatch) => dispatch(confirmationShow({
        title, description, callback, isError
    }));
}
