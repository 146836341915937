import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReloadButton from 'components/ReloadButton';
import { translations } from 'services';
import { transportsRemove, transportsSearch } from 'data/transportsList/actions';
import Search from 'components/Search';
import PassingsList from './components/PassingsList';
import TransportsFilters from './components/TransportsFilters';
import TransportsBulkRemove from './components/TransportsBulkRemove';
import './styles.scss';

const Passings = ({ search, clearResets, setSearch }) => {
    const { page } = useParams();
    const pageNumber = page ? parseInt(page, 10) : 1;
    const [reload, setReload] = useState(1);

    useEffect(() => {
        clearResets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    return (
        <div className="Passings">
            <div className="Passings__header">
                <Search callback={setSearch} value={search} placeholder={translations('front.transports_filters.search')} />
                <TransportsBulkRemove
                    trigger={() => { setReload((v) => v + 1); }}
                />
                <ReloadButton onClick={() => { setReload((v) => v + 1); }} style={{ marginLeft: 'auto' }} />
                <TransportsFilters />
            </div>
            <PassingsList page={pageNumber} reloadTrigger={reload} />
        </div>
    );
};

Passings.propTypes = {
    search: PropTypes.string.isRequired,
    clearResets: PropTypes.func.isRequired,
    setSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transportsList } = state;
    const { search } = transportsList;

    return {
        search
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearResets: () => dispatch(transportsRemove([])),
    setSearch: (toSearch = '') => dispatch(transportsSearch(toSearch))
});

export default connect(mapStateToProps, mapDispatchToProps)(Passings);
