export default (to,
    receiverDifferent, senderDifferent, destinationAddress,
    receiver, startingAddress, sender) => {
    let lat = '';
    let lng = '';
    if (to) {
        if (receiverDifferent) {
            lat = destinationAddress.lat;
            lng = destinationAddress.lng;
        } else {
            lat = receiver.lat;
            lng = receiver.lng;
        }
    } else if (senderDifferent) {
        lat = startingAddress.lat;
        lng = startingAddress.lng;
    } else {
        lat = sender.lat;
        lng = sender.lng;
    }

    if (typeof lat === 'undefined') {
        lat = '';
    }
    if (typeof lng === 'undefined') {
        lng = '';
    }

    return { lat, lng };
};
