import { useEffect, useState } from 'react';
import { API, errorHandler } from 'services';
import {
    TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_REMOVE
} from '../submissionStatuses';

const useTransportRemoveLoad = (status, loads, transport = false) => {
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (status === TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_REMOVE
            // eslint-disable-next-line camelcase
            && transport?.id && transport?.passing_loads?.length > 0) {
            transport.passing_loads.forEach((load) => {
                let shouldDelete = true;
                if (loads.length > 0) {
                    if (loads.findIndex((elm) => elm.id === load.id) > -1) {
                        shouldDelete = false;
                    }
                }

                if (shouldDelete) {
                    setIsLoading((v) => v + 1);
                    API.delete(`/passings/${transport.id}/loads/${load.id}`)
                        .then(() => {
                            setIsLoading((v) => v - 1);
                        })
                        .catch((err) => {
                            errorHandler(err, () => {
                                setIsLoading((v) => v - 1);
                                setError(err);
                            });
                        });
                }
            });
        }
    }, [status, loads, transport]);

    return { isLoading: isLoading > 0, error };
};

export default useTransportRemoveLoad;
