import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import translations from 'services/translations';
import './styles.scss';
import { connect } from 'react-redux';
import draftValidation from 'pages/TransportAdd/services/draftValidation';
import {
    transportDraftCarrier,
    transportDraftContractor,
    transportDraftDriverOption,
    transportDraftValid
} from 'data/transportDraft/actions';

const HeaderTransport = ({
    sender, driver, receiver, showBackButton, goBack, notValid,
}) => {
    let id = 0;
    const location = useLocation();
    const isAddTransport = location.pathname.startsWith('/transports/add');
    let isDriverPath = false;
    let isEditTransport = false;
    if (!isAddTransport && location.pathname.startsWith('/transports/single/')) {
        if (location.pathname.endsWith('/edit')) {
            isEditTransport = true;
        } else if (location.pathname.includes('/edit')) {
            if (location.pathname.endsWith('/from-company')) {
                isEditTransport = true;
            } else if (location.pathname.endsWith('/to-company')) {
                isEditTransport = true;
            } else if (location.pathname.endsWith('/load')) {
                isEditTransport = true;
            } else if (location.pathname.endsWith('/driver')) {
                isEditTransport = true;
            }
        }
    }

    if (isAddTransport || isEditTransport) {
        if (location.pathname.endsWith('/driver')) {
            isDriverPath = true;
        }
    }

    if (isEditTransport) {
        const splitedLocation = location.pathname.split('/transports/single/');
        try {
            if (splitedLocation[1] !== 'undefined') {
                id = parseInt(splitedLocation[1].split('/edit')[0], 10);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const back = (
        <>
            {(showBackButton && isDriverPath) && (
                <div className="HeaderTransport__back">
                    <button
                        className="HeaderTransport__back--link"
                        onClick={() => { goBack(); notValid(); }}
                        type="button"
                    >
                        {translations('general.undo')}
                    </button>
                </div>
            )}
        </>
    );

    if (!isAddTransport && !isEditTransport) {
        return (
            <NavLink exact className="Header__add Header__add--btn pull-right" to="/transports/add/from-company">
                <span className="Header__add--btn-span">{translations('front.header.add_transit')}</span>
            </NavLink>
        );
    }

    const buttonEnabled = sender.validation && receiver.validation && driver.validation;

    let copy = translations('front.general.add');
    if (isEditTransport) {
        copy = translations('front.general.save');
    }

    if (buttonEnabled) {
        return (
            <div className="HeaderTransport-outer">
                {back}
                <NavLink exact to={isAddTransport ? '/transports/add/accept' : `/transports/single/${id}/edit/accept`} className="HeaderTransport">
                    <span className="HeaderTransport--copy">{copy}</span>
                </NavLink>
            </div>
        );
    }

    return (
        <div className="HeaderTransport-outer">
            {back}
            <button type="button" className="HeaderTransport" disabled={!buttonEnabled}>
                <span className="HeaderTransport--copy">{copy}</span>
            </button>
        </div>
    );
};

const elementObject = {
    validation: PropTypes.bool,
    status: PropTypes.string,
};

HeaderTransport.propTypes = {
    sender: PropTypes.shape(elementObject).isRequired,
    receiver: PropTypes.shape(elementObject).isRequired,
    driver: PropTypes.shape(elementObject).isRequired,
    showBackButton: PropTypes.bool.isRequired,
    goBack: PropTypes.func.isRequired,
    notValid: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const validation = draftValidation(transportDraft);
    const { sender, receiver, driver } = validation;

    const showBackButton = transportDraft.driver === 2 || transportDraft.driver === 3;

    return {
        sender, receiver, driver, showBackButton,
    };
};

const mapDispatchToProps = (dispatch) => ({
    notValid: (edit = false) => {
        setTimeout(() => dispatch(transportDraftValid(false, 'driver', edit)), 100);
    },
    goBack: (edit = false) => {
        dispatch(transportDraftContractor({
            name: '', email: '', avatar: ''
        }, edit));
        dispatch(transportDraftDriverOption());
        setTimeout(() => dispatch(transportDraftCarrier(false, edit)), 100);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTransport);
