import React, { useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import { translations } from 'services';
import { useParams } from 'react-router-dom';
import './styles.scss';
import { transportDraftLoadSummary } from 'data/transportDraft/actions';

const TransportLoadSummaryForm = ({ loadSummary, extendedLoad, push }) => {
    const { id } = useParams();
    const edit = !!id;
    const reference = useRef(null);
    const timeout = useRef(null);

    useEffect(() => () => {
        reference.current.submitForm();
    }, []);

    const handlePush = (values, withTimeout = false) => {
        clearTimeout(timeout.current);
        if (withTimeout) {
            timeout.current = setTimeout(() => {
                if (!extendedLoad) {
                    push(values.loadSummary, edit);
                }
            }, 200);
        } else if (!extendedLoad) {
            push(values.loadSummary, edit);
        }
    };

    return (
        <div className="TransportLoadSummaryForm">
            <div className="TransportAdd__card">
                <Formik
                    initialValues={{
                        loadSummary
                    }}
                    onSubmit={(values) => { handlePush(values); }}
                    innerRef={reference}
                >
                    {({ values }) => (
                        <Form onChange={() => { handlePush(values, true); }}>
                            <FormRow className="TransportLoadSummaryForm__form">
                                <FormField
                                    name="loadSummary"
                                    type="textarea"
                                    label={translations('front.passing.single.nav.load')}
                                />
                            </FormRow>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

TransportLoadSummaryForm.propTypes = {
    loadSummary: PropTypes.string.isRequired,
    extendedLoad: PropTypes.bool.isRequired,
    push: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const { loadSummary, extendedLoad } = transportDraft;

    return {
        loadSummary, extendedLoad,
    };
};

const mapDispatchToProps = (dispatch) => ({
    push: (summary, isEdit = false) => dispatch(transportDraftLoadSummary(summary, isEdit)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TransportLoadSummaryForm);
