export const SET_PASSINGS = 'SET_PASSINGS';
export const GET_PASSINGS = 'GET_PASSINGS';
export const SET_TRUCKS = 'SET_TRUCKS';
export const GET_TRUCKS = 'GET_TRUCKS';
export const SET_USERS = 'SET_USERS';
export const GET_USERS = 'GET_USERS';
export const SET_DOCS = 'SET_DOCS';
export const GET_DOCS = 'GET_DOCS';
export const SET_CARRIERS = 'SET_CARRIERS';
export const GET_CARRIERS = 'GET_CARRIERS';
