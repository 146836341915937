import React from 'react';
import './styles.scss';
import { translations } from 'services';

const NotFound = () => (
    <div className="NotFound">
        <div className="NotFound__header">404</div>
        <div className="NotFound__info">{translations('front.general.not_found')}</div>
    </div>
);

export default NotFound;
