import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { translations, classNames, getLoadString } from 'services';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { transportDraftPopLoad } from 'data/transportDraft/actions';

const TransportLoadSingle = ({
    load, index, pop,
}) => {
    const { id } = useParams();
    const edit = !!id;
    const [actions, setActions] = useState(false);

    const handleDelete = () => {
        pop(load.id, edit);
    };

    return (
        <div className="TransportLoadSingle">
            <div className="TransportLoadSingle__title">
                {index}
                .
                {' '}
                {load.load_no && load.load_no.length ? load.load_no : translations('front.passing.single.nav.load')}
            </div>
            <div className="TransportLoadSingle__values">{getLoadString(load)}</div>
            <button type="button" className="TransportLoadSingle__actions" onClick={() => { setActions((a) => !a); }}>actions</button>
            <div className={classNames('TransportLoadSingle__actions-list', actions && 'active')}>
                <button type="button" className="TransportLoadSingle__actions-list--delete" onClick={() => { handleDelete(); }}>{translations('front.general.delete')}</button>
            </div>
        </div>
    );
};

TransportLoadSingle.propTypes = {
    load: PropTypes.shape({
        amount: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        company_id: PropTypes.number,
        created_at: PropTypes.string,
        id: PropTypes.number,
        load_no: PropTypes.string,
        load_type: PropTypes.string,
        package_type: PropTypes.string,
        pallet_id: PropTypes.string,
        passing_id: PropTypes.number,
        updated_at: PropTypes.string,
        volume: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        weight: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }).isRequired,
    index: PropTypes.number.isRequired,
    pop: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    pop: (idToRemove, isEdit = false) => dispatch(transportDraftPopLoad(idToRemove, isEdit)),
});

export default connect(null, mapDispatchToProps)(TransportLoadSingle);
