import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const FilterButton = ({ onClick }) => (<button className="FilterButton" type="button" onClick={onClick}>filters</button>);

FilterButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default FilterButton;
