import React from 'react';
import PropTypes from 'prop-types';
import defaultTranslation from 'services/defaultTranslations';
import { classNames } from 'services';
import style from './ChatMessageReadby.module.scss';

const ChatMessageReadby = ({ readBy, reverse }) => {
    if (!readBy || readBy.length === 0) {
        return null;
    }
    const lang = localStorage.getItem('language') ?? 'en';
    const readByCopy = defaultTranslation('readBy', lang);

    return (
        <div className={classNames(style.ChatMessageReadby, reverse && style['ChatMessageReadby--reverse'])}>
            {readByCopy}
            {' '}
            {readBy}
        </div>
    );
};

ChatMessageReadby.propTypes = {
    readBy: PropTypes.string.isRequired,
    reverse: PropTypes.bool,
};

ChatMessageReadby.defaultProps = {
    reverse: false,
};

export default ChatMessageReadby;
