const translations = {
    loading: 'Betöltés',
    loading_data: 'Adatok betöltése ...',
    validate_email: 'Ez nem a helyes e-mail cím',
    validate_required: 'Ez a mező kötelező',
    validate_the_same: 'Mindkét jelszónak meg kell felelnie',
    sign_in: 'Bejelentkezés',
    sign_up__invitation: 'Nincs fiókod?',
    sign_up: 'Regisztráció!',
    forgot_password: 'Nem emlékszel a jelszóra?',
    error_occurred: 'Hiba történt!',
    activation_token_broken: 'Az aktiváló token helytelen.',
    return_to_login: 'Vissza a bejelentkezéshez',
    activation_success: 'Köszönöm! Fiókja most aktív.',
    forgot_password_submit: 'Állítsa vissza a jelszót',
    password: 'Jelszó',
    password_confirmation: 'Jelszó újra',
    email: 'Email',
    remember_me: 'Emlékezz rám',
    welcome: 'Üdvözöljük!',
    log_in_to_access: 'Jelentkezzen be a kezdetbe',
    password_forgot_message: 'Nem tud bejelentkezni? Adja meg e-mail címét a jelszó visszaállításához!',
    back_to: 'visszatér rá',
    sign_in_page: 'Belépés',
    select: 'Választ...',
    select_new: 'Teremt:',
    invitation_sent: 'Meghívó elküldve',
    users_custom_company_data: 'Használjon más vállalati adatokat',
    or_just_attachment_name: 'Vagy csak add hozzá a csatolmány nevét:',
    other_docs: 'Egyéb dokumentumok:',
    readBy: 'Olvasva:',
};
export default translations;
