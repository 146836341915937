import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';

import Button from 'components/Button';

import './style.scss';
import defaultTranslation from 'services/defaultTranslations';

const FormWrapper = ({
    children,
    initialValues,
    validationSchema,
    onSubmit,
    isLoading
}) => {
    const language = localStorage.getItem('language') ?? 'en';

    const translations = {
        sign_in: defaultTranslation('sign_in', language),
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
        >
            {(formikBag) => (
                <Form className="login-form">
                    {children(formikBag)}
                    <div className="login-form__button-wrapper">
                        <Button
                            block
                            shadow
                            withLoader
                            loading={isLoading}
                            type="submit"
                        >
                            {translations.sign_in}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

FormWrapper.propTypes = {
    children: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired, //eslint-disable-line
    validationSchema: PropTypes.any.isRequired, //eslint-disable-line
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        isLoading: user.loading,
    };
};

export default connect(mapStateToProps)(FormWrapper);
