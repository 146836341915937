import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { transportsRemoveLoading } from 'data/transportsList/actions';
import './style.scss';
import translations from 'services/translations';
import { confirm } from 'data/confirmation/actions';
import { API } from 'services';

const TransportsBulkRemove = ({
    trigger, toRemove, confirmation, setLoading, ...props
}) => {
    const defaultColor = '#CDCDDF';
    const dangerColor = '#F94341';
    const colorToUse = toRemove.length > 0 ? dangerColor : defaultColor;

    const removeCalls = useCallback(async () => {
        setLoading(true);
        await Promise.all(toRemove.map(async (id) => {
            try {
                return await API.delete(`/passings/${id}`);
            } catch (e) {
                console.error(e);
                return trigger();
            }
        }));
        setLoading(false);
        trigger();
    }, [setLoading, toRemove, trigger]);

    const handleRemove = () => {
        if (toRemove.length > 0) {
            confirmation(translations('front.general.attention'), translations('front.general.confirmations.transports_remove'), removeCalls);
        }
    };

    return (
        <button
            disabled={toRemove.length === 0}
            className="TransportsBulkRemove"
            onClick={handleRemove}
            type="button"
            {...props}
        >
            <svg
                className="TransportsBulkRemove__svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M1.60747 3.04975C1.57803 2.75541 1.80918 2.5 2.10499 2.5H9.89501C10.1908 2.5 10.422 2.75541 10.3925 3.04975L9.68258 10.1493C9.6059 10.9161 8.96065 11.5 8.19002 11.5H3.80998C3.03935 11.5 2.3941 10.9161 2.31742 10.1493L1.60747 3.04975Z" stroke={colorToUse} />
                <line x1="0.5" y1="2.5" x2="11.5" y2="2.5" stroke={colorToUse} strokeLinecap="round" />
                <rect x="3.5" y="0.5" width="5" height="2" rx="0.5" stroke={colorToUse} />
                <line x1="5" y1="5" x2="5" y2="9" stroke={colorToUse} strokeLinecap="round" />
                <line x1="7" y1="5" x2="7" y2="9" stroke={colorToUse} strokeLinecap="round" />
            </svg>
        </button>
    );
};

TransportsBulkRemove.propTypes = {
    trigger: PropTypes.func.isRequired,
    toRemove: PropTypes.arrayOf(PropTypes.number).isRequired,
    confirmation: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transportsList } = state;
    const { toRemove } = transportsList;

    return {
        toRemove
    };
};

const mapDispatchToProps = (dispatch) => ({
    confirmation: (t, d, c) => dispatch(confirm(t, d, c)),
    setLoading: (v) => dispatch(transportsRemoveLoading(v)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportsBulkRemove);
