import React, { useMemo, useState } from 'react';
import Yup from 'services/translatedYup';
import PropTypes from 'prop-types';


import defaultTranslation from 'services/defaultTranslations';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';

import { NavLink } from 'react-router-dom';
import { getUrl } from 'services';
import FormWrapper from '../FormWrapper';

const LoginForm = ({ onSubmit }) => {
    const validationSchema = useMemo(() => {
        const language = localStorage.getItem('language') ?? 'en';

        const translations = {
            validate_email: defaultTranslation('validate_email', language),
            validate_required: defaultTranslation('validate_required', language),
        };

        return Yup.object().shape({
            email: Yup.string().email(translations.validate_email)
                .required(translations.validate_required),
            password: Yup.string().required(translations.validate_required),
            remember_me: Yup.bool(),
        });
    }, []);


    const [passwordVisible, setPasswordVisible] = useState(false);
    const lang = localStorage.getItem('language') ?? 'en';
    const trans = {
        email: defaultTranslation('email', lang),
        password: defaultTranslation('password', lang),
        forgot_password: defaultTranslation('forgot_password', lang),
        sign_up__invitation: defaultTranslation('sign_up__invitation', lang),
        sign_up: defaultTranslation('sign_up', lang),
        remember_me: defaultTranslation('remember_me', lang),
    };

    return (
        <>
            <FormWrapper
                initialValues={{
                    email: '', password: '', remember_me: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => onSubmit(values)}
            >
                {() => (
                    <>
                        <FormRow>
                            <FormField
                                label={trans.email}
                                name="email"
                                type="email"
                                icon="email"
                            />
                        </FormRow>
                        <FormRow className="Login__password-row">
                            <FormField
                                label={trans.password}
                                name="password"
                                type={passwordVisible ? 'text' : 'password'}
                                icon="password"
                            />
                            <button
                                className="Login__password-visibility"
                                type="button"
                                onClick={(() => setPasswordVisible(!passwordVisible))}
                            >
                                Show password
                            </button>
                        </FormRow>
                        <FormRow className="Login__remember-row">
                            <FormField
                                type="checkbox"
                                name="remember_me"
                                copy={trans.remember_me}
                            />
                            <div className="Login__forgot-password"><NavLink to="/forgot-password" exact>{trans.forgot_password}</NavLink></div>
                        </FormRow>
                    </>
                )}
            </FormWrapper>
            <p className="Login__register">
                {trans.sign_up__invitation}
                {' '}
                <a href={getUrl('sign-up')}>{trans.sign_up}</a>
            </p>
        </>
    );
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default LoginForm;
