const translations = {
    loading: 'Cargando',
    loading_data: 'Cargando de datos ...',
    validate_email: 'Esta no es la dirección de correo electrónico correcta',
    validate_required: 'Este campo es obligatorio',
    validate_the_same: 'Ambas contraseñas deben ser las mismas',
    sign_in: 'Registrarse',
    sign_up__invitation: '¿No tienes una cuenta?',
    sign_up: '¡Registrarse!',
    forgot_password: '¿No recuerdas la contraseña?',
    error_occurred: '¡Ocurrió un error!',
    activation_token_broken: 'El token de activación es incorrecto.',
    return_to_login: 'Volver al inicio de sesión',
    activation_success: '¡Gracias! Tu cuenta ahora está activa.',
    forgot_password_submit: 'Restablecer la contraseña',
    password: 'Clave',
    password_confirmation: 'Repita la contraseña',
    email: 'Correo electrónico',
    remember_me: 'Recuérdame',
    welcome: '¡Bienvenidos!',
    log_in_to_access: 'Inicia sesión para comenzar',
    password_forgot_message: '¿No puedes iniciar sesión? ¡Introduzca su dirección de correo electrónico para restablecer su contraseña!',
    back_to: 'regresa a',
    sign_in_page: 'acceso',
    select: 'Escoger...',
    select_new: 'Crear:',
    invitation_sent: 'Invitación enviada',
    users_custom_company_data: 'Utiliza otros datos de la empresa',
    or_just_attachment_name: 'O simplemente añade el nombre del adjunto:',
    other_docs: 'Otros documentos:',
    readBy: 'Leído por:',
};
export default translations;
