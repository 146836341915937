import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { classNames, hasPermission, translations } from 'services';
import './styles.scss';
import { connect } from 'react-redux';

const SettingsMenu = ({ canUpdateCompany }) => {
    const location = useLocation();
    const isCompany = (location.pathname
        && (!location.pathname.endsWith('/settings') && !location.pathname.endsWith('/settings/my-account')));
    const elements = [
        {
            to: '/settings',
            copy: translations('front.settings.nav.settings'),
        },
        // {
        //     to: '/settings/notifications',
        //     copy: translations('front.settings.nav.notifications'),
        // },
        {
            to: '/settings/my-account',
            copy: translations('front.settings.nav.my_account'),
        },
        ...(canUpdateCompany ? [{
            to: '/settings/company',
            copy: translations('front.settings.nav.company'),
            active: isCompany,
        }] : [])
    ];

    return (
        <ul className="SettingsMenu">
            {elements.map((element) => (
                <li key={element.to} className="SettingsMenu__element">
                    <NavLink
                        to={element.to}
                        exact
                        className={classNames('SettingsMenu__element--link', element.active && 'active')}
                    >
                        {element.copy}
                    </NavLink>
                </li>
            ))}
        </ul>
    );
};

SettingsMenu.propTypes = {
    canUpdateCompany: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;
    const { permissions } = user;
    const canUpdateCompany = hasPermission('my_company_update_data', permissions);

    return {
        canUpdateCompany,
    };
};

export default connect(mapStateToProps, null)(SettingsMenu);
