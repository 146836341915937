import {
    bg, cs, da, de, el, es, et, fi, fr, hr, hu, it, lb, lt, lv, nl, pl, pt, ro, ru, sk, sl, sv, uk,
} from 'date-fns/locale';
import en from 'date-fns/locale/en-US';


export default () => {
    const language = localStorage.getItem('language') ?? 'en';

    switch (language) {
    case 'bg':
        return bg;
    case 'cs':
        return cs;
    case 'da':
        return da;
    case 'de':
        return de;
    case 'el':
        return el;
    case 'es':
        return es;
    case 'et':
        return et;
    case 'fi':
        return fi;
    case 'fr':
        return fr;
    case 'hr':
        return hr;
    case 'hu':
        return hu;
    case 'it':
        return it;
    case 'lb':
        return lb;
    case 'lt':
        return lt;
    case 'lv':
        return lv;
    case 'nl':
        return nl;
    case 'pl':
        return pl;
    case 'pt':
        return pt;
    case 'ro':
        return ro;
    case 'ru':
        return ru;
    case 'sk':
        return sk;
    case 'sl':
        return sl;
    case 'sv':
        return sv;
    case 'uk':
        return uk;
    default:
        return en;
    }
};
