import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import { classNames, getUrl, translations } from 'services';

const UpgradePopup = ({ packageName, close }) => {
    const version = packageName === 'standard' ? 'premium' : 'standard';
    const listArray = version === 'premium' ? [1, 2, 3, 4] : [1, 2];

    if (packageName === 'premium') {
        return <></>;
    }

    return (
        <div className={classNames('UpgradePopup', version)}>
            <div className="UpgradePopup__overlay" onClick={close} onKeyDown={close} role="button" tabIndex={0}>close</div>
            <div className="UpgradePopup__content">
                <div className="UpgradePopup--inner">
                    <button className="UpgradePopup__close" type="button" onClick={close}>close</button>
                    <div className="UpgradePopup__content--inner">
                        <div className="UpgradePopup__content--header">{translations(`front.popups.${version}.header`)}</div>
                        <div className="UpgradePopup__content--copy">{translations(`front.popups.${version}.copy`)}</div>
                        <ul className="UpgradePopup__content--list">
                            {listArray.map((elm) => (
                                <li key={elm} className="UpgradePopup__content--single">{translations(`front.popups.${version}.list${elm}`)}</li>
                            ))}
                        </ul>
                        <a href={getUrl('admin-panel')} className="UpgradePopup__btn">{translations('front.general.upgrade_btn')}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

UpgradePopup.propTypes = {
    packageName: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;
    let packageName = 'basic';
    // eslint-disable-next-line camelcase
    if (user?.user?.current_subscription_id) {
        packageName = user.user.current_subscription_id;
    }

    return {
        packageName
    };
};

export default connect(mapStateToProps, null)(UpgradePopup);
