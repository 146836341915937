import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'services/classNames';
import { sendFile } from 'data/currentChat/actions';

import './styles.scss';

const ChatFileForm = ({ chatID, version, send }) => {
    const onChangeHandler = (event) => {
        try {
            const file = event.target.files[0];
            if (file) {
                const mbSize = (file.size / (1024 * 1024));
                if (mbSize >= 25) {
                    const eventToSend = new CustomEvent('ta-show-modal', { detail: 'file-error' });
                    document.dispatchEvent(eventToSend);
                } else {
                    send(chatID, file);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={classNames('ChatFileForm', version === 'image' && 'for-image')}>
            <form>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className={classNames('ChatFileForm__button', version === 'image' && 'for-image')}>
                    {version === 'image' ? (
                        <input
                            type="file"
                            name="file"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={onChangeHandler}
                        />
                    ) : <input type="file" name="file" onChange={onChangeHandler} />}

                </label>
            </form>
        </div>
    );
};

ChatFileForm.propTypes = {
    version: PropTypes.string,
    chatID: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]).isRequired,
    send: PropTypes.func.isRequired,
};

ChatFileForm.defaultProps = {
    version: 'file',
};

const mapStateToProps = (state) => {
    const { currentChat } = state;

    return {
        chatID: currentChat.chatID,
    };
};

const mapDispatchToProps = (dispatch) => ({
    send: (id, file) => dispatch(sendFile(id, file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatFileForm);
