import React from 'react';

import './styles.scss';
import { NavLink, useParams } from 'react-router-dom';
import translations from 'services/translations';

const UserDetailsNav = () => {
    const { page, user } = useParams();

    return (
        <div className="UserDetailsNav">
            <NavLink
                className="UserDetailsNav__single"
                to={`/users/list/${page}/${user}`}
                exact
            >
                {translations('front.users.nav.user')}
            </NavLink>
            <NavLink
                className="UserDetailsNav__single"
                to={`/users/list/${page}/${user}/privacy`}
                exact
            >
                {translations('front.users.nav.privacy')}
            </NavLink>
            <NavLink
                className="UserDetailsNav__single"
                to={`/users/list/${page}/${user}/edit`}
                exact
            >
                {translations('front.users.nav.edition')}
            </NavLink>
        </div>
    );
};

export default UserDetailsNav;
