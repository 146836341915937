import React from 'react';
import PropTypes from 'prop-types';
import translations from 'services/translations';
import './styles.scss';
import { getLoadString } from 'services';

const PassingDataSingleLoad = ({
    load, index, isAdr
}) => {
    if (isAdr) {
        const adrValues = [];
        if (load.adr && load.adr.length) {
            adrValues.push(load.adr);
        }
        if (load.number_un && load.number_un.length) {
            adrValues.push(load.number_un);
        }
        if (load.class && load.class.length) {
            adrValues.push(load.class);
        }
        if (load.packing_group && load.packing_group.length) {
            adrValues.push(load.packing_group);
        }
        return (
            <div className="PassingDataSingleLoad is_adr">
                <div className="PassingDataSingleLoad__title">ADR</div>
                <div className="PassingDataSingleLoad__values">
                    {adrValues.join(', ')}
                </div>
            </div>
        );
    }

    return (
        <div className="PassingDataSingleLoad">
            <div className="PassingDataSingleLoad__title">
                {index}
                .
                {' '}
                {load.load_no && load.load_no.length ? load.load_no : translations('front.passing.single.nav.load')}
            </div>
            <div className="PassingDataSingleLoad__values">
                {getLoadString(load)}
            </div>
        </div>
    );
};

PassingDataSingleLoad.propTypes = {
    load: PropTypes.oneOfType([
        PropTypes.shape({
            amount: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            company_id: PropTypes.number,
            created_at: PropTypes.string,
            id: PropTypes.number,
            load_no: PropTypes.string,
            load_type: PropTypes.string,
            package_type: PropTypes.string,
            pallet_id: PropTypes.string,
            passing_id: PropTypes.number,
            updated_at: PropTypes.string,
            volume: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            weight: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
        PropTypes.shape({
            adr: PropTypes.string,
            class: PropTypes.string,
            company_id: PropTypes.number,
            created_at: PropTypes.string,
            id: PropTypes.number,
            number_un: PropTypes.string,
            packing_group: PropTypes.string,
            passing_id: PropTypes.number,
            updated_at: PropTypes.string,
        })
    ]).isRequired,
    index: PropTypes.number.isRequired,
    isAdr: PropTypes.bool,
};

PassingDataSingleLoad.defaultProps = {
    isAdr: false,
};

export default PassingDataSingleLoad;
