import React, {
    useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {
    DirectionsRenderer,
    GoogleMap, Marker
} from '@react-google-maps/api';
import { connect } from 'react-redux';
import getMapStyles from 'services/mapStyles';
import findFromAndTo from './findFromAndTo';


const directionsCallback = (setDirectionsResponse, res) => {
    if (res !== null && res.status === 'OK') {
        setDirectionsResponse(res);
    } else {
        setDirectionsResponse(null);
    }
};

const getDirectionsService = (from, to, callback) => {
    if (!from || !to) {
        return;
    }
    if (typeof window.google === 'undefined') {
        return;
    }
    try {
        const directionsService = new window.google.maps.DirectionsService();
        if (!directionsService) {
            return;
        }

        directionsService.route({
            origin: `${from.lat},${from.lng}`,
            destination: `${to.lat},${to.lng}`,
            travelMode: 'DRIVING',
        }, callback);
    } catch (e) {
        console.error(e);
    }
};


const HomeMap = ({
    positions, activePassing, passings,
}) => {
    const [map, setMap] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const containerStyle = {
        width: '100%',
        height: '100%'
    };
    const currentPassing = useMemo(() => passings.find(
        (elm) => elm.id === activePassing
    ), [passings, activePassing]);
    const { from, to } = useMemo(() => findFromAndTo(currentPassing), [currentPassing]);
    const styles = getMapStyles();
    const options = {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles
    };

    useEffect(() => {
        getDirectionsService(from, to, (res) => directionsCallback(setDirectionsResponse, res));
    }, [from, to]);

    useEffect(() => {
        if (!map || !from || !to) return;

        try {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng(from));
            bounds.extend(new window.google.maps.LatLng(to));

            map.fitBounds(bounds);
        } catch (e) {
            console.error(e);
        }
    }, [map, from, to, directionsResponse]);

    const markers = (positions && positions.length > 0) ? positions.map((elm) => (
        <Marker
            position={{
                lat: elm.lat,
                lng: elm.lng,
            }}
            key={`${elm.lat}-${elm.lng}-${elm.id}`}
            icon={{
                url: `https://www.transassist.eu/_inc/images/${elm.status}.png`,
                size: { width: 46, height: 50 },
            }}
        />
    )) : '';


    const center = {
        lat: 48.131362,
        lng: 4.1664429
    };
    const mapProps = ((from && to) || positions.length > 0) ? {
        defaultCenter: center
    } : {
        center,
    };

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            defaultZoom={3}
            zoom={3}
            options={options}
            onLoad={setMap}
            {...mapProps}
        >
            { /* Child components, such as markers, info windows, etc. */}
            <>
                {positions.length > 0 && markers}
                {from && (
                    <Marker
                        position={{
                            lat: from.lat,
                            lng: from.lng,
                        }}
                        icon={{
                            url: 'https://www.transassist.eu/_inc/images/from.png',
                            anchor: { x: 23, y: 40 },
                            size: { width: 46, height: 50 },
                        }}
                    />
                )}
                {to && (
                    <Marker
                        position={{
                            lat: to.lat,
                            lng: to.lng,
                        }}
                        icon={{
                            url: 'https://www.transassist.eu/_inc/images/to.png',
                            anchor: { x: 23, y: 40 },
                            size: { width: 46, height: 50 },
                        }}
                    />
                )}
                {directionsResponse && (
                    <DirectionsRenderer
                        options={{
                            directions: directionsResponse,
                            suppressMarkers: true,
                            preserveViewport: true,
                        }}
                    />
                )}
            </>
        </GoogleMap>
    );
};

HomeMap.propTypes = {
    passings: PropTypes.arrayOf(PropTypes.object).isRequired,
    positions: PropTypes.arrayOf(PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
    })).isRequired,
    activePassing: PropTypes.number.isRequired,
};


const mapStateToProps = (state) => {
    const { home } = state;
    const {
        loading,
        error,
        passings,
        activePassing,
        positions,
    } = home;

    return {
        loading,
        error,
        passings,
        activePassing,
        positions,
    };
};

export default connect(mapStateToProps, null)(HomeMap);
