const translations = {
    loading: 'Зареждане',
    loading_data: 'Данни зареждане ...',
    validate_email: 'Това не е правилният имейл адрес',
    validate_required: 'Това поле е задължително',
    validate_the_same: 'Двете пароли трябва да са еднакви',
    sign_in: 'Впиши се',
    sign_up__invitation: 'Не имате ли сметка?',
    sign_up: 'Регистрирам!',
    forgot_password: 'Не си спомняте ли паролата?',
    error_occurred: 'Възникна грешка!',
    activation_token_broken: 'Активиращият знак е неправилен.',
    return_to_login: 'Връщане към влизане',
    activation_success: 'Благодаря ти! Сега профилът ви е активен.',
    forgot_password_submit: 'Нулиране на паролата',
    password: 'Парола',
    password_confirmation: 'Повтори паролата',
    email: 'Електронна поща',
    remember_me: 'Помни ме',
    welcome: 'Добре дошли!',
    log_in_to_access: 'Влезте, за да започнете',
    password_forgot_message: 'Не можете ли да влезете? Въведете имейл адреса си, за да възстановите паролата си!',
    back_to: 'връщам се',
    sign_in_page: 'Влизам',
    select: 'Избирам...',
    select_new: 'Създаване:',
    invitation_sent: 'Поканата е изпратена',
    users_custom_company_data: 'Използвайте други фирмени данни',
    or_just_attachment_name: 'Или просто добавете името на прикачения файл:',
    other_docs: 'Други документи:',
    readBy: 'Прочетено от:',
};
export default translations;
