import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';
import {
    Route, Switch, useHistory, useParams
} from 'react-router-dom';
import {
    transportDraftAdr,
    transportDraftCarrier, transportDraftComments, transportDraftContractor,
    transportDraftDifferentReceiver, transportDraftDifferentSender,
    transportDraftDriverOption, transportDraftExtendedLoad,
    transportDraftLoad, transportDraftLoadSummary,
    transportDraftPushDocument, transportDraftPushLoad,
    transportDraftReceiver, transportDraftReceivingPoint,
    transportDraftSender,
    transportDraftStartingPoint, transportDraftUnload,
    transportDraftUpdate, transportDraftValid,
} from 'data/transportDraft/actions';
import { API, errorHandler } from 'services';
import TransportDestinationForm from './components/TransportDestinationForm';
import TransportAddMenu from './components/TransportAddMenu';
import TransportAddSwitcher from './components/TransportAddSwitcher';
import TransportDriverForm from './components/TransportDriverForm';
import TransportShipment from './components/TransportShipment';
import TransportAccept from './components/TransportAccept';
import TransportFileForm from './components/TransportFileForm';
import TransportLoad from './components/TransportLoad';
import PassingList from '../Passing/components/PassingList';
import TransportAdr from './components/TransportAdr';
import TransportLoadComments from './components/TransportLoadComments';
import { getLastDestinations } from '../../data/lastDestinations/actions';
import Delayed from '../../components/Delayed';


const TransportAdd = ({
    edit, setDraft, setSender, setStarting,
    driver, getDestinations,
    loadPush, unloadPush,
    setSenderDifferent, setReceiverDifferent,
    setReceiver, setReceiving, setValid, setLoads,
    setDriver, setDriverOption, setContractors, setExtended, setAdr, pushFile,
    setLoadSummary, setComments,
}) => {
    const [transport, setTransport] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const wasEdit = useRef(edit);
    const history = useHistory();
    const { id } = useParams();

    const handleExit = useCallback(() => {
        let storedDefaults = localStorage.getItem('transport-draft');
        try {
            storedDefaults = JSON.parse(storedDefaults);
        } catch {
            storedDefaults = false;
        }
        if (storedDefaults !== false && storedDefaults !== null) {
            localStorage.setItem('transport-draft', JSON.stringify({ ...storedDefaults, documents: [] }));
        }
    }, []);

    const getTransport = () => {
        const includes = [
            'startingAddress',
            'destinationAddress',
            'sender',
            'receiver',
            // 'company',
            // 'createdBy',
            'passingLoads',
            // 'payments',
            'carriers',
            // 'signatures',
            'additionalDocs',
            'invitations',
            'adrLoads',
            'currentCarrier',
        ];
        const includeString = `include=${includes.join(',')}`;
        setLoading(true);

        API.get(`/passings/${id}?${includeString}`)
            .then((response) => {
                if (response.status === 200) {
                    setTransport(response.data.data);
                    const responseData = response.data.data;
                    const { sender, receiver } = responseData;

                    if (sender) {
                        setSender(sender);
                        setValid('sender');
                    }
                    if (responseData.starting_address) {
                        setStarting(responseData.starting_address);
                        setSenderDifferent(true);
                    } else {
                        setSenderDifferent(false);
                    }

                    if (receiver) {
                        setReceiver(receiver);
                        setValid('receiver');
                    }
                    if (responseData.destination_address) {
                        setReceiving(responseData.destination_address);
                        setReceiverDifferent(true);
                    } else {
                        setReceiverDifferent(false);
                    }
                    if (responseData.load_time_start || responseData.load_time_end) {
                        loadPush({
                            load_time_start: responseData.load_time_start || '',
                            load_time_end: responseData.load_time_end || '',
                        });
                    }
                    if (responseData.unload_time_start || responseData.unload_time_end) {
                        unloadPush({
                            unload_time_start: responseData.unload_time_start || '',
                            unload_time_end: responseData.unload_time_end || '',
                        });
                    }
                    let isOutside = false;
                    if (responseData?.invitations?.length > 0) {
                        isOutside = true;
                    } else if (responseData?.carriers?.length > 0) {
                        if (responseData.company_id
                            // eslint-disable-next-line camelcase
                            !== responseData.carriers[0]?.company_id) {
                            isOutside = true;
                        }
                    }
                    if (isOutside) {
                        setDriverOption(3);
                        if (responseData.invitations && responseData.invitations.length) {
                            let invitationName = '';
                            try {
                                // eslint-disable-next-line camelcase
                                if (responseData.invitations[0]?.to_company_relation?.name.length) {
                                    invitationName = responseData
                                        .invitations[0].to_company_relation.name;
                                }
                            } catch (e) {
                                // eslint-disable-next-line no-console
                                console.error(e);
                            }
                            setContractors({
                                email: responseData.invitations[0].to_email,
                                name: invitationName,
                                avatar: '',
                            });
                        } else {
                            let invitationName = '';
                            try {
                                // eslint-disable-next-line camelcase
                                if (responseData.current_carrier?.name.length) {
                                    invitationName = responseData.current_carrier?.name;
                                }
                            } catch (e) {
                                // eslint-disable-next-line no-console
                                console.error(e);
                            }
                            setContractors({
                                email: responseData.current_carrier.email,
                                name: invitationName,
                                avatar: '',
                            });
                        }
                    } else if (responseData.carriers && responseData.carriers.length) {
                        setDriver(responseData.carriers[0]);
                        setDriverOption(2);
                    }
                    // eslint-disable-next-line camelcase
                    if (responseData?.passing_loads?.length) {
                        responseData.passing_loads.forEach((load) => {
                            setLoads(load);
                        });
                        setExtended();
                    }
                    // eslint-disable-next-line camelcase
                    if (responseData?.adr_loads?.length) {
                        setAdr({
                            adr: responseData.adr_loads[0].adr,
                            un: responseData.adr_loads[0].number_un,
                            group: responseData.adr_loads[0].packing_group,
                            class: responseData.adr_loads[0].class,
                        });
                    }
                    // eslint-disable-next-line camelcase
                    if (responseData?.additional_docs?.length) {
                        responseData.additional_docs.forEach((doc) => {
                            pushFile(doc);
                        });
                    }

                    // eslint-disable-next-line camelcase
                    if (responseData?.load_summary_description?.length) {
                        setLoadSummary(responseData.load_summary_description);
                    }

                    // eslint-disable-next-line camelcase
                    if (responseData?.sender_instructions?.length
                    // eslint-disable-next-line camelcase
                        || responseData?.special_provisions?.length) {
                        setComments({
                            // eslint-disable-next-line camelcase
                            senderInstructions: responseData?.sender_instructions?.length ? responseData.sender_instructions : '',
                            // eslint-disable-next-line camelcase
                            specialProvisions: responseData?.special_provisions?.length ? responseData.special_provisions : '',
                        });
                    }
                }

                setIsReady(true);
                setLoading(false);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setIsReady(true);
                    setLoading(false);
                    setError(err);
                });
            });
    };


    /**
     * Reload the page if redirected from edit to another edit
     *
     */
    useEffect(() => {
        if (edit !== wasEdit.current) {
            window.location.reload(false);
        }
    }, [edit]);


    /**
     * Get destinations
     * Get transport details if is EDIT
     */
    useEffect(() => {
        if (edit && id) {
            setDraft(false);
            getTransport();
        } else if (!edit) {
            let storedDefaults = localStorage.getItem('transport-draft');
            try {
                storedDefaults = JSON.parse(storedDefaults);
            } catch {
                storedDefaults = false;
            }
            if (!storedDefaults) {
                setDraft(false);
            } else {
                setDraft(storedDefaults);
            }
            setIsReady(true);
        }
        getDestinations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, edit]);

    useEffect(() => {
        if (transport && transport.accesses && transport.accesses.length) {
            let canEdit = false;
            transport.accesses.forEach((access) => {
                if (access.access_type === 'owner') {
                    canEdit = true;
                }
            });

            if (!canEdit) {
                history.push(`/transports/single/${transport.id}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transport]);

    useEffect(() => {
        window.addEventListener('beforeunload', handleExit);
        return () => {
            window.removeEventListener('beforeunload', handleExit);
        };
    }, [handleExit]);

    if (loading) {
        return <div className="TransportAdd isLoading"><SmallLoader dark /></div>;
    }

    if (error) {
        return <div className="TransportAdd"><Error errors={error} /></div>;
    }

    if (edit && typeof transport?.id === 'undefined') {
        return <></>;
    }

    return (
        <div className="TransportAdd">
            <div className="TransportAdd__container">
                <TransportAddMenu edit={edit && id ? id : false} />
                {
                    isReady && (
                        <Switch>
                            <Route path={['/transports/single/:id/edit/from-company', '/transports/add/from-company']}>
                                <div className="TransportAdd__card with-address">
                                    <TransportDestinationForm key="transport-add-destination-from" edit={edit && !!id} />
                                </div>
                            </Route>
                            <Route path={['/transports/single/:id/edit/to-company', '/transports/add/to-company']}>
                                <div className="TransportAdd__card with-address">
                                    <TransportDestinationForm key="transport-add-destination-to" edit={edit && !!id} to />
                                </div>
                            </Route>
                            <Route path={['/transports/single/:id/edit/load', '/transports/add/load']}>
                                <TransportLoad />
                                <TransportAdr />
                                <TransportFileForm />
                                <TransportLoadComments />
                            </Route>
                            <Route path={['/transports/single/:id/edit/driver', '/transports/add/driver']}>
                                <div>
                                    {driver === 1 && <TransportAddSwitcher />}
                                    {driver === 2 && <TransportDriverForm />}
                                    {driver === 3 && <TransportShipment />}
                                </div>
                            </Route>
                            <Route path={['/transports/single/:id/edit/accept', '/transports/add/accept']}>
                                <Delayed><TransportAccept edit={edit && !!id} /></Delayed>
                            </Route>
                        </Switch>
                    )
                }
            </div>
            <PassingList />
        </div>
    );
};

TransportAdd.propTypes = {
    edit: PropTypes.bool,
    setDraft: PropTypes.func.isRequired,
    setSender: PropTypes.func.isRequired,
    setReceiver: PropTypes.func.isRequired,
    setStarting: PropTypes.func.isRequired,
    setReceiving: PropTypes.func.isRequired,
    loadPush: PropTypes.func.isRequired,
    unloadPush: PropTypes.func.isRequired,
    getDestinations: PropTypes.func.isRequired,
    setReceiverDifferent: PropTypes.func.isRequired,
    setSenderDifferent: PropTypes.func.isRequired,
    setDriver: PropTypes.func.isRequired,
    setDriverOption: PropTypes.func.isRequired,
    setValid: PropTypes.func.isRequired,
    setContractors: PropTypes.func.isRequired,
    setLoads: PropTypes.func.isRequired,
    setExtended: PropTypes.func.isRequired,
    pushFile: PropTypes.func.isRequired,
    setAdr: PropTypes.func.isRequired,
    driver: PropTypes.number.isRequired,
    setLoadSummary: PropTypes.func.isRequired,
    setComments: PropTypes.func.isRequired,
};

TransportAdd.defaultProps = {
    edit: false,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const { driver } = transportDraft;

    return {
        driver,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setDraft: (data) => dispatch(transportDraftUpdate(data)),
    setSender: (values) => dispatch(transportDraftSender(values, true)),
    setReceiver: (values) => dispatch(transportDraftReceiver(values, true)),
    setStarting: (values) => dispatch(transportDraftStartingPoint(values, true)),
    setReceiving: (values) => dispatch(transportDraftReceivingPoint(values, true)),
    setReceiverDifferent: (val) => dispatch(transportDraftDifferentReceiver(val, true)),
    setSenderDifferent: (val) => dispatch(transportDraftDifferentSender(val, true)),
    loadPush: (values) => dispatch(transportDraftLoad(values, true)),
    unloadPush: (values) => dispatch(transportDraftUnload(values, true)),
    getDestinations: () => dispatch(getLastDestinations()),
    setDriver: (values, edit = true) => dispatch(transportDraftCarrier(values, edit)),
    setDriverOption: (version, edit = true) => dispatch(transportDraftDriverOption(version, edit)),
    setValid: (version) => dispatch(transportDraftValid(true, version, true)),
    setContractors: (payload, edit = true) => dispatch(transportDraftContractor(payload, edit)),
    setLoads: (values, isEdit = true) => dispatch(transportDraftPushLoad(values, isEdit)),
    setExtended: (edit = true) => dispatch(transportDraftExtendedLoad(true, edit)),
    setAdr: (values, isEdit = true) => dispatch(transportDraftAdr(values, isEdit)),
    pushFile: (document, isEdit = true) => dispatch(transportDraftPushDocument(document, isEdit)),
    setLoadSummary: (summary, ed = true) => dispatch(transportDraftLoadSummary(summary, ed)),
    setComments: (comments, ed = true) => dispatch(transportDraftComments(comments, ed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportAdd);
