import { isDefined } from '../index';

const getLoadString = (load) => {
    const valueCheck = (v) => (isDefined(v) && v !== null && `${v}`.length > 0);


    const loadValues = [];
    if (valueCheck(load.amount)) {
        loadValues.push(load.amount);
    }
    if (valueCheck(load.package_type)) {
        loadValues.push(load.package_type);
    }
    if (valueCheck(load.load_type)) {
        loadValues.push(load.load_type);
    }
    if (valueCheck(load.pallet_id)) {
        loadValues.push(load.pallet_id);
    }
    if (valueCheck(load.weight)) {
        try {
            loadValues.push(`${new Intl.NumberFormat().format(load.weight)}kg`);
        } catch (e) {
            console.error(e);
        }
    }
    if (valueCheck(load.volume)) {
        try {
            loadValues.push(`${new Intl.NumberFormat().format(load.volume)}m³`);
        } catch (e) {
            console.error(e);
        }
    }

    if (loadValues.length === 0) {
        return '';
    }

    return loadValues.join(', ');
};

export default getLoadString;
