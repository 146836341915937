import { useEffect, useState } from 'react';
import { API, errorHandler } from 'services';
import querystring from 'querystring';
import {
    TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_UPDATE
} from '../submissionStatuses';

const useTransportUpdateLoad = (status, loads, transport = false) => {
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (status === TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_UPDATE
            // eslint-disable-next-line camelcase
            && transport?.id && transport?.passing_loads?.length > 0) {
            transport.passing_loads.forEach((load) => {
                let shouldUpdate = false;
                if (loads.length > 0) {
                    shouldUpdate = loads.findIndex((elm) => elm.id === load.id);
                }

                if (shouldUpdate !== false && shouldUpdate > -1) {
                    setIsLoading((v) => v + 1);
                    const dataToUpdate = {
                        ...loads[shouldUpdate]
                    };
                    delete dataToUpdate.id;
                    API.put(`/passings/${transport.id}/loads/${load.id}`, querystring.stringify(dataToUpdate))
                        .then(() => {
                            setIsLoading((v) => v - 1);
                        })
                        .catch((err) => {
                            errorHandler(err, () => {
                                setIsLoading((v) => v - 1);
                                setError(err);
                            });
                        });
                }
            });
        }
    }, [status, loads, transport]);

    return { isLoading: isLoading > 0, error };
};

export default useTransportUpdateLoad;
