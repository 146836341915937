import React, {
    useState, useRef, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { translations, API, errorHandler } from 'services';
import './styles.scss';
import Button from 'components/Button';
import Error from 'components/Error';
import SmallLoader from 'components/SmallLoader';
import { getUser } from 'data/user/actions';
import { CancelToken } from 'axios';

const SettingsAvatar = ({ getCurrentUser, companyFallback }) => {
    const cancelToken = useRef(null);
    const [upImg, setUpImg] = useState();
    const [show, setShow] = useState(false);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const btnDisabled = !completedCrop?.width || !completedCrop?.height;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setShow(true);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const cpp = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = cpp.width * pixelRatio;
        canvas.height = cpp.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            cpp.x * scaleX,
            cpp.y * scaleY,
            cpp.width * scaleX,
            cpp.height * scaleY,
            0,
            0,
            cpp.width,
            cpp.height
        );
    }, [completedCrop]);

    const cancel = () => {
        setIsLoading(false);
        setUpImg(undefined);
        setCompletedCrop(null);
        setShow(false);
    };

    const handleChange = (canvas) => {
        if (!canvas) {
            return;
        }

        canvas.toBlob(
            (blob) => {
                setIsLoading(true);

                const settings = {
                    fieldName: 'avatar',
                    endpoint: '/me',
                };

                if (companyFallback !== false) {
                    settings.fieldName = 'logo';
                    settings.endpoint = '/my-company';
                }


                const body = new FormData();
                body.append('_method', 'put');
                body.append(settings.fieldName, blob, '123.png');
                cancelToken.current = CancelToken.source();


                API.post(settings.endpoint, body, {
                    cancelToken: cancelToken.current.token,
                })
                    .then(() => {
                        setIsLoading(false);
                        setUpImg(undefined);
                        setCompletedCrop(null);
                        setShow(false);
                        if (companyFallback !== false) {
                            companyFallback();
                        } else {
                            getCurrentUser();
                        }
                    })
                    .catch((err) => {
                        errorHandler(err, () => {
                            setIsLoading(false);
                            setError(err);
                        });
                    });
            },
            'image/png',
            1
        );
    };

    if (isLoading) {
        return <div className="loading-wrapper" style={{ height: 'auto', paddingTop: '10px' }}><SmallLoader dark /></div>;
    }

    return (
        <div className="SettingsAvatar">
            {error && <Error errors={error} />}
            {!show && (
                <div className="SettingsAvatar__form">
                    <label
                        htmlFor="settings-avatar-file-input"
                        className="SettingsAvatar__form--label"
                    >
                        {translations(companyFallback !== false ? 'front.settings.company.change_logo' : 'user.change_photo')}
                    </label>
                    <input
                        id="settings-avatar-file-input"
                        type="file"
                        accept="image/*"
                        className="SettingsAvatar__form--input"
                        onChange={onSelectFile}
                    />
                </div>
            )}
            {(show) && (
                <>
                    <div className="SettingsAvatar__crop">
                        <div className="SettingsAvatar__crop--crop">
                            <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                                minWidth={80}
                                minHeight={80}
                                keepSelection
                                imageStyle={{
                                    width: '320px',
                                    height: 'auto',
                                }}
                            />
                        </div>
                        <div className="SettingsAvatar__crop--result" style={{ display: 'none' }}>
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    width: Math.round(completedCrop?.width ?? 0),
                                    height: Math.round(completedCrop?.height ?? 0)
                                }}
                            />
                        </div>
                        <div className="SettingsAvatar__btn">
                            <Button
                                block
                                disabled={btnDisabled}
                                onClick={() => { handleChange(previewCanvasRef.current); }}
                                type="button"
                            >
                                {translations('front.settings.crop-btn')}
                            </Button>
                            <Button
                                block
                                disabled={btnDisabled}
                                onClick={() => { cancel(); }}
                                theme="outline"
                                type="button"
                            >
                                {translations('front.general.cancel')}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

SettingsAvatar.propTypes = {
    getCurrentUser: PropTypes.func.isRequired,
    companyFallback: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool
    ]),
};

SettingsAvatar.defaultProps = {
    companyFallback: false,
};

const mapDispatchToProps = (dispatch) => ({
    getCurrentUser: () => dispatch(getUser(false)),
});

export default connect(null, mapDispatchToProps)(SettingsAvatar);
