import { API, getMomentLocale } from 'services';
import querystring from 'querystring';
import userHandler from 'services/userHandler';
import Cookies from 'js-cookie';
import Moment from 'moment';


import {
    USER_SUCCESS,
    USER_REQUEST,
    USER_ERROR,
    USER_LOGOUT,
    USER_LOG_IN_REQUEST,
    USER_NIP,
    USER_NIP_MESSAGE,
    SET_USER_NOT_LOGGED_IN,
    ACTIVATION_SUCCESS,
    ACTIVATION_ERROR,
    ACTIVATION_REQUEST,
} from './constants';

const cookiesSettings = {
    secure: window.location.hostname !== 'localhost',
    domain: window.location.hostname !== 'localhost' ? 'transassist.eu' : 'localhost',
    sameSite: 'strict',
};

export function startUserRequest() {
    return { type: USER_REQUEST };
}

export function startActivationRequest() {
    return { type: ACTIVATION_REQUEST };
}

export function startLogInRequest() {
    return { type: USER_LOG_IN_REQUEST };
}

export function userSuccess(payload, permissions = []) {
    return { type: USER_SUCCESS, payload, permissions };
}

export function userError(payload) {
    return { type: USER_ERROR, payload };
}

export function setUserNotLoggedIn(payload) {
    return { type: SET_USER_NOT_LOGGED_IN, payload };
}

export function userActivated() {
    return { type: ACTIVATION_SUCCESS };
}

export function activationError(payload) {
    return { type: ACTIVATION_ERROR, payload };
}

export function userLogout() {
    return { type: USER_LOGOUT };
}

export function setUserNip(payload) {
    return { type: USER_NIP, payload };
}

export function setUserNipMessage(payload) {
    return { type: USER_NIP_MESSAGE, payload };
}

export function getUser(checkToken = true) {
    return (dispatch) => {
        dispatch(startUserRequest());

        if (checkToken) {
            const token = Cookies.get('token');
            if (!token) {
                return dispatch(setUserNotLoggedIn());
            }

            API.defaults.headers.Authorization = `Bearer ${token}`;
        }

        return API.get('me').then((response) => {
            if (response.status !== 200) {
                return dispatch(userError('error'));
            }
            if (response.data.data.company_nip) {
                dispatch(setUserNip(response.data.data.company_nip));
            }
            userHandler(response);
            const permissions = response.data.data.permissions
                ? response.data.data.permissions : [];

            return dispatch(userSuccess(response.data.data.user, permissions));
        }).catch((error) => dispatch(userError(error)));
    };
}

export function setUser(user) {
    return (dispatch) => dispatch(userSuccess(user, false));
}

export function logIn(email, password, remember = false) {
    return (dispatch) => {
        dispatch(startLogInRequest());
        const authData = {
            username: email,
            password,
            grant_type: 'password',
            client_secret: 'p3TCxwCoTaeErbmQpBdvzSNXFvTSNOMfltliSDfX',
            client_id: '3',
            scope: '*',
        };

        const cookiesSettingsToUse = { ...cookiesSettings };

        if (remember) {
            cookiesSettingsToUse.expires = 730;
        }

        const lang = localStorage.getItem('language') ?? 'en';
        return API.post(`${lang}/first-party/token`, querystring.stringify(authData))
            .then((response) => {
                if (response.status !== 200) {
                    return dispatch(userError(response));
                }
                const token = response.data.access_token;
                Cookies.set('token', token, cookiesSettingsToUse);
                localStorage.setItem('language', response.data.user_data.language);
                try {
                    Moment.locale(getMomentLocale(response.data.user_data.language));
                } catch (e) {
                    console.error(e);
                }
                document.documentElement.lang = response.data.user_data.language;
                API.defaults.headers.Authorization = `Bearer ${token}`;

                return dispatch(getUser(false));
            })
            .catch((error) => dispatch(userError(error)));
    };
}

export function logOut() {
    return (dispatch) => API.post('/logout')
        .then(() => {
            delete API.defaults.headers.Authorization;
            // localStorage.removeItem('token');
            Cookies.remove('token', cookiesSettings);
            localStorage.removeItem('active_chat');
            localStorage.removeItem('user_id');
            localStorage.removeItem('transport-draft');

            return dispatch(userLogout());
        })
        .catch((error) => dispatch(userError(error)));
}

export function activateUser(token = null) {
    return (dispatch) => {
        if (!token) {
            return dispatch(activationError());
        }
        Cookies.remove('token', cookiesSettings);
        dispatch(startActivationRequest());

        const lang = localStorage.getItem('language') ?? 'en';
        return API.get(`/${lang}/guest/verify_email/${token}`)
            .then((response) => {
                if (typeof response.data.access_token !== 'undefined') {
                    const accessToken = response.data.access_token;
                    Cookies.set('token', accessToken, cookiesSettings);
                    API.defaults.headers.Authorization = `Bearer ${accessToken}`;
                    dispatch(userActivated());
                    return dispatch(getUser(false));
                }
                return dispatch(userActivated());
            })
            .catch((error) => {
                console.error(error.message);
                return dispatch(activationError(error));
            });
    };
}
