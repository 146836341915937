import Yup from 'services/translatedYup';
import { phoneValidator } from 'services';
import moment from 'moment';

const emailValidationSchema = Yup.string().test(
    'is-email',
    'Invalid email address',
    (value) => {
        if (!value) return true;
        const emails = value.split(',').map((email) => email.trim());
        return emails.every((email) =>
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email));
    }
);

const pointValidation = {
    name: Yup.string().required(),
    email: emailValidationSchema,
    phone: Yup.string().nullable().test('phone', (value) => phoneValidator(value, true)),
    address_country_id: Yup.number().required().positive().integer(),
    address_postcode: Yup.string().required().min(2),
    address_city: Yup.string().required().min(2),
    address_street: Yup.string().required().min(2),
    address_housenumber: Yup.string(),
    additional_info: Yup.string().max(250).nullable(),
};


export default Yup.object().shape({
    company: Yup.object().shape(pointValidation),
    timeStart: Yup.date().required(),
    timeEnd: Yup.date().when(
        'timeStart',
        (timeStart, schema) => {
            try {
                return timeStart && schema.min(moment(timeStart).add(1, 'minutes').toDate());
            } catch (e) {
                return schema;
            }
        }
    ),
    differentPoint: Yup.boolean(),
    point: Yup.object().when('differentPoint', {
        is: (differentPoint) => !!differentPoint,
        then: Yup.object().shape(pointValidation),
    }),
});
