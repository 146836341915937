import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translations, classNames } from 'services';
import './style.scss';
import Moment from 'moment';
import SvgCreator from 'components/SvgCreator';
import PassingSignaturePassword from '../PassingSignaturePassword';

const PassingSignature = ({
    type, isSigned, downloadUrl, click, opened,
    name, pw, closePassword, formRef, accesses, invitations,
    carriers, companyID,
}) => {
    const headerTransKey = `passing.${type === 'driver' ? 'carrier' : type}_signature`;
    const trans = {
        header: translations(headerTransKey),
        status: translations('signatures.status'),
        success_status: translations('signatures.success_status'),
        wait_status: translations('signatures.wait_status'),
    };

    let canSign = false;
    if (accesses.length) {
        accesses.forEach((access) => {
            if (access.access_type === 'destination_address' && type === 'receiver') {
                canSign = true;
            }
            if (access.access_type === 'starting_address' && type === 'sender') {
                canSign = true;
            }
            try {
                if (access.access_type === 'owner') {
                    if (carriers.length === 0 && typeof invitations[0] === 'undefined') {
                        canSign = true;
                    } else if (carriers.length) {
                        if (typeof carriers[0].company_id !== 'undefined' && carriers[0].company_id === companyID) {
                            canSign = true;
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }

            if (access.access_type === 'driver' || access.access_type === 'carrier') {
                canSign = true;
            }
        });
    }

    const setFocus = () => {
        const $element = document.getElementById('signature-name-field');
        const $elementCity = document.getElementById('signature-city-field');

        if ($element && $elementCity) {
            if ($element.value && $element.value.length) {
                $elementCity.focus();
            } else {
                $element.focus();
            }
        }
    };

    return (
        <div className={classNames('PassingSignature', isSigned && 'signed')}>
            {isSigned && (
                <a
                    href={downloadUrl}
                    className="PassingSignature__btn"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="PassingSignature__btn--title">{trans.header}</span>
                    <span className="PassingSignature__btn--date">{trans.success_status}</span>
                </a>
            )}
            {!isSigned && (
                <>
                    {opened === type && (
                        <>
                            <div className="PassingSignature__btn disabled">
                                <div className="PassingSignature__btn--column">
                                    <span className="PassingSignature__btn--title">{trans.header}</span>
                                    <span className="PassingSignature__btn--date">{trans.status}</span>
                                </div>
                                <div className="PassingSignature__btn--column">
                                    <input
                                        id="signature-name-field"
                                        type="text"
                                        defaultValue={name}
                                        placeholder={translations('front.admin.columns.name')}
                                        className="PassingSignature__btn--name"
                                    />
                                    <input
                                        id="signature-city-field"
                                        type="text"
                                        defaultValue=""
                                        placeholder={translations('front.city')}
                                        className="PassingSignature__btn--city"
                                    />
                                    <button
                                        className="PassingSignature__btn--name-btn"
                                        type="button"
                                        onClick={() => { setFocus(); }}
                                    />
                                    <div className="PassingSignature__btn--city">&nbsp;</div>
                                </div>
                                <div className="PassingSignature__btn--column">
                                    <div className="PassingSignature__btn--id">&nbsp;</div>
                                    <div className="PassingSignature__btn--time">{Moment(new Date()).format('lll')}</div>
                                </div>
                            </div>
                            <SvgCreator />
                            {pw
                            && (
                                <PassingSignaturePassword
                                    reference={formRef}
                                    closeHandler={() => { closePassword(); }}
                                />
                            )}
                        </>
                    )}
                    {(opened !== type && canSign) && (
                        <button
                            className="PassingSignature__btn"
                            type="button"
                            onClick={() => {
                                click(type);
                            }}
                        >
                            <span className="PassingSignature__btn--title">{trans.header}</span>
                            <span className="PassingSignature__btn--date">{trans.status}</span>
                        </button>
                    )}
                    {(opened !== type && !canSign) && (
                        <div
                            className="PassingSignature__btn for-readonly"
                        >
                            <span className="PassingSignature__btn--title">{trans.header}</span>
                            <span className="PassingSignature__btn--date">{trans.status}</span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

PassingSignature.propTypes = {
    type: PropTypes.string.isRequired,
    isSigned: PropTypes.bool,
    downloadUrl: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired,
    name: PropTypes.string,
    opened: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]).isRequired,
    pw: PropTypes.bool,
    closePassword: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func,
    ]),
    // eslint-disable-next-line react/forbid-prop-types
    formRef: PropTypes.any,
    accesses: PropTypes.arrayOf(PropTypes.object),
    invitations: PropTypes.arrayOf(PropTypes.object),
    carriers: PropTypes.arrayOf(PropTypes.object),
    companyID: PropTypes.number.isRequired,
};

PassingSignature.defaultProps = {
    isSigned: false,
    name: '',
    pw: false,
    closePassword: false,
    formRef: false,
    accesses: [],
    carriers: [],
    invitations: [],
};

const mapStateToProps = (state) => {
    const { user } = state;
    const companyID = user.user && user.user.company_id ? user.user.company_id : 0;

    return {
        companyID
    };
};

export default connect(mapStateToProps, null)(PassingSignature);
