import { API, getLocale } from 'services';

import {
    LANGUAGES_REQUEST, LANGUAGES_LOADED, LANGUAGES_ERROR,
} from './constants';

export function startLanguagesRequest() {
    return { type: LANGUAGES_REQUEST };
}

export function languagesSuccess(payload) {
    return { type: LANGUAGES_LOADED, payload };
}

export function languagesError(payload) {
    return { type: LANGUAGES_ERROR, payload };
}

export function getLanguages() {
    return (dispatch) => {
        dispatch(startLanguagesRequest());
        return API.get(`/${getLocale()}/language`)
            .then((res) => {
                if (res.data.data && res.data.data.length) {
                    return dispatch(languagesSuccess(res.data.data));
                }
                return dispatch(languagesSuccess([]));
            })
            .catch((err) => (dispatch(languagesError(err))));
    };
}
