import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './styles.scss';
import { translations } from 'services';
import { transportDraftDriverOption } from 'data/transportDraft/actions';

const TransportAddSwitcher = ({ set }) => {
    const copy1 = translations('front.transport-add-switcher.copy1');
    const copy2 = translations('front.transport-add-switcher.copy2');
    const copy3 = translations('front.transport-add-switcher.copy3');
    const copy4 = translations('front.transport-add-switcher.copy4');

    const arrow = (
        <svg className="TransportAddSwitcher__btns--btn-svg" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="TransportAddSwitcher__btns--btn-svg-path" d="M1 4.16064H10.2089" strokeWidth="0.972545" strokeLinecap="round" strokeLinejoin="round" />
            <path className="TransportAddSwitcher__btns--btn-svg-path" d="M7.47559 1L10.6364 4.16077L7.47559 7.32154" strokeWidth="0.972545" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );

    return (
        <div className="TransportAdd__card">
            <div className="TransportAddSwitcher">
                <div className="TransportAddSwitcher__copy">
                    {copy1}
                    {' '}
                    <span className="color-blue">{copy2}</span>
                    {'. '}
                    {copy3}
                    {' '}
                    <span className="color-green">{copy4}</span>
                    .
                </div>
                <div className="TransportAddSwitcher__btns">
                    <button
                        onClick={() => { set(2); }}
                        type="button"
                        className="TransportAddSwitcher__btns--btn transport-in"
                    >
                        <span className="TransportAddSwitcher__btns--btn-copy">{translations('passing.internal_type')}</span>
                        <span className="TransportAddSwitcher__btns--btn-select">
                            {translations('user.select')}
                            {arrow}
                        </span>
                    </button>
                    <button
                        onClick={() => { set(3); }}
                        type="button"
                        className="TransportAddSwitcher__btns--btn transport-out"
                    >
                        <span className="TransportAddSwitcher__btns--btn-copy">{translations('passing.external_type')}</span>
                        <span className="TransportAddSwitcher__btns--btn-select">
                            {translations('user.select')}
                            {arrow}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

TransportAddSwitcher.propTypes = {
    set: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    set: (version) => dispatch(transportDraftDriverOption(version)),
});

export default connect(null, mapDispatchToProps)(TransportAddSwitcher);
