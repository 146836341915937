import { API } from 'services';

import {
    VEHICLES_LOADED, VEHICLES_ERROR, VEHICLES_REQUEST
} from './constants';

export function startVehiclesRequest() {
    return { type: VEHICLES_REQUEST };
}

export function vehiclesSuccess(payload) {
    return { type: VEHICLES_LOADED, payload };
}

export function vehiclesError(payload) {
    return { type: VEHICLES_ERROR, payload };
}

export function getVehicles() {
    return (dispatch) => {
        dispatch(startVehiclesRequest());
        return API.get('/vehicles/types')
            .then((res) => {
                const vehiclesArray = [];
                if (res.data.data && res.data.data.length) {
                    res.data.data.forEach((elm) => {
                        vehiclesArray.push({
                            label: elm.name,
                            value: elm.idstr,
                        });
                    });
                }
                return dispatch(vehiclesSuccess(vehiclesArray));
            })
            .catch((err) => (dispatch(vehiclesError(err))));
    };
}
