import React from 'react';
import PropTypes from 'prop-types';
import {
    GoogleMap, Marker
} from '@react-google-maps/api';
import { getMapStyles } from 'services';

const TransportAddMap = ({ positions }) => {
    const containerStyle = {
        width: '100%',
        height: '280px',
        borderRadius: '20px',
        overflow: 'hidden',
    };

    const showPositions = positions
        && positions.length
        && positions[0].lat !== 999999999 && positions[0].lng !== 999999999;

    const center = showPositions ? {
        lat: positions[0].lat,
        lng: positions[0].lng,
    } : {
        lat: 48.131362,
        lng: 4.1664429
    };

    const styles = getMapStyles();

    const options = {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles
    };

    const markers = showPositions ? positions.map((elm) => (
        <Marker
            position={{
                lat: elm.lat,
                lng: elm.lng,
            }}
            key={`${elm.lat}-${elm.lng}`}
            icon={{
                url: 'https://www.transassist.eu/logo-marker.png',
                size: { width: 42, height: 41 },
            }}
            onLoad={((marker) => {
                if (marker && marker.map) {
                    if (positions && positions.length > 1) {
                        const bounds = new window.google.maps.LatLngBounds();
                        // eslint-disable-next-line array-callback-return
                        positions.map((x) => {
                            bounds.extend(new window.google.maps.LatLng({
                                lat: x.lat,
                                lng: x.lng,
                            }));
                        });
                        marker.map.fitBounds(bounds);
                    }
                }
            })}
        />
    )) : '';

    return (

        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={3}
            options={options}
        >
            { /* Child components, such as markers, info windows, etc. */ }
            <>
                {markers}
            </>
        </GoogleMap>
    );
};

TransportAddMap.propTypes = {
    positions: PropTypes.arrayOf(PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
    })).isRequired,
};

export default TransportAddMap;
