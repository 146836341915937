import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

const ReloadButton = ({ onClick, ...props }) => (
    <button
        onClick={() => {
            if (onClick !== false) {
                onClick();
            } else {
                window.location.reload(false);
            }
        }}
        type="button"
        className="ReloadButton"
        {...props}
    >
        reload
    </button>
);

ReloadButton.propTypes = {
    onClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool,
    ]),
};

ReloadButton.defaultProps = {
    onClick: false,
};

export default ReloadButton;
