const findFromAndTo = (passing) => {
    const getLatAndLng = (address) => {
        if (address.lat && address.lng
            && !Number.isNaN(parseFloat(address.lat)) && !Number.isNaN(parseFloat(address.lng))) {
            return {
                lat: parseFloat(address.lat),
                lng: parseFloat(address.lng),
            };
        }

        return false;
    };
    let from; let
        to;

    if (!passing) {
        return { from, to };
    }

    const {
        starting_address: startingAddress, destination_address: destinationAddress, sender, receiver
    } = passing;

    if (startingAddress && getLatAndLng(startingAddress)) {
        from = getLatAndLng(startingAddress);
    }

    if (!from && sender && getLatAndLng(sender)) {
        from = getLatAndLng(sender);
    }

    if (destinationAddress && getLatAndLng(destinationAddress)) {
        to = getLatAndLng(destinationAddress);
    }

    if (!to && receiver && getLatAndLng(receiver)) {
        to = getLatAndLng(receiver);
    }

    return { from, to };
};

export default findFromAndTo;
