import React from 'react';

import logoImage from 'images/logo.svg';
import signetImage from 'images/sygnet.svg';
import { NavLink } from 'react-router-dom';

const Logo = () => (
    <NavLink exact to="/" style={{ display: 'block' }}>
        <img
            width={143}
            height={36}
            className="Sidebar__logo"
            src={logoImage}
            alt=""
        />
        <img
            width={24}
            height={27}
            className="Sidebar__signet"
            src={signetImage}
            alt=""
        />
    </NavLink>
);
export default Logo;
