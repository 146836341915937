import { useEffect, useState } from 'react';
import querystring from 'querystring';
import { API, errorHandler } from 'services';
import { TRANSPORT_ADD_SUBMISSION_STATUS_INIT } from '../submissionStatuses';

const useTransportInit = (status, data, id = false, isValid = true) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [transport, setTransport] = useState(false);
    const method = id === false ? 'post' : 'put';

    useEffect(() => {
        if (isValid && status === TRANSPORT_ADD_SUBMISSION_STATUS_INIT) {
            setIsLoading(true);
            API[method](`/passings${id !== false ? `/${id}` : ''}`, querystring.stringify(data))
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setTransport(response.data.data);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    errorHandler(err, () => {
                        setIsLoading(false);
                        setError(err);
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return { isLoading, error, transport };
};

export default useTransportInit;
