const translations = {
    loading: 'Läser in',
    loading_data: 'Data loading ...',
    validate_email: 'Det här är inte den korrekta e-postadressen',
    validate_required: 'Detta fält krävs',
    validate_the_same: 'Båda lösenorden måste vara desamma',
    sign_in: 'Logga in',
    sign_up__invitation: 'Har du inte ett konto?',
    sign_up: 'Registrera!',
    forgot_password: 'Kommer du inte ihåg lösenordet?',
    error_occurred: 'Ett fel inträffade!',
    activation_token_broken: 'Aktiveringstoken är felaktigt.',
    return_to_login: 'Återgå till inloggning',
    activation_success: 'Tack! Ditt konto är nu aktivt.',
    forgot_password_submit: 'Återställ lösenordet',
    password: 'Lösenord',
    password_confirmation: 'repetera lösenord',
    email: 'E-post',
    remember_me: 'Kom ihåg mig',
    welcome: 'Välkommen!',
    log_in_to_access: 'Logga in för att starta',
    password_forgot_message: 'Kan du inte logga in? Ange din e-postadress för att återställa ditt lösenord!',
    back_to: 'komma tillbaka till',
    sign_in_page: 'logga in',
    select: 'Välja...',
    select_new: 'Skapa:',
    invitation_sent: 'Inbjudan skickad',
    users_custom_company_data: 'Använd annan företagsdata',
    or_just_attachment_name: 'Eller lägg bara till bilagans namn:',
    other_docs: 'Andra dokument:',
    readBy: 'Läst av:',
};
export default translations;
