import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Login from 'components/Login';
import AuthenticatedWrapper from 'components/AuthenticatedWrapper';
import EnvSwitcher from 'components/EnvSwitcher';

const AppWrapper = ({ isLoggedIn }) => (
    <>
        {
            isLoggedIn ? <AuthenticatedWrapper /> : <Login />
        }
        <EnvSwitcher />
    </>
);


AppWrapper.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        isLoggedIn: user.isLoggedIn,
    };
};

export default connect(mapStateToProps)(AppWrapper);
