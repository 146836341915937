import Moment from 'moment';

export default (date) => {
    const backup = date;
    let toReturn = date;

    if (date.length) {
        try {
            toReturn = new Date(date);
        } catch (e) {
            console.error(e);
            return backup;
        }
    }


    if (Moment(toReturn).isValid()) {
        return toReturn;
    }

    return '';
};
