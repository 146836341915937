import React, { useRef, useState } from 'react';
import './styles.scss';
import LanguageIcon from 'components/LanguageIcon';
import LanguageMenu from 'components/LanguageMenu';
import useOutsideClick from 'services/useOutsideClick';

const LangSwitcher = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const language = localStorage.getItem('language') ?? 'en';
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => { setMenuVisible(false); });

    return (
        <div style={{ position: 'relative' }} ref={wrapperRef}>
            <button
                className="LangSwitcher"
                type="button"
                onClick={() => { setMenuVisible((v) => !v); }}
            >
                <LanguageIcon code={language} />
            </button>
            {menuVisible && <LanguageMenu />}
        </div>
    );
};


export default LangSwitcher;
