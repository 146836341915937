import React, { useEffect, useRef, useState } from 'react';
import { API, errorHandler } from 'services';
import querystring from 'querystring';
import './styles.scss';
import PropTypes from 'prop-types';
import { CancelToken } from 'axios';
import LangSwitcher from 'components/LangSwitcher';
import Error from 'components/Error';
import { NavLink } from 'react-router-dom';
import PasswordForm from './components/PasswordForm';
import Success from '../Success';

const ResetPassword = ({ ...props }) => {
    const cancelToken = useRef(null);
    const { match } = props;
    const { token } = match.params;
    const [isLoading, setLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formSuccess, setFormSucces] = useState('');
    const language = localStorage.getItem('language') ?? 'en';
    const handleSubmit = (values) => {
        setLoading(true);
        setFormSucces('');
        setFormError(false);
        cancelToken.current = CancelToken.source();

        API.post(`${language}/password/reset/${token}`, querystring.stringify(values), {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                if (response.data.message && response.data.message.length) {
                    setFormSucces(response.data.message);
                }
                setLoading(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setFormError(error);
                    setLoading(false);
                });
            });
    };

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    return (
        <div className="ResetPassword">
            <div className="Login__lang">
                <LangSwitcher />
            </div>
            <NavLink to="/">
                <img src="/images/login_logo.svg" className="logo Login__logo" alt="" width={158} height={64} />
            </NavLink>
            {formError && <Error errors={formError} />}
            {(formSuccess && formSuccess.length > 0) && <Success message={formSuccess} />}
            <PasswordForm onSubmit={handleSubmit} isLoading={isLoading} />
        </div>
    );
};


ResetPassword.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired
        })
    }).isRequired,
};

export default ResetPassword;
