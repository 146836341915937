import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

const TransportDriverFormObserver = () => {
    const {
        values, setFieldValue, setFieldTouched, validateField
    } = useFormikContext();

    useEffect(() => {
        if (!values.driver_id) {
            return;
        }

        if (typeof values.driver_id !== 'string' && !(values.driver_id instanceof String)) {
            return;
        }

        const ids = values.driver_id.split('-');
        if (!Number.isNaN(parseInt(ids[0], 10))) {
            setTimeout(() => {
                setFieldValue('driver_id', parseInt(ids[0], 10));
                validateField('driver_id');
            }, 1);
        }
        if (typeof ids[1] !== 'undefined' && !Number.isNaN(parseInt(ids[1], 10))) {
            setTimeout(() => {
                setFieldValue('vehicle_id', parseInt(ids[1], 10));
                validateField('vehicle_id');
            }, 1);
        }
        if (typeof ids[2] !== 'undefined' && !Number.isNaN(parseInt(ids[2], 10))) {
            setTimeout(() => {
                setFieldValue('trailer_id', parseInt(ids[2], 10));
                validateField('trailer_id');
            }, 1);
        }
    }, [setFieldValue, setFieldTouched, values, validateField]);

    return <></>;
};

export default TransportDriverFormObserver;
