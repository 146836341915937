import {
    HOME_PASSINGS_REQUEST,
    HOME_PASSINGS_ERROR,
    HOME_PASSINGS_LOADED,
    HOME_PASSINGS_SET_ACTIVE,
    HOME_PASSINGS_NEW_LOADED,
    HOME_PASSINGS_NEW_REQUEST,
    HOME_PASSINGS_NEW_ERROR,
} from './constants';

export const initialGeneralState = {
    loading: false,
    error: false,
    passings: [],
    positions: [],
    activePassing: -1,
    currentPage: 1,
    totalPages: 1,
    newLoading: false,
    newError: false,
};

const uniqueById = (array) => {
    const seen = {};
    return array.filter((item) => {
        if (Object.prototype.hasOwnProperty.call(seen, item.id)) {
            return false;
        }
        seen[item.id] = true;
        return true;
    });
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case HOME_PASSINGS_REQUEST:
        newState.passings = [];
        newState.positions = [];
        newState.activePassing = -1;
        newState.loading = true;
        newState.error = false;
        return newState;
    case HOME_PASSINGS_NEW_REQUEST:
        newState.newLoading = true;
        newState.newError = false;
        return newState;
    case HOME_PASSINGS_LOADED:
        newState.passings = uniqueById([...newState.passings, ...action.payload.passings]);
        newState.activePassing = action.payload.activePassing;
        newState.positions = uniqueById([...newState.positions, ...action.payload.positions]);
        newState.currentPage = action.payload.currentPage;
        newState.totalPages = action.payload.totalPages;
        newState.loading = false;
        newState.error = false;
        return newState;
    case HOME_PASSINGS_NEW_LOADED:
        newState.passings = uniqueById([...newState.passings, ...action.payload.passings]);
        newState.positions = uniqueById([...newState.positions, ...action.payload.positions]);
        newState.currentPage = action.payload.currentPage;
        newState.totalPages = action.payload.totalPages;
        newState.newLoading = false;
        newState.newError = false;
        return newState;
    case HOME_PASSINGS_ERROR:
        newState.loading = false;
        newState.error = action.payload;
        return newState;
    case HOME_PASSINGS_NEW_ERROR:
        newState.newLoading = false;
        newState.newError = action.payload;
        return newState;
    case HOME_PASSINGS_SET_ACTIVE:
        newState.activePassing = action.payload;
        return newState;
    default:
        return state;
    }
};
