import React from 'react';
import PropTypes from 'prop-types';
import PopupAlerts from 'components/PopupAlerts';
import { useTransportDuplicate } from 'services';
import { initialGeneralState } from 'data/transportDraft/reducers';
import { useHistory } from 'react-router-dom';
import SmallLoader from '../SmallLoader';
import Error from '../Error';

const DuplicatePopup = ({ close, transportId }) => {
    const history = useHistory();
    const useDuplicate = useTransportDuplicate(transportId);
    const { isLoading, error, transport } = useDuplicate;
    const defaultMessage = 'Powielenie transportu spowoduje utratę aktualnej wersji roboczej, czy chcesz kontynuować?';

    const isDefinedAndStringOrNumber = (v) => {
        if (typeof v === 'undefined') {
            return false;
        }
        if (!v) {
            return false;
        }
        return typeof v === 'string' || v instanceof String || (!Number.isNaN(parseFloat(v)) && !Number.isNaN(v - 0));
    };

    const handleConfirm = () => {
        if (isLoading || error || error.length > 0) {
            return;
        }

        let newDraft = { ...initialGeneralState };

        // HANDLE LOCATION POINTS - START
        [
            {
                backend_key: 'sender',
                frontend_key: 'sender',
            },
            {
                backend_key: 'receiver',
                frontend_key: 'receiver',
            },
            {
                backend_key: 'destination_address',
                frontend_key: 'destinationAddress',
            },
            {
                backend_key: 'starting_address',
                frontend_key: 'startingAddress',
            },
        ].forEach((elm) => {
            if (typeof newDraft[elm.frontend_key] !== 'undefined'
            && typeof transport[elm.backend_key] !== 'undefined'
            && transport[elm.backend_key]) {
                const point = { ...newDraft[elm.frontend_key] };
                if (elm.frontend_key === 'destinationAddress') {
                    newDraft = { ...newDraft, receiverDifferent: true };
                }
                if (elm.frontend_key === 'startingAddress') {
                    newDraft = { ...newDraft, senderDifferent: true };
                }
                Object.keys(point).forEach((key) => {
                    if (isDefinedAndStringOrNumber(transport[elm.backend_key][key])) {
                        point[key] = transport[elm.backend_key][key];
                    }
                });
                newDraft = { ...newDraft, ...{ [elm.frontend_key]: point } };
            }
        });
        // HANDLE LOCATION POINTS - END

        // HANDLE ADR - START
        if (transport.adr_loads?.length > 0) {
            const adr = { ...newDraft.adr };
            const transportAdrs = transport.adr_loads[0];
            [
                {
                    backend_key: 'number_un',
                    frontend_key: 'un',
                },
                {
                    backend_key: 'packing_group',
                    frontend_key: 'group',
                },
                {
                    backend_key: 'class',
                    frontend_key: 'class',
                },
                {
                    backend_key: 'adr',
                    frontend_key: 'adr',
                },
            ].forEach((elm) => {
                if (typeof adr[elm.frontend_key] !== 'undefined' && isDefinedAndStringOrNumber(transportAdrs[elm.backend_key])) {
                    adr[elm.frontend_key] = transportAdrs[elm.backend_key];
                }
            });
            newDraft = { ...newDraft, adr };
        }
        // HANDLE ADR - END

        // HANDLE SPECIAL PROVISIONS AND INSTRUCTIONS - START
        if (isDefinedAndStringOrNumber(transport.sender_instructions)) {
            newDraft = { ...newDraft, senderInstructions: transport.sender_instructions };
        }
        if (isDefinedAndStringOrNumber(transport.special_provisions)) {
            newDraft = { ...newDraft, specialProvisions: transport.special_provisions };
        }
        // HANDLE SPECIAL PROVISIONS AND INSTRUCTIONS - END

        // HANDLE LOAD - START
        if (typeof transport.passing_loads !== 'undefined' && transport.passing_loads.length > 0) {
            newDraft = { ...newDraft, extendedLoad: true };
            const loadKeys = [
                'id',
                'amount',
                'load_no',
                'load_type',
                'package_type',
                'pallet_id',
                'volume',
                'weight',
            ];
            transport.passing_loads.forEach((load) => {
                let loadToPush = {};
                loadKeys.forEach((loadKey) => {
                    loadToPush = { ...loadToPush, [loadKey]: isDefinedAndStringOrNumber(load[loadKey]) ? load[loadKey] : '' };
                });
            });
        } else {
            newDraft = {
                ...newDraft,
                extendedLoad: false,
                loadSummary: isDefinedAndStringOrNumber(transport.load_summary_description) ? transport.load_summary_description : ''
            };
        }
        // HANDLE LOAD - END

        // HANDLE TIME - START
        newDraft = {
            ...newDraft,
            loadTimeStart: isDefinedAndStringOrNumber(transport.load_time_start) ? transport.load_time_start : '',
            loadTimeEnd: isDefinedAndStringOrNumber(transport.load_time_end) ? transport.load_time_end : '',
            unloadTimeStart: isDefinedAndStringOrNumber(transport.unload_time_start) ? transport.unload_time_start : '',
            unloadTimeEnd: isDefinedAndStringOrNumber(transport.unload_time_end) ? transport.unload_time_end : '',
        };
        // HANDLE TIME - END

        // HANDLE CARRIER - START
        let carrierAdded = false;
        try {
            if (isDefinedAndStringOrNumber(transport.company_id) && typeof transport.carriers[0] !== 'undefined'
                    && transport.carriers[0].company_id === transport.company_id) {
                let carrier = {};
                let hasCarrier = false;
                ['driver_id', 'trailer_id', 'vehicle_id'].forEach((carrierKey) => {
                    carrier = {
                        ...carrier,
                        [carrierKey]: isDefinedAndStringOrNumber(transport.carriers[0][carrierKey]) ? transport.carriers[0][carrierKey] : '',
                        additional_info: '',
                    };
                    if (isDefinedAndStringOrNumber(transport.carriers[0][carrierKey])) {
                        hasCarrier = true;
                    }
                });
                if (hasCarrier) {
                    newDraft = { ...newDraft, driver: 2, carrier };
                    carrierAdded = true;
                }
            }
        } catch (e) {
            console.error(e);
        }
        try {
            if (!carrierAdded
                && isDefinedAndStringOrNumber(transport.company_id)
                && typeof transport.current_passing_carrier !== 'undefined'
                && transport.current_passing_carrier
                && transport.current_passing_carrier.company_id !== transport.company_id) {
                const currentContractor = transport.current_passing_carrier.company;
                let logoUrl = '/images/logo-company-default.svg';
                try {
                    if (currentContractor.logo
                        && currentContractor.logo.download_url) {
                        logoUrl = currentContractor.logo.download_url;
                    }
                } catch (e) {
                    logoUrl = '/images/logo-company-default.svg';
                    console.error(e);
                }
                const contractor = {
                    name: currentContractor.name,
                    email: currentContractor.email,
                    avatar: logoUrl,
                };
                newDraft = {
                    ...newDraft,
                    driver: 3,
                    contractor,
                };
                carrierAdded = true;
            }
        } catch (e) {
            console.error(e);
        }
        try {
            if (!carrierAdded
                && typeof transport.invitations !== 'undefined'
                && typeof transport.invitations[0] !== 'undefined') {
                const currentContractor = transport.invitations[transport.invitations.length - 1];
                if (typeof currentContractor.to_email !== 'undefined') {
                    const contractor = {
                        name: '',
                        email: currentContractor.to_email,
                        avatar: '',
                    };
                    newDraft = {
                        ...newDraft,
                        driver: 3,
                        contractor,
                    };
                    carrierAdded = true;
                }
            }
        } catch (e) {
            console.error(e);
        }
        // HANDLE CARRIER - END


        localStorage.setItem('transport-draft', JSON.stringify({
            ...newDraft,
            senderVisited: false,
            receiverVisited: false,
            driverVisited: false,
            documents: [],
        }));

        history.push('/transports/add');
        close();
    };

    let message = <>{defaultMessage}</>;

    if (isLoading) {
        message = <SmallLoader dark />;
    }

    if (error || error.length > 0) {
        message = <Error errors={error} />;
    }

    return (
        <PopupAlerts
            title="Ostrzeżenie"
            close={close}
            confirm={handleConfirm}
            hideConfirm={!!(isLoading || error)}
        >
            {message}
        </PopupAlerts>
    );
};

DuplicatePopup.propTypes = {
    close: PropTypes.func.isRequired,
    transportId: PropTypes.number.isRequired,
};

export default DuplicatePopup;
