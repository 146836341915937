import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { datepickerLocale } from 'services';


const DatePickerField = ({ time, ...props }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(props);
    let format = 'YYYY-MM-dd';

    if (time) {
        format = 'Pp';
    }

    return (
        <DatePicker
            {...field}
            {...props}
            locale={datepickerLocale()}
            showTimeSelect={time}
            dateFormat={format}
            selected={(field.value && new Date(field.value)) || null}
            onChange={(val) => {
                const valToSet = val || '';
                setFieldTouched(field.name, true);
                setFieldValue(field.name, valToSet);
            }}
            onBlur={() => {
                setFieldTouched(field.name, true);
            }}
            autoComplete="off"
        />
    );
};

DatePickerField.propTypes = {
    time: PropTypes.bool,
};

DatePickerField.defaultProps = {
    time: false,
};

export default DatePickerField;
