import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useTransportInvitation from 'services/useTransportInvitation';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';
import { translations } from 'services';
import './styles.scss';
import { getSingleTransport } from 'data/transport/actions';

const PassingInvitationAnswer = ({
    isInvite, invitationTokens, get, id
}) => {
    const invitationHandler = useTransportInvitation(invitationTokens, () => { get(id); });
    const {
        isLoading, error, handleReject, handleAccept
    } = invitationHandler;

    if (!isInvite) {
        return <></>;
    }

    if (isLoading) {
        return (
            <div className="PassingInvitationAnswer">
                <SmallLoader dark />
            </div>
        );
    }

    if (error) {
        return <div className="PassingInvitationAnswer with-error"><Error errors={error} /></div>;
    }

    return (
        <div className="PassingInvitationAnswer">
            <button type="button" className="PassingInvitationAnswer__accept" onClick={() => { handleAccept(); }}>
                <span className="PassingInvitationAnswer__accept--copy">
                    {translations('invitation_email.accept')}
                </span>
            </button>
            <button type="button" className="PassingInvitationAnswer__reject" onClick={() => { handleReject(); }}>
                <span className="PassingInvitationAnswer__reject--copy">{translations('invitation_email.reject')}</span>
            </button>
        </div>
    );
};

PassingInvitationAnswer.propTypes = {
    isInvite: PropTypes.bool.isRequired,
    invitationTokens: PropTypes.shape({
        unique_token: PropTypes.string,
        accept_token: PropTypes.string,
        reject_token: PropTypes.string,
    }).isRequired,
    get: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    const { transport } = state;
    let isInvite = false;
    const invitationTokens = {
        unique_token: '',
        accept_token: '',
        reject_token: '',
    };

    const { id } = transport.transport;

    try {
        const { accesses, invitations } = transport.transport;
        if (accesses.length) {
            accesses.forEach((access) => {
                if (access.access_type === 'invited') {
                    isInvite = true;
                }
            });
        }
        if (isInvite && typeof invitations[0] === 'undefined') {
            isInvite = false;
        }

        if (isInvite) {
            invitationTokens.unique_token = invitations[0].unique_token;
            invitationTokens.accept_token = invitations[0].accept_token;
            invitationTokens.reject_token = invitations[0].reject_token;
        }
    } catch (e) {
        console.error(e);
    }

    return {
        isInvite, invitationTokens, id,
    };
};

const mapDispatchToProps = (dispatch) => ({
    get: (transportToGet = '') => dispatch(getSingleTransport(transportToGet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PassingInvitationAnswer);
