import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import Avatar from 'components/Avatar';
import GetCmr from 'components/GetCmr';
import RouteMap from 'components/RouteMap';
import { NavLink } from 'react-router-dom';
import translations from 'services/translations';
import { confirm } from 'data/confirmation/actions';
import PassingPoint from '../PassingPoint';
import PassingDataSingleLoad from './PassingDataSingleLoad';
import PassingCarrier from '../PassingCarrier';
import PassingInvitationAnswer from '../PassingInvitationAnswer';

const PassingData = ({
    passing, deletePassing, userCompanyID, hideActions, confirmation,
}) => {
    const [optionsVisible, setOptionsVisible] = useState(false);
    let avatarImage = '';

    try {
        if (passing.carriers && typeof passing.carriers[0] !== 'undefined'
            && typeof passing.carriers[0].driver !== 'undefined' && passing.carriers[0].driver) {
            avatarImage = passing.carriers[0].driver.avatar.download_url;
        }
    } catch (e) {
        console.error(e);
    }

    useEffect(() => {
        [1, 2].forEach((point) => {
            const $elements = document.querySelectorAll(`[data-km-passing-point="${point}"]`);

            if ($elements && $elements.length) {
                let min = 0;
                for (let i = 0; i < $elements.length; i += 1) {
                    min = min < $elements[i].offsetHeight ? $elements[i].offsetHeight : min;
                }
                if (min > 0) {
                    for (let i = 0; i < $elements.length; i += 1) {
                        $elements[i].style.minHeight = `${min}px`;
                    }
                }
            }
        });
    }, []);

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOptionsVisible(false);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const defaultPointStructure = {
        title: '',
        address_city: '',
        address_country: '',
        address_housenumber: '',
        address_postcode: '',
        address_street: '',
        address_apartment_number: '',
        email: '',
        additional_info: '',
        additional_email: '',
    };

    const points = {
        sender: defaultPointStructure,
        receiver: defaultPointStructure,
        starting_address: defaultPointStructure,
        destination_address: defaultPointStructure,
    };

    [passing.sender,
        passing.receiver,
        passing.starting_address,
        passing.destination_address]
        .forEach((elm, index) => {
            if (!elm) {
                return;
            }
            let name = '';
            switch (index) {
            case 0:
                name = 'sender';
                break;
            case 1:
                name = 'receiver';
                break;
            case 2:
                name = 'starting_address';
                break;
            case 3:
                name = 'destination_address';
                break;
            default:
                break;
            }
            if (!name || name.length === 0) {
                return;
            }

            points[name] = {
                title: elm.name,
                address_city:
                elm.address_city ? elm.address_city : '',
                address_country:
                elm.address_country ? elm.address_country : '',
                address_housenumber:
                elm.address_housenumber ? elm.address_housenumber : '',
                address_postcode:
                elm.address_postcode ? elm.address_postcode : '',
                address_street:
                elm.address_street ? elm.address_street : '',
                address_apartment_number:
                elm.address_apartment_number ? elm.address_apartment_number : '',
                email: elm.email ? elm.email : '',
                additional_info: elm.additional_info ? elm.additional_info : '',
                additional_email: elm.additional_email ? elm.additional_email : '',
            };
        });

    const carrierData = passing.carriers && passing.carriers[0] ? passing.carriers[0] : null;

    let driverPosition = null;

    try {
        if (carrierData && carrierData.driver && carrierData.driver.lat && carrierData.driver.lng) {
            const driverLat = parseFloat(carrierData.driver.lat);
            const driverLng = parseFloat(carrierData.driver.lng);
            if (!Number.isNaN(driverLat) && !Number.isNaN(driverLng)) {
                driverPosition = {
                    lat: driverLat,
                    lng: driverLng,
                };
            }
        }
    } catch (e) {
        console.error(e);
    }

    const passingDocs = passing.additional_docs
    && passing.additional_docs.length ? passing.additional_docs : [];

    let startLocation = passing.starting_address
        ? passing.starting_address.full_address
        : passing.sender.full_address;

    let endLocation = passing.destination_address
        ? passing.destination_address.full_address
        : passing.receiver.full_address;

    if (passing.starting_address) {
        if (passing.starting_address.lat && `${passing.starting_address.lat}`.length && passing.starting_address.lng && `${passing.starting_address.lng}`.length) {
            startLocation = {
                lat: typeof passing.starting_address.lat !== 'number' ? parseFloat(passing.starting_address.lat) : passing.starting_address.lat,
                lng: typeof passing.starting_address.lng !== 'number' ? parseFloat(passing.starting_address.lng) : passing.starting_address.lng,
            };
        }
    } else if (passing.sender.lat && `${passing.sender.lat}`.length && passing.sender.lng && `${passing.sender.lng}`.length) {
        startLocation = {
            lat: typeof passing.sender.lat !== 'number' ? parseFloat(passing.sender.lat) : passing.sender.lat,
            lng: typeof passing.sender.lng !== 'number' ? parseFloat(passing.sender.lng) : passing.sender.lng,
        };
    }

    if (passing.destination_address) {
        if (passing.destination_address.lat && `${passing.destination_address.lat}`.length && passing.destination_address.lng && `${passing.destination_address.lng}`.length) {
            endLocation = {
                lat: typeof passing.destination_address.lat !== 'number' ? parseFloat(passing.destination_address.lat) : passing.destination_address.lat,
                lng: typeof passing.destination_address.lng !== 'number' ? parseFloat(passing.destination_address.lng) : passing.destination_address.lng,
            };
        }
    } else if (passing.receiver.lat && `${passing.receiver.lat}`.length && passing.receiver.lng && `${passing.receiver.lng}`.length) {
        endLocation = {
            lat: typeof passing.receiver.lat !== 'number' ? parseFloat(passing.receiver.lat) : passing.receiver.lat,
            lng: typeof passing.receiver.lng !== 'number' ? parseFloat(passing.receiver.lng) : passing.receiver.lng,
        };
    }


    return (
        <div className="PassingData">
            <div className="PassingData__info">
                {userCompanyID === passing.company_id ? <Avatar image={avatarImage} /> : ''}
                <div className="PassingData__info--status">{passing.status}</div>
                <PassingInvitationAnswer />
                {!hideActions && (
                    <div
                        className="PassingData__actions"
                        ref={wrapperRef}
                    >
                        <button
                            type="button"
                            onClick={() => setOptionsVisible((t) => !t)}
                            className="PassingData__action option-button"
                        >
                            actions
                        </button>
                        <div
                            style={{ display: optionsVisible ? 'block' : 'none' }}
                            className="PassingData__actions-options"
                        >
                            <NavLink
                                to={`/transports/single/${passing.id}/edit`}
                                className="PassingData__actions-options--edit"
                            >
                                {translations('front.general.edit')}
                            </NavLink>
                            <button
                                type="button"
                                className="PassingData__actions-options--delete"
                                onClick={() => {
                                    confirmation(translations('front.general.attention'), translations('front.general.confirmations.transport_remove'), deletePassing);
                                }}
                            >
                                {translations('front.general.delete')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div className="PassingData__cmr">
                <div className="PassingData__cmr--number PassingData-heading">{translations('front.passing.single.number')}</div>
                <GetCmr
                    passingID={passing.id}
                    title={passing.passing_idstr}
                    date={passing.created_at}
                    downloadUrl={passing.cmr_url && passing.cmr_url.download_url ? passing.cmr_url.download_url : ''}
                    docs={passingDocs}
                />
            </div>
            <div className="PassingData__points">
                <div className="PassingData__points--col">
                    <PassingPoint
                        type="sender"
                        data={points.sender}
                    />
                    <PassingPoint
                        type="starting_address"
                        data={passing.starting_address
                            ? {
                                ...points.starting_address,
                                additional_info: points.sender.additional_info
                            } : points.sender}
                        timeStart={passing.load_time_start}
                        timeEnd={passing.load_time_end}
                    />
                </div>
                <div className="PassingData__points--col">
                    <PassingPoint
                        type="receiver"
                        data={points.receiver}
                    />
                    <PassingPoint
                        type="destination_address"
                        data={passing.destination_address
                            ? {
                                ...points.destination_address,
                                additional_info: points.receiver.additional_info
                            }
                            : points.receiver}
                        timeStart={passing.unload_time_start}
                        timeEnd={passing.unload_time_end}
                    />
                </div>
            </div>
            <div className="PassingData__load">
                <div className="PassingData-heading PassingData__loadr--heading">{translations('front.passing.single.load.header')}</div>
                {
                    passing.passing_loads
                    && passing.passing_loads.length
                        ? passing.passing_loads.map((load, index) => (
                            <PassingDataSingleLoad load={load} index={index + 1} key={load.id} />
                        ))
                        : <div className="PassingData__load--summary">{passing.load_summary_description}</div>
                }
                {
                    passing.adr_loads
                    && passing.adr_loads.length
                        ? passing.adr_loads.map((load, index) => (
                            <PassingDataSingleLoad
                                load={load}
                                index={index + 1}
                                key={load.id}
                                isAdr
                            />
                        ))
                        : ''
                }
                <div className="PassingDataSingleLoad">
                    <div className="PassingDataSingleLoad__title">
                        {translations('front.passing.single.load.sender_instructions')}
                    </div>
                    <div className="PassingDataSingleLoad__values">
                        {passing.sender_instructions && passing.sender_instructions.length ? passing.sender_instructions : '---'}
                    </div>
                </div>
                <div className="PassingDataSingleLoad">
                    <div className="PassingDataSingleLoad__title">
                        {translations('passing.special_provisions')}
                    </div>
                    <div className="PassingDataSingleLoad__values">
                        {passing.special_provisions && passing.special_provisions.length ? passing.special_provisions : '---'}
                    </div>
                </div>
            </div>
            <PassingCarrier />
            <div className="PassingData__route">
                <div className="PassingData-heading">{translations('front.passing.single.route')}</div>
                {passing.sender && passing.receiver ? (
                    <RouteMap
                        from={startLocation}
                        to={endLocation}
                        driverPosition={driverPosition}
                    />
                ) : ''}
            </div>
        </div>
    );
};


PassingData.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    passing: PropTypes.object.isRequired,
    deletePassing: PropTypes.func.isRequired,
    userCompanyID: PropTypes.number.isRequired,
    hideActions: PropTypes.bool,
    confirmation: PropTypes.func.isRequired,
};

PassingData.defaultProps = {
    hideActions: false,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        userCompanyID: user.user.company_id,
    };
};

const mapDispatchToProps = (dispatch) => ({
    confirmation: (t, d, c) => dispatch(confirm(t, d, c)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PassingData);
