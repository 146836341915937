import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'services/classNames';
import translations from 'services/translations';
import './styles.scss';
import { confirm } from 'data/confirmation/actions';

const TrailerListElement = ({
    trailer, edit, del, hideForm, confirmation,
}) => {
    const [optionsVisible, setOptionsVisible] = useState(false);

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOptionsVisible(false);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    let dimensionsArray = trailer.dimensions ? trailer.dimensions.split('x') : [];
    let dimensionsString = '';
    if (dimensionsArray.length) {
        if (dimensionsArray.length === 1) {
            dimensionsArray = dimensionsArray[0].split(' ');
        }
        dimensionsArray.forEach((elm, index) => {
            dimensionsString += `${index > 0 ? ' x ' : ''}${elm.trim()}m`;
        });
    }

    return (
        <div className={classNames('TrailerListElement', 'list-element')}>
            <div className="TrailerListElement--text TrailerListElement__brand">{trailer.brand}</div>
            <div className="TrailerListElement--text TrailerListElement__model">{trailer.model}</div>
            <div className="TrailerListElement--text TrailerListElement__type">{trailer.type}</div>
            <div className="TrailerListElement--text TrailerListElement__registration_number"><strong>{trailer.registration_number}</strong></div>
            <div className="TrailerListElement--text TrailerListElement__weight">{trailer.deadweight_load ? `${trailer.deadweight_load}kg` : ''}</div>
            <div className="TrailerListElement--text TrailerListElement__gvwr">{trailer.gross_vehicle_mass ? `${trailer.gross_vehicle_mass}kg` : ''}</div>
            <div className="TrailerListElement--text TrailerListElement__dimensions">{(dimensionsString.length > 0) ? dimensionsString : ''}</div>
            <div className="TrailerListElement--text TrailerListElement__capacity">{trailer.capacity ? `${trailer.capacity}m³` : ''}</div>
            <div
                className="TrailerListElement__actions"
                ref={wrapperRef}
            >
                <button
                    type="button"
                    onClick={() => { hideForm(); setOptionsVisible(!optionsVisible); }}
                    className="TrailerListElement__action option-button"
                >
                    actions
                </button>
                <div
                    className="TrailerListElement__buttons"
                    style={{ display: optionsVisible ? 'block' : 'none' }}
                >
                    <button
                        type="button"
                        onClick={() => { edit(trailer); }}
                        className="TrailerListElement__edit"
                    >
                        {translations('front.general.edit')}
                    </button>
                    <button
                        type="button"
                        className="TrailerListElement__delete"
                        onClick={() => {
                            confirmation(translations('front.general.attention'), translations('front.fleet.trailers_delete_confirmation'), del);
                        }}
                    >
                        {translations('front.general.delete')}
                    </button>
                </div>
            </div>
        </div>
    );
};

TrailerListElement.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    trailer: PropTypes.object.isRequired,
    edit: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    hideForm: PropTypes.func.isRequired,
    confirmation: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    confirmation: (t, d, c) => dispatch(confirm(t, d, c)),
});

export default connect(null, mapDispatchToProps)(TrailerListElement);
