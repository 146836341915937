import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { classNames, translations } from 'services';
import CircleLoader from 'components/CircleLoader';
import TransportClear from './components/TransportClear';
import './styles.scss';
import draftValidation from '../../services/draftValidation';

const TransportAddMenu = ({
    edit,
    sender, receiver, driver,
    loadValid,
}) => {
    const location = useLocation();
    let prev = 'from-company';
    let next = 'to-company';
    const showEnd = location.pathname.endsWith('/to-company');
    const showLoad = location.pathname.endsWith('/load');
    const showDriver = location.pathname.endsWith('/driver');

    if (showEnd) {
        next = 'load';
    } else if (showLoad) {
        prev = 'to-company';
        next = 'driver';
    } else if (showDriver) {
        prev = 'load';
        next = 'driver';
    }

    const elements = [
        {
            label: translations('front.passing.routing.from_company'),
            slug: 'from-company',
            // eslint-disable-next-line no-nested-ternary
            status: sender.status,
            classToAdd: prev !== 'from-company' ? 'hide-on-md' : '',
        },
        {
            label: translations('front.passing.routing.to_company'),
            slug: 'to-company',
            status: receiver.status,
        },
        {
            label: translations('front.passing.single.nav.load'),
            slug: 'load',
            status: loadValid ? 'success' : '',
        },
        {
            label: translations('front.passing.single.nav.carrier'),
            slug: 'driver',
            status: driver.status,
            classToAdd: prev === 'from-company' ? 'hide-on-md' : '',
        },
    ];


    const urlPrefix = edit ? `/transports/single/${edit}/edit/` : '/transports/add/';

    const mappedElements = elements.map((elm) => (
        <NavLink
            key={elm.slug}
            to={urlPrefix + elm.slug}
            className={classNames('TransportAddMenu__link', elm.status, elm.classToAdd)}
        >
            {elm.label}
            {elm.status === 'loading' && <CircleLoader />}
        </NavLink>
    ));

    const removeAll = <TransportClear />;

    return (
        <div className="TransportAddMenu">
            {mappedElements}
            {removeAll}
            <div className="TransportAddMenu__arrows">
                <NavLink className="TransportAddMenu__arrows--single" exact to={urlPrefix + prev}>prev</NavLink>
                <NavLink className="TransportAddMenu__arrows--single" exact to={urlPrefix + next}>next</NavLink>
            </div>
        </div>
    );
};

const elementObject = {
    validation: PropTypes.bool,
    status: PropTypes.string,
};

TransportAddMenu.propTypes = {
    edit: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
    ]),
    sender: PropTypes.shape(elementObject).isRequired,
    receiver: PropTypes.shape(elementObject).isRequired,
    driver: PropTypes.shape(elementObject).isRequired,
    loadValid: PropTypes.bool.isRequired,
};

TransportAddMenu.defaultProps = {
    edit: false,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const validation = draftValidation(transportDraft);
    const { sender, receiver, driver } = validation;
    const {
        extendedLoad, loadSummary, loads, adr, documents
    } = transportDraft;

    const loadValid = (extendedLoad && loads.length > 0)
        || (!extendedLoad && loadSummary.length > 0)
        || (adr.adr.length > 0)
    || documents.length > 0;


    return {
        sender, receiver, driver, loadValid,
    };
};

export default connect(mapStateToProps, null)(TransportAddMenu);
