import React, { useState } from 'react';
import classNames from 'services/classNames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Status from 'components/Status';
import { NavLink } from 'react-router-dom';
import translations from 'services/translations';
import Icon from 'components/Icon';
import Progress from 'components/Progress';
import Avatar from 'components/Avatar';
import timeProgress from 'services/timeProgress';
import { transportsRemove } from 'data/transportsList/actions';
import style from './PassingsListElement.module.scss';
import PassingsListElementInfo from '../PassingsListElementInfo';
import PassingsListInvitation from '../PassingsListInvitation';

const PassingsListElement = ({ passing, setReset, toRemove }) => {
    const [currentStatus, setCurrentStatus] = useState(passing.status_id);
    const moreText = translations('passing.more');
    const progress = timeProgress([
        passing.load_time_start,
        passing.load_time_end,
        passing.unload_time_start,
        passing.unload_time_end
    ]);

    let isInvite = false;
    const invitationTokens = {};

    const clickHandler = (e) => {
        const tagName = e?.target?.tagName.toLowerCase();
        const { id } = passing;
        if (tagName !== 'a' && tagName !== 'button' && id) {
            setReset(id);
        }
    };

    if (passing.accesses && passing.accesses.length) {
        passing.accesses.forEach((access) => {
            if (access.access_type === 'invited') {
                isInvite = true;
            }
        });
    }

    if (isInvite && typeof passing.invitations[0] === 'undefined') {
        isInvite = false;
    }

    if (isInvite) {
        invitationTokens.unique_token = passing.invitations[0].unique_token;
        invitationTokens.accept_token = passing.invitations[0].accept_token;
        invitationTokens.reject_token = passing.invitations[0].reject_token;
    }

    const readStatusChange = (newStatus) => {
        if (newStatus !== currentStatus) {
            setCurrentStatus(newStatus);
        }
        return newStatus;
    };

    let startingTime = '';
    try {
        if (passing.load_time_start && passing.load_time_end) {
            startingTime = `${Moment(passing.load_time_start).format('lll')} ${Moment(passing.load_time_end).format('lll')}`;
        } else if (passing.load_time_start) {
            startingTime = `${Moment(passing.load_time_start).format('lll')}`;
        }
    } catch (e) {
        console.error(e);
    }

    let endingTime = '';
    try {
        if (passing.unload_time_start && passing.unload_time_end) {
            endingTime = `${Moment(passing.unload_time_start).format('lll')} ${Moment(passing.unload_time_end).format('lll')}`;
        } else if (passing.unload_time_start) {
            endingTime = `${Moment(passing.unload_time_start).format('lll')}`;
        }
    } catch (e) {
        console.error(e);
    }

    const startingAdditionals = {
        time: startingTime,
    };

    const destinationAdditionals = {
        time: endingTime,
    };

    const starting = passing.starting_address
        ? { ...passing.starting_address, ...startingAdditionals }
        : { ...passing.sender, ...startingAdditionals };
    const destination = passing.destination_address
        ? { ...passing.destination_address, ...destinationAdditionals }
        : { ...passing.receiver, ...destinationAdditionals };
    const vehicle = {
        is_vehicle: true,
        name: '',
        registration_number: '',
        vehicle_data: '',
        vehicle_norm: '',
    };
    let showVehicle = false;

    if (typeof passing.carriers[0] !== 'undefined' && passing.carriers[0].vehicle) {
        showVehicle = true;
        // vehicle.name = passing.carriers[0].vehicle.name;
        vehicle.registration_number = passing.carriers[0].vehicle.registration_number;
        vehicle.name = `${passing.carriers[0].vehicle.brand} ${passing.carriers[0].vehicle.model}`;
        vehicle.vehicle_norm = `${passing.carriers[0].vehicle.deadweight_load ? `${passing.carriers[0].vehicle.deadweight_load}kg` : ''} ${passing.carriers[0].vehicle.gross_vehicle_mass ? `${passing.carriers[0].vehicle.gross_vehicle_mass}kg` : ''}`;
    }

    let avatarUrl = '';

    try {
        if (passing.carriers && passing.carriers[0] && passing.carriers[0].driver) {
            avatarUrl = passing.carriers[0].driver.avatar.download_url;
        }
    } catch (e) {
        console.error(e);
    }

    return (
        <div
            role="button"
            onClick={clickHandler}
            onKeyPress={clickHandler}
            tabIndex={0}
            className={classNames(
                style.Element,
                'list-element',
                (currentStatus === 'finished') && 'blank',
                toRemove.includes(passing.id) && style.SelectedElement,
            )}
        >
            <Avatar width={32} height={32} image={avatarUrl} />
            <div className={style.General}>
                <Status
                    passing={passing.id}
                    status={currentStatus}
                    withMenu
                    handleStatusChange={readStatusChange}
                />
                <div className={style.General__id}>{passing.passing_idstr}</div>
                <div className={style.General__progress}>
                    <Progress progress={progress} />
                </div>
            </div>
            <PassingsListElementInfo passing={starting} type="from" />
            <PassingsListElementInfo passing={destination} type="to" />
            <PassingsListElementInfo passing={vehicle} type="vehicle" hidden={!showVehicle} />
            <div className={style.Buttons}>
                <button
                    type="button"
                    className={style.Duplicate}
                    onClick={() => {
                        const eventToSend = new CustomEvent('ta-show-modal', {
                            detail: {
                                title: 'passing-duplicate',
                                transportId: passing.id
                            }
                        });
                        document.dispatchEvent(eventToSend);
                    }}
                >
                    Powiel transport
                </button>
                <NavLink
                    className={style.More}
                    to={`/transports/single/${passing.id}`}
                    exact
                >
                    {moreText}
                </NavLink>
            </div>
            {passing.default_chat_id && (
                <NavLink
                    to={`/messages/${passing.default_chat_id}`}
                    className={style.Chat}
                >
                    <Icon className={style.Chat__img} icon="chat" />
                </NavLink>
            )}
            {isInvite && <PassingsListInvitation tokens={invitationTokens} />}
        </div>
    );
};

PassingsListElement.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    passing: PropTypes.object.isRequired,
    setReset: PropTypes.func.isRequired,
    toRemove: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const mapStateToProps = (state) => {
    const { transportsList } = state;
    const { toRemove } = transportsList;

    return {
        toRemove
    };
};

const mapDispatchToProps = (dispatch) => ({
    setReset: (id) => dispatch(transportsRemove([id])),
});

export default connect(mapStateToProps, mapDispatchToProps)(PassingsListElement);
