import 'moment/locale/bg';
import 'moment/locale/cs';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/el';
import 'moment/locale/es';
import 'moment/locale/et';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/hr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/lb';
import 'moment/locale/lt';
import 'moment/locale/lv';
import 'moment/locale/nl';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sk';
import 'moment/locale/sl';
import 'moment/locale/sv';
import 'moment/locale/uk';
import 'moment/locale/pl';
import 'date-fns/locale/en-US';

export default (lang = '') => lang;
