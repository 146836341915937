import React from 'react';
import PropTypes from 'prop-types';
import { transportDraftPushDocument } from 'data/transportDraft/actions';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import FormField from 'components/FormField';
import Button from 'components/Button';
import { v4 as uuidv4 } from 'uuid';
import Yup from 'services/translatedYup';
import { defaultTranslations, translations } from 'services';
import style from './TransportFileJustNames.module.scss';


const TransportFileJustNames = ({ push }) => {
    const lang = localStorage.getItem('language') || 'en';
    const header = defaultTranslations('or_just_attachment_name', lang);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
    });

    const onSubmit = (values) => {
        const { name } = values;
        push({
            name,
            extension: null,
            objectURL: null,
            size: null,
            modified: null,
            id: uuidv4(),
        });
    };

    return (
        <div className={style.TransportFileJustNames}>
            <div className={style.Header}>{header}</div>
            <Formik
                initialValues={{ name: '' }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    onSubmit(values);
                    resetForm();
                }}
                enableReinitialize
            >
                <Form>
                    <FormField name="name" />
                    <div className={style.Button}>
                        <Button type="submit" small>{translations('front.general.add')}</Button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

TransportFileJustNames.propTypes = {
    push: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    push: (document, isEdit = false) => dispatch(transportDraftPushDocument(document, isEdit)),
});

export default connect(null, mapDispatchToProps)(TransportFileJustNames);
