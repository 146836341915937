import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'services';

import './styles.scss';

const Progress = ({ progress, rotate }) => {
    const prog = progress > 0 && progress < 1 ? progress * 100 : progress;

    const width = {
        width: `${prog}%`
    };
    const height = {
        height: `${prog}%`
    };

    return (
        <div className={classNames('Progress', rotate && 'rotate')}>
            <div className="Progress-line hide-md" style={!rotate ? width : height} />
            <div className="Progress-line show-md" style={rotate ? width : height} />
        </div>
    );
};

Progress.propTypes = {
    progress: PropTypes.number.isRequired,
    rotate: PropTypes.bool,
};

Progress.defaultProps = {
    rotate: false,
};

export default Progress;
