import {
    COUNTRIES_LOADED,
    COUNTRIES_REQUEST,
    COUNTRIES_ERROR,
} from './constants';

export const initialGeneralState = {
    loading: false,
    error: false,
    countries: [],
    codes: [],
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case COUNTRIES_REQUEST:
        newState.countries = [];
        newState.codes = [];
        newState.loading = true;
        newState.error = false;
        return newState;
    case COUNTRIES_LOADED:
        newState.countries = action.payload.countriesArray;
        newState.codes = action.payload.countriesCodes;
        newState.loading = false;
        newState.error = false;
        return newState;
    case COUNTRIES_ERROR:
        newState.countries = [];
        newState.codes = [];
        newState.loading = false;
        newState.error = action.payload;
        return newState;
    default:
        return state;
    }
};
