const translations = {
    loading: 'Загрузка',
    loading_data: 'Загрузка данных ...',
    validate_email: 'Это не правильный адрес электронной почты',
    validate_required: 'Это поле обязательно к заполнению',
    validate_the_same: 'Обе пароли должны быть одинаковыми',
    sign_in: 'авторизоваться',
    sign_up__invitation: 'У вас нет аккаунта?',
    sign_up: 'Регистр!',
    forgot_password: 'Не помните пароль?',
    error_occurred: 'Произошла ошибка!',
    activation_token_broken: 'Токен активации неверный.',
    return_to_login: 'Вернуться в логин',
    activation_success: 'Спасибо! Ваша учетная запись сейчас активна.',
    forgot_password_submit: '',
    password: 'Сбросить пароль',
    password_confirmation: 'Пароль',
    email: 'Повторите пароль',
    remember_me: 'Эл. почта',
    welcome: 'Запомнить меня',
    log_in_to_access: 'Добро пожаловать!',
    password_forgot_message: 'Войдите, чтобы начать',
    back_to: 'Не могу вы войти в систему? Введите свой адрес электронной почты для восстановления пароля!',
    sign_in_page: 'вернуться к',
    select: 'авторизоваться',
    select_new: 'Выбирать...',
    invitation_sent: 'Приглашение отправлено',
    users_custom_company_data: 'Используйте другие данные компании',
    or_just_attachment_name: 'Или просто добавьте имя вложения:',
    other_docs: 'Другие документы:',
    readBy: 'Прочитано:',
};
export default translations;
