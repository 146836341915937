const translations = {
    loading: 'Se încarcă',
    loading_data: 'Încărcare de date ...',
    validate_email: 'Aceasta nu este adresa corectă de e-mail',
    validate_required: 'Acest câmp este obligatoriu',
    validate_the_same: 'Ambele parole trebuie să fie aceleași',
    sign_in: 'Conectare',
    sign_up__invitation: 'Nu aveți un cont?',
    sign_up: 'Inregistreaza-te!',
    forgot_password: 'Nu-ți amintești parola?',
    error_occurred: 'A aparut o eroare!',
    activation_token_broken: 'Tokenul de activare este incorect.',
    return_to_login: 'Reveniți la conectare',
    activation_success: 'Mulțumesc! Contul dvs. este acum activ.',
    forgot_password_submit: 'Resetați parola',
    password: 'Parola',
    password_confirmation: 'Repetați parola',
    email: 'E-mail',
    remember_me: 'Amintește-ți de mine',
    welcome: 'Bine ati venit!',
    log_in_to_access: 'Conectați-vă pentru a începe',
    password_forgot_message: 'Nu vă puteți conecta? Introduceți adresa dvs. de e-mail pentru a reseta parola!',
    back_to: 'mă întorc la',
    sign_in_page: 'Autentificare',
    select: 'Alege...',
    select_new: 'Crea:',
    invitation_sent: 'Invitatie trimisa',
    users_custom_company_data: 'Utilizați alte date ale companiei',
    or_just_attachment_name: 'Sau adăugați doar numele atașamentului:',
    other_docs: 'Alte documente:',
    readBy: 'Citit de:',
};
export default translations;
