export const CHATS_LIST_REQUEST = 'CHATS_LIST_REQUEST';
export const CHATS_LIST_ERROR = 'CHATS_LIST_ERROR';
export const CHATS_LIST_SUCCESS = 'CHATS_LIST_SUCCESS';
export const CHATS_LIST_NO_OLDER = 'CHATS_LIST_NO_OLDER';
export const CHATS_LIST_OLDER_REQUEST = 'CHATS_LIST_OLDER_REQUEST';
export const CHATS_LIST_OLDER_ERROR = 'CHATS_LIST_OLDER_ERROR';
export const CHATS_LIST_OLDER_SUCCESS = 'CHATS_LIST_OLDER_SUCCESS';
export const CHATS_LIST_NEW_REQUEST = 'CHATS_LIST_NEW_REQUEST';
export const CHATS_LIST_NEW_SUCCESS = 'CHATS_LIST_NEW_SUCCESS';
export const CHATS_LIST_NEW_ERROR = 'CHATS_LIST_NEW_ERROR';
