import React, { useMemo } from 'react';
import Yup from 'services/translatedYup';
import PropTypes from 'prop-types';
import { phoneValidator } from 'services';
import { connect } from 'react-redux';


import FormRow from 'components/FormRow';
import FormField from 'components/FormField';

import translations from 'services/translations';
import UserCustomCompany from 'components/UserCustomCompany';
import { customCompanyValidation } from 'services/customCompany';
import FormWrapper from '../FormWrapper';


const UserForm = ({
    onSubmit, formValues, isLoading, isAdd, roles
}) => {
    const validationSchema = useMemo(() => Yup.object().shape(isAdd ? {
        name: Yup.string().required().min(2),
        surname: Yup.string().required().min(2),
        phone: Yup.string().test('phone', (value) => phoneValidator(value)),
        email: Yup.string().email().required(),
        role_name: Yup.string().required().min(2),
        ...customCompanyValidation,
    } : {
        name: Yup.string().required().min(2),
        surname: Yup.string().required().min(2),
        phone: Yup.string().test('phone', (value) => phoneValidator(value)),
        role_name: Yup.string().required().min(2),
        ...customCompanyValidation,
    }), [isAdd]);


    return (
        <FormWrapper
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
            isLoading={isLoading}
        >
            {() => (
                <>
                    <FormRow>
                        <FormField
                            name="name"
                            type="text"
                            label={translations('front.users.name')}
                            isRequired
                        />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="surname"
                            type="text"
                            label={translations('front.users.surname')}
                            isRequired
                        />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="phone"
                            type="text"
                            label={translations('front.users.phone')}
                            isRequired
                            isPhone
                        />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="email"
                            type="text"
                            label={translations('front.users.email')}
                            isRequired
                            disabled={!isAdd}
                        />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="role_name"
                            type="select"
                            isSelect={roles.map((elm) => (
                                {
                                    label: elm.label,
                                    value: elm.name,
                                }
                            ))}
                            label={translations('front.users.role')}
                            isRequired
                        />
                    </FormRow>
                    <UserCustomCompany />
                </>
            )}
        </FormWrapper>
    );
};

UserForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    isLoading: PropTypes.bool.isRequired,
    formValues: PropTypes.shape({
        name: PropTypes.string,
        surname: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
    }).isRequired,
    isAdd: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

UserForm.defaultProps = {
    isAdd: false,
};

const mapStateToProps = (state) => {
    const { roles } = state;

    return {
        roles: roles.roles,
    };
};

export default connect(mapStateToProps, null)(UserForm);
