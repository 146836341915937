import { API } from 'services';
import querystring from 'querystring';
import Moment from 'moment';

import {
    CHAT_REQUEST,
    SET_CHAT,
    SET_MESSAGES,
    SET_DATA,
    CLOSE_CHAT, ADD_MESSAGE,
    CHAT_LOADING,
    CHAT_LOADED,
    OLD_MESSAGE,
    CHAT_NO_OLDER_MESSAGE,
    CHAT_NO_OLDER_MESSAGE_ERROR,
    CHAT_NO_OLDER_MESSAGE_REQUEST, CHAT_NEW_MESSAGE,
    REMOVE_MESSAGE,
    CHAT_NEW_MESSAGE_REQUEST,
    CHAT_HIDE_ARROW,
    CHAT_LIST_REQUEST, CHAT_LIST_SUCCESS, CHAT_LIST_ERROR, CHAT_NEW_MESSAGE_ERROR, CHAT_RECEIVERS,
} from './constants';

const loadOnce = 30;

export function startChatRequest() {
    return { type: CHAT_REQUEST };
}

export function setChatId(payload) {
    return { type: SET_CHAT, payload };
}

export function startLoading() {
    return { type: CHAT_LOADING };
}

export function stopLoading() {
    return { type: CHAT_LOADED };
}

export function scrollToBottom() {
    return () => setTimeout(() => {
        const $elm = document.querySelector('.ChatSingle__list');
        if ($elm) {
            $elm.scrollTo(0, $elm.scrollHeight);
        }
    }, 100);
}

export function setMessages(payload) {
    return { type: SET_MESSAGES, payload };
}


export function setData(payload) {
    return { type: SET_DATA, payload };
}

export function closeChat() {
    localStorage.removeItem('active_chat');
    return { type: CLOSE_CHAT };
}

export function addMessage(payload) {
    return { type: ADD_MESSAGE, payload };
}

export function removeMessage(payload) {
    return { type: REMOVE_MESSAGE, payload };
}

export function pushOldMessages(payload) {
    return { type: OLD_MESSAGE, payload };
}

export function pushNewMessages(payload) {
    return { type: CHAT_NEW_MESSAGE, payload };
}

export function setNoOlder() {
    return { type: CHAT_NO_OLDER_MESSAGE };
}

export function throwOlderError(payload) {
    return { type: CHAT_NO_OLDER_MESSAGE_ERROR, payload };
}

export function startOlderRequest() {
    return { type: CHAT_NO_OLDER_MESSAGE_REQUEST };
}

export function newMessageRequest() {
    return { type: CHAT_NEW_MESSAGE_REQUEST };
}

export function hideArrow() {
    return { type: CHAT_HIDE_ARROW };
}

export function startListRequest() {
    return { type: CHAT_LIST_REQUEST };
}

export function listSuccess(payload) {
    return { type: CHAT_LIST_SUCCESS, payload };
}

export function listError(payload) {
    return { type: CHAT_LIST_ERROR, payload };
}

export function sendMessageError(payload) {
    return { type: CHAT_NEW_MESSAGE_ERROR, payload };
}

export function chatReceivers(payload) {
    return { type: CHAT_RECEIVERS, payload };
}

export function getOlderMessages(id, page = 1) {
    return (dispatch) => {
        dispatch(startOlderRequest());
        return API.get(`/messages/chats/${id}/sent-list?page[size]=${loadOnce}&page[number]=${page}`, { timeout: 12000 })
            .then((res) => {
                if (res.data.meta) {
                    if (res.data.meta.current_page && res.data.meta.last_page) {
                        if (res.data.meta.current_page === res.data.meta.last_page) {
                            if (!Number.isNaN(parseInt(res.data.meta.current_page, 10))) {
                                dispatch(setNoOlder());
                            }
                        }
                    }
                }
                if (res.data.data && res.data.data.length) {
                    return dispatch(pushOldMessages(res.data.data));
                }
                return dispatch(pushOldMessages([]));
            })
            .catch((err) => {
                try {
                    if (err.response.status === 404) {
                        return dispatch(setChatId(null));
                    }
                } catch (e) {
                    console.error(e);
                }
                return dispatch(throwOlderError(err));
            });
    };
}

export function getNewMessages(id, remove = false) {
    return (dispatch) => {
        dispatch(newMessageRequest());
        return API.get(`/messages/chats/${id}/sent-list?page[size]=${loadOnce}`, { timeout: 12000 })
            .then((res) => {
                if (remove) {
                    dispatch(removeMessage(remove));
                }
                try {
                    if (res.data?.chat?.receivers?.length > 0) {
                        dispatch(chatReceivers(res.data.chat.receivers));
                    }
                } catch (e) {
                    console.error(e);
                }
                if (res.data.data && res.data.data.length) {
                    return dispatch(pushNewMessages(res.data.data));
                }
                return dispatch(pushNewMessages([]));
            })
            .catch((err) => {
                try {
                    if (err.response.status === 404) {
                        return dispatch(setChatId(null));
                    }
                } catch (e) {
                    console.error(e);
                }
                if (remove) {
                    dispatch(removeMessage(remove));
                }
                return dispatch(pushNewMessages([]));
            });
    };
}

export function getMessagesList() {
    return (dispatch) => {
        dispatch(startListRequest());

        return API.get('/messages/available')
            .then((response) => {
                if (response.data.data && response.data.data.length) {
                    return dispatch(listSuccess(response.data.data));
                }
                return dispatch(listSuccess([]));
            })
            .catch((error) => dispatch(listError(error)));
    };
}

export function getMessages(id, withScroll = false) {
    return (dispatch) => API.get(`/messages/chats/${id}/sent-list?page[size]=${loadOnce}`, { timeout: 12000 })
        .then((res) => {
            if (res.data.data && res.data.data.length) {
                if (withScroll) {
                    dispatch(scrollToBottom());
                }
                dispatch(getMessagesList());
                dispatch(stopLoading());
                return dispatch(setMessages(res.data.data));
            }
            dispatch(stopLoading());
            return dispatch(setMessages([]));
        })
        .catch((err) => {
            try {
                if (err.response.status === 404) {
                    return dispatch(setChatId(null));
                }
            } catch (e) {
                console.error(e);
            }
            dispatch(stopLoading());
            return dispatch(setMessages([]));
        });
}

export function getChat(id) {
    return (dispatch) => {
        dispatch(setChatId(id));
        localStorage.setItem('active_chat', id);
        return API.get(`/messages/chats/${id}`)
            .then((response) => {
                if (response.data.data) {
                    dispatch(setData(response.data.data));
                    return dispatch(getMessages(id, true));
                }
                return dispatch(setChatId(null));
            })
            .catch((error) => {
                console.error(error);
                localStorage.removeItem('active_chat');
                try {
                    if (error.response.status === 404) {
                        return dispatch(setChatId(null));
                    }
                } catch (e) {
                    console.error(e);
                }
                // return dispatch(setData(null));
                return dispatch(setChatId(null));
            });
    };
}

export function sendMessage(id, msg) {
    return (dispatch) => {
        const idToSet = parseInt(Moment().format('x'), 10);
        dispatch(addMessage({ msg, id: idToSet }));
        dispatch(scrollToBottom());
        const data = {
            chat_id: id,
            text: msg,
        };
        return API.post('/messages/send', querystring.stringify(data))
            .then(() => dispatch(getNewMessages(id, idToSet)))
            .catch((error) => {
                console.error(error);
                dispatch(scrollToBottom());
                return dispatch(sendMessageError(idToSet));
            });
    };
}

export function sendFile(id, file) {
    return (dispatch) => {
        const data = new FormData();
        dispatch(startLoading());
        data.append('file', file);
        return API.post('/messages/upload-file', data)
            .then((response) => {
                if (response.status === 201 && response.data.data.id) {
                    return API.post('/messages/send', querystring.stringify({
                        attachment_id: response.data.data.id,
                        chat_id: id,
                    }))
                        .then(() => dispatch(getMessages(id, true)))
                        .catch((err) => {
                            console.error(err);
                            return dispatch(stopLoading());
                        });
                }
                return dispatch(stopLoading());
            })
            .catch((error) => {
                console.error(error);
                return dispatch(stopLoading());
            });
    };
}

export function hideArrowButton() {
    return (dispatch) => dispatch(hideArrow());
}
