import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';

import './style.scss';

const FormWrapper = ({
    children,
    initialValues,
    onSubmit,
}) => (
    <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={false}
        enableReinitialize={true}
    >
        {(formikBag) => (
            <Form className="pagination-form">
                {children(formikBag)}
            </Form>
        )}
    </Formik>
);

FormWrapper.propTypes = {
    children: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired, //eslint-disable-line
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        isLoading: user.loading,
    };
};

export default connect(mapStateToProps)(FormWrapper);
