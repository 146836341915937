import React, { useState, useRef, useEffect } from 'react';

import './styles.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'services/classNames';
import translations from 'services/translations';
import Logout from 'components/Logout';
import { hasPermission } from 'services';

const UserMenu = ({ user, adminPanel }) => {
    const [clicked, setClicked] = useState(false);
    const prodUrl = 'https://admin.transassist.eu';
    let adminPanelUrl = prodUrl;

    if (window.location.host === 'localhost:3000'
        || window.location.host === 'front-tests-dev.transassist.eu') {
        adminPanelUrl = 'https://admin-tests-dev.transassist.eu';
    }

    if (window.location.host === 'front-tests-prod.transassist.eu') {
        adminPanelUrl = 'https://admin-tests-prod.transassist.eu';
    }

    if (window.location.host === 'app-staging.transassist.eu') {
        adminPanelUrl = 'https://admin-staging.transassist.eu';
    }

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setClicked(false);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };
    let styles = {};
    let nameAndSurname = '';
    let role = '';
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    if (user.user) {
        if (user.user.avatar && user.user.avatar.download_url) {
            styles = { backgroundImage: `url(${user.user.avatar.download_url})` };
        }
        if (user.user.name) {
            nameAndSurname = user.user.name;
        }
        if (user.user.surname) {
            nameAndSurname = nameAndSurname.length ? `${user.user.name} ${user.user.surname}` : user.user.surname;
        }
        if (user.user?.role?.label) {
            role = user.user.role.label;
        }
    }

    const handleClick = () => {
        setClicked(!clicked);
    };

    return (
        <div className="UserMenu-outer">
            <div ref={wrapperRef} className={classNames('UserMenu', clicked ? 'active' : '')}>
                <button type="button" onClick={handleClick} className="UserMenu__button">
                    <span className="UserMenu__button--avatar" style={styles} />
                </button>
                {/* eslint-disable-next-line max-len */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className="UserMenu__info" onClick={() => { setClicked(false); }}>
                    <div className="UserMenu__info--name">{nameAndSurname}</div>
                    <div className="UserMenu__info--role">{translations(role)}</div>
                    <div className="UserMenu__info--profile">
                        <NavLink exact to="/settings" className="UserMenu__info--profile-url">{translations('front.header.check_profile')}</NavLink>
                    </div>
                    <div className="UserMenu__info--logout">
                        <Logout />
                    </div>
                    {adminPanel && (
                        <div className="UserMenu__info--admin">
                            <a href={adminPanelUrl}>{translations('front.admin.panel_name')}</a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

UserMenu.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.object.isRequired,
    adminPanel: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;
    const { permissions } = user;
    const adminPanel = hasPermission('admin_panel_manage', permissions);

    return {
        user,
        adminPanel,
    };
};

export default connect(mapStateToProps, null)(UserMenu);
