import { useEffect, useRef, useState } from 'react';
import { CancelToken } from 'axios';
import { API, errorHandler, transformLastDriverToSelect } from 'services';

const useTransportDrivers = (driver = null) => {
    const cancelTokenDrivers = useRef(null);
    const [last, setLast] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [driversLoading, setDriversLoading] = useState(false);
    const [driversError, setDriversError] = useState(false);

    const parseData = (d, lastPassings) => {
        const array = [];

        lastPassings.forEach((single) => {
            if (!single.driver || !single.vehicle) {
                return;
            }
            array.push(transformLastDriverToSelect(single));
        });

        d.forEach((single) => {
            array.push({
                value: parseInt(single.id, 10),
                label: `${single.name} ${single.surname}`,
            });
        });
        return array;
    };

    useEffect(() => {
        const sourceLast = CancelToken.source();
        const sourceDrivers = CancelToken.source();
        setDriversLoading(true);
        API.get('/last/passing/drivers', {
            cancelToken: sourceLast.token,
        })
            .then((response) => {
                if (response.status === 200 && response.data?.data?.length > 0) {
                    setLast(response.data.data);
                }
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setLast([]);
                });
            });
        API.get('/users?filters[driver]=1&page[size]=100', {
            cancelToken: sourceDrivers.token,
        })
            .then((response) => {
                if (response.data.data && response.data.data.length) {
                    const arrayToSet = response.data.data;
                    if (driver && driver.id) {
                        if (!response.data.data.find((elm) => elm.id === driver.id)) {
                            arrayToSet.push(driver);
                        }
                    }
                    setDrivers(arrayToSet);
                }
                setDriversLoading(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setDriversError(error);
                    setDriversLoading(false);
                });
            });
    }, [driver]);


    useEffect(() => () => {
        if (cancelTokenDrivers.current) {
            cancelTokenDrivers.current.cancel();
        }
    }, []);


    return {
        drivers: parseData(drivers, last),
        lastDrivers: last,
        driversLoading,
        driversError,
    };
};

export default useTransportDrivers;
