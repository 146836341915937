import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import translationGroup from 'services/translationGroup';

import Button from 'components/Button';

import './style.scss';

const FormWrapper = ({
    children,
    initialValues,
    validationSchema,
    onSubmit,
}) => {
    const translations = translationGroup('general.');
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            // validateOnChange={false}
        >
            {(formikBag) => (
                <Form className="SetFirstPassword__form">
                    {children(formikBag)}
                    <div className="SetFirstPassword__button-wrapper">
                        <Button shadow type="submit" disabled={!(formikBag.isValid && formikBag.dirty)}>{translations['general.save']}</Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

FormWrapper.propTypes = {
    children: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired, //eslint-disable-line
    validationSchema: PropTypes.any.isRequired, //eslint-disable-line
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        isLoggedIn: user.isLoggedIn,
    };
};

export default connect(mapStateToProps)(FormWrapper);
