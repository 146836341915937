import {
    TRANSPORTS_FILTERS_SET, TRANSPORTS_REMOVE_LOADING, TRANSPORTS_REMOVE_SET, TRANSPORTS_SEARCH
} from './constants';

const defaultValues = {
    status: [],
    sorting: '-updated_at',
    toRemove: [],
    toRemoveLoading: false,
    search: '',
};

export const initialGeneralState = {
    status: defaultValues.status,
    sorting: defaultValues.sorting,
    toRemove: defaultValues.toRemove,
    toRemoveLoading: defaultValues.toRemoveLoading,
    search: defaultValues.search,
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };
    let index;

    switch (action.type) {
    case TRANSPORTS_FILTERS_SET:
        newState.status = action.payload?.status.length > 0
            ? action.payload.status : defaultValues.status;
        newState.sorting = action.payload?.sorting.length > 0
            ? action.payload.sorting : defaultValues.sorting;
        return newState;
    case TRANSPORTS_REMOVE_SET:
        if (action.payload.length === 1) {
            index = newState.toRemove.findIndex((elm) => elm === action.payload[0]);
            if (index > -1) {
                newState.toRemove = [
                    ...newState.toRemove.slice(0, index),
                    ...newState.toRemove.slice(index + 1),
                ];
            } else {
                newState.toRemove = [
                    ...newState.toRemove,
                    action.payload[0]
                ];
            }
        } else {
            newState.toRemove = action.payload;
        }
        return newState;
    case TRANSPORTS_REMOVE_LOADING:
        newState.toRemoveLoading = !!action.payload;
        return newState;
    case TRANSPORTS_SEARCH:
        newState.search = action.payload.length ? action.payload : defaultValues.search;
        return newState;
    default:
        return state;
    }
};
