import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import API from 'services/API';
import { CancelToken } from 'axios';
import Success from 'components/Success';
import Error from 'components/Error';
import errorHandler from 'services/errorHandler';
import { getCustomCompanyValues, parseCustomCompanyData } from 'services/customCompany';
import UserForm from '../UserForm';

const UserAdd = ({ triggerFunction }) => {
    const cancelToken = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [formMessage, setFormMessage] = useState('');

    const handleSubmit = (values) => {
        setFormMessage('');
        setIsLoading(true);
        cancelToken.current = CancelToken.source();

        const data = {
            send_generate_password_mail: 1,
            name: values.name,
            surname: values.surname,
            phone: values.phone,
            role_name: values.role_name,
            email: values.email,
            ...(parseCustomCompanyData(values)),
        };

        API.post('/users', qs.stringify(data), {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                setFormError(null);
                if (response.data.message) {
                    setFormMessage(response.data.message);
                    triggerFunction();
                } else {
                    setFormMessage('');
                }
                const eventToSend = new Event('ta-users-form-reset');
                document.dispatchEvent(eventToSend);
                setIsLoading(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setFormError(error);
                    setIsLoading(false);
                });
            });
    };

    const formValues = {
        name: '',
        surname: '',
        phone: '',
        email: '',
        role_name: '',
        ...getCustomCompanyValues(),
    };

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    return (
        <div className="UserAdd">
            {formMessage && <Success message={formMessage} />}
            {formError && <Error errors={formError} />}
            <UserForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                formValues={formValues}
                isAdd
            />
        </div>
    );
};

UserAdd.propTypes = {
    triggerFunction: PropTypes.func.isRequired,
};

export default UserAdd;
