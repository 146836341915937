import React, {
    useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import Yup from 'services/translatedYup';
import querystring from 'querystring';
import {
    API, translations, errorHandler, hasPermission
} from 'services';
import Button from 'components/Button';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';
import Success from 'components/Success';
import { CancelToken } from 'axios';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { setUserNip, setUserNipMessage } from 'data/user/actions';
import Avatar from 'components/Avatar';
import SettingsAvatar from '../SettingsAvatar';


const SettingsCompanyForm = ({
    countries, needToSetCompany, setNip, setNipMessage, nipMessage,
}) => {
    const history = useHistory();
    const cancelToken = useRef(null);
    const [dataLoading, setDataLoading] = useState(true);
    const [dataError, setDataError] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [formResponse, setFormResponse] = useState(null);
    const [triggerChange, setTriggerChange] = useState(1);
    let logoUrl = '/images/logo-company-default.svg';

    // eslint-disable-next-line camelcase
    if (data?.logo?.download_url) {
        // eslint-disable-next-line camelcase
        logoUrl = data.logo.download_url;
    }

    const defaultValues = {
        name: (data ? data.name : '') || '',
        nip: (data ? data.nip : '') || '',
        address_country_id: (data ? data.address_country_id : '') || '',
        address_city: (data ? data.address_city : '') || '',
        address_postcode: (data ? data.address_postcode : '') || '',
        address_street: (data ? data.address_street : '') || '',
        address_housenumber: (data ? data.address_housenumber : '') || '',
    };
    const validationSchema = useMemo(() => Yup.object().shape({
        name: Yup.string().min(2).required(),
        nip: Yup.string().min(2).max(12).required(),
        address_country_id: Yup.number().required(),
        address_city: Yup.string().min(2).required(),
        address_postcode: Yup.string().min(2).required(),
        address_street: Yup.string().min(2).required(),
        address_housenumber: Yup.string().required(),
    }), []);

    const handleSubmit = (values) => {
        setNipMessage('');
        if (needToSetCompany) {
            history.push('/settings/company');
        }
        setLoading(true);
        setFormResponse(null);
        setFormError(null);
        cancelToken.current = CancelToken.source();
        API.put('/my-company', querystring.stringify(values), {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                setLoading(false);
                if (needToSetCompany) {
                    setNipMessage(translations('front.empty.company_updated'));
                    if (response.data.data.nip) {
                        setNip(response.data.data.nip);
                    } else {
                        window.location.reload(false);
                    }
                } else if (response.data.message) {
                    setFormResponse(response.data.message);
                }
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setLoading(false);
                    setFormError(error);
                });
            });
    };

    useEffect(() => {
        const source = CancelToken.source();
        API.get('/my-company', {
            cancelToken: source.token,
        })
            .then((response) => {
                setData(response.data.data);
                setDataLoading(false);
            })
            .catch((error) => {
                setDataError(error);
            });


        return () => {
            source.cancel();
        };
    }, [triggerChange]);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    if (dataLoading) {
        return <div className="loading-wrapper"><SmallLoader dark /></div>;
    }

    if (countries.length === 0) {
        return <></>;
    }

    return (
        <div className="SettingsCompanyForm">
            {dataError && <Error errors={dataError} />}
            {formError && <Error errors={formError} />}
            {formResponse && <Success message={formResponse} />}
            {nipMessage.length > 0 && <Success message={nipMessage} />}
            <div className="SettingsCompanyForm__avatar">
                <Avatar image={logoUrl} width={80} height={80} />
                <SettingsAvatar companyFallback={() => { setTriggerChange((v) => v + 1); }} />
            </div>
            <Formik
                initialValues={defaultValues}
                enableReinitialize
                onSubmit={(vals) => { handleSubmit(vals); }}
                validationSchema={validationSchema}
            >
                {(formikBag) => (
                    <Form className="SettingsCompanyForm__form">
                        <div className="SettingsCompanyForm__column">
                            <FormRow>
                                <FormField
                                    name="name"
                                    label={translations('front.settings.company.name')}
                                    isRequired
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="nip"
                                    label={translations('front.settings.company.nip')}
                                    isRequired
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="address_country_id"
                                    label={translations('front.settings.company.address_country')}
                                    isSelect={countries}
                                    isRequired
                                />
                            </FormRow>
                        </div>
                        <div className="SettingsCompanyForm__column">
                            <FormRow className="SettingsCompanyForm__city-outer">
                                <FormField
                                    name="address_postcode"
                                    label={translations('front.settings.company.address_postcode')}
                                    isRequired
                                />
                                <FormField
                                    name="address_city"
                                    label={translations('front.settings.company.address_city')}
                                    isRequired
                                />
                            </FormRow>
                            <FormRow className="SettingsCompanyForm__street-outer">
                                <FormField
                                    name="address_street"
                                    label={translations('front.settings.company.address_street')}
                                    isRequired
                                />
                                <FormField
                                    name="address_housenumber"
                                    label={translations('front.settings.company.address_housenumber')}
                                    isRequired
                                />
                            </FormRow>
                        </div>
                        <div className="SettingsCompanyForm__submit">
                            <Button
                                withLoader
                                loading={loading}
                                small
                                type="submit"
                                disabled={loading || !(formikBag.isValid)}
                            >
                                {translations('front.general.save')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

SettingsCompanyForm.propTypes = {
    countries: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    })).isRequired,
    needToSetCompany: PropTypes.bool.isRequired,
    setNip: PropTypes.func.isRequired,
    setNipMessage: PropTypes.func.isRequired,
    nipMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    const { countries } = state;
    const { user } = state;
    const { permissions } = user;
    const canUpdateCompany = hasPermission('my_company_update_data', permissions);
    const needToSetCompany = canUpdateCompany && user.nip === null;

    return {
        needToSetCompany,
        countries: countries.countries,
        nipMessage: user.nipMessage,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setNip: (n) => dispatch(setUserNip(n)),
    setNipMessage: (n) => dispatch(setUserNipMessage(n)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsCompanyForm);
