export default function (response) {
    const dataToReturn = {
        redirectTo: 0,
        lastPage: 1,
    };

    if (typeof response.data !== 'undefined' && typeof response.data.meta !== 'undefined') {
        if (response.data.meta.last_page
            && !Number.isNaN(parseInt(response.data.meta.last_page, 10))) {
            dataToReturn.lastPage = parseInt(response.data.meta.last_page, 10);
        }
        if (response.data.meta.current_page
            && response.data.meta.last_page
            && response.data.meta.current_page > response.data.meta.last_page) {
            dataToReturn.redirectTo = dataToReturn.lastPage;
        }
    }

    return dataToReturn;
}
