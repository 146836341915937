const translations = {
    loading: 'Wird geladen',
    loading_data: 'Daten wird geladen ...',
    validate_email: 'Dies ist nicht die richtige E-Mail-Adresse',
    validate_required: 'Dieses Feld wird benötigt',
    validate_the_same: 'Beide Passwörter müssen gleich sein',
    sign_in: 'Einloggen',
    sign_up__invitation: 'Hast du kein Konto?',
    sign_up: 'Registrieren!',
    forgot_password: 'Erinnern Sie sich nicht an das Passwort?',
    error_occurred: 'Es ist ein Fehler aufgetreten!',
    activation_token_broken: 'Aktivierungstoken ist falsch.',
    return_to_login: 'Zurück zum Login',
    activation_success: 'Danke! Ihr Konto ist jetzt aktiv.',
    forgot_password_submit: 'Das Passwort zurücksetzen.',
    password: 'Passwort',
    password_confirmation: 'Passwort wiederholen',
    email: 'Email',
    remember_me: 'Behalte mich in Erinnerung',
    welcome: 'Willkommen!',
    log_in_to_access: 'Melden Sie sich an, um zu beginnen',
    password_forgot_message: 'Kannst du dich nicht anmelden? Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen!',
    back_to: 'zurück zu',
    sign_in_page: 'Anmeldung',
    select: 'Wählen...',
    select_new: 'Schaffen:',
    invitation_sent: 'Einladung versendet',
    users_custom_company_data: 'Verwenden Sie andere Firmendaten',
    or_just_attachment_name: 'Oder füge einfach den Anhangsnamen hinzu:',
    other_docs: 'Andere Dokumente:',
    readBy: 'Gelesen von:',
};
export default translations;
