import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { transportDraftReset } from 'data/transportDraft/actions';

import './styles.scss';
import { confirm } from 'data/confirmation/actions';
import translations from 'services/translations';

const TransportClear = ({ reset, confirmation }) => {
    const { id } = useParams();
    const edit = !!id;
    const clear = () => {
        confirmation(translations('front.general.attention'), translations('front.general.confirm_please'), () => { reset(edit); window.location.reload(false); });
    };

    if (edit) {
        return <></>;
    }

    return (
        <button
            onClick={() => { clear(); }}
            className="TransportClear"
            type="button"
        >
            Clear data
        </button>
    );
};

TransportClear.propTypes = {
    reset: PropTypes.func.isRequired,
    confirmation: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    reset: (isEdit) => dispatch(transportDraftReset(false, isEdit)),
    confirmation: (t, d, c) => dispatch(confirm(t, d, c, true)),
});

export default connect(null, mapDispatchToProps)(TransportClear);
