import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { NavLink, useHistory } from 'react-router-dom';
import PerPage from 'components/PerPage';
import FormWrapper from './components/FormWrapper';
import FormRow from '../FormRow';
import FormField from '../FormField';

const Pagination = ({
    currentPage, lastPage, urlPrefix, keyName,
}) => {
    const history = useHistory();
    const prevPage = currentPage > 1 ? currentPage - 1 : currentPage;
    const nextPage = lastPage > currentPage + 1 ? currentPage + 1 : lastPage;

    const onSubmit = (vals) => {
        let pageToRedirect = currentPage;

        if (vals.page) {
            if (!Number.isNaN(parseInt(vals.page, 10))) {
                pageToRedirect = parseInt(vals.page, 10);
                if (pageToRedirect > lastPage) {
                    pageToRedirect = lastPage;
                }
                if (pageToRedirect < 1) {
                    pageToRedirect = 1;
                }
            }
        }

        if (pageToRedirect !== currentPage) {
            history.push(urlPrefix + (pageToRedirect));
        }
    };

    return (
        <div className="Pagination">
            <PerPage keyName={keyName} />
            <div className="Pagination__right">
                <div className="Pagination__from-to">
                    <FormWrapper
                        initialValues={{
                            page: currentPage,
                        }}
                        onSubmit={(values) => onSubmit(values)}
                    >
                        {() => (
                            <FormRow>
                                <FormField
                                    name="page"
                                    type="number"
                                    className="Pagination__input"
                                />
                            </FormRow>
                        )}
                    </FormWrapper>
                    <span className="Pagination__from-to--separator">/</span>
                    <span>{lastPage}</span>
                </div>
                <div className="Pagination__pages">
                    <NavLink to={urlPrefix + (prevPage)} exact className="Pagination__pages--btn Pagination__pages--btn-prev">Prev</NavLink>
                    <NavLink to={urlPrefix + (nextPage)} exact className="Pagination__pages--btn Pagination__pages--btn-next">Next</NavLink>
                </div>
            </div>
        </div>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
    urlPrefix: PropTypes.string.isRequired,
    keyName: PropTypes.string.isRequired,
};


export default Pagination;
