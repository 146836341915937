const translations = {
    loading: 'Pakrovimas',
    loading_data: 'Duomenų įkėlimas ...',
    validate_email: 'Tai nėra teisingas el. Pašto adresas',
    validate_required: 'Šį lauką būtina užpildyti',
    validate_the_same: 'Abu slaptažodžiai turi būti tokie patys',
    sign_in: 'Prisijungti',
    sign_up__invitation: 'Ar neturite paskyros?',
    sign_up: 'Registruotis!',
    forgot_password: 'Ar nepamenate slaptažodžio?',
    error_occurred: 'Įvyko klaida!',
    activation_token_broken: 'Aktyvinimo raktas yra neteisingas.',
    return_to_login: 'Grįžti į prisijungimą',
    activation_success: 'Ačiū! Dabar jūsų paskyra yra aktyvi.',
    forgot_password_submit: 'Atstatykite slaptažodį',
    password: 'Slaptažodis',
    password_confirmation: 'Pakartokite slaptažodį',
    email: 'El. Paštas',
    remember_me: 'Prisimink mane',
    welcome: 'Sveiki!',
    log_in_to_access: 'Prisijunkite, kad pradėtumėte',
    password_forgot_message: 'Ar negalite prisijungti? Įveskite savo el. Pašto adresą, kad iš naujo nustatytumėte slaptažodį!',
    back_to: 'Grįžti pas',
    sign_in_page: 'Prisijungti',
    select: 'Pasirinkite ...',
    select_new: 'Sukurti:',
    invitation_sent: 'Kvietimas išsiųstas',
    users_custom_company_data: 'Naudokite kitus įmonės duomenis',
    or_just_attachment_name: 'Arba tiesiog pridėkite priedo pavadinimą:',
    other_docs: 'Kiti dokumentai:',
    readBy: 'Skaityta:',
};
export default translations;
