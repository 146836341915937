import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import { translations } from 'services';
import './styles.scss';
import { confirm } from 'data/confirmation/actions';
import { useParams } from 'react-router-dom';
import { transportDraftClearLoads, transportDraftExtendedLoad, transportDraftLoadSummary } from 'data/transportDraft/actions';
import TransportLoadSummaryForm from '../TransportLoadSummaryForm';
import TransportLoadForm from '../TransportLoadForm';
import TransportLoadSingle from '../TransportLoadSingle';

const TransportLoad = ({
    extendedLoad, setExtended, setSimple, loadSummaryClear, confirmation,
    loadSummary, loads,
    deleteAllLoads,
}) => {
    const { id } = useParams();
    const edit = !!id;

    return (
        <div className="TransportLoad">
            {!extendedLoad && <TransportLoadSummaryForm />}
            {extendedLoad && (
                <>
                    {(
                        (loads.length > 0)
                            ? loads.map((elm, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <TransportLoadSingle load={elm} key={`${JSON.stringify(elm)}-${index}`} index={(index + 1)} />
                            )) : ''
                    )}
                    <TransportLoadForm />
                </>
            )}
            <Formik
                initialValues={{
                    is_extended: extendedLoad,
                }}
                onSubmit={() => null}
            >
                {({ values, setFieldValue }) => {
                    const changeViewHandler = () => {
                        setFieldValue('is_extended', !values.is_extended);
                        if (!values.is_extended) {
                            if (loadSummary.length) {
                                setTimeout(() => {
                                    loadSummaryClear(edit);
                                }, 300);
                            }
                            setExtended(edit);
                        } else {
                            if (loads.length) {
                                deleteAllLoads();
                            }
                            setSimple(edit);
                        }
                    };
                    return (
                        <FormRow className="TransportLoad__extended-form">
                            <FormField
                                name="is_extended"
                                type="checkbox"
                                label={translations('front.passing.single.load.extended')}
                                copy={translations('front.passing.single.load.extended')}
                                checked={values.is_extended}
                                onChange={() => {
                                    let hasValue = false;
                                    if (!values.is_extended) {
                                        if (loadSummary.length) {
                                            hasValue = true;
                                        }
                                    } else if (loads.length) {
                                        hasValue = true;
                                    }
                                    if (!hasValue) {
                                        changeViewHandler();
                                    } else {
                                        confirmation(translations('front.general.attention'), translations('front.passing.add.switch_load'), changeViewHandler);
                                    }
                                }}
                            />
                        </FormRow>
                    );
                }}
            </Formik>
        </div>
    );
};

TransportLoad.propTypes = {
    extendedLoad: PropTypes.bool.isRequired,
    loadSummary: PropTypes.string.isRequired,
    loads: PropTypes.arrayOf(PropTypes.object).isRequired,
    setExtended: PropTypes.func.isRequired,
    setSimple: PropTypes.func.isRequired,
    loadSummaryClear: PropTypes.func.isRequired,
    deleteAllLoads: PropTypes.func.isRequired,
    confirmation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const { extendedLoad, loadSummary, loads } = transportDraft;

    return {
        extendedLoad,
        loadSummary,
        loads,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setExtended: (edit = false) => dispatch(transportDraftExtendedLoad(true, edit)),
    setSimple: (edit = false) => dispatch(transportDraftExtendedLoad(false, edit)),
    loadSummaryClear: (edit = false) => dispatch(transportDraftLoadSummary('', edit)),
    deleteAllLoads: (edit = false) => dispatch(transportDraftClearLoads(edit)),
    confirmation: (t, d, c) => dispatch(confirm(t, d, c)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportLoad);
