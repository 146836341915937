import Moment from 'moment';

const timeProgress = (times = []) => {
    if (times.length === 0) {
        return 0;
    }

    const timesArray = [];
    times.forEach((elm) => {
        const val = parseInt(Moment(elm).format('x'), 10);
        if (!Number.isNaN(val)) {
            timesArray.push(parseInt(Moment(elm).format('x'), 10));
        }
    });

    let minDate = 0;
    let maxDate = 0;
    if (timesArray.length) {
        [minDate] = timesArray;
        [maxDate] = timesArray;
        timesArray.forEach((elm) => {
            if (!Number.isNaN(elm)) {
                if (elm > maxDate) {
                    maxDate = elm;
                }
                if (elm < minDate) {
                    minDate = elm;
                }
            }
        });
    }

    const now = parseInt(Moment().format('x'), 10);
    let progress = 0;
    if (!Number.isNaN(now)) {
        if (now > maxDate) {
            progress = 100;
        } else if (now < minDate) {
            progress = 0;
        } else {
            progress = (maxDate - now) / (maxDate - minDate);
        }
    }

    return progress;
};

export default timeProgress;
