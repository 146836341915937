import {
    CHATS_LIST_ERROR, CHATS_LIST_REQUEST, CHATS_LIST_SUCCESS,
    CHATS_LIST_NO_OLDER, CHATS_LIST_OLDER_REQUEST, CHATS_LIST_OLDER_SUCCESS, CHATS_LIST_OLDER_ERROR,
    CHATS_LIST_NEW_REQUEST,
    CHATS_LIST_NEW_ERROR,
    CHATS_LIST_NEW_SUCCESS,
} from './constants';

import { USER_LOGOUT } from '../user/constants';

export const initialGeneralState = {
    loading: false,
    error: false,
    chats: [],
    ids: {},
    hasOlder: true,
    loadingOlder: false,
    errorOlder: false,
    newLoading: false,
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };
    const olderMessagesToAdd = [];
    let index = -1;

    switch (action.type) {
    case CHATS_LIST_REQUEST:
        newState.loading = true;
        newState.error = false;
        return newState;
    case CHATS_LIST_SUCCESS:
        if (action.payload.length) {
            action.payload.forEach((elm) => {
                newState.ids[elm.id] = true;
            });
        }
        newState.chats = action.payload;
        newState.loading = false;
        newState.error = false;
        return newState;
    case CHATS_LIST_ERROR:
        newState.chats = [];
        newState.loading = false;
        newState.error = action.payload;
        return newState;
    case CHATS_LIST_NO_OLDER:
        newState.hasOlder = false;
        return newState;
    case CHATS_LIST_OLDER_REQUEST:
        newState.loadingOlder = true;
        newState.errorOlder = false;
        return newState;
    case CHATS_LIST_OLDER_SUCCESS:
        if (action.payload.length) {
            action.payload.forEach((elm) => {
                if (newState.ids[elm.id] !== true) {
                    olderMessagesToAdd.push(elm);
                    newState.ids[elm.id] = true;
                }
            });
        }
        newState.chats = newState.chats.concat(olderMessagesToAdd);
        newState.loadingOlder = false;
        newState.errorOlder = false;
        return newState;
    case CHATS_LIST_OLDER_ERROR:
        newState.loadingOlder = false;
        newState.errorOlder = action.payload;
        return newState;
    case CHATS_LIST_NEW_REQUEST:
        newState.newLoading = true;
        newState.error = false;
        return newState;
    case CHATS_LIST_NEW_SUCCESS:
        index = -1;
        if (typeof action.payload[0] !== 'undefined') {
            if (newState.chats.length === 0) {
                newState.chats = action.payload;
            } else if (newState.chats[0].id === action.payload[0].id) {
                newState.chats = [
                    action.payload[0],
                    ...newState.chats.slice(1),
                ];
            } else {
                index = newState.chats.map((e) => e.id).indexOf(action.payload[0].id);
                if (index === -1) {
                    newState.chats = [
                        action.payload[0],
                        ...newState.chats,
                    ];
                } else {
                    newState.chats = [
                        action.payload[0],
                        ...newState.chats.slice(0, index),
                        ...newState.chats.slice(index + 1),
                    ];
                }
            }
        }
        newState.newLoading = false;
        return newState;
    case CHATS_LIST_NEW_ERROR:
        newState.newLoading = false;
        newState.error = action.payload;
        return newState;
    case USER_LOGOUT:
        return { ...initialGeneralState, ids: {} };
    default:
        return state;
    }
};
