import React from 'react';
import PropTypes from 'prop-types';


import './styles.scss';

const MainContent = (props) => {
    const { children } = props;
    return (
        <div className="MainContent">
            <div className="MainContent--inner">
                {children}
            </div>
        </div>
    );
};

MainContent.propTypes = {
    children: PropTypes.node
};

MainContent.defaultProps = {
    children: <></>
};

export default MainContent;
