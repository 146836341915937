import React from 'react';

import './styles.scss';

import defaultTranslation from 'services/defaultTranslations';

import loader from 'images/loader-without-bgr.gif';
import PropTypes from 'prop-types';

const Loader = ({ hideText }) => {
    const language = localStorage.getItem('language') ?? 'en';
    const translation = defaultTranslation('loading_data', language);
    return (
        <div className="loader">
            <img src={loader} alt="" className="loader-img" />
            {!hideText && <span className="loader-txt">{translation}</span>}
        </div>
    );
};


Loader.propTypes = {
    hideText: PropTypes.bool,
};

Loader.defaultProps = {
    hideText: false
};

export default Loader;
