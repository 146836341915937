import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'services';
import style from './PassingsListElementInfo.module.scss';

const PassingsListElementInfo = ({
    passing, type, hidden, isFullWidth
}) => {
    if (!passing) {
        return <></>;
    }

    let titleString = '\u00A0';
    let titleTitleString = '';
    let timeString = '\u00A0';
    let timeTitleString = '';
    let addressString = '\u00A0';
    let hasAddress = false;
    let country = <></>;

    if (passing.name) {
        titleString = passing.name;
        titleTitleString = passing.name;
    }

    if (passing.is_vehicle) {
        return (
            <div className={classNames(style.Container, style[type],
                isFullWidth && style.IsFullWidth, hidden && style.IsHidden)}
            >
                <div className={style.Title} title={passing.name}>{passing.name}</div>
                <div className={style.Time}>{passing.registration_number}</div>
                <div className={style.Address}>{passing.vehicle_norm}</div>
            </div>
        );
    }
    if (passing.time) {
        timeString = passing.time;
        timeTitleString = passing.time;
    }

    if (passing.address_street
            && passing.address_postcode
            && passing.address_city) {
        hasAddress = true;
        addressString = `${passing.address_street}${passing.address_housenumber ? ` ${passing.address_housenumber}` : ''}, ${passing.address_postcode} ${passing.address_city}`;
    }

    if (hasAddress && passing.address_country) {
        country = (
            <>
                <br />
                {passing.address_country}
            </>
        );
    }


    const title = <div className={style.Title} title={titleTitleString}>{titleString}</div>;
    const time = <div className={style.Time} title={timeTitleString}>{timeString}</div>;
    const address = (
        <div className={style.Address}>
            {addressString}
            {country}
        </div>
    );
    return (
        <div className={classNames(style.Container, style[type],
            isFullWidth && style.IsFullWidth, hidden && style.IsHidden)}
        >
            {title}
            {time}
            {address}
        </div>
    );
};

PassingsListElementInfo.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    passing: PropTypes.object,
    hidden: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    type: PropTypes.oneOf(['from', 'to', 'vehicle']).isRequired,
};

PassingsListElementInfo.defaultProps = {
    passing: {
        name: '',
    },
    hidden: false,
    isFullWidth: false,
};

export default PassingsListElementInfo;
