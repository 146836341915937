import { useEffect, useState } from 'react';
import { API, errorHandler } from 'services';
import querystring from 'querystring';
import {
    TRANSPORT_ADD_SUBMISSION_STATUS_DRIVER
} from '../submissionStatuses';

const useTransportDriver = (status, carrier, contractor, transport = false) => {
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(false);

    const addMyCarrier = () => {
        if (carrier) {
            setIsLoading((v) => v + 1);
            API.post(`/passings/${transport.id}/carriers`, querystring.stringify(carrier))
                .then(() => {
                    setIsLoading((v) => v - 1);
                })
                .catch((err) => {
                    errorHandler(err, () => {
                        setIsLoading((v) => v - 1);
                        setError(err);
                    });
                });
        }
    };

    const inviteToPassing = () => {
        setIsLoading((v) => v + 1);
        API.post(`/passings/${transport.id}/invites`, querystring.stringify({ to_email: contractor.email }))
            .then(() => {
                setIsLoading((v) => v - 1);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setIsLoading((v) => v - 1);
                    setError(err);
                });
            });
    };

    useEffect(() => {
        if (status === TRANSPORT_ADD_SUBMISSION_STATUS_DRIVER) {
            if (contractor?.email?.length > 0) {
                if (transport?.invitations?.length) {
                    if (transport.invitations[0].to_email !== contractor.email) {
                        inviteToPassing();
                    }
                } else if (transport.current_carrier) {
                    if (transport.current_carrier.email !== contractor.email) {
                        if (transport?.carriers?.length) {
                            setIsLoading((v) => v + 1);
                            API.delete(`/passings/${transport.id}/carriers/${transport.carriers[0].id}`)
                                .then(() => {
                                    setIsLoading((v) => v - 1);
                                    inviteToPassing();
                                })
                                .catch((err) => {
                                    errorHandler(err, () => {
                                        setIsLoading((v) => v - 1);
                                        setError(err);
                                    });
                                });
                        } else {
                            inviteToPassing();
                        }
                    }
                } else {
                    inviteToPassing();
                }
            } else if (transport?.carriers?.length > 0) {
                if (transport.carriers[0].company_id === transport.company_id) {
                    setIsLoading((v) => v + 1);
                    API.put(`/passings/${transport.id}/carriers/${transport.carriers[0].id}`, querystring.stringify(carrier))
                        .then(() => {
                            setIsLoading((v) => v - 1);
                        })
                        .catch((err) => {
                            errorHandler(err, () => {
                                setIsLoading((v) => v - 1);
                                setError(err);
                            });
                        });
                } else {
                    setIsLoading((v) => v + 1);
                    API.delete(`/passings/${transport.id}/carriers/${transport.carriers[0].id}`)
                        .then(() => {
                            setIsLoading((v) => v - 1);
                            addMyCarrier();
                        })
                        .catch((err) => {
                            errorHandler(err, () => {
                                setIsLoading((v) => v - 1);
                                setError(err);
                            });
                        });
                }
            } else {
                addMyCarrier();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carrier, contractor, status, transport]);

    return { isLoading: isLoading > 0, error };
};

export default useTransportDriver;
