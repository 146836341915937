import { useEffect, useState } from 'react';
import { API, errorHandler } from 'services';
import querystring from 'querystring';
import {
    TRANSPORT_ADD_SUBMISSION_STATUS_FILES
} from '../submissionStatuses';

const useTransportFiles = (status, documents, transport = false) => {
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(false);

    const changeDocumentName = (document) => {
        if (document.newName === document.name) {
            return;
        }
        setIsLoading((v) => v + 1);
        API.put(`/passings/${transport.id}/documents/${document.id}`, querystring.stringify({
            name: document.newName,
        }))
            .then(() => {
                setIsLoading((v) => v - 1);
            }).catch((err) => {
                errorHandler(err, () => {
                    setIsLoading((v) => v - 1);
                    setError(err);
                });
            });
    };

    const uploadDocumentName = (name) => {
        setIsLoading((v) => v + 1);
        API.post(`/passings/${transport.id}/documents/store-no-file`, querystring.stringify({
            name,
        }))
            .then(() => {
                setIsLoading((v) => v - 1);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setIsLoading((v) => v - 1);
                    setError(err);
                });
            });
    };

    const uploadDocument = (URL, name) => {
        setIsLoading((v) => v + 1);
        fetch(URL)
            .then((r) => r.blob())
            .then((blobFile) => {
                if (blobFile) {
                    const formData = new FormData();
                    formData.append('file', blobFile, name);
                    API.post(`/passings/${transport.id}/documents`, formData)
                        .then(() => {
                            setIsLoading((v) => v - 1);
                        })
                        .catch((err) => {
                            errorHandler(err, () => {
                                setIsLoading((v) => v - 1);
                                setError(err);
                            });
                        });
                }
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setIsLoading((v) => v - 1);
                    setError(err);
                });
            });
    };

    const uploadDocumentOrName = (doc) => {
        if (doc.objectURL && doc.objectURL.length > 0) {
            uploadDocument(doc.objectURL, doc.newName || doc.name);
        } else {
            uploadDocumentName(doc.newName || doc.name);
        }
    };

    useEffect(() => {
        if (status === TRANSPORT_ADD_SUBMISSION_STATUS_FILES) {
            // eslint-disable-next-line camelcase
            if (transport?.additional_docs?.length) {
                transport.additional_docs.forEach((doc) => {
                    if (documents.findIndex((document) => document.id === doc.id) === -1) {
                        setIsLoading((v) => v + 1);
                        API.delete(`/passings/${transport.id}/documents/${doc.id}`)
                            .then(() => {
                                setIsLoading((v) => v - 1);
                            })
                            .catch((err) => {
                                errorHandler(err, () => {
                                    setError(err);
                                    setIsLoading((v) => v - 1);
                                });
                            });
                    }
                });
                documents.forEach((doc) => {
                    if (transport.additional_docs.findIndex(
                        (document) => document.id === doc.id
                    ) === -1) {
                        uploadDocumentOrName(doc);
                    } else if (doc.newName) {
                        changeDocumentName(doc);
                    }
                });
            } else {
                documents.forEach((doc) => {
                    uploadDocumentOrName(doc);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents, status, transport]);

    return { isLoading: isLoading > 0, error };
};

export default useTransportFiles;
