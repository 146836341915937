import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import timeProgress from 'services/timeProgress';
import PassingsListElementInfo from 'pages/Passings/components/PassingsListElementInfo';
import Avatar from 'components/Avatar';
import Status from 'components/Status';
import Icon from 'components/Icon';
import Progress from 'components/Progress';
import { NavLink } from 'react-router-dom';

const SimplePassing = ({ passing }) => {
    const [currentStatus, setCurrentStatus] = useState(passing.status_id);

    const readStatusChange = (newStatus) => {
        if (newStatus !== currentStatus) {
            setCurrentStatus(newStatus);
        }
        return newStatus;
    };

    const progress = timeProgress([
        passing.load_time_start,
        passing.load_time_end,
        passing.unload_time_start,
        passing.unload_time_end
    ]);

    const startingAdditionals = {
        time_start: passing.load_time_start,
        time_end: passing.load_time_end,
    };

    const destinationAdditionals = {
        time_start: passing.unload_time_start,
        time_end: passing.unload_time_end,
    };

    const starting = passing.starting_address
        ? { ...passing.starting_address, ...startingAdditionals }
        : { ...passing.sender, ...startingAdditionals };
    const destination = passing.destination_address
        ? { ...passing.destination_address, ...destinationAdditionals }
        : { ...passing.receiver, ...destinationAdditionals };

    let avatarUrl = '';
    try {
        avatarUrl = passing.carriers[0].driver.avatar.download_url;
        // eslint-disable-next-line no-empty
    } catch (error) {}

    return (
        <div className="SimplePassing">
            <div className="SimplePassing__avatar">
                <Avatar width={32} height={32} image={avatarUrl} />
            </div>
            <div className="SimplePassing__general">
                <Status
                    passing={passing.id}
                    status={currentStatus}
                    withMenu
                    handleStatusChange={readStatusChange}
                />
                <div className="SimplePassing__general--id">{passing.passing_idstr}</div>
            </div>
            <div style={{ marginBottom: '6px', }}><PassingsListElementInfo type="from" passing={starting} isFullWidth /></div>
            <PassingsListElementInfo type="to" passing={destination} isFullWidth />
            <Progress progress={progress} rotate />
            {passing.default_chat_id && (
                <NavLink
                    to={`/messages/${passing.default_chat_id}`}
                    className="SimplePassing__chat"
                >
                    <Icon className="SimplePassing__chat--img" icon="chat" />
                </NavLink>
            )}
        </div>
    );
};

SimplePassing.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    passing: PropTypes.object.isRequired,
};

export default SimplePassing;
