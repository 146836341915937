import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'services/classNames';
import chevron from './chevron-down.svg';

const MenuLink = ({
    icon, translation, to, ...props
}) => {
    const location = useLocation();
    const iconClass = 'Menu--link-icon--image';
    const icons = {
        start:
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={iconClass}>
        <path d="M7.53181 17.9039V15.1437C7.5318 14.4443 8.10554 13.876 8.81621 13.8716H11.4257C12.1395 13.8716 12.7182 14.4412 12.7182 15.1437V17.8959C12.7182 18.5026 13.2155 18.9956 13.8319 19H15.6122C16.4436 19.0021 17.2418 18.6785 17.8305 18.1006C18.4192 17.5228 18.75 16.7381 18.75 15.9198V8.07926C18.75 7.41825 18.4523 6.79124 17.9371 6.36715L11.889 1.60684C10.8318 0.77421 9.32185 0.801107 8.29595 1.67085L2.37795 6.36715C1.83841 6.77874 1.51594 7.40761 1.5 8.07926V15.9118C1.5 17.6173 2.90486 19 4.63784 19H6.37747C6.67425 19.0021 6.95961 18.8876 7.17023 18.6818C7.38085 18.476 7.53182 18.196 7.53181 17.9039Z" stroke="#9B9BBE" strokeWidth="1.8" />
    </svg>,
        transports:
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(iconClass, 'with-stroke')}>
        <path d="M14.6764 16.9463H3.32551C2.04559 16.9463 1 15.8998 1 14.6236V3.27184C1 1.99285 2.04559 0.947266 3.32551 0.947266H14.6773C15.9525 0.947266 17 1.99285 17 3.27184V14.6246C16.9991 15.8998 15.9525 16.9463 14.6764 16.9463Z" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 13L17 13" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.14648 5.12354H13.8513" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 8H9" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>,
        fleet:
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={iconClass}>
        <path d="M8.182 12.382C7.73013 12.382 7.36381 12.7483 7.36381 13.2002C7.36381 13.6521 7.73013 14.0184 8.18199 14.0184L8.182 12.382ZM9.81836 14.0184C10.2702 14.0184 10.6365 13.6521 10.6365 13.2002C10.6365 12.7483 10.2702 12.382 9.81836 12.382L9.81836 14.0184ZM8.18199 14.0184L9.81836 14.0184L9.81836 12.382L8.182 12.382L8.18199 14.0184Z" fill="#9B9BBE" />
        <path d="M6.54599 10.2818C6.09412 10.2818 5.7278 10.6481 5.7278 11.1C5.7278 11.5518 6.09412 11.9182 6.54599 11.9182L6.54599 10.2818ZM11.4551 11.9182C11.9069 11.9182 12.2733 11.5518 12.2733 11.1C12.2733 10.6481 11.9069 10.2818 11.4551 10.2818L11.4551 11.9182ZM6.54599 11.9182L11.4551 11.9182L11.4551 10.2818L6.54599 10.2818L6.54599 11.9182Z" fill="#9B9BBE" />
        <path d="M4.909 8.18182C4.45713 8.18182 4.09082 8.54813 4.09082 9C4.09082 9.45187 4.45713 9.81818 4.909 9.81818L4.909 8.18182ZM13.0908 9.81818C13.5427 9.81818 13.909 9.45187 13.909 9C13.909 8.54813 13.5427 8.18182 13.0908 8.18182L13.0908 9.81818ZM4.909 9.81818L13.0908 9.81818L13.0908 8.18182L4.909 8.18182L4.909 9.81818Z" fill="#9B9BBE" />
        <path d="M1.63698 5.72772C1.18512 5.72772 0.818804 6.09403 0.818804 6.5459C0.818804 6.99777 1.18512 7.36408 1.63698 7.36408L1.63698 5.72772ZM16.3643 7.36408C16.8161 7.36408 17.1824 6.99777 17.1824 6.5459C17.1824 6.09403 16.8161 5.72772 16.3643 5.72772L16.3643 7.36408ZM1.63698 7.36408L16.3643 7.36408L16.3643 5.72772L1.63698 5.72772L1.63698 7.36408Z" fill="#9B9BBE" />
        <path d="M1.63574 10.7002H3.27211C4.17584 10.7002 4.90847 11.4328 4.90847 12.3366C4.90847 13.2403 4.17584 13.9729 3.27211 13.9729H1.63574V10.7002Z" fill="#9B9BBE" />
        <path d="M13.0908 12.3366C13.0908 11.4328 13.8234 10.7002 14.7272 10.7002H16.3635V13.9729H14.7272C13.8234 13.9729 13.0908 13.2403 13.0908 12.3366Z" fill="#9B9BBE" />
        <path d="M0.818182 3.27273C0.818182 1.91712 1.91712 0.818182 3.27273 0.818182H14.7273C16.0829 0.818182 17.1818 1.91712 17.1818 3.27273V16.3636C17.1818 16.8155 16.8155 17.1818 16.3636 17.1818H14.1818C14.0312 17.1818 13.9091 17.0597 13.9091 16.9091C13.9091 16.156 13.2986 15.5455 12.5455 15.5455H11.4545H9.81818H8.18182H6.54545H5.45455C4.70143 15.5455 4.09091 16.156 4.09091 16.9091C4.09091 17.0597 3.9688 17.1818 3.81818 17.1818H1.63636C1.18449 17.1818 0.818182 16.8155 0.818182 16.3636V3.27273Z" stroke="#9B9BBE" strokeWidth="1.63636" />
    </svg>,
        message:
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={iconClass}>
        <path d="M4.375 3.00554C4.54121 2.58694 4.73727 2.26037 4.96565 2.06313C6.62315 0.645625 16.0056 0.645625 17.6631 2.06313C19.3206 3.48063 19.3206 11.5206 17.6631 12.9381C17.5326 13.0483 17.3488 13.1518 17.125 13.2462" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M14.9406 4.92055C13.2831 3.50305 3.90062 3.50305 2.24312 4.92055C0.585625 6.33805 0.585625 14.378 2.24312 15.7955C2.93689 16.3934 4.99605 16.737 7.2771 16.8335C7.34605 16.8365 7.40892 16.8744 7.44316 16.9344L8.41447 18.6342C8.49125 18.7685 8.68499 18.7685 8.76177 18.6342L9.73308 16.9344C9.76732 16.8744 9.83019 16.8365 9.89914 16.8335C12.1802 16.737 14.2393 16.3934 14.9331 15.7955C16.5981 14.3705 16.5981 6.33805 14.9406 4.92055Z" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>,
        documents:
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(iconClass, 'with-stroke')}>
        <path d="M14.6795 19H3.32054C2.04327 19 1 18.0418 1 16.8687V3.13135C1 1.95821 2.04327 1 3.32054 1H14.6795C15.9567 1 17 1.95821 17 3.13135V16.8687C17 18.0418 15.9567 19 14.6795 19Z" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" />
        <path d="M3.56445 6.99982H8.99529" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M3.56445 9.89972H14.4164" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M3.56445 12.8499H14.4164" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>,
        user:
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(iconClass, 'with-stroke')}>
        <path d="M7.66992 1.87305C8.33039 1.87305 8.95421 2.05549 9.49783 2.37674C9.79391 2.5522 10.0702 2.76956 10.3118 3.01922" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.3254 4.88281C9.19644 4.88281 7.47051 6.77274 7.47051 9.10524C7.47051 12.5045 8.54884 11.484 9.08355 12.5333C9.2202 12.8039 9.27169 13.4743 9.08355 13.7187C7.51804 15.7414 4.64844 14.184 4.64844 18.0381C4.64844 19.584 4.64844 20.127 11.3303 20.127" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.3262 4.88281C13.4581 4.88281 15.183 6.77274 15.183 9.10524C15.183 12.5045 14.1047 11.484 13.572 12.5333C13.4333 12.8039 13.3818 13.4743 13.572 13.7187C15.1365 15.7414 18.01 14.184 18.01 18.0381C18.01 19.584 18.01 20.127 11.3262 20.127" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.67015 1.87305C5.53924 1.87305 3.81331 3.76297 3.81331 6.09547C3.81331 9.49384 4.89263 8.47424 5.42734 9.52439C5.56399 9.79588 5.61449 10.4654 5.42734 10.709C3.86183 12.7307 0.989258 11.1725 0.989258 15.0266C0.989258 15.9484 0.989258 16.5114 2.4003 16.8187" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>,
        settings:
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={iconClass}>
        <path d="M9.49974 12.4458C11.1273 12.4458 12.4467 11.1263 12.4467 9.49876C12.4467 7.87117 11.1273 6.55176 9.49974 6.55176C7.87215 6.55176 6.55273 7.87117 6.55273 9.49876C6.55273 11.1263 7.87215 12.4458 9.49974 12.4458Z" stroke="#9B9BBE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.396 15.1288L7.716 15.6728L8.042 17.9978H10.958L11.285 15.6728L12.604 15.1288L14.479 16.5408L16.541 14.4778L15.13 12.6038L15.673 11.2848L18 10.9568V8.04076L15.673 7.71376L15.13 6.39376L16.541 4.52076L14.479 2.45776L12.604 3.86976L11.285 3.32676L10.958 0.999756H8.042L7.716 3.32676L6.396 3.86976L4.52 2.45776L2.459 4.52076L3.871 6.39376L3.328 7.71376L1 8.04076V10.9568L3.328 11.2848L3.871 12.6038L2.459 14.4778L4.52 16.5408L6.396 15.1288Z" stroke="#9B9BBE" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>,
        help:
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={iconClass}>
        <path d="M8.9999 17.0525C4.57 17.0525 0.947266 13.4279 0.947266 8.9999C0.947266 4.57 4.57 0.947266 8.9999 0.947266C13.4298 0.947266 17.0525 4.57095 17.0525 8.9999C17.0525 13.4279 13.4298 17.0525 8.9999 17.0525Z" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" />
        <path d="M6.9707 5.64987C7.32502 4.89102 8.09428 4.36523 8.98765 4.36523C10.2145 4.36523 11.2121 5.36281 11.2121 6.59155C11.2121 7.34092 10.8398 8.00408 10.2732 8.40576C10.2732 8.40576 9.08997 9.55871 9.08997 11.281" stroke="#9B9BBE" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0715 13.7722C10.0715 14.2649 9.6694 14.6732 9.17253 14.6732C8.67357 14.6732 8.27148 14.2639 8.27148 13.7722C8.27148 13.2753 8.67357 12.8701 9.17253 12.8701C9.67357 12.8701 10.0715 13.2764 10.0715 13.7722Z" fill="#9B9BBE" />
    </svg>

    };

    const iconElm = icon ? (
        <div className="Menu--link-icon">
            {icons[icon]}
        </div>
    ) : '';

    const content = (
        <>
            {iconElm}
            <span className="Menu--link-span">{translation}</span>
        </>
    );

    const touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);


    const asButton = (window.mobileAndTabletcheck() === true && touchsupport === true) ? (
        <button type="button">
            {content}
            {' '}
            <img src={chevron} alt="" className="Menu--link-chevron" />
        </button>
    ) : (
        <NavLink to={to} className="as-button">
            {content}
            {' '}
            <img src={chevron} alt="" className="Menu--link-chevron" />
        </NavLink>
    );

    const forceActive = to === '/users/list' && location.pathname.startsWith('/users/list');

    const asLink = (
        <NavLink exact activeClassName="is-active" className={classNames('Menu--hyperlink', forceActive && 'is-active')} to={to}>{content}</NavLink>
    );


    return (
        (typeof props.isButton !== 'undefined' && props.isButton ? asButton : asLink)
    );
};

MenuLink.propTypes = {
    icon: PropTypes.string,
    to: PropTypes.string,
    translation: PropTypes.string.isRequired,
};

MenuLink.defaultProps = {
    to: '/'
};

export default MenuLink;
