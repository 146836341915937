import React, { useState } from 'react';
import translationGroup from 'services/translationGroup';
import classNames from 'services/classNames';
import './styles.scss';
import PropTypes from 'prop-types';
import querystring from 'querystring';
import { getStatuses, API } from 'services';

const Status = ({
    status, handleStatusChange, passing, withMenu
}) => {
    const translations = translationGroup('passing.status');
    const [opened, setOpened] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(status);
    const [loading, setLoading] = useState(false);
    const statuses = getStatuses();
    let current = null;
    const showMenu = withMenu && (
        currentStatus !== 'waiting_for_accept'
        && currentStatus !== 'working_copy'
        && currentStatus !== 'rejected');

    statuses.forEach((elm) => {
        if (elm === currentStatus) {
            current = {
                id: currentStatus,
                translation: translations[`passing.status.${currentStatus}`]
            };
        }
    });


    const handleUpdate = (newStatus) => {
        const data = { status_id: newStatus };
        setLoading(true);

        return API.put(`/passings/${passing}`, querystring.stringify(data))
            .then((response) => {
                setLoading(false);
                setOpened(false);
                if (typeof response.data.data !== 'undefined' && typeof response.data.data.status_id !== 'undefined') {
                    // status = response.data.data.status_id;
                    setCurrentStatus(newStatus);
                    handleStatusChange(newStatus);
                }
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setOpened(false);
            });
    };

    const handleMenu = () => {
        if (opened) {
            setOpened(false);
        } else {
            setOpened(true);
        }
    };


    const statusesMenu = statuses.map((singleStatus) => {
        if (singleStatus === currentStatus
            || singleStatus === 'accepted'
            || singleStatus === 'rejected'
            || singleStatus === 'working_copy'
            || singleStatus === 'waiting_for_accept') {
            return <React.Fragment key={singleStatus} />;
        }
        return (
            <li key={singleStatus} className="Status__menu--element">
                <button type="button" className={classNames('Status__menu--element--btn', singleStatus)} onClick={() => handleUpdate(singleStatus)}>
                    {translations[`passing.status.${singleStatus}`]}
                </button>
            </li>
        );
    });

    return (
        <div className={classNames('Status', currentStatus, showMenu && 'withMenu', opened && 'open', loading && 'loading')}>
            <div className="Status__main">
                {
                    showMenu && <button type="button" className="Status__button" onClick={() => handleMenu()} />
                }
                {current ? current.translation : <>&nbsp;</>}
            </div>
            {
                showMenu && (
                    <ul className={classNames('Status__menu')}>
                        {statusesMenu}
                    </ul>
                )
            }
        </div>
    );
};

Status.propTypes = {
    status: PropTypes.string.isRequired,
    passing: PropTypes.number.isRequired,
    handleStatusChange: PropTypes.func,
    withMenu: PropTypes.bool,
};

Status.defaultProps = {
    withMenu: false,
    handleStatusChange: () => {}
};

export default Status;
