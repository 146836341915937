import React, { useState } from 'react';

import './styles.scss';
import translationGroup from 'services/translationGroup';
import API from 'services/API';
import querystring from 'querystring';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUser } from 'data/user/actions';
import Error from 'components/Error';
import Loader from '../Loader';
import SetFirstPasswordForm from './components/SetFirstPasswordForm';
import { errorHandler } from '../../services';


const SetFirstPassword = ({ getCurrentUser }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();


    const handleSubmit = (values) => {
        setLoading(true);
        API.post('/me', querystring.stringify({
            _method: 'put',
            password: values.password,
            password_confirmation: values.passwordConfirmation
        }))
            .then(
                () => {
                    setLoading(false);
                    history.push('/');
                    getCurrentUser();
                }
            )
            .catch((err) => {
                errorHandler(err, () => {
                    setError(err);
                    setLoading(false);
                });
            });
    };

    const translations = translationGroup('user.');

    return (
        <div className="SetFirstPassword">
            <div className="SetFirstPassword__inner">
                {error && <Error errors={error} />}
                {
                    loading ? <Loader /> : (
                        <div>
                            <div className="SetFirstPassword__header">
                                <p>{translations['user.set_password']}</p>
                                <p>{translations['user.set_password2']}</p>
                            </div>
                            <SetFirstPasswordForm onSubmit={handleSubmit} />
                        </div>
                    )
                }
            </div>
        </div>
    );
};

SetFirstPassword.propTypes = {
    getCurrentUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    getCurrentUser: () => dispatch(getUser(false)),
});
export default connect(null, mapDispatchToProps)(SetFirstPassword);
