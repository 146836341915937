import { useEffect, useState } from 'react';
import { API, errorHandler } from 'services';
import querystring from 'querystring';
import {
    TRANSPORT_ADD_SUBMISSION_STATUS_DESTINATION,
    TRANSPORT_ADD_SUBMISSION_STATUS_RECEIVER,
    TRANSPORT_ADD_SUBMISSION_STATUS_STARTING,
    TRANSPORT_ADD_SUBMISSION_STATUS_SENDER
} from '../submissionStatuses';

const useTransportPoint = (status, state = {
    sender: false,
    receiver: false,
    destinationAddress: false,
    startingAddress: false,
}, transport = false) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    let pointToUse = false;
    let pointID = false;
    let type = '';

    if (state?.sender !== false
        && state?.receiver !== false
        && state?.destinationAddress !== false
        && state?.startingAddress !== false
        && transport !== false) {
        switch (status) {
        case TRANSPORT_ADD_SUBMISSION_STATUS_DESTINATION:
            pointToUse = state.destinationAddress;
            type = 'destination_address';
            // eslint-disable-next-line camelcase
            if (transport?.destination_address?.id) {
                pointID = transport.destination_address.id;
            }
            break;
        case TRANSPORT_ADD_SUBMISSION_STATUS_RECEIVER:
            pointToUse = state.receiver;
            type = 'receiver';
            if (transport?.receiver?.id) {
                pointID = transport.receiver.id;
            }
            break;
        case TRANSPORT_ADD_SUBMISSION_STATUS_STARTING:
            pointToUse = state.startingAddress;
            type = 'starting_address';
            // eslint-disable-next-line camelcase
            if (transport?.starting_address?.id) {
                pointID = transport.starting_address.id;
            }
            break;
        case TRANSPORT_ADD_SUBMISSION_STATUS_SENDER:
            pointToUse = state.sender;
            type = 'sender';
            if (transport?.sender?.id) {
                pointID = transport.sender.id;
            }
            break;
        default:
            break;
        }
    }

    useEffect(() => {
        if (pointToUse !== false && transport?.id && pointToUse?.name.length > 0) {
            const method = pointID !== false ? 'put' : 'post';
            const emails = pointToUse?.email?.length > 0 ? pointToUse.email.split(',') : [];
            const email = emails.length > 0 ? emails[0] : '';
            const additionalEmails = emails.length > 1 ? emails.slice(1).join(',') : null;
            setIsLoading(true);
            API[method](`/passings/${transport.id}/destination-points${pointID !== false ? `/${pointID}` : ''}`, querystring.stringify({
                type,
                ...pointToUse,
                email,
                additional_email: additionalEmails,
            })).then(() => {
                setIsLoading(false);
            })
                .catch((err) => {
                    errorHandler(err, () => {
                        setError(err);
                        setIsLoading(false);
                    });
                });
        }
    }, [pointID, pointToUse, transport, type]);

    return { isLoading, error };
};

export default useTransportPoint;
