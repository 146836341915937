import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import appStoreImage from 'images/app-store.svg';
import appStoreMobileImage from 'images/app-store-mobile.svg';
import googlePlayImage from 'images/google-play.svg';
import googlePlayMobileImage from 'images/google-play-mobile.svg';
import classNames from 'services/classNames';
import AppVersion from 'components/AppVersion';
import Logo from './components/Logo';
import Menu from './components/Menu';

import './styles.scss';

const Sidebar = () => {
    const location = useLocation();
    const [menuActive, setMenuActive] = useState(false);

    useEffect(() => {
        setMenuActive(false);
    }, [location]);

    const toggleMenu = () => {
        if (menuActive) {
            setMenuActive(false);
        } else {
            setMenuActive(true);
        }
    };

    return (
        <>
            <button
                className={classNames('show-smart-menu', menuActive && 'active')}
                type="button"
                onClick={toggleMenu}
                id="show-smart-menu"
            >
                <span className="show-smart-menu--line" />
                <span className="show-smart-menu--line" />
                <span className="show-smart-menu--line" />
            </button>
            <div className={classNames('Sidebar', menuActive && 'visible')}>
                <div className="Sidebar__inner">
                    <div className="Sidebar__top">
                        <Logo />
                    </div>
                    <div className="Sidebar__middle">
                        <Menu />
                    </div>
                    <div className="Sidebar__bottom">
                        <div className="Sidebar__downloads">
                            <a href="https://apps.apple.com/pl/app/trans-assist/id1538245267" className="Sidebar__downloads--link" target="_blank" rel="noopener noreferrer">
                                <img
                                    width={160}
                                    height={48}
                                    className="Sidebar__downloads--img hide-md"
                                    src={appStoreImage}
                                    alt=""
                                />
                                <img
                                    width={40}
                                    height={40}
                                    className="Sidebar__downloads--img show-md"
                                    src={appStoreMobileImage}
                                    alt=""
                                />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=eu.transassist.transassistapp" className="Sidebar__downloads--link" target="_blank" rel="noopener noreferrer">
                                <img
                                    width={160}
                                    height={48}
                                    className="Sidebar__downloads--img hide-md"
                                    src={googlePlayImage}
                                    alt=""
                                />
                                <img
                                    width={40}
                                    height={48}
                                    className="Sidebar__downloads--img show-md"
                                    src={googlePlayMobileImage}
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="Sidebar--version">
                            <AppVersion />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
