import React, { useMemo } from 'react';
import Yup from 'services/translatedYup';
import PropTypes from 'prop-types';


import FormRow from 'components/FormRow';
import FormField from 'components/FormField';

import translationGroup from 'services/translationGroup';

import FormWrapper from '../FormWrapper';

const SetFirstPasswordForm = ({ onSubmit }) => {
    const translations = translationGroup('user.');

    const validationSchema = useMemo(() => Yup.object().shape({
        password: Yup.string().required(),
        passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required()
    }), []);

    return (
        <FormWrapper
            initialValues={{
                password: '', passwordConfirmation: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
        >
            {({ isSubmitting }) => (
                <>
                    <FormRow>
                        <FormField
                            name="password"
                            type="password"
                            icon="password"
                            label={translations['user.password']}
                        />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="passwordConfirmation"
                            type="password"
                            icon="password"
                            label={translations['user.password_repeat']}
                        />
                    </FormRow>
                </>
            )}
        </FormWrapper>
    );
};

SetFirstPasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default SetFirstPasswordForm;
