import React from 'react';
import PropTypes from 'prop-types';
import { classNames, translations } from 'services';
import style from './Document.module.scss';
import './styles.scss';

const Document = ({
    name, extension, downloadUrl, deleteFunc, editFunc
}) => (
    <div className={style.Document}>
        {downloadUrl?.length > 0 ? (
            <a
                href={downloadUrl}
                download={name}
                target="_blank"
                rel="noopener noreferrer"
                title={name}
                className={classNames(style.Document__doc, 'file', extension)}
            >
                {name}
            </a>
        ) : (
            <span
                title={name}
                className={classNames(style.Document__doc, 'file', extension, 'is-span')}
            >
                {name}
            </span>
        )}
        {typeof editFunc !== typeof false && (
            <>
                <button type="button" onClick={editFunc} className={classNames(style.Edit, 'km-tooltip')} data-tooltip={translations('front.general.edit')}>{translations('front.general.edit')}</button>
            </>
        )}
        {typeof deleteFunc !== typeof false && (
            <button
                onClick={deleteFunc}
                type="button"
                className={classNames(style.Delete, 'delete-button', 'km-tooltip')}
                data-tooltip={translations('front.general.delete')}
            >
                {translations('front.general.delete')}
            </button>
        )}
    </div>
);

Document.propTypes = {
    name: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
    downloadUrl: PropTypes.string,
    deleteFunc: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func,
    ]),
    editFunc: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func,
    ]),
};

Document.defaultProps = {
    deleteFunc: false,
    editFunc: false,
    downloadUrl: '',
};

export default Document;
