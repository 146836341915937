import React from 'react';
import PropTypes from 'prop-types';

import icons from './icons';

const Icon = ({ icon, ...props }) => {
    if (!icons[icon]) {
        return null;
    }

    return (
        <img src={icons[icon]} alt="" {...props} />
    );
};

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
};

export default Icon;
