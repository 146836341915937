import { API } from 'services';

import {
    TRANSPORT_SINGLE_GET_REQUEST, TRANSPORT_SINGLE_GET_ERROR, TRANSPORT_SINGLE_GET_SUCCESS,
    TRANSPORT_SINGLE_DELETE_REQUEST, TRANSPORT_SINGLE_DELETE_SUCCESS, TRANSPORT_SINGLE_DELETE_ERROR,
} from './constants';

export function startSingleGetRequest() {
    return { type: TRANSPORT_SINGLE_GET_REQUEST };
}

export function transportSingleGetSuccess(payload) {
    return { type: TRANSPORT_SINGLE_GET_SUCCESS, payload };
}

export function transportSingleGetError(payload) {
    return { type: TRANSPORT_SINGLE_GET_ERROR, payload };
}

export function startSingleDeleteRequest() {
    return { type: TRANSPORT_SINGLE_DELETE_REQUEST };
}

export function transportSingleDeleteSuccess(payload) {
    return { type: TRANSPORT_SINGLE_DELETE_SUCCESS, payload };
}

export function transportSingleDeleteError(payload) {
    return { type: TRANSPORT_SINGLE_DELETE_ERROR, payload };
}


export function getSingleTransport(transportID = '') {
    return (dispatch) => {
        dispatch(startSingleGetRequest());

        const includes = [
            'startingAddress',
            'destinationAddress',
            'sender',
            'receiver',
            'company',
            'createdBy',
            'passingLoads',
            'payments',
            'carriers',
            'signatures',
            'additionalDocs',
            'invitations',
            'adrLoads',
            'currentCarrier',
        ];
        const includeString = `include=${includes.join(',')}`;

        return API.get(`/passings/${transportID}?${includeString}`)
            .then((response) => dispatch(transportSingleGetSuccess(response.data.data)))
            .catch((error) => dispatch(transportSingleGetError(error)));
    };
}

export function deleteSingleTransport(transportID = '') {
    return (dispatch) => {
        dispatch(startSingleDeleteRequest());

        return API.delete(`/passings/${transportID}`)
            .then(() => dispatch(transportSingleDeleteSuccess()))
            .catch((err) => dispatch(transportSingleDeleteError(err)));
    };
}
