import React from 'react';

const transformLastDriverToSelect = (single) => ({
    value: `${single.driver_id}-${single.vehicle_id}${single.trailer ? `-${single.trailer_id}` : ''}`,
    label:
    <div>
        {single.driver.name}
        {' '}
        {single.driver.surname}
        {single.vehicle && (
            <div className="hide-if-selected">
                <small>
                    -
                    {' '}
                    {single.vehicle.brand}
                    {' '}
                    {single.vehicle.model}
                    {' '}
                    {single.vehicle.registration_number}
                </small>
            </div>
        )}
        {single.trailer && (
            <div className="hide-if-selected">
                <small>
                    -
                    {' '}
                    {single.trailer.brand}
                    {' '}
                    {single.trailer.model}
                    {' '}
                    {single.trailer.registration_number}
                </small>
            </div>
        )}
    </div>,
});

export default transformLastDriverToSelect;
