import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Form, Field, Formik } from 'formik';
import FormField from 'components/FormField';
import querystring from 'querystring';
import { API, errorHandler, translations } from 'services';
import Button from 'components/Button';
import { CancelToken } from 'axios';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';

const PassingPayments = ({ passing }) => {
    const cancelToken = useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [optionsVisible, setOptionsVisible] = useState(false);
    const currencyCodes = ['AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BOV', 'BRL', 'BSD', 'BTN', 'BWP', 'BYR', 'BZD', 'CAD', 'CDF', 'CHE', 'CHF', 'CHW', 'CLF', 'CLP', 'CNY', 'COP', 'COU', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'EUR', 'FJD', 'FKP', 'GBP', 'GEL', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'IQD', 'IRR', 'ISK', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LTL', 'LVL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MXV', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'SSP', 'STD', 'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'USD', 'USN', 'USS', 'UYI', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XAG', 'XAU', 'XBA', 'XBB', 'XBC', 'XBD', 'XCD', 'XDR', 'XFU', 'XOF', 'XPD', 'XPF', 'XPT', 'XTS', 'XXX', 'YER', 'ZAR', 'ZMW'];
    const currenciesForSelect = [];
    let hasPayments = false;
    let canEdit = false;

    if (passing.accesses && passing.accesses.length) {
        passing.accesses.forEach((access) => {
            if (access.access_type === 'owner' || access.access_type === 'driver' || access.access_type === 'carrier') {
                canEdit = true;
            }
        });
    }

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOptionsVisible(false);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    currencyCodes.forEach((currency) => {
        currenciesForSelect.push({
            value: currency,
            label: currency,
        });
    });
    let payment = {
        is_paid: false,
        receiver_additional_fee: '',
        receiver_balance: '',
        receiver_discount: '',
        receiver_insurance_fee: '',
        receiver_surcharges: '',
        receiver_transportable_fee: '',
        sender_additional_fee: '',
        sender_balance: '',
        sender_discount: '',
        sender_insurance_fee: '',
        sender_surcharges: '',
        sender_transportable_fee: '',
        to_pay_sum: '',
        currency_idstr: '',
    };
    let senderTotal = 0;
    let receiverTotal = 0;

    try {
        const pay = passing.payments[0];
        if (typeof pay !== 'undefined') {
            payment = {
                is_paid: !!pay.is_paid,
                receiver_additional_fee: pay.receiver_additional_fee ? `${pay.receiver_additional_fee}` : '',
                receiver_balance: pay.receiver_balance ? `${pay.receiver_balance}` : '',
                receiver_discount: pay.receiver_discount ? `${pay.receiver_discount}` : '',
                receiver_insurance_fee: pay.receiver_insurance_fee ? `${pay.receiver_insurance_fee}` : '',
                receiver_surcharges: pay.receiver_surcharges ? `${pay.receiver_surcharges}` : '',
                receiver_transportable_fee: pay.receiver_transportable_fee ? `${pay.receiver_transportable_fee}` : '',
                sender_additional_fee: pay.sender_additional_fee ? `${pay.sender_additional_fee}` : '',
                sender_balance: pay.sender_balance ? `${pay.sender_balance}` : '',
                sender_discount: pay.sender_discount ? `${pay.sender_discount}` : '',
                sender_insurance_fee: pay.sender_insurance_fee ? `${pay.sender_insurance_fee}` : '',
                sender_surcharges: pay.sender_surcharges ? `${pay.sender_surcharges}` : '',
                sender_transportable_fee: pay.sender_transportable_fee ? `${pay.sender_transportable_fee}` : '',
                to_pay_sum: pay.to_pay_sum ? `${pay.to_pay_sum}` : '',
                currency_idstr: pay.currency_idstr ? `${pay.currency_idstr}` : '',
            };
            hasPayments = true;

            ['sender', 'receiver'].forEach((prefix) => {
                ['additional_fee',
                    'balance',
                    'discount',
                    'insurance_fee',
                    'surcharges',
                    'transportable_fee'].forEach((elm) => {
                    if (!Number.isNaN(parseInt(payment[`${prefix}_${elm}`], 10))) {
                        if (prefix === 'sender') {
                            senderTotal += parseFloat(payment[`${prefix}_${elm}`]);
                        } else {
                            receiverTotal += parseFloat(payment[`${prefix}_${elm}`]);
                        }
                    }
                });
            });

            if (receiverTotal) {
                receiverTotal = receiverTotal.toFixed(2);
            }
            if (senderTotal) {
                senderTotal = senderTotal.toFixed(2);
            }
        }
    } catch (e) {
        console.error(e);
    }

    const defaultsToUse = payment;

    const handleSubmit = (values) => {
        let method = 'post';
        let paymentID = '';
        if (hasPayments) {
            method = 'put';
            paymentID = `/${passing.payments[0].id}`;
        }
        setLoading(true);
        cancelToken.current = CancelToken.source();
        API[method](`/passings/${passing.id}/payments${paymentID}`, querystring.stringify({ ...values, is_paid: values.is_paid ? 1 : 0 }), {
            cancelToken: cancelToken.current.token,
        })
            .then(() => {
                window.location.reload();
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setLoading(false);
                    setError(err);
                });
            });
    };

    if (isLoading) {
        return <div className="PassingPayments"><div className="PassingPayments-loading"><SmallLoader dark /></div></div>;
    }

    const emptyTable = (
        <tbody>
            <tr>
                <th scope="row">
                    <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.freightage')}</span>
                </th>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <th scope="row">
                    <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender_discount')}</span>
                </th>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <th scope="row">
                    <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender_balance')}</span>
                </th>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <th scope="row">
                    <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender_surcharges')}</span>
                </th>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <th scope="row">
                    <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender_additional_fee')}</span>
                </th>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <th scope="row">
                    <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender_insurance_fee')}</span>
                </th>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <th scope="row">
                    <strong>
                        <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.total')}</span>
                    </strong>
                </th>
                <td>-</td>
                <td>-</td>
            </tr>
        </tbody>
    );

    const tableHeader = (
        <thead>
            <tr>
                <th scope="col">
                    <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.to_pay')}</span>
                </th>
                <th scope="col">
                    <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender')}</span>
                </th>
                <th scope="col">
                    <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.receiver')}</span>
                </th>
            </tr>
        </thead>
    );

    const table = (
        <table className="PassingPayments__table">
            {tableHeader}
            {payment ? (
                <tbody>
                    <tr>
                        <th scope="row">
                            <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.freightage')}</span>
                        </th>
                        <td>
                            {isEdit ? (
                                <FormField name="sender_transportable_fee" />
                            ) : (<span className="PassingPayments__table--text">{payment.sender_transportable_fee}</span>)}
                        </td>
                        <td>
                            {isEdit ? (
                                <FormField name="receiver_transportable_fee" />
                            ) : (<span className="PassingPayments__table--text">{payment.receiver_transportable_fee}</span>)}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender_discount')}</span>
                        </th>
                        <td>
                            {isEdit ? (
                                <FormField name="sender_discount" />
                            ) : (<span className="PassingPayments__table--text">{payment.sender_discount}</span>)}
                        </td>
                        <td>
                            {isEdit ? (
                                <FormField name="receiver_discount" />
                            ) : (<span className="PassingPayments__table--text">{payment.receiver_discount}</span>)}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender_balance')}</span>
                        </th>
                        <td>
                            {isEdit ? (
                                <FormField name="sender_balance" />
                            ) : (<span className="PassingPayments__table--text">{payment.sender_balance}</span>)}
                        </td>
                        <td>
                            {isEdit ? (
                                <FormField name="receiver_balance" />
                            ) : (<span className="PassingPayments__table--text">{payment.receiver_balance}</span>)}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender_surcharges')}</span>
                        </th>
                        <td>
                            {isEdit ? (
                                <FormField name="sender_surcharges" />
                            ) : (<span className="PassingPayments__table--text">{payment.sender_surcharges}</span>)}
                        </td>
                        <td>
                            {isEdit ? (
                                <FormField name="receiver_surcharges" />
                            ) : (<span className="PassingPayments__table--text">{payment.receiver_surcharges}</span>)}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender_additional_fee')}</span>
                        </th>
                        <td>
                            {isEdit ? (
                                <FormField name="sender_additional_fee" />
                            ) : (<span className="PassingPayments__table--text">{payment.sender_additional_fee}</span>)}
                        </td>
                        <td>
                            {isEdit ? (
                                <FormField name="receiver_additional_fee" />
                            ) : (<span className="PassingPayments__table--text">{payment.receiver_additional_fee}</span>)}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.sender_insurance_fee')}</span>
                        </th>
                        <td>
                            {isEdit ? (
                                <FormField name="sender_insurance_fee" />
                            ) : (<span className="PassingPayments__table--text">{payment.sender_insurance_fee}</span>)}
                        </td>
                        <td>
                            {isEdit ? (
                                <FormField name="receiver_insurance_fee" />
                            ) : (<span className="PassingPayments__table--text">{payment.receiver_insurance_fee}</span>)}
                        </td>
                    </tr>
                    {
                        !isEdit && (
                            <tr>
                                <th scope="row">
                                    <strong>
                                        <span className="PassingPayments__table--text">{translations('front.passing.single.load.payments.total')}</span>
                                    </strong>
                                </th>
                                <td>
                                    <span className="PassingPayments__table--text">{senderTotal}</span>
                                </td>
                                <td>
                                    <span className="PassingPayments__table--text">{receiverTotal}</span>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            )
                : (
                    { emptyTable }
                )}
        </table>
    );

    return (
        <div className="PassingPayments">
            <div className="PassingPayments-heading" style={{ position: 'relative' }}>
                {translations('front.passing.single.load.payments.general')}
                {canEdit && !isEdit && (
                    <div
                        className="PassingPayments__actions"
                        ref={wrapperRef}
                    >
                        <button
                            type="button"
                            onClick={() => setOptionsVisible((t) => !t)}
                            className="PassingData__action option-button"
                        >
                            actions
                        </button>
                        <div
                            style={{ display: optionsVisible ? 'block' : 'none' }}
                            className="PassingPayments__actions-options"
                        >
                            <button
                                type="button"
                                className="PassingPayments__actions-options--edit"
                                onClick={() => { setIsEdit(true); }}
                            >
                                {translations('front.general.edit')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {error && <Error errors={error} />}
            {canEdit && isEdit ? (
                <Formik initialValues={defaultsToUse} onSubmit={handleSubmit}>
                    {() => (
                        <Form>
                            <div className="PassingPayments__section">
                                <div className="PassingPayments__section--h">&nbsp;</div>
                                <div className="PassingPayments__section-with-form">
                                    <Field type="checkbox" name="is_paid" className="PassingPayments__checkbox" id="km-is-paid" />
                                    <label
                                        htmlFor="km-is-paid"
                                        className="PassingPayments__checkbox-span"
                                    >
                                        {translations('passing.transportable_fee_paid')}
                                    </label>
                                </div>
                            </div>
                            <div className="PassingPayments__section">
                                <div className="PassingPayments__section--h">{translations('passing.currency')}</div>
                                <div className="PassingPayments__section-with-form">
                                    <FormField name="currency_idstr" isSelect={currenciesForSelect} />
                                </div>
                            </div>
                            {table}
                            <div className="PassingPayments__section">
                                <div className="PassingPayments__section--h">{translations('front.passing.single.load.payments.to_pay')}</div>
                                <div className="PassingPayments__section-with-form">
                                    <FormField name="to_pay_sum" />
                                </div>
                            </div>
                            <div className="PassingPayments__section with-buttons">
                                <Button type="submit" block theme="blue">{translations('front.general.save')}</Button>
                                <Button type="button" onClick={() => { setIsEdit(false); }} block theme="outline">{translations('front.general.cancel')}</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <>
                    <div className="PassingPayments__section">
                        <div className="PassingPayments__section--h">{translations('passing.transportable_fee_paid')}</div>
                        {/* eslint-disable-next-line camelcase */}
                        <div className="PassingPayments__section--v">{translations(`user.${payment?.is_paid ? 'yes' : 'no'}`)}</div>
                    </div>
                    {table}
                    <div className="PassingPayments__section">
                        <div className="PassingPayments__section--h">{translations('front.passing.single.load.payments.to_pay')}</div>
                        <div className="PassingPayments__section--v">{payment && payment.to_pay_sum.length ? `${payment.to_pay_sum}${payment.currency_idstr}` : '---'}</div>
                    </div>
                </>
            )}
        </div>
    );
};

PassingPayments.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    passing: PropTypes.object.isRequired,
};

export default PassingPayments;
