import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import translations from 'services/translations';
import './styles.scss';
import Button from 'components/Button';
import { getSingleTransport } from 'data/transport/actions';
import PassingCarrierCompany from './PassingCarrierCompany';
import PassingCarrierForm from './PassingCarrierForm';

const PassingCarrier = ({
    carriers, invitations, accesses, companyID, carrierID, currentCarrier, get, transportID,
}) => {
    const [isEdit, setIsEdit] = useState(false);
    if (typeof carriers[0] !== 'undefined') {
        let driverName = '';
        let vehicle = '';
        let vehicleRegistration = '';
        let trailer = '';
        let trailerRegistration = '';
        let canEdit = false;
        const showDriverLayout = carriers[0].driver || carriers[0].vehicle || carriers[0].trailer;

        if (accesses && accesses.length) {
            accesses.forEach((access) => {
                if (access.access_type === 'carrier' || access.access_type === 'driver') {
                    canEdit = true;
                }
            });
        }

        if (carriers[0].driver) {
            if (carriers[0].driver.name && carriers[0].driver.name.length) {
                driverName += carriers[0].driver.name;
            }
            if (carriers[0].driver.surname && carriers[0].driver.surname.length) {
                driverName += (driverName.length ? ' ' : '') + carriers[0].driver.surname;
            }
        }
        if (carriers[0].vehicle) {
            vehicle = `${carriers[0].vehicle.brand} ${carriers[0].vehicle.model}`;
            if (carriers[0].vehicle.registration_number
                && carriers[0].vehicle.registration_number.length) {
                vehicleRegistration = `(${carriers[0].vehicle.registration_number})`;
            }
        }
        if (carriers[0].trailer) {
            trailer = `${carriers[0].trailer.brand} ${carriers[0].trailer.model}`;
            if (carriers[0].trailer.registration_number
                && carriers[0].trailer.registration_number.length) {
                trailerRegistration = `(${carriers[0].trailer.registration_number})`;
            }
        }

        const emptyDriverLayout = (
            <div className="PassingCarrier-driver">
                <div className="PassingCarrier__header-with-edit">
                    <div className="PassingData-heading PassingCarrier__header">
                        {translations('front.passing.single.driver')}
                    </div>
                    {(!isEdit && canEdit) && (
                        <Button
                            type="button"
                            onClick={() => { setIsEdit(true); }}
                            theme="outline-light"
                        >
                            {translations('front.general.edit')}
                        </Button>
                    )}
                </div>
            </div>
        );

        const driverLayout = (
            <div className="PassingCarrier-driver">
                <div className="PassingCarrier__header-with-edit">
                    <div className="PassingData-heading PassingCarrier__header">
                        {translations('front.passing.single.driver')}
                    </div>
                    {(!isEdit && canEdit) && (
                        <Button
                            type="button"
                            onClick={() => { setIsEdit(true); }}
                            theme="outline-light"
                        >
                            {translations('front.general.edit')}
                        </Button>
                    )}
                </div>
                {(carriers[0].driver && driverName.length > 0) && (<div className="PassingCarrier-driver__name">{driverName}</div>)}
                {(carriers[0].vehicle || carriers[0].trailer) && (
                    <div className="PassingCarrier-driver__info">
                        <div className="PassingCarrier-driver__info--car">
                            <div className="PassingCarrier-driver__info-h" title={vehicle}>
                                {vehicle}
                            &nbsp;
                            </div>
                            <div className="PassingCarrier-driver__info-v" title={vehicleRegistration}>
                                {vehicleRegistration}
                            &nbsp;
                            </div>
                        </div>
                        {trailer.length > 0 && (
                            <div className="PassingCarrier-driver__info--trailer">
                                <div className="PassingCarrier-driver__info-h" title={trailer}>
                                    {trailer}
                            &nbsp;
                                </div>
                                <div className="PassingCarrier-driver__info-v" title={trailerRegistration}>
                                    {trailerRegistration}
                            &nbsp;
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="PassingCarrier-driver__comments">
                    <div className="PassingCarrier-driver__comments--header">{translations('front.passing.single.load.carrier_info')}</div>
                    <div className="PassingCarrier-driver__comments--value">
                        {(carriers[0].additional_info && carriers[0].additional_info.length > 0) ? carriers[0].additional_info : '---'}
                    </div>
                </div>
            </div>
        );
        if (companyID !== -1 && carrierID !== -1) {
            if (companyID === carrierID) {
                if (showDriverLayout) {
                    return (
                        <>
                            <div className="PassingCarrier">{driverLayout}</div>
                            {isEdit && (
                                <PassingCarrierForm
                                    callback={() => { setIsEdit(false); get(transportID); }}
                                />
                            )}
                        </>
                    );
                }
            } else if (Object.keys(currentCarrier).length) {
                return (
                    <div className="PassingCarrier">
                        <div className="PassingCarrier__header PassingData-heading">{translations('front.passing.single.nav.carrier')}</div>
                        <PassingCarrierCompany
                            company={currentCarrier}
                            email={currentCarrier.email}
                        />
                        {!isEdit && showDriverLayout && driverLayout}
                        {!isEdit && !showDriverLayout && emptyDriverLayout}
                        {isEdit && (
                            <PassingCarrierForm
                                callback={() => { setIsEdit(false); get(transportID); }}
                            />
                        )}
                    </div>
                );
            }
        }

        if (showDriverLayout) {
            return <div className="PassingCarrier">{driverLayout}</div>;
        }
    }


    if (typeof invitations[0] !== 'undefined') {
        return (
            <div className="PassingCarrier">
                <div className="PassingCarrier__header PassingData-heading">{translations('front.passing.single.nav.carrier')}</div>
                <PassingCarrierCompany
                    company={invitations[0].to_company_relation}
                    isInvitation
                    email={invitations[0].to_email}
                />
            </div>
        );
    }

    return <div />;
};

PassingCarrier.propTypes = {
    carriers: PropTypes.arrayOf(PropTypes.object).isRequired,
    invitations: PropTypes.arrayOf(PropTypes.object).isRequired,
    accesses: PropTypes.arrayOf(PropTypes.object).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    currentCarrier: PropTypes.object.isRequired,
    companyID: PropTypes.number.isRequired,
    carrierID: PropTypes.number.isRequired,
    transportID: PropTypes.number.isRequired,
    get: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transport } = state;

    return {
        carriers: transport.transport.carriers,
        invitations: transport.transport.invitations,
        accesses: transport.transport.accesses,
        companyID: transport.transport.company_id
            ? transport.transport.company_id : -1,
        carrierID: transport.transport.current_carrier_id
            ? transport.transport.current_carrier_id : -1,
        currentCarrier: transport.transport.current_carrier
        && Object.keys(transport.transport.current_carrier).length
            ? transport.transport.current_carrier : {},
        transportID: transport.transport.id,
    };
};

const mapDispatchToProps = (dispatch) => ({
    get: (transportToGet = '') => dispatch(getSingleTransport(transportToGet)),
    // del: (transportToGet = '') => dispatch(deleteSingleTransport(transportToGet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PassingCarrier);
