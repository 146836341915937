const translations = {
    loading: 'Bezig met laden',
    loading_data: 'Gegevens laden ...',
    validate_email: 'Dit is niet het juiste e-mailadres',
    validate_required: 'dit veld is verplicht',
    validate_the_same: 'Beide wachtwoorden moeten hetzelfde zijn',
    sign_in: 'Log in',
    sign_up__invitation: 'Heb je geen account?',
    sign_up: 'Register!',
    forgot_password: 'Herinner je het wachtwoord niet?',
    error_occurred: 'Er is een fout opgetreden!',
    activation_token_broken: 'Activatietoken is onjuist.',
    return_to_login: 'Keer terug naar inloggen',
    activation_success: 'Bedankt! Uw account is nu actief.',
    forgot_password_submit: 'Reset het wachtwoord',
    password: 'Wachtwoord',
    password_confirmation: 'herhaal wachtwoord',
    email: 'E-mail',
    remember_me: 'Onthoud me',
    welcome: 'Welkom!',
    log_in_to_access: 'Log in om te starten',
    password_forgot_message: 'Kan je niet inloggen? Voer uw e-mailadres in om uw wachtwoord opnieuw in te stellen!',
    back_to: 'terugkrijgen',
    sign_in_page: 'Log in',
    select: 'Kiezen...',
    select_new: 'Creëren:',
    invitation_sent: 'Uitnodiging verzonden',
    users_custom_company_data: 'Gebruik andere bedrijfsgegevens',
    or_just_attachment_name: 'Of voeg gewoon de naam van de bijlage toe:',
    other_docs: 'Andere documenten:',
    readBy: 'Gelezen door:',
};
export default translations;
