import { useEffect, useState } from 'react';
import { API, errorHandler, defaultTranslations } from 'services';

const useTransportDuplicate = (transportId) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [transport, setTransport] = useState(false);
    const lang = localStorage.getItem('language') || 'en';
    const defaultError = defaultTranslations('error_occurred', lang);

    useEffect(() => {
        if (!transportId || transportId < 0) {
            return;
        }
        setIsLoading(true);

        const includes = [
            'startingAddress',
            'destinationAddress',
            'sender',
            'receiver',
            'company',
            'createdBy',
            'passingLoads',
            'payments',
            'carriers',
            'signatures',
            'additionalDocs',
            'invitations',
            'adrLoads',
            'currentCarrier',
        ];
        const includeString = `include=${includes.join(',')}`;

        API.get(`/passings/${transportId}?${includeString}`)
            .then((response) => {
                if (response.status !== 200 || typeof response.data.data === 'undefined') {
                    setError(defaultError);
                } else {
                    setTransport(response.data.data);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setError(err);
                    setIsLoading(false);
                });
            });
    }, [defaultError, transportId]);

    return {
        isLoading,
        error,
        transport,
    };
};

export default useTransportDuplicate;
