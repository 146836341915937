import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import translationGroup from 'services/translationGroup';

import Button from 'components/Button';

import './style.scss';

const FormWrapper = ({
    children,
    initialValues,
    validationSchema,
    onSubmit,
    isLoading,
}) => {
    const reference = useRef(null);
    const translations = translationGroup('general');
    const handleFormResetEvent = useCallback(() => {
        if (reference.current) {
            reference.current.resetForm({
                values: initialValues
            });
        }
    }, [initialValues]);

    useEffect(() => {
        document.addEventListener('ta-users-form-reset', handleFormResetEvent);
        return () => {
            document.removeEventListener('ta-users-form-reset', handleFormResetEvent);
        };
    }, [handleFormResetEvent]);

    return (
        <Formik
            innerRef={reference}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            // validateOnChange={false}
        >
            {(formikBag) => (
                <Form className="User-form">
                    {children(formikBag)}
                    <Button block shadow loading={isLoading} withLoader type="submit" disabled={isLoading || !(formikBag.isValid)}>{translations['general.save']}</Button>
                </Form>
            )}
        </Formik>
    );
};

FormWrapper.propTypes = {
    children: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired, //eslint-disable-line
    validationSchema: PropTypes.any.isRequired, //eslint-disable-line
    isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        isLoggedIn: user.isLoggedIn,
    };
};

export default connect(mapStateToProps)(FormWrapper);
