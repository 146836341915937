export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_NIP = 'USER_NIP';
export const USER_NIP_MESSAGE = 'USER_NIP_MESSAGE';
export const USER_LOG_IN_REQUEST = 'USER_LOG_IN_REQUEST';
export const SET_USER_NOT_LOGGED_IN = 'SET_USER_NOT_LOGGED_IN';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR';
export const ACTIVATION_REQUEST = 'ACTIVATION_REQUEST';
