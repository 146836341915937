import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './styles.scss';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import ChatSingle from 'components/Chat/components/ChatSingle';
import { getChat } from 'data/currentChat/actions';
import { CancelToken } from 'axios';
import SmallLoader from 'components/SmallLoader';
import {
    API, classNames, errorHandler, translations
} from 'services';
import Error from 'components/Error';

const Messages = ({ click }) => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [error, setError] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const source = CancelToken.source();
        if (id) {
            click(id);
        } else {
            setIsLoading(true);
            setError(false);
            API.get('/messages/chats?page[size]=1', {
                cancelToken: source.token,
            })
                .then((response) => {
                    setIsLoading(false);
                    if (response.data.data && response.data.data.length) {
                        try {
                            history.push(`/messages/${response.data.data[0].id}`);
                        } catch (e) {
                            console.error(e);
                        }
                    } else {
                        setIsEmpty(true);
                    }
                })
                .catch((err) => {
                    errorHandler(err, () => {
                        setIsLoading(false);
                        setError(err);
                    });
                });
        }
        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);


    return (
        <div className={classNames('Messages', isEmpty && 'empty-layout')}>
            {error && <Error errors={error} />}
            {isLoading && (
                <div className="loading-wrapper">
                    <SmallLoader dark />
                </div>
            )}
            {(!isLoading && !isEmpty) && <ChatSingle fullScreen />}
            {(!isLoading && isEmpty) && (
                <div className="empty-layout-content">
                    <div className="empty-layout--copy">{translations('front.empty.full_chats_messages')}</div>
                    <NavLink to="/transports/add" className="empty-layout--add" exact>
                        <span className="empty-layout--add-inner">
                            {translations('route.api.passing.store')}
                        </span>
                    </NavLink>
                </div>
            )}
        </div>
    );
};

Messages.propTypes = {
    click: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    click: (chatID) => dispatch(getChat(chatID)),
});

export default connect(null, mapDispatchToProps)(Messages);
