import React, { useMemo } from 'react';
import Yup from 'services/translatedYup';
import PropTypes from 'prop-types';


import FormRow from 'components/FormRow';
import FormField from 'components/FormField';

import translations from 'services/translations';
import FormWrapper from '../FormWrapper';


const UserPrivacyForm = ({ onSubmit, isLoading }) => {
    const validationSchema = useMemo(() => Yup.object().shape({
        password: Yup.string().required().min(6),
        password_confirmation: Yup
            .string().required().min(6).oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }), []);

    return (
        <FormWrapper
            initialValues={{
                password: '',
                password_confirmation: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
            isLoading={isLoading}
        >
            {() => (
                <>
                    <FormRow>
                        <FormField
                            name="password"
                            type="password"
                            icon="password"
                            label={translations('front.users.password')}
                            isRequired
                        />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="password_confirmation"
                            type="password"
                            label={translations('front.users.password_confirmation')}
                            icon="password"
                            isRequired
                        />
                    </FormRow>
                </>
            )}
        </FormWrapper>
    );
};

UserPrivacyForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    isLoading: PropTypes.bool.isRequired,
};

export default UserPrivacyForm;
