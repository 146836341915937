import {
    HOME_PASSINGS_REQUEST,
    HOME_PASSINGS_ERROR,
    HOME_PASSINGS_LOADED,
    HOME_PASSINGS_SET_ACTIVE,
    HOME_PASSINGS_NEW_REQUEST,
    HOME_PASSINGS_NEW_LOADED,
    HOME_PASSINGS_NEW_ERROR,
} from './constants';

export function startHomePassingsRequest() {
    return { type: HOME_PASSINGS_REQUEST };
}

export function startHomePassingsNewRequest() {
    return { type: HOME_PASSINGS_NEW_REQUEST };
}

export function homePassingsRequestSuccess(payload) {
    return { type: HOME_PASSINGS_LOADED, payload };
}

export function homePassingsNewRequestSuccess(payload) {
    return { type: HOME_PASSINGS_NEW_LOADED, payload };
}

export function homePassingsRequestError(payload) {
    return { type: HOME_PASSINGS_ERROR, payload };
}

export function homePassingsNewRequestError(payload) {
    return { type: HOME_PASSINGS_NEW_ERROR, payload };
}

export function homeSetActivePassing(payload) {
    return { type: HOME_PASSINGS_SET_ACTIVE, payload };
}
