import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'services/classNames';

const ChatDefinedMessageContent = ({ data, list }) => {
    const params = [];

    if (data.form_params && Object.keys(data.form_params).length) {
        Object.keys(data.form_params).forEach((key) => {
            params.push({
                key,
                value: data.form_params[key],
            });
        });
    }

    const definedMessage = list.length ? list.find((elm) => elm.id === data.message.id) : undefined;
    let title = '';
    let values = <></>;
    let showValues = false;

    try {
        if (definedMessage) {
            let i = 0;
            const questions = [];
            const answers = [];

            if (definedMessage.params) {
                title = definedMessage.title;
                let currentOption = definedMessage.params.find(
                    (param) => param.name === params[i].key
                );
                if (currentOption) {
                    if (currentOption.options && currentOption.options.length) {
                        let currentAnswer = true;
                        while (currentAnswer && currentOption && i < params.length) {
                            questions.push(currentOption.title);
                            if (params[i]) {
                                if (currentOption.type === 'select' && currentOption.options.length) {
                                    currentAnswer = currentOption.options.find(
                                        // eslint-disable-next-line no-loop-func
                                        (elm) => elm.value === params[i].value
                                    );
                                    i += 1;
                                    if (currentAnswer.params && params[i]) {
                                        currentOption = currentAnswer.params.find(
                                            // eslint-disable-next-line no-loop-func
                                            (param) => param.name === params[i].key
                                        );
                                    } else {
                                        currentOption = false;
                                    }
                                    answers.push(currentAnswer.label);
                                } else {
                                    answers.push(params[i].value);
                                    i += 1;
                                    currentAnswer = false;
                                }
                            }
                        }
                    } else if (currentOption.type === 'date_time') {
                        answers.push(params[i].value);
                    } else {
                        answers.push('');
                    }
                }
            }

            if (questions.length && answers.length && questions.length === answers.length) {
                showValues = true;
                values = questions.map((question, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={question + index}>
                        {question}
                        {' '}
                        <span style={{ whiteSpace: 'nowrap' }}>{answers[index]}</span>
                    </div>
                ));
            }
        }
    } catch (e) {
        console.error(e);
        title = data.text;
        showValues = false;
    }


    return (
        <div className={classNames('ChatDefinedMessageContent', `type_${data.sent_message_id}`)}>
            <div className="ChatDefinedMessageContent__header">
                {title}
            </div>
            {showValues && (
                <div className="ChatDefinedMessageContent__params">
                    {values}
                </div>
            )}
        </div>
    );
};

ChatDefinedMessageContent.propTypes = {
    data: PropTypes.shape({
        attachment_file_id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        chat_id: PropTypes.number,
        company_id: PropTypes.number,
        created_at: PropTypes.string,
        form_params: PropTypes.any,
        from_email: PropTypes.string,
        from_user_id: PropTypes.number,
        id: PropTypes.number,
        is_sent: PropTypes.number,
        sent_message_id: PropTypes.number,
        text: PropTypes.string,
        user: PropTypes.object,
        isLoading: PropTypes.bool,
        message: PropTypes.object,
    }).isRequired,
    list: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = (state) => {
    const { currentChat } = state;

    return {
        list: currentChat.list,
    };
};

export default connect(mapStateToProps, null)(ChatDefinedMessageContent);
