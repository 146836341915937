const getUrl = (which) => {
    if (which === 'sign-up') {
        let signupUrl = 'https://www.transassist.eu/#get-started';

        if (window.location.host === 'localhost:3000'
            || window.location.host === 'front-tests-dev.transassist.eu') {
            signupUrl = 'https://staging.transassist.eu';
        }

        return signupUrl;
    }
    if (which === 'admin-panel') {
        let adminPanelUrl = 'https://admin.transassist.eu';

        if (window.location.host === 'localhost:3000'
            || window.location.host === 'front-tests-dev.transassist.eu') {
            adminPanelUrl = 'https://admin-tests-dev.transassist.eu';
        }

        if (window.location.host === 'front-tests-prod.transassist.eu') {
            adminPanelUrl = 'https://admin-tests-prod.transassist.eu';
        }

        if (window.location.host === 'app-staging.transassist.eu') {
            adminPanelUrl = 'https://admin-staging.transassist.eu';
        }

        return adminPanelUrl;
    }

    return '';
};

export default getUrl;
