const translationGroup = (domain) => {
    const strings = localStorage.getItem('translations');
    const parsedStrings = JSON.parse(strings);
    const forReturn = {};

    try {
        Object.keys(parsedStrings).forEach((keyName) => {
            if (Object.prototype.hasOwnProperty.call(parsedStrings, keyName)) {
                if (keyName.startsWith(domain)) {
                    forReturn[keyName] = parsedStrings[keyName];
                }
            }
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return {};
    }

    return forReturn;
};

export default translationGroup;
