import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Anchorme } from 'react-anchorme';
import Avatar from 'components/Avatar';
import classNames from 'services/classNames';
import SmallLoader from 'components/SmallLoader';
import Document from 'components/Document';
import checkIsImage from 'services/isImage';
import ChatDefinedMessageContent from '../ChatDefinedMessageContent';
import ChatMessageError from '../ChatMessageError';
import ChatAutoMessage from '../ChatAutoMessage';
import './styles.scss';
import ChatMessageReadby from './components/ChatMessageReadby';

const ChatMessage = ({ data, readBy }) => {
    const time = Moment(data.created_at).format('LT');
    const timeFull = Moment(data.created_at).format('LLL');
    const userID = !Number.isNaN(parseInt(localStorage.getItem('user_id'), 10))
        ? parseInt(localStorage.getItem('user_id'), 10)
        : 0;

    const isFile = !!data.attachment_file_id;
    const isImage = checkIsImage(data);
    let isMe = true;

    if (data.message && data.message.type && data.message.type.startsWith('auto')) {
        return <ChatAutoMessage data={data} />;
    }

    if (data.from_user_id) {
        if (data.from_user_id !== userID) {
            isMe = false;
        }
    }

    const text = (data.text && data.text.length > 0) && data.text.split('\n').map((item) => (
        <div key={`${item}-${data.id}`}>
            <Anchorme target="_blank" rel="noreferrer noopener">{item}</Anchorme>
            <br />
        </div>
    ));

    const imageMaxWidth = isMe ? 272 : 232;
    let content = (
        <div className="ChatMessage__content--text">{text}</div>
    );
    let imageWidth = 0;
    let imageHeight = 0;

    if (data.sent_message_id) {
        const parsedToInt = parseInt(data.sent_message_id, 10);
        if (parsedToInt >= 1 && data.sent_message_id <= 4) {
            content = <ChatDefinedMessageContent data={data} />;
        } else if (isImage) {
            if (data.attachment_file.image_width && data.attachment_file.image_height) {
                if (data.attachment_file.image_width < imageMaxWidth) {
                    imageWidth = data.attachment_file.image_width;
                    imageHeight = data.attachment_file.image_height;
                } else {
                    imageWidth = imageMaxWidth;
                    imageHeight = Math.round(
                        (imageWidth * data.attachment_file.image_height)
                        / data.attachment_file.image_width
                    );
                }
            }
            content = (
                <a
                    href={data.attachment_file.download_url}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="ChatMessage__content--image-outer"
                    style={{ minHeight: `${imageHeight}px` }}
                >
                    <img
                        src={data.attachment_file.download_url}
                        width={imageWidth > 0 ? imageWidth : ''}
                        height={imageHeight > 0 ? imageHeight : ''}
                        alt=""
                        className="ChatMessage__content--image"
                    />
                </a>
            );
        } else if (isFile) {
            content = (
                <Document
                    extension={data.attachment_file.extension}
                    name={data.attachment_file.name}
                    downloadUrl={data.attachment_file.download_url}
                />
            );
        }
    }

    return (
        <div className={classNames('ChatMessage', !isMe && 'reverse')} title={timeFull}>
            <div className="ChatMessage__time">{time}</div>
            <div className="ChatMessage__content">{content}</div>
            {
                !isMe && (
                    <div className="ChatMessage__avatar" title={`${data.user.name} ${data.user.surname}`}>
                        <Avatar
                            width={40}
                            height={40}
                            image={data.user.avatar.download_url}
                        />
                    </div>
                )
            }
            {data.isLoading && <SmallLoader dark />}
            {data.error && <ChatMessageError />}
            <ChatMessageReadby readBy={readBy} reverse={!isMe} />
        </div>
    );
};

ChatMessage.propTypes = {
    data: PropTypes.shape({
        attachment_file_id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        attachment_file: PropTypes.object,
        chat_id: PropTypes.number,
        company_id: PropTypes.number,
        created_at: PropTypes.string,
        form_params: PropTypes.any,
        from_email: PropTypes.string,
        from_user_id: PropTypes.number,
        id: PropTypes.number,
        is_sent: PropTypes.number,
        sent_message_id: PropTypes.number,
        text: PropTypes.string,
        user: PropTypes.object,
        isLoading: PropTypes.bool,
        error: PropTypes.bool,
        message: PropTypes.object,
        message_template: PropTypes.string,
    }).isRequired,
    readBy: PropTypes.string,
};

ChatMessage.defaultProps = {
    readBy: '',
};

export default ChatMessage;
