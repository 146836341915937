import React from 'react';
import PropTypes from 'prop-types';
import { translations } from 'services';
import Avatar from 'components/Avatar';
import './styles.scss';

const ChatModals = ({ showWhat, close, receivers }) => {
    if (showWhat === 'file') {
        return (
            <div className="ChatModals">
                {/* eslint-disable-next-line max-len */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                <div role="button" className="ChatModals__overlay" onClick={() => { close(); }} />
                <div className="ChatModals__content for-files">
                    <div className="ChatModals__content--header">{translations('front.modals.files.header')}</div>
                    <div className="ChatModals__content--copy">{translations('front.modals.files.content')}</div>
                    <button type="button" className="ChatModals__x" onClick={() => { close(); }} />
                    <button type="button" className="ChatModals__close" onClick={() => { close(); }}>{translations('front.general.close')}</button>
                </div>
            </div>
        );
    } if (showWhat === 'receivers' && receivers.length) {
        return (
            <div className="ChatModals">
                {/* eslint-disable-next-line max-len */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                <div role="button" className="ChatModals__overlay" onClick={() => { close(); }} />
                <div className="ChatModals__content for-receivers">
                    <div className="ChatModals__users">
                        {receivers.map((receiver) => (
                            <div className="ChatModals__users--user" key={receiver.id}>
                                {receiver.avatar && (
                                    <Avatar
                                        image={receiver.avatar.download_url}
                                        width={40}
                                        height={40}
                                    />
                                )}
                                {receiver.user ? (
                                    <>
                                        {`${receiver.user.name} ${receiver.user.surname}`}
                                        <div className="ChatModals__users--user__email">{receiver.email}</div>
                                    </>
                                ) : receiver.email}
                            </div>
                        ))}
                    </div>
                    <button type="button" className="ChatModals__x" onClick={() => { close(); }} />
                </div>
            </div>
        );
    }

    return <></>;
};

ChatModals.propTypes = {
    showWhat: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    receivers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ChatModals;
