import { useEffect, useRef, useState } from 'react';
import { CancelToken } from 'axios';
import querystring from 'querystring';
import { API, errorHandler } from 'services';

const useTransportInvitation = (tokens, callback = () => { window.location.reload(); }) => {
    const cancelToken = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const request = (data) => {
        setIsLoading(true);
        cancelToken.current = CancelToken.source();
        const lang = localStorage.getItem('language') ?? 'en';
        API.post(`/${lang}/guest/invitation-answer`, querystring.stringify(data), {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                console.log(response);
                setIsLoading(false);
                callback();
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setError(err);
                    setIsLoading(false);
                });
            });
    };

    const handleReject = () => {
        request({
            unique_token: tokens.unique_token,
            reject_token: tokens.reject_token,
        });
    };

    const handleAccept = () => {
        request({
            unique_token: tokens.unique_token,
            accept_token: tokens.accept_token,
        });
    };

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    return {
        isLoading,
        error,
        handleAccept,
        handleReject,
    };
};

export default useTransportInvitation;
