import { API } from 'services';

import {
    APP_VERSION_REQUEST,
    APP_VERSION_LOADED,
    APP_VERSION_ERROR,
} from './constants';

export function startAppVersionRequest() {
    return { type: APP_VERSION_REQUEST };
}

export function appVersionSuccess(payload) {
    return { type: APP_VERSION_LOADED, payload };
}

export function appVersionError(payload) {
    return { type: APP_VERSION_ERROR, payload };
}

export function getAppVersion() {
    return (dispatch) => {
        dispatch(startAppVersionRequest());
        return API.get('/version')
            .then((res) => {
                // eslint-disable-next-line camelcase
                if (res?.data?.front_version) {
                    return dispatch(appVersionSuccess(`${res.data.front_version}`));
                }
                return dispatch(appVersionSuccess(''));
            })
            .catch((err) => (dispatch(appVersionError(err))));
    };
}
