import React, { useEffect, useRef, useState } from 'react';
import { CancelToken } from 'axios';
import querystring from 'querystring';
import Loader from 'components/Loader';
import './styles.scss';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'components/Pagination';
import Button from 'components/Button';
import Error from 'components/Error';
import {
    setPaginationVariables, API, classNames, translations, errorHandler
} from 'services';
import TrucksListElement from './components/TrucksListElement';
import TrucksForm from './components/TrucksForm';
import TrailersForm from './components/TrailersForm';
import TrailerListElement from './components/TrailersListElement';

const Trucks = ({ perPage, isTrailers }) => {
    const cancelToken = useRef(null);
    const translationsGroup = {
        add: isTrailers ? translations('front.fleet.add.trailer') : translations('front.fleet.add.truck'),
    };

    const defaultFormValues = isTrailers ? {
        brand: '',
        model: '',
        registration_number: '',
        deadweight_load: '',
        gross_vehicle_mass: '',
        dimensions: '',
        capacity: '',
        type_idstr: '',
    } : {
        brand: '',
        model: '',
        registration_number: '',
        deadweight_load: '',
        gross_vehicle_mass: '',
    };

    const { page } = useParams();
    const pageNumber = page ? parseInt(page, 10) : 1;
    const [loading, setLoading] = useState(false);
    const [trucksList, setTrucksList] = useState([]);
    const [redirectTo, setRedirectTo] = useState(0);
    const [lastPage, setLastPage] = useState(pageNumber);
    const [formError, setFormError] = useState(null);
    const onPage = perPage.trucks ? perPage.trucks : 10;
    const [postRequestCounter, setPostRequestConter] = useState(0);
    const [formOpened, setFormOpened] = useState(false);
    const [initFormValues, setInitFormValues] = useState(defaultFormValues);
    const [isEdit, setIsEdit] = useState(-1);
    const [getError, setGetError] = useState(false);
    const [forceReload, setForceReload] = useState(1);

    const handleDelete = (idToRemove, isTrailer = false) => {
        setLoading(true);
        API.delete(`/${isTrailer ? 'trailers' : 'vehicles'}/${idToRemove}`)
            .then(() => {
                setLoading(false);
                setForceReload((v) => v + 1);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setLoading(false);
                });
            });
    };

    useEffect(() => {
        setLoading(true);
        const source = CancelToken.source();

        let url = `/vehicles?page[number]=${pageNumber}&page[size]=${onPage}`;

        if (isTrailers) {
            url = `/trailers?page[number]=${pageNumber}&page[size]=${onPage}`;
        }

        API.get(url, {
            cancelToken: source.token,
        })
            .then((response) => {
                const paginationVariables = setPaginationVariables(response);
                setRedirectTo(paginationVariables.redirectTo);
                setLastPage(paginationVariables.lastPage);
                if (response && response.data.data.length) {
                    setTrucksList(response.data.data);
                } else {
                    setTrucksList([]);
                }
                setLoading(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setGetError(error);
                    setLoading(false);
                });
            });

        return () => {
            source.cancel();
        };
    }, [pageNumber, onPage, postRequestCounter, isTrailers, forceReload]);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    if (redirectTo > 0) {
        return <Redirect to={`/fleet/trucks/${redirectTo}`} />;
    }

    const triggerEditForm = (elm) => {
        window.scrollTo(0, 0);
        setIsEdit(elm.id);
        setInitFormValues(isTrailers ? {
            brand: elm.brand ? elm.brand : '',
            model: elm.model ? elm.model : '',
            registration_number: elm.registration_number ? elm.registration_number : '',
            deadweight_load: elm.deadweight_load ? elm.deadweight_load : '',
            gross_vehicle_mass: elm.gross_vehicle_mass ? elm.gross_vehicle_mass : '',
            dimensions: elm.dimensions ? elm.dimensions : '',
            capacity: elm.capacity ? elm.capacity : '',
            type_idstr: elm.type_idstr ? elm.type_idstr : '',
        } : {
            brand: elm.brand ? elm.brand : '',
            model: elm.model ? elm.model : '',
            registration_number: elm.registration_number ? elm.registration_number : '',
            deadweight_load: elm.deadweight_load ? elm.deadweight_load : '',
            gross_vehicle_mass: elm.gross_vehicle_mass ? elm.gross_vehicle_mass : '',
            type_idstr: elm.type_idstr ? elm.type_idstr : '',
        });
        setFormOpened(true);
    };

    const trucksListView = trucksList.map((elm) => (
        isTrailers ? (
            <TrailerListElement
                trailer={elm}
                key={elm.id}
                hideForm={() => { setFormOpened(false); }}
                edit={triggerEditForm}
                del={() => { handleDelete(elm.id, true); }}
            />
        )
            : (
                <TrucksListElement
                    truck={elm}
                    key={elm.id}
                    hideForm={() => { setFormOpened(false); }}
                    edit={triggerEditForm}
                    del={() => { handleDelete(elm.id); }}
                />
            )
    ));

    const handleSubmit = (values) => {
        cancelToken.current = CancelToken.source();
        let url = '/vehicles';

        if (isTrailers) {
            url = '/trailers';
        }

        const data = {
            name: `name-${Math.floor(Date.now() / 1000)}`,
            ...values,
        };

        if (isEdit !== -1) {
            if (isTrailers) {
                url = `/trailers/${isEdit}`;
            } else {
                url = `/vehicles/${isEdit}`;
            }
            const method = '_method';
            data[method] = 'put';
        }


        setLoading(true);
        API.post(url, querystring.stringify(data), {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                if (response.status === 201) {
                    setPostRequestConter((postRequestCounter + 1));
                    setFormOpened(false);
                } else if (response.status === 200) {
                    setPostRequestConter((postRequestCounter + 1));
                    setInitFormValues(defaultFormValues);
                    setFormOpened(false);
                }
                setLoading(false);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setLoading(false);
                    setFormError(err);
                });
            });
    };

    return (
        <>
            <div className="list-nav">
                <div className="TrucksList-form-outer">
                    <Button
                        withPlus
                        small
                        onClick={() => {
                            setIsEdit(-1);
                            setInitFormValues({
                                timestamp: Math.floor(Date.now() / 1000),
                                ...defaultFormValues
                            });
                            setFormOpened(true);
                        }}
                    >
                        {translationsGroup.add}
                    </Button>
                    <div className={classNames('TrucksList-form-inner', formOpened && 'form-opened', isTrailers && 'is-trailer-form')}>
                        <Button
                            onClick={() => { setFormOpened(false); }}
                            close
                        >
                            close
                        </Button>
                        <Error errors={formError} />
                        {
                            isTrailers ? (
                                <>
                                    {formOpened && (
                                        <TrailersForm
                                            formValues={initFormValues}
                                            onSubmit={handleSubmit}
                                            isLoading={loading}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    {formOpened && (
                                        <TrucksForm
                                            formValues={initFormValues}
                                            onSubmit={handleSubmit}
                                            isLoading={loading}
                                        />
                                    )}
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={classNames('TrucksList', 'list', ((!loading && trucksList.length === 0) && 'empty-layout'))}>
                {getError && <Error errors={getError} /> }
                {loading ? <Loader hideText /> : (
                    <>
                        {trucksList.length > 0 && trucksListView}
                        {trucksList.length === 0
                        && (
                            <div className="empty-layout-content">
                                <div className="empty-layout--copy">
                                    {translations(isTrailers ? 'front.empty.trailers_list' : 'front.empty.trucks_list')}
                                </div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsEdit(-1);
                                        setInitFormValues({
                                            timestamp: Math.floor(Date.now() / 1000),
                                            ...defaultFormValues
                                        });
                                        setFormOpened(true);
                                    }}
                                    className={classNames('empty-layout--add', isTrailers ? 'for-trailers' : 'for-cars')}
                                >
                                    <span className="empty-layout--add-inner">
                                        {translationsGroup.add}
                                    </span>
                                </button>
                            </div>
                        )}
                    </>
                ) }
            </div>
            <Pagination keyName="trucks" urlPrefix={isTrailers ? '/fleet/trailers/' : '/fleet/trucks/'} lastPage={lastPage} currentPage={pageNumber} />
        </>
    );
};


Trucks.defaultProps = {
    isTrailers: false,
};

Trucks.propTypes = {
    perPage: PropTypes.shape({
        passings: PropTypes.number,
        trucks: PropTypes.number,
        users: PropTypes.number,
    }).isRequired,
    isTrailers: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const { perPage } = state;
    return {
        perPage
    };
};

export default connect(mapStateToProps, null)(Trucks);
