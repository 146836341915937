import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageIcon from 'components/LanguageIcon';
import './style.scss';
import {
    API, classNames, errorHandler, mapLanguages
} from 'services';
import { CancelToken } from 'axios';
import querystring from 'querystring';
import Error from 'components/Error';
import SmallLoader from 'components/SmallLoader';
import active from './active.svg';

const LanguageMenu = ({ languagesList, isLoggedIn }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const cancelToken = useRef(null);
    const currentLanguage = localStorage.getItem('language') ?? 'en';

    const handleClick = useCallback((code) => {
        if (isLoggedIn) {
            setIsLoading(true);
            cancelToken.current = CancelToken.source();
            API.put('/me', querystring.stringify({ language: code }), {
                cancelToken: cancelToken.current.token,
            })
                .then(() => {
                    window.location.reload(false);
                })
                .catch((err) => {
                    errorHandler(err, () => {
                        setError(err);
                    });
                });
        } else {
            localStorage.setItem('language', code);
            window.location.reload(false);
        }
    }, [isLoggedIn]);

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);


    if (languagesList.length === 0) {
        return <></>;
    }

    return (
        <div className="LanguageMenu">
            {error && <Error errors={error} />}
            <div className={classNames('LanguageMenu__content', isLoading && 'loading')}>
                {isLoading && <SmallLoader dark />}
                {languagesList.map((language) => {
                    const lang = mapLanguages(language.code);

                    if (lang === false) {
                        return <React.Fragment key={language.code} />;
                    }

                    return (
                        <div className="LanguageMenu__single" key={language.code}>
                            <button
                                type="button"
                                onClick={() => { handleClick(language.code); }}
                                className={classNames('LanguageMenu__button', currentLanguage === language.code && 'active')}
                                title={`${lang.label} - ${lang.isoName}`}
                            >
                                <LanguageIcon className="LanguageMenu__flag" code={language.code} />
                                <span className="LanguageMenu__copy">{lang.label}</span>
                                {currentLanguage === language.code && <img src={active} className="LanguageMenu__active" alt="" />}
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

LanguageMenu.propTypes = {
    languagesList: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { languages, user } = state;
    const { isLoggedIn } = user;
    const { languagesList } = languages;
    return {
        languagesList, isLoggedIn,
    };
};

export default connect(mapStateToProps, null)(LanguageMenu);
