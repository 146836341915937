import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import SmallLoader from '../SmallLoader';

const Delayed = ({ children, waitBeforeShow }) => {
    const [isShown, setIsShown] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsShown(true);
        }, waitBeforeShow);
        return () => {
            if (location.pathname.includes('transport')) {
                if (location.pathname.includes('/edit') || location.pathname.includes('/add')) {
                    clearTimeout(timeout);
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitBeforeShow]);

    return isShown ? <>{children}</> : <div className="TransportAccept with-loader"><SmallLoader dark /></div>;
};

Delayed.propTypes = {
    children: PropTypes.node.isRequired,
    waitBeforeShow: PropTypes.number,
};

Delayed.defaultProps = {
    waitBeforeShow: 500,
};

export default Delayed;
