import {
    LAST_DESTINATIONS_REQUEST, LAST_DESTINATIONS_LOADED, LAST_DESTINATIONS_ERROR
} from './constants';
import { USER_LOGOUT } from '../user/constants';

export const initialGeneralState = {
    loading: false,
    error: false,
    destinations: [],
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case LAST_DESTINATIONS_REQUEST:
        newState.destinations = [];
        newState.loading = true;
        newState.error = false;
        return newState;
    case LAST_DESTINATIONS_LOADED:
        newState.destinations = action.payload;
        newState.loading = false;
        newState.error = false;
        return newState;
    case LAST_DESTINATIONS_ERROR:
        newState.destinations = [];
        newState.loading = false;
        newState.error = action.payload;
        return newState;
    case USER_LOGOUT:
        return { ...initialGeneralState };
    default:
        return state;
    }
};
