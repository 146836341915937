import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Error from 'components/Error';
import Loader from 'components/Loader';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSingleTransport, deleteSingleTransport } from 'data/transport/actions';
import PassingData from './components/PassingData/index';
import PassingNav from './components/PassingNav';
import PassingPayments from './components/PassingPayments';
import PassingSignatures from './components/PassingSignatures';
import PassingList from './components/PassingList';

const Passing = ({
    view, transport, loading, error, get, del,
}) => {
    const { id } = useParams();
    const history = useHistory();

    let hideMenu = true;
    let showActions = false;

    if (transport) {
        if (transport.accesses && transport.accesses.length) {
            transport.accesses.forEach((access) => {
                if (access.access_type === 'owner') {
                    showActions = true;
                    hideMenu = false;
                }
                if (access.access_type === 'carrier'
                    || access.access_type === 'driver'
                    || access.access_type === 'starting_address'
                    || access.access_type === 'destination_address') {
                    hideMenu = false;
                }
            });
        }
    }

    const handleDelete = () => {
        if (Object.keys(transport).length === 0) {
            return;
        }

        del(id)
            .then(() => {
                history.push('/transports/list');
            });
    };

    useEffect(() => {
        get(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (loading) {
        return <Loader hideText />;
    }

    return (
        <div className="PassingSingle">
            {error ? <Error errors={error} /> : (
                <div className="PassingSingle--main">
                    {Object.keys(transport).length > 0
                        ? (
                            <>
                                {!hideMenu && <PassingNav id={parseInt(id, 10)} />}
                                {hideMenu && <div style={{ height: '80px', width: '100%' }} />}
                                {
                                    view === 'main' && <PassingData passing={transport} deletePassing={handleDelete} hideActions={!showActions} />
                                }
                                {
                                    (!hideMenu && view === 'payments') && <PassingPayments passing={transport} />
                                }
                                {
                                    (!hideMenu && view === 'signatures') && <PassingSignatures passing={transport} />
                                }
                                <PassingList />
                            </>
                        ) : <>error</>}
                </div>
            )}
        </div>
    );
};

Passing.propTypes = {
    view: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    transport: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object,
    ]).isRequired,
    get: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
};

Passing.defaultProps = {
    view: 'main',
};

const mapStateToProps = (state) => {
    const { transport } = state;

    return {
        transport: transport.transport,
        loading: transport.transportLoading,
        error: transport.transportError,
    };
};

const mapDispatchToProps = (dispatch) => ({
    get: (transportToGet = '') => dispatch(getSingleTransport(transportToGet)),
    del: (transportToGet = '') => dispatch(deleteSingleTransport(transportToGet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Passing);
