const getAvatarUrl = (passing) => {
    let avatarUrl = '';

    try {
        if (passing.carriers && passing.carriers[0] && passing.carriers[0].driver) {
            avatarUrl = passing.carriers[0].driver.avatar.download_url;
        }
    } catch (e) {
        console.error(e);
    }

    return avatarUrl;
};

export default getAvatarUrl;
