const getMapStyles = () => [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36
            },
            {
                color: '#9B9BBE'
            },
            {
                lightness: 40
            }
        ]
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off'
            },
            {
                color: '#FFFFFF'
            },
            {
                lightness: 16
            }
        ]
    },
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#FEFEFE'
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#FEFEFE'
            },
            {
                lightness: 17
            },
            {
                weight: 1.2
            },
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative.locality',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9B9BBE'
            }
        ]
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#FAFCFF'
            },
            {
                lightness: 20
            },
            {
                visibility: 'simplified'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#F5F5F5'
            },
            {
                lightness: 21
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#DBE3F2'
            },
            {
                lightness: 21
            },
            {
                visibility: 'on'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#BDBDE0'
            },
            {
                lightness: 17
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#FFFFFF'
            },
            {
                lightness: 29
            },
            {
                weight: 0.2
            },
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#FFFFFF'
            },
            {
                lightness: 18
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#FFFFFF'
            },
            {
                lightness: 16
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#FFFFFF'
            },
            {
                lightness: 19
            },
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#DBE3F2'
            },
            {
                lightness: 17
            }
        ]
    }
];
export default getMapStyles;
