import {
    GET_PASSINGS,
    SET_PASSINGS,
    GET_TRUCKS,
    SET_TRUCKS,
    GET_USERS,
    SET_USERS,
    GET_DOCS,
    SET_DOCS,
    GET_CARRIERS,
    SET_CARRIERS,
} from './constants';

export function getPassing() {
    return { type: GET_PASSINGS };
}

export function getTrucks() {
    return { type: GET_TRUCKS };
}

export function getUsers() {
    return { type: GET_USERS };
}

export function getDocs() {
    return { type: GET_DOCS };
}

export function getAllCarriers() {
    return { type: GET_CARRIERS };
}

export function setPassings(payload) {
    localStorage.setItem('passings_per_page', payload);
    return { type: SET_PASSINGS, payload };
}

export function setTrucks(payload) {
    localStorage.setItem('trucks_per_page', payload);
    return { type: SET_TRUCKS, payload };
}

export function setUsers(payload) {
    localStorage.setItem('users_per_page', payload);
    return { type: SET_USERS, payload };
}

export function setDocs(payload) {
    localStorage.setItem('docs_per_page', payload);
    return { type: SET_DOCS, payload };
}

export function setAllCarriers(payload) {
    localStorage.setItem('carriers_per_page', payload);
    return { type: SET_CARRIERS, payload };
}
