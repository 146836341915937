import React from 'react';
import {
    classNames, getAvatarUrl, getDriverName, translations, getFullAddress, timeProgress
} from 'services';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import './styles.scss';
import Moment from 'moment';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Progress from 'components/Progress';

const HomePassing = ({ passing, changePassing, activePassing }) => {
    const showDetails = activePassing === passing.id;
    const avatarUrl = getAvatarUrl(passing);
    const name = getDriverName(passing);
    const progress = timeProgress([
        passing.load_time_start,
        passing.load_time_end,
        passing.unload_time_start,
        passing.unload_time_end
    ]);

    return (
        <div className="HomePassing">
            <Avatar image={avatarUrl} width={40} height={40} />
            <div className="HomePassing__column">
                <div className={classNames('HomePassing__status', `passing-status--${passing.status_id}`)}>{passing.status}</div>
                <div className="HomePassing__name" title={name}>{name}</div>
            </div>
            <div className="HomePassing__column">
                <div className="HomePassing__number">{translations('front.home.transport_number')}</div>
                <div className="HomePassing__id" title={name}>{passing.passing_idstr}</div>
            </div>
            {showDetails && (
                <div className="HomePassing__details">
                    <div className="HomePassing__progress">
                        <Progress progress={progress} rotate />
                    </div>
                    <div className="HomePassing__column">
                        <div className="HomePassing__label">{translations('front.passing.single.sender')}</div>
                        {passing.starting_address || passing.sender ? <div className="HomePassing__company_name">{passing.starting_address ? passing.starting_address.name : passing.sender.name}</div>
                            : <div className="HomePassing__company_name">&nbsp;</div>}
                        <div className="HomePassing__address">{getFullAddress(passing.starting_address ? passing.starting_address : passing.sender)}</div>
                    </div>
                    <div className="HomePassing__column">
                        <div className="HomePassing__label">{translations('front.home.date_start')}</div>
                        <div className="HomePassing__date">
                            {passing.load_time_end ? (
                                <>
                                    {translations('front.general.from')}
                                    :
                                    {' '}
                                    <strong>{Moment(passing.load_time_start).format('lll')}</strong>
                                </>
                            ) : <strong>{Moment(passing.load_time_start).format('lll')}</strong>}
                            {passing.load_time_end ? (
                                <>
                                    <br />
                                    {translations('front.general.to')}
                                    :
                                    {' '}
                                    <strong>{Moment(passing.load_time_end).format('lll')}</strong>
                                </>
                            ) : ''}
                        </div>
                    </div>
                    <div className="HomePassing__column">
                        <div className="HomePassing__label">{translations('front.passing.single.receiver')}</div>
                        {passing.destination_address || passing.receiver ? <div className="HomePassing__company_name">{passing.destination_address ? passing.destination_address.name : passing.receiver.name}</div>
                            : <div className="HomePassing__company_name">&nbsp;</div>}
                        <div className="HomePassing__address">{getFullAddress(passing.destination_address ? passing.destination_address : passing.receiver)}</div>
                    </div>
                    <div className="HomePassing__column">
                        <div className="HomePassing__label">{translations('front.home.date_end')}</div>
                        <div className="HomePassing__date">
                            {passing.unload_time_end ? (
                                <>
                                    {translations('front.general.from')}
                                    :
                                    {' '}
                                    <strong>{Moment(passing.unload_time_start).format('lll')}</strong>
                                </>
                            ) : <strong>{Moment(passing.unload_time_start).format('lll')}</strong>}
                            {passing.unload_time_end ? (
                                <>
                                    <br />
                                    {translations('front.general.to')}
                                    :
                                    {' '}
                                    <strong>{Moment(passing.unload_time_end).format('lll')}</strong>
                                </>
                            ) : ''}
                        </div>
                    </div>
                    <NavLink to={`/transports/single/${passing.id}`} className="HomePassing__more">{translations('front.passing.more')}</NavLink>
                </div>
            )}
            {!showDetails && (
                <button
                    type="button"
                    className="HomePassing__arrow"
                    onClick={() => { changePassing(passing.id); }}
                >
                    show details
                </button>
            )}
        </div>
    );
};


HomePassing.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    passing: PropTypes.object.isRequired,
    changePassing: PropTypes.func.isRequired,
    activePassing: PropTypes.number.isRequired,
};
const mapStateToProps = (state) => {
    const { home } = state;
    const {
        activePassing,
    } = home;

    return {
        activePassing,
    };
};

export default connect(mapStateToProps, null)(HomePassing);
