import React, {
    useState, useRef, useEffect, useCallback
} from 'react';
import * as querystring from 'querystring';
import Moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Formik, Field } from 'formik';
import { errorHandler, API, translations } from 'services';
import { CancelToken } from 'axios';

import { transportDraftPointUpdate, transportDraftValid, transportDraftVisited } from 'data/transportDraft/actions';
import FormRow from 'components/FormRow';
import FormikEffect from 'components/FormikEffect';
import FormField from 'components/FormField';

import TransportAddMap from '../TransportAddMap';
import validationSchema from './services/validation';
import PointForm from './components/PointForm';
import findLocation from './services/findLocation';
import reformatDate from './services/reformatDate';

import './styles.scss';

const TransportDestinationForm = ({
    edit,
    countries,
    to,
    sender,
    receiver,
    startingAddress, destinationAddress,
    // senderError,
    // receiverError,
    // startingError,
    // endingError,
    senderDifferent,
    receiverDifferent,
    setVisited,
    setValid,
    loadTimeStart,
    loadTimeEnd,
    unloadTimeStart,
    unloadTimeEnd,
    setPoint,
    senderVisited,
}) => {
    const reference = useRef(null);
    const [fullAddress, setFullAddress] = useState({
        string: '',
        code: '',
    });
    const timeout = useRef(null);
    const [firstRender, setFirstRender] = useState(0);
    // const timeoutChange = useRef(null);

    const handleSubmit = useCallback((values) => {
        const {
            company, point, timeStart, timeEnd, differentPoint, lat, lng,
        } = values;

        const timeToPush = {
            start: '',
            end: '',
        };

        if (Moment(timeStart).isValid()) {
            timeToPush.start = Moment(timeStart).format('YYYY-MM-DD HH:mm:ss');
        }

        if (Moment(timeEnd).isValid()) {
            timeToPush.end = Moment(timeEnd).format('YYYY-MM-DD HH:mm:ss');
        }

        setPoint({
            company: {
                ...company,
                lat: differentPoint ? null : lat,
                lng: differentPoint ? null : lng,
            },
            point: {
                ...point,
                lat: differentPoint ? lat : null,
                lng: differentPoint ? lng : null,
            },
            timeStart: timeToPush.start,
            timeEnd: timeToPush.end,
            differentPoint
        }, to, edit);
    }, [edit, setPoint, to]);

    const changeHandler = useCallback(() => {
        setTimeout(() => {
            if (reference.current === null) {
                return;
            }
            const { values, errors } = reference.current;
            const { company, point, differentPoint } = values;
            const valuesToUse = differentPoint ? point : company;
            try {
                if (errors && errors.company) {
                    return;
                }
                if (countries.length === 0) {
                    return;
                }
                const countryIndex = countries.findIndex(
                    (x) => x.value === valuesToUse.address_country_id
                );
                if (countryIndex === -1) {
                    return;
                }
                setFullAddress({
                    string: `${valuesToUse.address_street} ${valuesToUse.address_housenumber}, ${valuesToUse.address_city} ${valuesToUse.address_postcode}`,
                    code: countries[countryIndex].code,
                });
            } catch (e) {
                console.error(e);
            }
        }, 50);

        clearTimeout(timeout.current);

        timeout.current = setTimeout(() => {
            if (reference.current) {
                if (Object.keys(reference.current.touched).length > 0) {
                    handleSubmit(reference.current.values);
                }
            }
        }, 200);


        return () => {
            clearTimeout(timeout.current);
        };
    }, [countries, handleSubmit]);


    const defaultPointValues = {
        name: '',
        address_country_id: null,
        address_postcode: '',
        address_city: '',
        address_street: '',
        address_housenumber: '',
        email: '',
        phone: '',
        lat: '',
        lng: '',
        additional_info: '',
    };

    const { lat, lng } = findLocation(to,
        receiverDifferent, senderDifferent, destinationAddress,
        receiver, startingAddress, sender);

    const defaultsToUse = {
        company: (!to ? sender : receiver) || defaultPointValues,
        point: (!to ? startingAddress : destinationAddress) || defaultPointValues,
        timeStart: reformatDate((!to ? loadTimeStart : unloadTimeStart) || ''),
        timeEnd: reformatDate((!to ? loadTimeEnd : unloadTimeEnd) || ''),
        differentPoint: to ? receiverDifferent : senderDifferent,
        lat,
        lng,
    };

    useEffect(() => {
        const source = CancelToken.source();
        let timer;
        if (firstRender > 0) {
            timer = setTimeout(() => {
                if (fullAddress.string.length) {
                    const addressData = fullAddress.code.length ? {
                        country_code: fullAddress.code,
                        address: fullAddress.string,
                    } : {
                        address: fullAddress.string,
                    };

                    API.post('/location-find', querystring.stringify(addressData), {
                        cancelToken: source.token,
                    })
                        .then((response) => {
                            try {
                                const { data } = response.data;
                                if (data.lon && data.lon.length) {
                                    if (data.lat && data.lat.length) {
                                        reference.current.setFieldValue('lat', data.lat);
                                        reference.current.setFieldValue('lng', data.lon);
                                    }
                                }
                            } catch (e) {
                                console.error(e);
                            }
                        })
                        .catch((error) => {
                            errorHandler(error, () => {
                            });
                        });
                }
            }, 500);
        }

        return () => {
            clearTimeout(timer);
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullAddress?.string, fullAddress?.code]);

    useEffect(() => {
        const t = setTimeout(() => {
            setFirstRender(1);
        }, 500);

        return () => {
            clearTimeout(t);
        };
    }, []);

    useEffect(() => {
        if (senderVisited) {
            if (reference.current) {
                reference.current.validateForm().then(
                    (errors) => reference.current.setTouched({
                        ...reference.current.touched,
                        ...errors
                    })
                );
            }
        }
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const form = reference.current;
            form.submitForm();
            form.validateForm()
                .then((e) => {
                    setValid(!Object.keys(e).length, to ? 'receiver' : 'sender', edit);
                });
            setVisited(true, to ? 'receiver' : 'sender');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="TransportDestinationForm">
            <Formik
                innerRef={reference}
                initialValues={defaultsToUse}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    values
                }) => (
                    <Form
                        className="TransportDestinationForm__form"
                    >
                        <FormikEffect onChange={changeHandler} />
                        <PointForm trigger={changeHandler} />
                        <FormRow>
                            <FormField
                                name="timeStart"
                                label={!to ? translations('front.passing.add.load_start')
                                    : translations('front.passing.add.unload_start')}
                                isDateTimePicker
                                isRequired
                            />
                            <FormField
                                name="timeEnd"
                                label={!to ? translations('front.passing.add.load_end')
                                    : translations('front.passing.add.unload_end')}
                                isDateTimePicker
                            />
                        </FormRow>
                        <div className="TransportDestinationDifferent">
                            <Field
                                type="checkbox"
                                name="differentPoint"
                                className="TransportDestinationDifferent__checkbox"
                                id={`transport_add_checkbox_${to ? 'to' : 'from'}`}
                            />
                            {/* eslint-disable-next-line max-len */}
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label
                                htmlFor={`transport_add_checkbox_${to ? 'to' : 'from'}`}
                                className="TransportDestinationDifferent__copy"
                            >
                                {to ? translations('front.passing.add.diff_end') : translations('front.passing.add.diff_start')}
                            </label>
                        </div>
                        {values.differentPoint
                            && (<PointForm isPoint trigger={changeHandler} />)}
                        <div className="TransportDestinationForm__bottom">
                            <TransportAddMap positions={[{
                                lat: values.lat ? parseFloat(values.lat) : 999999999,
                                lng: values.lng ? parseFloat(values.lng) : 999999999,
                                id: 1,
                            }]}
                            />
                            <FormRow>
                                <FormField name="lat" />
                                <FormField name="lng" />
                            </FormRow>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const pointShape = {
    additional_info: PropTypes.string,
    address_apartment_number: PropTypes.string,
    address_city: PropTypes.string,
    address_country: PropTypes.string,
    address_country_id: PropTypes.number,
    address_housenumber: PropTypes.string,
    address_postcode: PropTypes.string,
    address_street: PropTypes.string,
    created_at: PropTypes.string,
    email: PropTypes.string,
    full_address: PropTypes.string,
    id: PropTypes.number,
    lat: PropTypes.string,
    lng: PropTypes.string,
    name: PropTypes.string,
    passing_id: PropTypes.number,
    phone: PropTypes.string,
    type: PropTypes.string,
    updated_at: PropTypes.string,
};

TransportDestinationForm.propTypes = {
    edit: PropTypes.bool,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    to: PropTypes.bool,
    setPoint: PropTypes.func.isRequired,
    setVisited: PropTypes.func.isRequired,
    setValid: PropTypes.func.isRequired,
    // senderError: PropTypes.oneOfType([
    //     PropTypes.bool,
    //     PropTypes.object,
    // ]).isRequired,
    // receiverError: PropTypes.oneOfType([
    //     PropTypes.bool,
    //     PropTypes.object,
    // ]).isRequired,
    // startingError: PropTypes.oneOfType([
    //     PropTypes.bool,
    //     PropTypes.object,
    // ]).isRequired,
    // endingError: PropTypes.oneOfType([
    //     PropTypes.bool,
    //     PropTypes.object,
    // ]).isRequired,
    sender: PropTypes.shape(pointShape),
    startingAddress: PropTypes.shape(pointShape),
    destinationAddress: PropTypes.shape(pointShape),
    receiver: PropTypes.shape(pointShape),
    senderDifferent: PropTypes.bool.isRequired,
    receiverDifferent: PropTypes.bool.isRequired,
    loadTimeStart: PropTypes.string.isRequired,
    loadTimeEnd: PropTypes.string.isRequired,
    unloadTimeStart: PropTypes.string.isRequired,
    unloadTimeEnd: PropTypes.string.isRequired,
    senderVisited: PropTypes.bool.isRequired,
};

TransportDestinationForm.defaultProps = {
    edit: false,
    to: false,
    sender: null,
    receiver: null,
    startingAddress: null,
    destinationAddress: null,
};

const mapStateToProps = (state) => {
    const { countries, transportDraft } = state;
    const {
        sender, receiver, startingAddress, destinationAddress,
        loadTimeStart,
        loadTimeEnd,
        unloadTimeStart,
        unloadTimeEnd,
        receiverDifferent,
        senderDifferent,
        senderVisited,
    } = transportDraft;

    return {
        countries: countries.codes,
        sender,
        receiver,
        startingAddress,
        destinationAddress,
        loadTimeStart,
        loadTimeEnd,
        unloadTimeStart,
        unloadTimeEnd,
        receiverDifferent,
        senderDifferent,
        senderVisited,
        // senderError: transportAdd.senderError,
        // startingError: transportAdd.startingError,
        // receiverError: transportAdd.receiverError,
        // endingError: transportAdd.endingError,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPoint: (values, isTo, isEdit) => dispatch(transportDraftPointUpdate(values, isTo, isEdit)),
    setVisited: (value, name) => dispatch(transportDraftVisited(value, name)),
    setValid: (value, name, isEdit = false) => dispatch(transportDraftValid(value, name, isEdit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportDestinationForm);
