import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import './styles.scss';
import classNames from 'services/classNames';
import translations from 'services/translations';

const PassingNav = ({ id }) => {
    const location = useLocation();
    let current = 'main';
    let prev = '';
    let next = '/payments';
    if (location.pathname) {
        try {
            const splited = location.pathname.split('/');
            const last = splited[splited.length - 1];

            if (last && last.length && Number.isNaN(parseInt(last, 10))) {
                current = last;
            }

            switch (current) {
            case 'payments':
                prev = '/';
                next = '/signatures';
                break;
            case 'signatures':
                prev = '/payments';
                next = '/signatures';
                break;
            default:
                if (location.pathname.includes('/load/edit')) {
                    next = '/carrier';
                    current = 'load';
                }
                break;
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="PassingNav-outer">
            <ul
                className={classNames('PassingNav', current)}
                data-location={location.pathname ? location.pathname : ''}
            >
                <li className="PassingNav__single">
                    <NavLink exact to={`/transports/single/${id}`} title={translations('front.passing.single.nav.transport')}>
                        <span className="PassingNav__single--copy">{translations('front.passing.single.nav.transport')}</span>
                    </NavLink>
                </li>
                <li className="PassingNav__single">
                    <NavLink exact to={`/transports/single/${id}/payments`} title={translations('front.passing.single.nav.payments')}>
                        <span className="PassingNav__single--copy">{translations('front.passing.single.nav.payments')}</span>
                    </NavLink>
                </li>
                <li className="PassingNav__single">
                    <NavLink exact to={`/transports/single/${id}/signatures`} title={translations('front.passing.single.nav.signatures')}>
                        <span className="PassingNav__single--copy">{translations('front.passing.single.nav.signatures')}</span>
                    </NavLink>
                </li>
            </ul>
            <div className="PassingNav__arrows">
                <NavLink className="PassingNav__arrows--single" exact to={`/transports/single/${id}${prev}`}>prev</NavLink>
                <NavLink className="PassingNav__arrows--single" exact to={`/transports/single/${id}${next}`}>next</NavLink>
            </div>
        </div>
    );
};

PassingNav.propTypes = {
    id: PropTypes.number.isRequired,
};

export default PassingNav;
