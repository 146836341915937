import React, { useCallback, useEffect, useState } from 'react';
import ChatModals from 'components/ChatModals';
import UpgradePopup from 'components/UpgradePopup';
import DuplicatePopup from 'components/DuplicatePopup';

const Popups = () => {
    const [showWhat, setShowWhat] = useState({ title: '', data: null });

    const handleShowFile = useCallback(() => {
        setShowWhat({ title: 'file', data: null });
    }, []);
    const handleShowReceiversList = useCallback((receivers = null) => {
        setShowWhat({ title: 'receivers', data: receivers });
    }, []);
    const handleShowSubscribe = useCallback(() => {
        setShowWhat({ title: 'subscribe', data: null });
    }, []);
    const handleShowPassingDuplicate = useCallback((transportId = 0) => {
        setShowWhat({ title: 'duplicate', data: transportId });
    }, []);

    const handleEvent = useCallback((e) => {
        if (e?.detail === 'file-error' || e?.detail === 'file-error-20') {
            handleShowFile();
        }
        if (e?.detail === 'subscribe-popup') {
            handleShowSubscribe();
        }
        if (e?.detail?.title === 'receivers-list') {
            if (e?.detail?.receivers) {
                handleShowReceiversList(e.detail.receivers);
            } else {
                handleShowReceiversList([]);
            }
        }
        if (e?.detail?.title === 'passing-duplicate' && e?.detail?.transportId > 0) {
            handleShowPassingDuplicate(e.detail.transportId);
        }
    }, [handleShowFile, handleShowReceiversList, handleShowSubscribe, handleShowPassingDuplicate]);

    const close = () => {
        setShowWhat('');
    };

    useEffect(() => {
        document.addEventListener('ta-show-modal', handleEvent);
        return () => {
            document.removeEventListener('ta-show-modal', handleEvent);
        };
    }, [handleEvent]);

    if (showWhat.title === 'file' || showWhat.title === 'receivers') {
        return (
            <ChatModals
                showWhat={showWhat.title}
                close={close}
                receivers={showWhat.data || []}
            />
        );
    }

    if (showWhat.title === 'subscribe') {
        return <UpgradePopup close={close} />;
    }

    if (showWhat.title === 'duplicate') {
        return <DuplicatePopup close={close} transportId={showWhat.data} />;
    }

    return <></>;
};

export default Popups;
