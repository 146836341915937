import React from 'react';
import './style.scss';
import { translations } from 'services';

const DriversFormFallback = () => (
    <div className="DriversFormFallback">
        {translations('front.passing.drivers_fallback')}
    </div>
);

export default DriversFormFallback;
