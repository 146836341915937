export const CHAT_REQUEST = 'CHAT_REQUEST';
export const CHAT_LOADING = 'CHAT_LOADING';
export const CHAT_LOADED = 'CHAT_LOADED';
export const SET_CHAT = 'SET_CHAT';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_DATA = 'SET_DATA';
export const CLOSE_CHAT = 'CLOSE_CHAT';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const OLD_MESSAGE = 'OLD_MESSAGE';
export const CHAT_NEW_MESSAGE = 'CHAT_NEW_MESSAGE';
export const CHAT_NEW_MESSAGE_REQUEST = 'CHAT_NEW_MESSAGE_REQUEST';
export const CHAT_NEW_MESSAGE_ERROR = 'CHAT_NEW_MESSAGE_ERROR';
export const CHAT_NO_OLDER_MESSAGE = 'CHAT_NO_OLDER_MESSAGE';
export const CHAT_NO_OLDER_MESSAGE_REQUEST = 'CHAT_NO_OLDER_MESSAGE_REQUEST';
export const CHAT_NO_OLDER_MESSAGE_ERROR = 'CHAT_NO_OLDER_MESSAGE_ERROR';
export const CHAT_HIDE_ARROW = 'CHAT_HIDE_ARROW';
export const CHAT_LIST_REQUEST = 'CHAT_LIST_REQUEST';
export const CHAT_LIST_SUCCESS = 'CHAT_LIST_SUCCESS';
export const CHAT_LIST_ERROR = 'CHAT_LIST_ERROR';
export const CHAT_RECEIVERS = 'CHAT_RECEIVERS';
