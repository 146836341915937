import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import { mapLanguages, translations } from 'services';
import LanguageMenu from 'components/LanguageMenu';
import useOutsideClick from 'services/useOutsideClick';
import LanguageIcon from 'components/LanguageIcon';

const SettingsLanguage = ({ language }) => {
    const cancelToken = useRef(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => { setMenuVisible(false); });
    const currentLang = mapLanguages(language);

    const handleClick = () => {
        setMenuVisible((v) => !v);
    };

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    return (
        <div className="SettingsLanguage">
            <div className="SettingsLanguage__label">{translations('front.settings.language.language_version')}</div>
            <div style={{ position: 'relative' }} ref={wrapperRef}>
                <button
                    type="button"
                    className="SettingsLanguage__btn"
                    onClick={() => {
                        handleClick();
                    }}
                >
                    <span className="SettingsLanguage__span">{currentLang.label}</span>
                    <LanguageIcon className="SettingsLanguage__img" code={language} />
                </button>
                {menuVisible && <LanguageMenu />}
            </div>
        </div>
    );
};

SettingsLanguage.propTypes = {
    language: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        language: user.user && user.user.language ? user.user.language : 'en',
    };
};

export default connect(mapStateToProps, null)(SettingsLanguage);
