import {
    MOBILE_CHAT
} from './constants';

export const initialGeneralState = {
    visible: false,
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case MOBILE_CHAT:
        newState.visible = !state.visible;
        return newState;
    default:
        return state;
    }
};
