const translations = {
    loading: 'Ladataan',
    loading_data: 'Tietojen lataus ...',
    validate_email: 'Tämä ei ole oikea sähköpostiosoite',
    validate_required: 'Tämä kenttä pitää täyttää',
    validate_the_same: 'Molempien salasanojen on oltava samat',
    sign_in: 'Kirjaudu sisään',
    sign_up__invitation: 'Eikö sinulla ole tiliä?',
    sign_up: 'Rekisteröidy!',
    forgot_password: 'Etkö muista salasanaa?',
    error_occurred: 'Tapahtui virhe!',
    activation_token_broken: 'Aktivointimerkki on virheellinen.',
    return_to_login: 'Palaa sisäänkirjautumiseen',
    activation_success: 'Kiitos! Tilisi on nyt aktiivinen.',
    forgot_password_submit: 'Nollaa salasana',
    password: 'Salasana',
    password_confirmation: 'Toista salasana',
    email: 'Sähköposti',
    remember_me: 'Muista minut',
    welcome: 'Tervetuloa!',
    log_in_to_access: 'Kirjaudu sisään',
    password_forgot_message: 'Etkö voi kirjautua sisään? Anna sähköpostiosoitteesi palauttaaksesi salasanasi!',
    back_to: 'palaa takaisin',
    sign_in_page: 'Kirjaudu sisään',
    select: 'Valita...',
    select_new: 'Luoda:',
    invitation_sent: 'Kutsu lähetetty',
    users_custom_company_data: 'Käytä muita yritystietoja',
    or_just_attachment_name: 'Tai lisää vain liitetiedoston nimi:',
    other_docs: 'Muut dokumentit:',
    readBy: 'Lukenut:',
};
export default translations;
