import Moment from 'moment';
import {
    TRANSPORT_DRAFT_SENDER,
    TRANSPORT_DRAFT_RECEIVER,
    TRANSPORT_DRAFT_STARTING_POINT,
    TRANSPORT_DRAFT_RECEIVING_POINT,
    TRANSPORT_DRAFT_UPDATE,
    TRANSPORT_DRAFT_LOAD,
    TRANSPORT_DRAFT_UNLOAD,
    TRANSPORT_DRAFT_DIFFERENT_SENDER,
    TRANSPORT_DRAFT_DIFFERENT_RECEIVER,
    TRANSPORT_DRAFT_CARRIER,
    TRANSPORT_DRAFT_VISITED,
    TRANSPORT_DRAFT_VALID,
    TRANSPORT_DRAFT_POINT_UPDATE,
    TRANSPORT_DRAFT_DRIVER_OPTION,
    TRANSPORT_DRAFT_CONTRACTOR,
    TRANSPORT_DRAFT_COMMENTS,
    TRANSPORT_DRAFT_DOCUMENT_PUSH,
    TRANSPORT_DRAFT_DOCUMENT_POP,
    TRANSPORT_DRAFT_ADR,
    TRANSPORT_DRAFT_EXTENDED,
    TRANSPORT_DRAFT_LOAD_SUMMARY,
    TRANSPORT_DRAFT_LOAD_PUSH,
    TRANSPORT_DRAFT_LOAD_POP,
    TRANSPORT_DRAFT_RESET,
    TRANSPORT_DRAFT_LOAD_CLEAR,
    TRANSPORT_DRAFT_DOCUMENT_TO_CHANGE,
    TRANSPORT_DRAFT_DOCUMENT_NAME_PUSH,
} from './constants';
import { USER_LOGOUT } from '../user/constants';

const point = {
    name: '',
    address_country_id: null,
    address_postcode: '',
    address_city: '',
    address_street: '',
    address_housenumber: '',
    email: '',
    phone: '',
    lat: '',
    lng: '',
    additional_info: '',
};

const contractor = {
    name: '',
    email: '',
    avatar: '',
};

const validationStatuses = {
    senderValid: false,
    receiverValid: false,
    senderVisited: false,
    receiverVisited: false,
    driverVisited: false,
};

const setPoint = (newValue) => {
    const newObject = { ...point };
    const emails = [];

    if (typeof newValue.email !== 'undefined' && newValue.email !== null && newValue.email.length > 0) {
        emails.push(newValue.email);
    }

    try {
        if (newValue.additional_email && newValue.additional_email.length > 0) {
            const additionalEmails = newValue.additional_email.split(',');
            additionalEmails.forEach((email) => {
                if (email.length > 0 && !emails.includes(email)) {
                    emails.push(email);
                }
            });
        }
    } catch (e) {
        console.error(e);
    }

    Object.keys(point).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(newValue, key)) {
            newObject[key] = newValue[key] || '';
        }
    });

    if (emails.length > 0) {
        newObject.email = emails.join(',');
    }


    return newObject;
};

const saveState = (values, isEdit = false) => {
    if (!isEdit) {
        localStorage.setItem('transport-draft', JSON.stringify({
            ...values,
            senderVisited: false,
            receiverVisited: false,
            driverVisited: false,
            documents: [],
        }));
    }

    return values;
};

export const singleLoad = {
    load_no: '',
    amount: '',
    package_type: '',
    load_type: '',
    pallet_id: '',
    weight: '',
    volume: '',
};

export const initialGeneralState = {
    sender: { ...point },
    receiver: { ...point },
    destinationAddress: { ...point },
    startingAddress: { ...point },
    loadTimeStart: '',
    loadTimeEnd: '',
    unloadTimeStart: '',
    unloadTimeEnd: '',
    senderDifferent: false,
    receiverDifferent: false,
    carrier: false,
    isEdit: false,
    driver: 1,
    ...validationStatuses,
    contractor,
    specialProvisions: '',
    senderInstructions: '',
    documents: [],
    adr: {
        un: '',
        class: '',
        group: '',
        adr: '',
    },
    extendedLoad: false,
    loadSummary: '',
    loads: [],
    documentToChange: 0,
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };
    let index = -1;

    switch (action.type) {
    case TRANSPORT_DRAFT_DOCUMENT_TO_CHANGE:
        if (action.payload) {
            return { ...newState, documentToChange: action.payload };
        }
        return { ...newState, documentToChange: 0 };
    case TRANSPORT_DRAFT_UPDATE:
        if (action.payload === false) {
            return { ...initialGeneralState };
        }

        return action.payload;
    case TRANSPORT_DRAFT_POINT_UPDATE:
        // console.log(action.payload, TRANSPORT_DRAFT_POINT_UPDATE);
        if (action.isTo) {
            newState.receiverDifferent = action.payload.differentPoint;
            newState.receiver = {
                ...action.payload.company,
            };
            newState.destinationAddress = {
                ...action.payload.point,
            };
            newState.unloadTimeStart = action.payload.timeStart;
            newState.unloadTimeEnd = action.payload.timeEnd;
        } else {
            newState.senderDifferent = action.payload.differentPoint;
            newState.sender = {
                ...action.payload.company,
            };
            newState.startingAddress = {
                ...action.payload.point,
            };
            newState.loadTimeStart = action.payload.timeStart;
            newState.loadTimeEnd = action.payload.timeEnd;
        }
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_SENDER:
        newState.sender = setPoint(action.payload);
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_RECEIVER:
        newState.receiver = setPoint(action.payload);
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_STARTING_POINT:
        newState.startingAddress = setPoint(action.payload);
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_RECEIVING_POINT:
        newState.destinationAddress = setPoint(action.payload);
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_LOAD:
        newState.loadTimeStart = action.payload.load_time_start;
        newState.loadTimeEnd = action.payload.load_time_end;
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_UNLOAD:
        newState.unloadTimeStart = action.payload.unload_time_start;
        newState.unloadTimeEnd = action.payload.unload_time_end;
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_DIFFERENT_SENDER:
        newState.senderDifferent = !!action.payload;
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_DIFFERENT_RECEIVER:
        newState.receiverDifferent = !!action.payload;
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_CARRIER:
        newState.driverValid = true;
        newState.carrier = action.payload;
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_VISITED:
        switch (action.name) {
        case 'sender':
            newState.senderVisited = action.payload;
            return newState;
        case 'receiver':
            newState.receiverVisited = action.payload;
            return newState;
        case 'driver':
            newState.driverVisited = action.payload;
            return newState;
        default:
            return newState;
        }
    case TRANSPORT_DRAFT_VALID:
        switch (action.name) {
        case 'sender':
            newState.senderValid = action.payload;
            return saveState(newState, action.isEdit);
        case 'receiver':
            newState.receiverValid = action.payload;
            return saveState(newState, action.isEdit);
        default:
            return newState;
        }
    case TRANSPORT_DRAFT_DRIVER_OPTION:
        if (action.payload === 2 || action.payload === 3) {
            newState.driver = action.payload;
        } else {
            newState.driver = 1;
        }
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_CONTRACTOR:
        newState.contractor = {
            ...contractor,
            ...action.payload
        };
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_COMMENTS:
        newState.specialProvisions = action.payload.specialProvisions;
        newState.senderInstructions = action.payload.senderInstructions;
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_DOCUMENT_PUSH:
        newState.documents = [...newState.documents, action.payload];
        // return newState;
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_DOCUMENT_NAME_PUSH:
        newState.documents = newState.documents.map((elm) => {
            if (elm.id === action.payload.id) {
                return {
                    ...elm,
                    newName: action.payload.name,
                };
            }
            return elm;
        });
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_DOCUMENT_POP:
        index = newState.documents.findIndex((elm) => elm.id === action.payload);
        if (index > -1) {
            newState.documents = [
                ...newState.documents.slice(0, index),
                ...newState.documents.slice(index + 1),
            ];
        }
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_ADR:
        newState.adr = action.payload;
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_EXTENDED:
        newState.extendedLoad = !!action.payload;
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_LOAD_SUMMARY:
        newState.loadSummary = action.payload;
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_LOAD_PUSH:
        newState.loads = [...newState.loads, {
            ...action.payload,
            id: action.payload.id ? action.payload.id : parseInt(Moment().format('x'), 10)
        }];
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_LOAD_POP:
        index = newState.loads.findIndex((elm) => elm.id === action.payload);
        if (index > -1) {
            newState.loads = [
                ...newState.loads.slice(0, index),
                ...newState.loads.slice(index + 1),
            ];
        }
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_LOAD_CLEAR:
        newState.loads = [];
        return saveState(newState, action.isEdit);
    case TRANSPORT_DRAFT_RESET:
        if (action.isEdit === false) {
            localStorage.removeItem('transport-draft');
        }
        return { ...initialGeneralState };
    case USER_LOGOUT:
        return { ...initialGeneralState };
    default:
        return state;
    }
};
