import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import './styles.scss';
import Avatar from 'components/Avatar';
import qs from 'qs';
import API from 'services/API';
import Error from 'components/Error';
import Moment from 'moment';
import translations from 'services/translations';
import { CancelToken } from 'axios';
import errorHandler from 'services/errorHandler';
import { parseCustomCompanyData, getCustomCompanyValues } from 'services/customCompany';
import UserForm from '../UserForm';
import UserDetailsNav from '../UserDetailsNav';
import UserPrivacyForm from '../UserPrivacyForm';

const UserDetails = ({ user, triggerFunction, setMessage }) => {
    const cancelToken = useRef(null);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(null);

    let isEdit = false;
    let isPrivacy = false;
    if (location.pathname.slice(-4) === 'edit') {
        isEdit = true;
    } else if (location.pathname.slice(-7) === 'privacy') {
        isPrivacy = true;
    }

    const formValues = {
        name: user.name,
        surname: user.surname,
        phone: user.phone,
        email: user.email,
        role_name: user.role_name,
        ...getCustomCompanyValues(user),
    };

    const handleSubmit = (values, isPrivacyForm = false) => {
        const data = isPrivacyForm ? { ...values } : {
            name: values.name,
            surname: values.surname,
            phone: values.phone,
            role_name: values.role_name,
            ...(parseCustomCompanyData(values)),
        };

        setMessage('');
        setIsLoading(true);
        cancelToken.current = CancelToken.source();

        const method = '_method';
        data[method] = 'put';

        API.post(`/users/${user.id}`, qs.stringify(data), {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                setFormError(null);
                setMessage(response.data.message ? response.data.message : '');
                const now = parseInt(Moment().format('x'), 10);
                triggerFunction(now);
                setIsLoading(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setFormError(error);
                    setIsLoading(false);
                });
            });
    };

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    return (
        <div className="UserDetails">
            <UserDetailsNav />
            {!isPrivacy && (
                <div className="UserDetails-container" style={{ minHeight: '92px' }}>
                    <Avatar
                        width={80}
                        height={80}
                        image={user.avatar && user.avatar.download_url ? user.avatar.download_url : ''}
                    />
                    <div className="UserDetails-info">
                        <div className="UserDetails__name">
                            {user.name}
                            {' '}
                            {user.surname}
                        </div>
                        <div className="UserDetails__role">{user?.role?.label}</div>
                    </div>
                    <div className="UserDetails-license">
                        {translations('front.header.license')}
                        <br />
                        <span style={{
                            color: '#f2a600'
                        }}
                        >
                            Test
                        </span>
                    </div>
                </div>
            )}
            {!isEdit && !isPrivacy && (
                <div className="UserDetails-container">
                    <div className="UserDetails__email">{user.email}</div>
                    <div className="UserDetails__phone">{user.phone}</div>
                </div>
            )}
            {isEdit && (
                <>
                    {formError && <Error errors={formError} />}
                    <UserForm
                        onSubmit={handleSubmit}
                        isLoading={isLoading}
                        formValues={formValues}
                    />
                </>
            )}
            {isPrivacy && (
                <>
                    {formError && <Error errors={formError} />}
                    <UserPrivacyForm
                        onSubmit={(values) => handleSubmit(values, true)}
                        isLoading={isLoading}
                    />
                </>
            )}
        </div>
    );
};

UserDetails.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    user: PropTypes.object.isRequired,
    triggerFunction: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
};

export default UserDetails;
