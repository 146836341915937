import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
// import './styles.scss';
import Document from 'components/Document';
import { classNames, defaultTranslations } from 'services';
import style from './GetCmr.module.scss';

const GetCmr = ({
    title, date, downloadUrl, docs,
}) => {
    const [showDocs, setShowDocs] = useState(false);
    const showArrow = docs.length > 0;
    const docsToDownload = docs.filter((doc) => doc.download_url && doc.download_url.length > 0);
    const docsToDisplay = docs.filter((doc) => !doc.download_url || doc.download_url.length === 0);
    const lang = localStorage.getItem('language') || 'en';
    const docsLayout = showArrow ? docsToDownload.map((doc) => (
        <Document
            key={doc.id}
            extension={doc.extension}
            name={doc.name}
            downloadUrl={doc.download_url}
        />
    )) : '';

    return (
        <div className={classNames('GetCmr', style.GetCmr)}>
            <button
                type="button"
                onClick={() => {
                    window.open(downloadUrl, '_blank').focus();
                }}
                className={style.Button}
            >
                <span className={style.ButtonTitle}>{title}</span>
                <span className={style.ButtonDate}>{date ? Moment(date).format('lll') : ''}</span>
            </button>
            {showArrow && (
                <button
                    onClick={() => setShowDocs((prev) => !prev)}
                    type="button"
                    className={classNames('GetCmr__arrow', style.Arrow, showDocs && style.ArrowActive)}
                >
                    show docs
                </button>
            )}
            {(showArrow && showDocs) && (
                <>
                    <div className={style.Docs}>
                        {docsLayout}
                    </div>
                    {docsToDisplay.length > 0 && docsToDownload.length > 0
                        && <div className={style.OthersHeader}>{defaultTranslations('other_docs', lang)}</div>}
                    {docsToDisplay.length > 0 && (
                        <ul className="content-list">
                            {docsToDisplay.map((doc) => (
                                <li key={`others-list-${doc.id}`}>{doc.name}</li>
                            ))}
                        </ul>
                    )}
                </>
            )}
        </div>
    );
};

GetCmr.propTypes = {
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    downloadUrl: PropTypes.string.isRequired,
    docs: PropTypes.arrayOf(PropTypes.object),
};

GetCmr.defaultProps = {
    date: '',
    docs: [],
};

export default GetCmr;
