import {
    APP_VERSION_LOADED,
    APP_VERSION_REQUEST,
    APP_VERSION_ERROR,
} from './constants';

export const initialGeneralState = {
    loading: false,
    error: false,
    version: '',
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case APP_VERSION_REQUEST:
        newState.loading = true;
        newState.error = false;
        newState.version = '';
        return newState;
    case APP_VERSION_LOADED:
        newState.loading = false;
        newState.error = false;
        if (action.payload.length) {
            newState.version = action.payload;
            localStorage.setItem('version', action.payload);
        } else {
            newState.version = '';
        }
        return newState;
    case APP_VERSION_ERROR:
        newState.loading = false;
        newState.error = action.payload;
        newState.version = '';
        return newState;
    default:
        return state;
    }
};
