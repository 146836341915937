import React, { useEffect, useRef, useState } from 'react';
import { API, errorHandler } from 'services';
import { CancelToken } from 'axios';
import defaultTranslation from 'services/defaultTranslations';
import querystring from 'querystring';

import './styles.scss';
import Success from 'components/Success';
import Error from 'components/Error';
import LangSwitcher from 'components/LangSwitcher';
import { NavLink } from 'react-router-dom';
import PasswordForm from './components/PasswordForm';

const ForgotPassword = () => {
    const cancelToken = useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [formMessage, setFormMessage] = useState('');
    const language = localStorage.getItem('language') ?? 'en';
    const translations = {
        password_forgot_message: defaultTranslation('password_forgot_message', language),
    };
    let urlToUse = 'https://app.transassist.eu/reset-password/{token}';

    if (window.location.host === 'localhost:3000'
        || window.location.host === 'front-tests-dev.transassist.eu') {
        urlToUse = 'https://front-tests-dev.transassist.eu/reset-password/{token}';
    }

    if (window.location.host === 'front-tests-prod.transassist.eu') {
        urlToUse = 'https://front-tests-prod.transassist.eu/reset-password/{token}';
    }

    if (window.location.host === 'app-staging.transassist.eu') {
        urlToUse = 'https://app-staging.transassist.eu/reset-password/{token}';
    }


    const handleSubmit = (values) => {
        setLoading(true);
        setFormError('');
        setFormMessage('');
        cancelToken.current = CancelToken.source();
        API.post(`${language}/password/forget`, querystring.stringify({ ...values, frontend_url: urlToUse }), {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                setLoading(false);
                if (response.data.message) {
                    setFormMessage(response.data.message);
                } else {
                    setFormMessage('');
                }
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setFormError(error);
                    setLoading(false);
                });
            });
    };

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);

    return (
        <div className="ForgotPassword">
            <div className="Login__lang">
                <LangSwitcher />
            </div>
            <NavLink to="/">
                <img src="/images/login_logo.svg" className="logo Login__logo" alt="" width={158} height={64} />
            </NavLink>
            <div className="ForgotPassword__message">{translations.password_forgot_message}</div>
            {formMessage && <Success message={formMessage} />}
            {formError && <Error errors={formError} />}
            <PasswordForm onSubmit={handleSubmit} isLoading={isLoading} />
            <div className="Login__copy">Trans Assist © 2021 All rights reserved</div>
        </div>
    );
};


export default ForgotPassword;
