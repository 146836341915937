import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { classNames, translations } from 'services';
import Search from 'components/Search';
import TransportShipmentContractors from '../TransportShipmentContractor';
import TransportShipmentInvite from '../TransportShipmentInvite';

const TransportShipmentList = ({
    contractors,
    search,
    reload,
    isSelected,
    forceShowSearch
}) => {
    const [showInvite, setShowInvite] = useState(false);
    const myCopy = translations('front.passing.list_types.my');
    const allCopy = translations('front.passing.list_types.all');
    const selectedCopy = translations('front.passing.list_types.selected');
    let copy = '';
    if (search !== false) {
        copy = myCopy;
    } else if (isSelected) {
        copy = selectedCopy;
    } else {
        copy = allCopy;
    }

    const forceSearch = search === false && forceShowSearch;

    if (contractors.length === 0) {
        return <></>;
    }

    return (
        <div className="TransportShipmentList">
            <div className={classNames('TransportShipmentList__header', forceSearch && 'with-forced-search')}>
                <div className="TransportShipmentList__header--copy">{copy}</div>
                {(search !== false || forceSearch) && (
                    <Search value={search.value} callback={search.update} />
                )}
                {((search === false || (search !== false || forceSearch)) && !isSelected) && (
                    <button
                        onClick={() => { setShowInvite(true); }}
                        type="button"
                        className="TransportShipmentList__invite"
                    >
                        {translations('invite_carrier.header')}
                    </button>
                )}
            </div>
            {showInvite && (
                <TransportShipmentInvite
                    closeHandler={() => { setShowInvite(false); }}
                />
            )}
            <div className={classNames('TransportShipmentList__list', isSelected === false && 'with-star')}>
                {isSelected !== false && contractors.length === 1 && (
                    <TransportShipmentContractors
                        contractor={contractors[0]}
                        isFavourite={search !== false}
                        reload={reload}
                        isSelected={isSelected}
                    />
                )}
                {isSelected === false && contractors.length > 0
            && contractors.map((contractor) => (
                <TransportShipmentContractors
                    key={contractor.id}
                    contractor={contractor}
                    isFavourite={search !== false}
                    reload={reload}
                    isSelected={isSelected}
                />
            ))}
            </div>
        </div>
    );
};

TransportShipmentList.propTypes = {
    contractors: PropTypes.arrayOf(PropTypes.object).isRequired,
    search: PropTypes.oneOfType([
        PropTypes.shape({
            value: PropTypes.string,
            update: PropTypes.func,
        }),
        PropTypes.bool,
    ]).isRequired,
    reload: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
    forceShowSearch: PropTypes.bool,
};

TransportShipmentList.defaultProps = {
    isSelected: false,
    forceShowSearch: false,
};

export default TransportShipmentList;
