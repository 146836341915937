import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import {
    setAllCarriers,
    setDocs, setPassings, setTrucks, setUsers
} from 'data/perPage/actions';
import { translations } from 'services';

const PerPage = ({
    setPassingsPagination,
    setTrucksPagination,
    setUsersPagination,
    setDocsPagination,
    setCarriersPagination,
    keyName,
    perPage,
}) => {
    const onPage = perPage[keyName] ? perPage[keyName] : 10;

    const selectValues = [10, 25, 50, 100];

    const selectOptions = selectValues.map((elm) => (
        <option key={elm} value={elm}>{elm}</option>
    ));

    const onChange = (e) => {
        try {
            const parsed = parseInt(e.target.value, 10);
            if (!Number.isNaN(parsed)) {
                if (selectValues.includes(parsed)) {
                    switch (keyName) {
                    case 'trucks':
                        setTrucksPagination(parsed);
                        break;
                    case 'users':
                        setUsersPagination(parsed);
                        break;
                    case 'docs':
                        setDocsPagination(parsed);
                        break;
                    case 'carriers':
                        setCarriersPagination(parsed);
                        break;
                    default:
                        setPassingsPagination(parsed);
                        break;
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="PerPage">
            <span className="PerPage--info">{translations('front.general.per_page')}</span>
            <select className="PerPage--select" value={onPage} onChange={onChange}>
                {selectOptions}
            </select>
        </div>
    );
};

PerPage.propTypes = {
    setPassingsPagination: PropTypes.func.isRequired,
    setTrucksPagination: PropTypes.func.isRequired,
    setUsersPagination: PropTypes.func.isRequired,
    setDocsPagination: PropTypes.func.isRequired,
    setCarriersPagination: PropTypes.func.isRequired,
    keyName: PropTypes.string.isRequired,
    perPage: PropTypes.shape({
        passings: PropTypes.number,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const { perPage } = state;
    return {
        perPage
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPassingsPagination: (paginate) => dispatch(setPassings(paginate)),
    setTrucksPagination: (paginate) => dispatch(setTrucks(paginate)),
    setUsersPagination: (paginate) => dispatch(setUsers(paginate)),
    setDocsPagination: (paginate) => dispatch(setDocs(paginate)),
    setCarriersPagination: (paginate) => dispatch(setAllCarriers(paginate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerPage);
