import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';


const Success = ({ message }) => (
    (message && message.length > 0)
        ? <div className="Success">{message}</div> : <></>
);

Success.propTypes = {
    message: PropTypes.string,
};

Success.defaultProps = {
    message: ''
};

export default Success;
