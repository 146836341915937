import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import classNames from 'services/classNames';

import './styles.scss';

const ChatAvatar = ({ receivers, withLink, lastUser }) => {
    const clickHandler = () => {
        if (withLink) {
            const eventToSend = new CustomEvent('ta-show-modal', { detail: { title: 'receivers-list', receivers } });
            document.dispatchEvent(eventToSend);
        }
    };
    if (receivers.length === 0) {
        return (<div className="ChatAvatar" />);
    }

    if (receivers.length === 2) {
        let anotherGuy = null;

        receivers.forEach((receiver) => {
            if (!receiver.is_you) {
                anotherGuy = receiver;
            }
        });

        if (anotherGuy) {
            const online = anotherGuy.user && anotherGuy.user.is_online;
            return (
                // eslint-disable-next-line max-len
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                <div
                    className="ChatAvatar"
                    style={{ cursor: 'pointer' }}
                    role="button"
                    onClick={() => { clickHandler(); }}
                >
                    <div className={classNames('ChatAvatar__avatar', online && 'online')}>
                        {/* eslint-disable-next-line camelcase */}
                        <Avatar image={anotherGuy.avatar?.download_url ?? ''} width={40} height={40} />
                    </div>
                </div>
            );
        }
        return (<div className="ChatAvatar" />);
    }

    if (receivers.length === 1) {
        const online = receivers[0].user && receivers[0].user.is_online;
        return (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
            <div
                className="ChatAvatar"
                style={{ cursor: 'pointer' }}
                role="button"
                onClick={() => { clickHandler(); }}
            >
                <div className={classNames('ChatAvatar__avatar', online && 'online')}>
                    {/* eslint-disable-next-line camelcase */}
                    <Avatar image={receivers[0].avatar?.download_url ?? ''} width={40} height={40} />
                </div>
            </div>
        );
    }

    let receiverToShow = null;
    let receiversOnline = false;
    const receiversCount = receivers.length;

    receivers.forEach((receiver) => {
        if (!receiver.is_you) {
            // eslint-disable-next-line camelcase
            if (receiver?.user?.is_online) {
                receiversOnline = true;
            }
            if (receiverToShow === null) {
                receiverToShow = receiver;
            } else if (lastUser && receiver?.user?.id === lastUser) {
                receiverToShow = receiver;
            }
        } else if (lastUser && receiver?.user?.id === lastUser) {
            receiverToShow = receiver;
        }
    });

    return (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div
            className="ChatAvatar"
            role="button"
            style={{ cursor: 'pointer' }}
            onClick={() => { clickHandler(); }}
        >
            {receiverToShow !== null
            && (
                <div className={classNames('ChatAvatar__avatar', receiversOnline && 'online')}>
                    {/* eslint-disable-next-line camelcase */}
                    <Avatar image={receiverToShow.avatar?.download_url ?? ''} width={40} height={40} />
                    {receiversCount > 2 && <div className="ChatAvatar__counter">{receiversCount}</div>}
                </div>
            )}
        </div>
    );
};

ChatAvatar.propTypes = {
    receivers: PropTypes.arrayOf(PropTypes.shape({
        chat_id: PropTypes.number,
        company_id: PropTypes.number,
        created_at: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        is_you: PropTypes.bool,
        last_seen_message_at: PropTypes.string,
        updated_at: PropTypes.string,
        avatar: PropTypes.object,
        user: PropTypes.object,
    })).isRequired,
    withLink: PropTypes.bool,
    lastUser: PropTypes.number,
};

ChatAvatar.defaultProps = {
    withLink: false,
    lastUser: null,
};

export default ChatAvatar;
