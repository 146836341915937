import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import API from 'services/API';
import qs from 'qs';
import { getMessages, startLoading } from 'data/currentChat/actions';
import ChatSingleDefinedMessage from '../ChatSingleDefinedMessage';

import './styles.scss';

const ChatDefinedMessages = ({
    chatID, messages, trigger, triggerLoading
}) => {
    const [currentMessage, setCurrentMessage] = useState(-1);

    const handleSend = (id, params) => {
        let data = {
            message_id: id,
            chat_id: chatID,
        };

        const paramsToSend = {};
        if (params && params.length) {
            params.forEach((param) => {
                paramsToSend[param.name] = param.value;
            });
            data = {
                ...data,
                params: paramsToSend
            };
        } else {
            data = {
                ...data,
                params: paramsToSend,
            };
        }
        triggerLoading();
        API.post('/messages/send', qs.stringify(data))
            .then(() => {
                trigger(chatID);
            })
            .catch((error) => {
                console.error(error);
                trigger(chatID);
            });
    };

    if (currentMessage === -1) {
        const mappedMessages = messages && messages.length ? messages.map((message) => (
            <ChatSingleDefinedMessage
                key={message.id}
                message={message}
                showMessage={(id) => {
                    setCurrentMessage(id);
                }}
                sendMessage={handleSend}
            />
        )) : <div />;

        return <div className="ChatDefinedMessages">{mappedMessages}</div>;
    }

    let messageToShow = null;

    if (messages && messages.length) {
        messages.forEach((message) => {
            if (message.id === currentMessage) {
                messageToShow = (
                    <ChatSingleDefinedMessage
                        message={message}
                        showMessage={(id) => {
                            setCurrentMessage(id);
                        }}
                        isCurrent
                        sendMessage={handleSend}
                    />
                );
            }
        });
    }

    return messageToShow;
};

ChatDefinedMessages.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.object,
    ).isRequired,
    chatID: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    trigger: PropTypes.func.isRequired,
    triggerLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { currentChat } = state;

    return {
        chatID: currentChat.chatID,
    };
};

const mapDispatchToProps = (dispatch) => ({
    trigger: (id) => dispatch(getMessages(id, true)),
    triggerLoading: () => dispatch(startLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatDefinedMessages);
