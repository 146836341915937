import React from 'react';
import './styles.scss';
import { translations } from 'services';

const ChatMessageError = () => (
    <div className="ChatMessageError">
        <div className="ChatMessageError--inner">
            {translations('front.chat.message_error')}
        </div>
    </div>
);

export default ChatMessageError;
