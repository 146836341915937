import React from 'react';
import './styles.scss';
import translations from 'services/translations';
import Block from 'components/Block';

const Help = () => (
    <div className="Help">
        <Block header={translations('front.help.help')}>
            <div className="Help__content">
                {translations('front.help.buddy')}
                {' '}
                <strong>Piotr Śliwiński</strong>
                .
                <br />
                {translations('front.help.comments')}
                <br />
                <a className="Help-phone" href="tel:+48885244322">+48 885 244 322</a>
                <br />
                <a className="Help-mail" href="mailto:maksym.wach@transassist.eu">biuro@transassist.eu</a>
            </div>
        </Block>
    </div>
);

export default Help;
