import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ icon, ...props }) => {
    switch (icon) {
    case 'password':
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M0.6 9C0.6 7.67452 1.67452 6.6 3 6.6H13C14.3255 6.6 15.4 7.67452 15.4 9V13C15.4 14.3255 14.3255 15.4 13 15.4H3C1.67452 15.4 0.6 14.3255 0.6 13V9Z" stroke="#9B9BBE" strokeWidth="1.2" />
                <path d="M11 6.5L11 4C11 2.34315 9.65685 1 8 1V1C6.34315 1 5 2.34315 5 4L5 6.5" stroke="#9B9BBE" strokeWidth="1.2" strokeLinecap="round" />
                <path d="M6.79417 10.9709C6.64199 10.2099 7.224 9.5 8 9.5V9.5C8.776 9.5 9.35801 10.2099 9.20583 10.9709L9.0765 11.6175C8.97387 12.1306 8.52331 12.5 8 12.5V12.5C7.47669 12.5 7.02613 12.1306 6.9235 11.6175L6.79417 10.9709Z" stroke="#9B9BBE" strokeWidth="1.2" />
            </svg>
        );
    case 'email':
        return (
            <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M0.6 3C0.6 1.67452 1.67452 0.6 3 0.6H13C14.3255 0.6 15.4 1.67452 15.4 3V10C15.4 11.3255 14.3255 12.4 13 12.4H3C1.67452 12.4 0.6 11.3255 0.6 10V3Z" stroke="#9B9BBE" strokeWidth="1.2" />
                <path d="M1 1.25L7.34921 6.69218C7.7237 7.01317 8.2763 7.01317 8.65079 6.69218L15 1.25" stroke="#9B9BBE" strokeWidth="1.2" />
            </svg>
        );
    default:
        return null;
    }
};

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
};

export default Icon;
