const translations = (key) => {
    const strings = localStorage.getItem('translations');
    const parsedStrings = JSON.parse(strings);

    if (parsedStrings && typeof parsedStrings[key] !== 'undefined') {
        return parsedStrings[key];
    }

    if (typeof key !== 'undefined') {
        return key;
    }

    return '';
};

export default translations;
