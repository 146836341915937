import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import { translations } from 'services';
import './styles.scss';
import Button from 'components/Button';
import Yup from 'services/translatedYup';
import { transportDraftPushLoad } from 'data/transportDraft/actions';
import { useHistory, useParams } from 'react-router-dom';
import validation from './services/validation';

const TransportLoadForm = ({ push }) => {
    const history = useHistory();
    const { id } = useParams();
    const reference = useRef();
    const edit = !!id;
    const [validationError, setValidationError] = useState(false);
    const lang = localStorage.getItem('language') || 'en';
    const validationSchema = Yup.object().shape(validation);

    const checkIfFormNotEmpty = (vals) => {
        let valid = false;
        ['load_no', 'amount', 'package_type', 'load_type', 'pallet_id'].forEach((elm) => {
            if (vals[elm] && vals[elm].length) {
                valid = true;
            }
        });

        if (!valid) {
            ['weight', 'volume'].forEach((elm) => {
                if (vals[elm] !== '' && !Number.isNaN(vals[elm])) {
                    valid = true;
                }
            });
        }

        return valid;
    };

    const handlePush = (vals) => {
        const valid = checkIfFormNotEmpty(vals);

        if (!valid) {
            setValidationError(translations('front.general.validation_at_least_one'));
        } else {
            push(vals, edit);
            setValidationError(false);
            reference.current.resetForm();
        }
    };

    useEffect(() => {
        const unblock = history.block(() => {
            if (reference.current && typeof reference.current.values !== 'undefined' && checkIfFormNotEmpty(reference.current.values)) {
                // eslint-disable-next-line no-alert
                return window.confirm(translations('front.passing.cargo.confirm_leave_empty'));
            }
            return true;
        });

        return () => {
            unblock();
        };
    }, [history]);


    return (
        <div className="TransportLoadForm">
            <div className="TransportAdd__card">
                {validationError && <div className="TransportLoadForm__error">{validationError}</div>}
                <Formik
                    innerRef={reference}
                    initialValues={{
                        load_no: '',
                        amount: '',
                        package_type: '',
                        load_type: '',
                        pallet_id: '',
                        weight: '',
                        volume: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => { handlePush(values); }}
                >
                    {() => (
                        <Form className="TransportLoadForm__form">
                            <FormRow>
                                <FormField
                                    name="load_no"
                                    label={translations('front.passing.single.load.load_no')}
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="amount"
                                    label={translations('front.passing.single.load.quantity')}
                                />
                                <FormField
                                    name="package_type"
                                    label={translations('front.passing.single.load.package_type')}
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="load_type"
                                    label={lang === 'en' ? 'Nature of the goods' : 'Rodzaj towaru'}
                                />
                                <FormField
                                    name="pallet_id"
                                    label={translations('front.passing.single.load.pallet_id')}
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="weight"
                                    type="number"
                                    min={0}
                                    step={0.001}
                                    label={translations('front.passing.single.load.weight')}
                                />
                                <FormField
                                    name="volume"
                                    type="number"
                                    min={0}
                                    step={0.001}
                                    label={translations('front.passing.single.load.volume')}
                                />
                            </FormRow>
                            <Button type="submit" theme="outline-light" block>{translations('front.general.add')}</Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

TransportLoadForm.propTypes = {
    push: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    push: (values, isEdit = false) => dispatch(transportDraftPushLoad(values, isEdit)),
});
export default connect(null, mapDispatchToProps)(TransportLoadForm);
