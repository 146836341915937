import { useEffect, useState } from 'react';
import { API, errorHandler } from 'services';
import querystring from 'querystring';
import { CancelToken } from 'axios';
import {
    TRANSPORT_ADD_SUBMISSION_STATUS_ADR
} from '../submissionStatuses';

const useTransportAdr = (status, adr, transport = false) => {
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        const c1 = CancelToken.source();
        const c2 = CancelToken.source();
        const c3 = CancelToken.source();
        if (status === TRANSPORT_ADD_SUBMISSION_STATUS_ADR) {
            // eslint-disable-next-line camelcase
            if (transport?.adr_loads?.length > 0) {
                if (adr.adr.length > 0) {
                    setIsLoading((v) => v + 1);
                    API.put(`/passings/${transport.id}/adrs/${transport.adr_loads[0].id}`, querystring.stringify({
                        ...adr,
                        packing_group: adr.group,
                        number_un: adr.un,
                    }), {
                        cancelToken: c1.token,
                    })
                        .then(() => {
                            setIsLoading((v) => v - 1);
                        })
                        .catch((err) => {
                            errorHandler(err, () => {
                                setIsLoading((v) => v - 1);
                                setError(err);
                            });
                        });
                } else {
                    setIsLoading((v) => v + 1);
                    API.delete(`/passings/${transport.id}/adrs/${transport.adr_loads[0].id}`, {
                        cancelToken: c2.token,
                    })
                        .then(() => {
                            setIsLoading((v) => v - 1);
                        })
                        .catch((err) => {
                            errorHandler(err, () => {
                                setIsLoading((v) => v - 1);
                                setError(err);
                            });
                        });
                }
            } else if (adr.adr.length > 0) {
                setIsLoading((v) => v + 1);
                API.post(`/passings/${transport.id}/adrs`, querystring.stringify({
                    ...adr,
                    packing_group: adr.group,
                    number_un: adr.un,
                }), {
                    cancelToken: c3.token,
                })
                    .then(() => {
                        setIsLoading((v) => v - 1);
                    })
                    .catch((err) => {
                        errorHandler(err, () => {
                            setIsLoading((v) => v - 1);
                            setError(err);
                        });
                    });
            }
        }

        return () => {
            if (c1) {
                c1.cancel();
            }
            if (c2) {
                c2.cancel();
            }
            if (c3) {
                c3.cancel();
            }
        };
    }, [adr, status, transport]);

    return { isLoading: isLoading > 0, error };
};

export default useTransportAdr;
