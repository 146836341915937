import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import translations from 'services/translations';
import { toggleMobileChat } from 'data/mobileChat/actions';
import { classNames, getUrl, hasPermission } from 'services';
import UserMenu from './components/UserMenu';
import HeaderTransport from './components/HeaderTransport';

const Header = ({
    toggle, licence, adminPanel
}) => (
    <div className="Header">
        {licence !== 'premium' && adminPanel ? (
            <a href={getUrl('admin-panel')} className={classNames('Header__license', licence, 'is-btn')}>
                <div className="Header__license--title">{translations('front.header.license')}</div>
                <div className="Header__license--copy">{licence}</div>
                <div className="Header__license--hover">{translations('front.general.upgrade_btn')}</div>
            </a>
        ) : (
            <div className={classNames('Header__license', licence)}>
                <div className="Header__license--title">{translations('front.header.license')}</div>
                <div className="Header__license--copy">{licence}</div>
            </div>
        )}
        <HeaderTransport />
        <UserMenu />
        <button className="Header__chat-toggle" type="button" onClick={() => { toggle(); }}>toggle chat</button>
    </div>
);

Header.propTypes = {
    toggle: PropTypes.func.isRequired,
    licence: PropTypes.string.isRequired,
    adminPanel: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;
    const { permissions } = user;
    const adminPanel = hasPermission('admin_panel_manage', permissions);

    return {
        licence: user.user.current_subscription_id ? user.user.current_subscription_id : 'basic',
        adminPanel,
    };
};

const mapDispatchToProps = (dispatch) => ({
    toggle: () => dispatch(toggleMobileChat()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
