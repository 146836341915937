import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'services/classNames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';

import './styles.scss';
import { translations, datepickerLocale } from 'services';

const ChatSingleDefinedMessage = ({
    message, showMessage, isCurrent, sendMessage
}) => {
    const timeSpanOptions = [
        '15 min',
        '30 min',
        '45 min',
        '1 h',
        '2 h',
        '3 h',
        '4 h',
        '9 h',
        '16 h',
        '24 h',
    ];
    const [paramsQueue, setParamsQueue] = useState([]);
    const [pickerValue, setPickerValue] = useState('');

    const singleLayout = (
        <button
            type="button"
            onClick={() => {
                if (isCurrent) {
                    showMessage(-1);
                } else {
                    showMessage(message.id);
                }
            }}
            className={classNames('ChatSingleDefinedMessage', message.type, isCurrent && 'current')}
        >
            <div className="ChatSingleDefinedMessage--title">{message.title}</div>
        </button>
    );

    if (isCurrent) {
        let params = null;
        let options = null;
        let selectedOptions = null;

        if (paramsQueue.length) {
            selectedOptions = paramsQueue.map((param) => (
                <button
                    type="button"
                    key={param.value}
                    className="ChatSingleDefinedMessage__single--selected"
                    onClick={() => {
                        const newArray = [];
                        for (let i = 0; i < paramsQueue.length; i += 1) {
                            if (paramsQueue[i].label === param.label
                                && paramsQueue[i].value === param.value) {
                                break;
                            }
                            newArray.push(paramsQueue[i]);
                        }
                        setParamsQueue(newArray);
                    }}
                >
                    {param.label}
                </button>
            ));
        }

        if (message.params && message.params.length) {
            const currentOption = paramsQueue.length === 0
                ? message : paramsQueue[paramsQueue.length - 1];

            if (currentOption.params && currentOption.params.length) {
                const currentParam = paramsQueue.length === 0
                    ? currentOption.params[0] : currentOption.params[0];

                let optionsToUse = [];
                switch (currentParam.type) {
                case 'time_span':
                    optionsToUse = timeSpanOptions.map((elm) => ({
                        params: null,
                        value: elm,
                        label: elm
                    }));
                    break;
                case 'select':
                    if (currentParam.options && currentParam.options.length) {
                        optionsToUse = currentParam.options;
                    }
                    break;
                default:
                    break;
                }

                if (currentParam.type === 'date_time') {
                    options = (
                        <div className="ChatSingleDefinedMessage__single--list__picker">
                            <DatePicker
                                autoComplete="off"
                                name="chat_datepicker"
                                className="ChatSingleDefinedMessage__single--list__single"
                                placeholderText={translations('front.general.select_date')}
                                selected={pickerValue}
                                onChange={(val) => setPickerValue(val)}
                                showTimeSelect
                                dateFormat="Pp"
                                locale={datepickerLocale()}
                            />
                            <button
                                type="button"
                                onClick={() => {
                                    try {
                                        const date = Moment(pickerValue).format('lll');
                                        if (pickerValue && date && date.length) {
                                            setParamsQueue((currentQueue) => [...currentQueue, {
                                                name: currentParam.name,
                                                value: date,
                                                params: null,
                                                label: date,
                                            }]);
                                        }
                                    } catch (e) {
                                        console.error(e);
                                    }
                                }}
                                disabled={!pickerValue
                                || pickerValue.length === 0}
                                className="ChatSingleDefinedMessage__single--list__picker--btn"
                            >
                                Zatwierdź
                            </button>
                        </div>
                    );
                } else {
                    options = optionsToUse.map((option) => (
                        <button
                            type="button"
                            key={option.value}
                            onClick={() => {
                                setParamsQueue((currentQueue) => [...currentQueue, {
                                    name: currentParam.name,
                                    value: option.value,
                                    params: option.params,
                                    label: option.label,
                                }]);
                            }}
                            className="ChatSingleDefinedMessage__single--list__single"
                        >
                            {option.label}
                        </button>
                    ));
                }


                params = (
                    <>
                        <div className="ChatSingleDefinedMessage__single--ask">
                            {currentParam.title}
                        </div>
                        <div className="ChatSingleDefinedMessage__single--list">{options}</div>
                    </>
                );
            }
        }

        const sendButton = (
            <button
                type="button"
                className="ChatSingleDefinedMessage__single--send"
                onClick={() => {
                    sendMessage(message.id, paramsQueue);
                }}
            >
                <span>{translations('messages.send')}</span>
            </button>
        );

        return (
            <div className="ChatSingleDefinedMessage__single">
                {singleLayout}
                {selectedOptions || ''}
                {params || ''}
                {sendButton}
            </div>
        );
    }

    return singleLayout;
};

ChatSingleDefinedMessage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    message: PropTypes.object.isRequired,
    showMessage: PropTypes.func.isRequired,
    isCurrent: PropTypes.bool,
    sendMessage: PropTypes.func.isRequired,
};

ChatSingleDefinedMessage.defaultProps = {
    isCurrent: false,
};

export default ChatSingleDefinedMessage;
