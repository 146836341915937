import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';

import './styles.scss';
import { sendMessage } from 'data/currentChat/actions';

const ChatMessageForm = ({
    chatID, focusInHandler, focusOutHandler, sendMsg,
}) => {
    const formValues = {
        message: '',
    };

    const onSubmit = (values, { resetForm }) => {
        if (values.message.length && values.message.trim().length) {
            // triggerLoading();
            resetForm(formValues);
            sendMsg(chatID, values.message);
            // focusOutHandler();
            setTimeout(() => {
                const textarea = document.getElementById('chat-textarea');
                if (textarea) {
                    textarea.style.height = '';
                    textarea.value = '';
                }
            }, 15);
        }
    };

    const keyboardHandler = (e, submitTrigger) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            submitTrigger();
            e.preventDefault();
        }
    };

    const resize = (e) => {
        e.target.style.height = '';
        e.target.style.height = `${(e.target.scrollHeight > 72 ? 72 : e.target.scrollHeight)}px`;
    };

    return (
        <div className="ChatMessageForm">
            <Formik initialValues={formValues} onSubmit={onSubmit} enableReinitialize>
                {({
                    values, handleSubmit, submitForm, handleBlur
                }) => (
                // eslint-disable-next-line react/prop-types
                    <form onSubmit={handleSubmit} className="ChatMessageForm__form">
                        <FormRow>
                            <FormField
                                name="message"
                                type="textarea"
                                id="chat-textarea"
                                placeholder="Aa"
                                onInput={(e) => { resize(e); }}
                                onKeyDown={(e) => {
                                    keyboardHandler(e, submitForm);
                                }}
                                onFocus={() => { focusInHandler(); }}
                                onBlur={(e) => {
                                    handleBlur(e);
                                    if (!values.message || values.message.length === 0) {
                                        focusOutHandler();
                                    }
                                }}
                                tabIndex="0"
                            />
                        </FormRow>

                        <button type="submit" className="ChatMessageForm__form--send">send message</button>
                    </form>
                )}
            </Formik>
        </div>
    );
};

ChatMessageForm.propTypes = {
    chatID: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]).isRequired,
    focusInHandler: PropTypes.func.isRequired,
    focusOutHandler: PropTypes.func.isRequired,
    sendMsg: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { currentChat } = state;

    return {
        chatID: currentChat.chatID,
    };
};

const mapDispatchToProps = (dispatch) => ({
    sendMsg: (id, msg) => dispatch(sendMessage(id, msg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessageForm);
