import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MainContent from 'components/MainContent';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import SetFirstPassword from 'components/SetFirstPassword';
import Chat from 'components/Chat';
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect,
} from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';

import './styles.scss';
import Settings from 'pages/Settings';
import Passings from 'pages/Passings';
import Trucks from 'pages/Trucks';
import Passing from 'pages/Passing';
import Documents from 'pages/Documents';
import Home from 'pages/Home';
import Users from 'pages/Users';
import Help from 'pages/Help';
import Messages from 'pages/Messages';
import TransportAdd from 'pages/TransportAdd';
import NotFound from 'pages/NotFound';
import RouteChangeTracker from 'components/RouteChangeTracker';
import Popups from 'components/Popups';
import ActivationHandler from 'components/ActivationHandler';
import Confirmation from 'components/Confirmation';
import { classNames, hasPermission } from 'services';

const AuthenticatedWrapper = ({
    userData, canUpdateCompany, canManageTeam, canListTeam
}) => {
    const { user } = userData;
    const language = localStorage.getItem('language') ?? 'en';
    const mapsApiKey = 'AIzaSyDAiJx7uB2oX_20sXq08AUJEoXxhpxr4-A';
    const localMapsApiKey = 'AIzaSyBjFoGES5Kba_RN4vHxnC0IMWGxyA0YR-M';
    const needToSetPassword = !!(typeof user.has_password !== 'undefined' && user.has_password === false);
    const needToSetCompany = canUpdateCompany && userData.nip === null;
    const isDev = ['localhost:3000', 'front-tests-dev.transassist.eu', 'front-tests-prod.transassist.eu', 'app-staging.transassist.eu'].includes(window.location.host);

    return (
        <div className={classNames('AuthenticatedWrapper', needToSetCompany && 'AuthenticatedWrapper__set-company')}>
            <LoadScript
                googleMapsApiKey={window.location.host === 'localhost:3000' ? localMapsApiKey : mapsApiKey}
                language={language}
            >
                <Router>
                    <ActivationHandler />
                    {!isDev && <RouteChangeTracker />}
                    <div className="AuthenticatedWrapper-content" id="auth-wrapped-content">
                        <Sidebar />
                        <div className="AuthenticatedWrapper-content-inner">
                            <Header />
                            <MainContent>
                                {needToSetPassword && <SetFirstPassword />}
                                {!needToSetPassword && (
                                    <>
                                        {!needToSetCompany && (
                                            <Switch>
                                                <Route path="/" exact><Home /></Route>
                                                <Route path="/transports/list" exact><Passings /></Route>
                                                <Route path="/transports/list/:page" exact><Passings /></Route>
                                                <Route path="/transports/single/:id" exact><Passing /></Route>
                                                <Route path="/transports/single/:id/edit/:from" exact><TransportAdd edit /></Route>
                                                <Redirect from="/transports/single/:id/edit" to="/transports/single/:id/edit/from-company" />
                                                <Route path="/transports/single/:id/load" exact><Passing view="load" /></Route>
                                                <Route path="/transports/single/:id/load/edit" exact><Passing view="passing-load-simple" /></Route>
                                                <Route path="/transports/single/:id/load/edit/add" exact><Passing view="passing-load-add" /></Route>
                                                <Route path="/transports/single/:id/load/edit/extended" exact><Passing view="passing-load-extended" /></Route>
                                                <Route path="/transports/single/:id/load/edit/:load" exact><Passing view="passing-load-simple" /></Route>
                                                <Route path="/transports/single/:id/carrier" exact><Passing view="carrier" /></Route>
                                                <Route path="/transports/single/:id/instructions" exact><Passing view="instructions" /></Route>
                                                <Route path="/transports/single/:id/payments" exact><Passing view="payments" /></Route>
                                                <Route path="/transports/single/:id/signatures" exact><Passing view="signatures" /></Route>
                                                <Route path="/documents" exact><Documents /></Route>
                                                <Route path="/documents/:page" exact><Documents /></Route>
                                                <Route path="/transports/add/:from" exact><TransportAdd /></Route>
                                                <Redirect from="/transports/add" to="/transports/add/from-company" />
                                                <Route path="/fleet/trucks" exact><Trucks /></Route>
                                                <Route path="/fleet/trailers" exact><Trucks isTrailers /></Route>
                                                <Route path="/fleet/trucks/:page" exact><Trucks /></Route>
                                                <Route path="/fleet/trailers/:page" exact><Trucks isTrailers /></Route>
                                                <Route path="/settings" component={Settings} exact />
                                                <Route path="/settings/notifications" component={Settings} exact />
                                                <Route path="/settings/my-account" component={Settings} exact />
                                                <Route path="/settings/company" component={Settings} exact />
                                                <Route path="/users/list" exact>{canListTeam ? <Users /> : <NotFound />}</Route>
                                                <Route path="/users/add" exact>{canManageTeam ? <Users isAdd /> : <NotFound />}</Route>
                                                <Route path="/users/list/:page" exact>{canListTeam ? <Users /> : <NotFound />}</Route>
                                                <Route path="/users/list/:page/:user" exact>{canListTeam ? <Users /> : <NotFound />}</Route>
                                                <Route path="/users/list/:page/:user/edit" exact>{canListTeam ? <Users /> : <NotFound />}</Route>
                                                <Route path="/users/list/:page/:user/privacy" exact>{canListTeam ? <Users /> : <NotFound />}</Route>
                                                <Route path="/help" exact><Help /></Route>
                                                <Route path="/messages" exact><Messages /></Route>
                                                <Route path="/messages/:id" exact><Messages /></Route>
                                                <Route><NotFound /></Route>
                                            </Switch>
                                        )}
                                        {needToSetCompany && (
                                            <Switch>
                                                <Route path="/settings" component={Settings} exact />
                                                <Route path="/settings/notifications" component={Settings} exact />
                                                <Route path="/settings/my-account" component={Settings} exact />
                                                <Route path="/settings/company" component={Settings} exact />
                                                <Route><Settings /></Route>
                                            </Switch>
                                        )}
                                    </>
                                )}
                            </MainContent>
                        </div>
                    </div>
                    <Chat />
                    <Popups />
                    <Confirmation />
                </Router>
            </LoadScript>
        </div>
    );
};

AuthenticatedWrapper.propTypes = {
    userData: PropTypes.shape({
        loading: PropTypes.bool,
        user: PropTypes.object,
        error: PropTypes.any,
        isLoggedIn: PropTypes.bool,
        activated: PropTypes.bool,
        activationError: PropTypes.any,
        activationLoading: PropTypes.bool,
        nip: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }).isRequired,
    canUpdateCompany: PropTypes.bool.isRequired,
    canManageTeam: PropTypes.bool.isRequired,
    canListTeam: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;
    const { permissions } = user;
    const canUpdateCompany = hasPermission('my_company_update_data', permissions);
    const canManageTeam = hasPermission('team_manage', permissions);
    const canListTeam = hasPermission('team_list', permissions);

    return {
        userData: user,
        canUpdateCompany,
        canManageTeam,
        canListTeam,
    };
};

export default connect(mapStateToProps, null)(AuthenticatedWrapper);
