import Yup from 'services/translatedYup';

const validation = {
    load_no: Yup.string().max(100).nullable(),
    amount: Yup.string().max(100).nullable(),
    package_type: Yup.string().max(100).nullable(),
    load_type: Yup.string().max(100).nullable(),
    pallet_id: Yup.string().max(100).nullable(),
    weight: Yup.number().test(
        'maxDigitsAfterDecimal',
        'number field must have 3 digits after decimal or less and maximum length of 10 integers',
        (weight) => {
            if (!weight) {
                return true;
            }
            if (`${weight}`.length > 10) return false;
            if (Math.floor(weight) === weight) return true;
            if (typeof weight.toString().split('.')[1] !== 'undefined') {
                if (weight.toString().split('.')[1].length <= 3) {
                    return true;
                }
            } else {
                return true;
            }
            return false;
        }
    ).nullable(),
    volume: Yup.number().test(
        'maxDigitsAfterDecimal',
        'number field must have 3 digits after decimal or less and maximum length of 10 integers',
        (volume) => {
            if (!volume) {
                return true;
            }
            if (`${volume}`.length > 10) return false;
            if (Math.floor(volume) === volume) return true;
            if (typeof volume.toString().split('.')[1] !== 'undefined') {
                if (volume.toString().split('.')[1].length <= 3) {
                    return true;
                }
            } else {
                return true;
            }
            return false;
        }
    ),
};

export default validation;
