import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import FormField from 'components/FormField';
import { classNames, translations } from 'services';
import { transportDraftPushDocumentName, transportDraftSetDocumentToChange } from 'data/transportDraft/actions';
import Button from 'components/Button';
import style from './TransportFileFormRename.module.scss';

const TransportFileFormRename = ({
    documents, documentToChange, changeName, reset, isJustName
}) => {
    if (!documentToChange) {
        return null;
    }

    const myDocument = documents.find((doc) => doc.id === documentToChange);
    if (!myDocument) {
        return null;
    }

    if (!isJustName && (!myDocument.download_url || myDocument.download_url.length === 0)
        && (!myDocument.objectURL || myDocument.objectURL.length === 0)) {
        return null;
    }

    if (isJustName && ((myDocument.download_url
            && myDocument.download_url.length > 0)
        || (myDocument.objectURL && myDocument.objectURL.length > 0))) {
        return null;
    }

    let { name } = myDocument;
    const { extension } = myDocument;
    if (extension) {
        name = name.replace(`.${extension}`, '');
    }

    return (
        <Formik
            initialValues={{
                name,
            }}
            onSubmit={(values) => {
                changeName({
                    id: myDocument.id,
                    name: extension ? `${values.name}.${extension}` : values.name
                });
                reset();
            }}
            enableReinitialize
        >
            <Form className={style.TransportFileFormRename}>
                <div className={style.Title}>
                    {`${myDocument.name} - zmiana nazwy`}
                </div>
                <div className={classNames(style.Row, !extension && style['Row--single'])}>
                    <FormField
                        name="name"
                    />
                    {extension && (
                        <div className={style.Extension}>
                            .
                            {extension}
                        </div>
                    )}
                </div>
                <div className={style.Submit}>
                    <Button type="submit" small>{translations('front.general.save')}</Button>
                </div>
            </Form>
        </Formik>
    );
};

TransportFileFormRename.propTypes = {
    documents: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        extension: PropTypes.string,
    })).isRequired,
    documentToChange: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    changeName: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    isJustName: PropTypes.bool,
};

TransportFileFormRename.defaultProps = {
    isJustName: false,
};

const mapStateToProps = ({ transportDraft }) => {
    const { documents, documentToChange } = transportDraft;

    return {
        documents, documentToChange
    };
};

const mapDispatchToProps = (dispatch) => ({
    // eslint-disable-next-line max-len
    changeName: (document, isEdit = false) => dispatch(transportDraftPushDocumentName(document, isEdit)),
    reset: () => dispatch(transportDraftSetDocumentToChange(0)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportFileFormRename);
