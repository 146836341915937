import {
    SET_PASSINGS,
    SET_TRUCKS,
    SET_USERS,
    SET_DOCS,
    SET_CARRIERS,
} from './constants';

export const initialGeneralState = {
    passings: !Number.isNaN(parseInt(localStorage.getItem('passings_per_page'), 10))
        ? parseInt(localStorage.getItem('passings_per_page'), 10) : 10,
    trucks: !Number.isNaN(parseInt(localStorage.getItem('trucks_per_page'), 10))
        ? parseInt(localStorage.getItem('trucks_per_page'), 10) : 10,
    users: !Number.isNaN(parseInt(localStorage.getItem('users_per_page'), 10))
        ? parseInt(localStorage.getItem('users_per_page'), 10) : 10,
    docs: !Number.isNaN(parseInt(localStorage.getItem('docs_per_page'), 10))
        ? parseInt(localStorage.getItem('docs_per_page'), 10) : 10,
    carriers: !Number.isNaN(parseInt(localStorage.getItem('carriers_per_page'), 10))
        ? parseInt(localStorage.getItem('carriers_per_page'), 10) : 10,
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case SET_PASSINGS:
        if (!Number.isNaN(parseInt(action.payload, 10))) {
            newState.passings = parseInt(action.payload, 10);
        }
        return newState;
    case SET_TRUCKS:
        if (!Number.isNaN(parseInt(action.payload, 10))) {
            newState.trucks = parseInt(action.payload, 10);
        }
        return newState;
    case SET_USERS:
        if (!Number.isNaN(parseInt(action.payload, 10))) {
            newState.users = parseInt(action.payload, 10);
        }
        return newState;
    case SET_DOCS:
        if (!Number.isNaN(parseInt(action.payload, 10))) {
            newState.docs = parseInt(action.payload, 10);
        }
        return newState;
    case SET_CARRIERS:
        if (!Number.isNaN(parseInt(action.payload, 10))) {
            newState.carriers = parseInt(action.payload, 10);
        }
        return newState;
    default:
        return state;
    }
};
