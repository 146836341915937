import { useEffect, useState } from 'react';
import { API, errorHandler } from 'services';
import {
    TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_DESTINATION,
    TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_RECEIVER,
    TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_STARTING,
    TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_SENDER
} from '../submissionStatuses';

const useTransportRemovePoint = (status, transport = false) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    let pointToUse = false;

    if (transport !== false) {
        switch (status) {
        case TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_DESTINATION:
            pointToUse = transport.destination_address ? transport.destination_address.id : false;
            break;
        case TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_RECEIVER:
            pointToUse = transport.receiver ? transport.receiver.id : false;
            break;
        case TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_STARTING:
            pointToUse = transport.starting_address ? transport.starting_address.id : false;
            break;
        case TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_SENDER:
            pointToUse = transport.sender ? transport.sender.id : false;
            break;
        default:
            break;
        }
    }

    useEffect(() => {
        if (pointToUse !== false && transport?.id) {
            setIsLoading(true);
            API.delete(`/passings/${transport.id}/destination-points/${pointToUse}`)
                .then(() => {
                    setIsLoading(false);
                })
                .catch((err) => {
                    errorHandler(err, () => {
                        setIsLoading(false);
                        setError(false);
                    });
                });
        }
    }, [pointToUse, transport]);

    return { isLoading, error };
};

export default useTransportRemovePoint;
