import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { translations } from 'services';
import Error from 'components/Error';
import SmallLoader from 'components/SmallLoader';
import useTransportInvitation from 'services/useTransportInvitation';

const PassingsListInvitation = ({ tokens }) => {
    const invitationHandler = useTransportInvitation(tokens);
    const {
        isLoading, error, handleReject, handleAccept
    } = invitationHandler;

    if (isLoading) {
        return (
            <div className="PassingsListInvitation">
                <SmallLoader dark />
            </div>
        );
    }

    if (error) {
        return <div className="PassingsListInvitation with-error"><Error errors={error} /></div>;
    }

    return (
        <div className="PassingsListInvitation">
            <button type="button" className="PassingsListInvitation__accept" onClick={() => { handleAccept(); }}>
                <span className="PassingsListInvitation__reject--copy">
                    {translations('invitation_email.accept')}
                </span>
            </button>
            <button type="button" className="PassingsListInvitation__reject" onClick={() => { handleReject(); }}>
                <span className="PassingsListInvitation__reject--copy">{translations('invitation_email.reject')}</span>
            </button>
        </div>
    );
};

PassingsListInvitation.propTypes = {
    tokens: PropTypes.shape({
        unique_token: PropTypes.string,
        accept_token: PropTypes.string,
        reject_token: PropTypes.string,
    }).isRequired,
};

export default PassingsListInvitation;
