import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const ActivationHandler = ({ activated }) => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (activated) {
            if (location.pathname.includes('/activate/')) {
                history.push('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activated]);
    return <></>;
};

ActivationHandler.propTypes = {
    activated: PropTypes.bool,
};

ActivationHandler.defaultProps = {
    activated: null,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        activated: user.activated,
    };
};


export default connect(mapStateToProps, null)(ActivationHandler);
