import Yup from 'services/translatedYup';

const customCompanyValidation = {
    use_different_company: Yup.bool(),
    custom_company_name: Yup.string().when('use_different_company', (different, schema) => {
        if (different === true) {
            return schema.min(2).required();
        }
        return schema;
    }),
    custom_company_nip: Yup.string().when('use_different_company', (different, schema) => {
        if (different === true) {
            return schema.min(2).max(12).required();
        }
        return schema;
    }),
    custom_company_address_country_id: Yup.number().when('use_different_company', (different, schema) => {
        if (different === true) {
            return schema.required();
        }
        return schema;
    }),
    custom_company_address_city: Yup.string().when('use_different_company', (different, schema) => {
        if (different === true) {
            return schema.min(2).required();
        }
        return schema;
    }),
    custom_company_address_postcode: Yup.string().when('use_different_company', (different, schema) => {
        if (different === true) {
            return schema.min(2).required();
        }
        return schema;
    }),
    custom_company_address_street: Yup.string().when('use_different_company', (different, schema) => {
        if (different === true) {
            return schema.min(2).required();
        }
        return schema;
    }),
    custom_company_address_housenumber: Yup.string().when('use_different_company', (different, schema) => {
        if (different === true) {
            return schema.required();
        }
        return schema;
    }),
};

export default customCompanyValidation;
