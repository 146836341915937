import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import defaultTranslation from 'services/defaultTranslations';


const SelectField = ({
    options, isAsyncCreatable, isCreatable, withValue, forceValue, ...props
}) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(props);
    const lang = localStorage.getItem('language') ?? 'en';
    const placeholder = defaultTranslation('select', lang);
    const placeholderCreatable = defaultTranslation('select_new', lang);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: 40,
            borderRadius: 10,
            borderColor: '#CDCDDF',
            // none of react-select's styles are passed to <Control />
            // width: 200,
        }),
        menu: (provided) => ({
            ...provided,
            color: 'black',
            zIndex: 100,
        })
    };

    const triggerChangeOnDocument = () => {
        const event = new Event('ta-select-change');
        document.dispatchEvent(event);
    };

    const triggerCreatableChangeOnDocument = () => {
        const event = new Event('ta-select-creatable-change');
        document.dispatchEvent(event);
    };

    if (isAsyncCreatable !== false) {
        return (
            <AsyncSelect
                onChange={(option) => {
                    setFieldTouched(field.name, true);
                    setFieldValue(field.name, option.value);
                    triggerChangeOnDocument();
                }}
                defaultOptions={options}
                loadOptions={isAsyncCreatable}
                placeholder={placeholder}
                styles={customStyles}
                defaultValue={options.filter((option) => option.value === field.value)}
                value={forceValue ? options.filter((option) => option.value === field.value)
                    : undefined}
                classNamePrefix="ta_select"
            />
        );
    }

    if (isCreatable) {
        return (
            <CreatableSelect
                onChange={(option) => {
                    setFieldTouched(field.name, true);
                    setFieldValue(field.name, option.value);
                    triggerCreatableChangeOnDocument();
                }}
                options={options}
                placeholder={placeholder}
                styles={customStyles}
                defaultValue={options.filter((option) => option.value === field.value)}
                formatCreateLabel={(value) => `${placeholderCreatable} ${value}`}
            />
        );
    }

    if (withValue) {
        return (
            <Select
                onChange={(option) => {
                    setFieldTouched(field.name, true);
                    setFieldValue(field.name, option.value);
                    triggerChangeOnDocument();
                }}
                options={options}
                placeholder={placeholder}
                styles={customStyles}
                defaultValue={options.filter((option) => option.value === field.value)}
                value={options.filter((option) => option.value === field.value)}
            />
        );
    }

    return (
        <Select
            onChange={(option) => {
                setFieldTouched(field.name, true);
                setFieldValue(field.name, option.value);
                triggerChangeOnDocument();
            }}
            options={options}
            placeholder={placeholder}
            styles={customStyles}
            defaultValue={options.filter((option) => option.value === field.value)}
        />
    );
};

SelectField.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.node,
        ]),
    })).isRequired,
    isAsyncCreatable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func,
    ]),
    isCreatable: PropTypes.bool,
    withValue: PropTypes.bool,
    forceValue: PropTypes.bool,
};

SelectField.defaultProps = {
    isAsyncCreatable: false,
    isCreatable: false,
    withValue: false,
    forceValue: false,
};

export default SelectField;
