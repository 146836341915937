import {
    TRANSPORT_DRAFT_DOCUMENT_TO_CHANGE,
    TRANSPORT_DRAFT_SENDER,
    TRANSPORT_DRAFT_RECEIVER,
    TRANSPORT_DRAFT_STARTING_POINT,
    TRANSPORT_DRAFT_RECEIVING_POINT,
    TRANSPORT_DRAFT_UPDATE,
    TRANSPORT_DRAFT_LOAD,
    TRANSPORT_DRAFT_UNLOAD,
    TRANSPORT_DRAFT_DIFFERENT_RECEIVER,
    TRANSPORT_DRAFT_DIFFERENT_SENDER,
    TRANSPORT_DRAFT_CARRIER,
    TRANSPORT_DRAFT_VISITED,
    TRANSPORT_DRAFT_VALID,
    TRANSPORT_DRAFT_POINT_UPDATE,
    TRANSPORT_DRAFT_DRIVER_OPTION,
    TRANSPORT_DRAFT_CONTRACTOR,
    TRANSPORT_DRAFT_COMMENTS,
    TRANSPORT_DRAFT_DOCUMENT_PUSH,
    TRANSPORT_DRAFT_DOCUMENT_POP,
    TRANSPORT_DRAFT_ADR,
    TRANSPORT_DRAFT_EXTENDED,
    TRANSPORT_DRAFT_LOAD_SUMMARY,
    TRANSPORT_DRAFT_LOAD_PUSH,
    TRANSPORT_DRAFT_LOAD_POP,
    TRANSPORT_DRAFT_RESET, TRANSPORT_DRAFT_LOAD_CLEAR, TRANSPORT_DRAFT_DOCUMENT_NAME_PUSH,
} from './constants';

export function transportDraftSetDocumentToChange(payload) {
    return { type: TRANSPORT_DRAFT_DOCUMENT_TO_CHANGE, payload };
}

export function transportDraftUpdate(payload) {
    return { type: TRANSPORT_DRAFT_UPDATE, payload };
}

export function transportDraftPointUpdate(payload, isTo = false, isEdit = false) {
    return {
        type: TRANSPORT_DRAFT_POINT_UPDATE, payload, isTo, isEdit
    };
}

export function transportDraftSender(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_SENDER, payload, isEdit };
}

export function transportDraftReceiver(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_RECEIVER, payload, isEdit };
}

export function transportDraftStartingPoint(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_STARTING_POINT, payload, isEdit };
}

export function transportDraftReceivingPoint(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_RECEIVING_POINT, payload, isEdit };
}

export function transportDraftLoad(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_LOAD, payload, isEdit };
}

export function transportDraftUnload(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_UNLOAD, payload, isEdit };
}

export function transportDraftDifferentSender(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_DIFFERENT_SENDER, payload, isEdit };
}

export function transportDraftDifferentReceiver(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_DIFFERENT_RECEIVER, payload, isEdit };
}

export function transportDraftCarrier(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_CARRIER, payload, isEdit };
}

export function transportDraftVisited(payload, name) {
    return { type: TRANSPORT_DRAFT_VISITED, payload, name };
}

export function transportDraftValid(payload, name, isEdit = false) {
    return {
        type: TRANSPORT_DRAFT_VALID, payload, name, isEdit
    };
}

export function transportDraftDriverOption(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_DRIVER_OPTION, payload, isEdit };
}

export function transportDraftContractor(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_CONTRACTOR, payload, isEdit };
}

export function transportDraftComments(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_COMMENTS, payload, isEdit };
}

export function transportDraftPushDocument(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_DOCUMENT_PUSH, payload, isEdit };
}

export function transportDraftPushDocumentName(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_DOCUMENT_NAME_PUSH, payload, isEdit };
}

export function transportDraftPopDocument(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_DOCUMENT_POP, payload, isEdit };
}

export function transportDraftAdr(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_ADR, payload, isEdit };
}

export function transportDraftExtendedLoad(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_EXTENDED, payload, isEdit };
}

export function transportDraftLoadSummary(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_LOAD_SUMMARY, payload, isEdit };
}

export function transportDraftPushLoad(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_LOAD_PUSH, payload, isEdit };
}

export function transportDraftPopLoad(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_LOAD_POP, payload, isEdit };
}

export function transportDraftReset(payload, isEdit = false) {
    return { type: TRANSPORT_DRAFT_RESET, payload, isEdit };
}

export function transportDraftClearLoads(isEdit = false) {
    return { type: TRANSPORT_DRAFT_LOAD_CLEAR, isEdit };
}
