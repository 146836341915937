import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import './styles.scss';
import { CancelToken } from 'axios';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import { API, errorHandler, translations } from 'services';
import DriversFormFallback from 'components/DriversFormFallback';
import Error from 'components/Error';
import Yup from 'services/translatedYup';
import Button from 'components/Button';
import querystring from 'querystring';
import SmallLoader from 'components/SmallLoader';

const PassingCarrierForm = ({
    accesses, carriers, passingID, callback,
}) => {
    const cancelTokenDrivers = useRef(null);
    const cancelTokenTrucks = useRef(null);
    const cancelTokenTrailers = useRef(null);
    const cancelToken = useRef(null);
    const [drivers, setDrivers] = useState([]);
    const [driversError, setDriversError] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [vehiclesError, setVehiclesError] = useState(false);
    const [trailers, setTrailers] = useState([]);
    const [trailersError, setTrailersError] = useState(false);
    const [backendError, setBackendError] = useState(false);
    const [driversLoading, setDriversLoading] = useState(false);
    const [vehiclesLoading, setVehiclesLoading] = useState(false);
    const [trailersLoading, setTrailersLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const validationObject = {
        driver_id: Yup.number().required().positive().integer(),
        vehicle_id: Yup.number().required().positive().integer(),
        trailer_id: Yup.number().positive().integer(),
        // additional_info: Yup
    };
    const formTranslations = {
        driver: translations('front.passing.add.driver'),
        trailer: translations('front.passing.add.trailer'),
        vehicle: translations('front.passing.add.vehicle'),
        additional_info: translations('front.passing.single.load.carrier_info'),
    };
    const defaultValues = {
        driver_id: '',
        vehicle_id: '',
        trailer_id: '',
        additional_info: '',
    };
    const parseData = (data, forWho = 'drivers') => {
        const array = [];
        data.forEach((single) => {
            array.push({
                value: single.id,
                label: forWho === 'drivers' ? `${single.name} ${single.surname}` : `${single.brand} ${single.model} ${single.registration_number}`,
            });
        });
        return array;
    };

    const handleSubmit = (values) => {
        let method = 'post';

        if (typeof carriers[0] !== 'undefined') {
            method = 'put';
        }

        setLoading(true);
        cancelToken.current = CancelToken.source();
        API[method](`/passings/${passingID}/me-carrier`, querystring.stringify(values), {
            cancelToken: cancelToken.current.token,
        })
            .then(() => {
                setLoading(false);
                callback();
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setLoading(false);
                    setBackendError(err);
                });
            });
    };
    let showForm = false;
    let isCarrier = false;

    useEffect(() => () => {
        if (cancelToken.current) {
            cancelToken.current.cancel();
        }
    }, []);


    useEffect(() => () => {
        if (cancelTokenDrivers.current) {
            cancelTokenDrivers.current.cancel();
        }
        if (cancelTokenTrucks.current) {
            cancelTokenTrucks.current.cancel();
        }
        if (cancelTokenTrailers.current) {
            cancelTokenTrailers.current.cancel();
        }
    }, []);

    useEffect(() => {
        const source1 = CancelToken.source();
        const source2 = CancelToken.source();
        const source3 = CancelToken.source();
        if (showForm && isCarrier) {
            setDriversLoading(true);
            API.get('/users?filters[driver]=1&page[size]=10', {
                cancelToken: source1.token,
            })
                .then((response) => {
                    if (response.data.data && response.data.data.length) {
                        const arrayToSet = response.data.data;
                        if (carriers[0] && carriers[0].driver && carriers[0].driver.id) {
                            if (!response.data.data.find((elm) => elm.id
                                === carriers[0].driver.id)) {
                                arrayToSet.push(carriers[0].driver);
                            }
                        }
                        setDrivers(parseData(arrayToSet));
                    }
                    setDriversLoading(false);
                })
                .catch((error) => {
                    errorHandler(error, () => {
                        setDriversError(error);
                        setDriversLoading(false);
                    });
                });
            setVehiclesLoading(true);
            API.get('/vehicles?page[size]=10', {
                cancelToken: source2.token,
            })
                .then((response) => {
                    if (response.data.data && response.data.data.length) {
                        const arrayToSet = response.data.data;
                        if (carriers[0] && carriers[0].vehicle && carriers[0].vehicle.id) {
                            if (!response.data.data.find((elm) => elm.id
                                === carriers[0].vehicle.id)) {
                                arrayToSet.push(carriers[0].vehicle);
                            }
                        }
                        setVehicles(parseData(arrayToSet, 'vehicles'));
                    }
                    setVehiclesLoading(false);
                })
                .catch((error) => {
                    errorHandler(error, () => {
                        setVehiclesError(error);
                        setVehiclesLoading(false);
                    });
                });
            setTrailersLoading(true);
            API.get('/trailers?page[size]=10', {
                cancelToken: source3.token,
            })
                .then((response) => {
                    if (response.data.data && response.data.data.length) {
                        const arrayToSet = response.data.data;
                        if (carriers[0] && carriers[0].trailer && carriers[0].trailer.id) {
                            if (!response.data.data.find((elm) => elm.id
                                === carriers[0].trailer.id)) {
                                arrayToSet.push(carriers[0].trailer);
                            }
                        }
                        setTrailers(parseData(arrayToSet, 'trailers'));
                    }
                    setTrailersLoading(false);
                })
                .catch((error) => {
                    errorHandler(error, () => {
                        setTrailersError(error);
                        setTrailersLoading(false);
                    });
                });
        }

        return () => {
            source1.cancel();
            source2.cancel();
            source3.cancel();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showForm, isCarrier]);

    if (accesses.length) {
        accesses.forEach((access) => {
            if (access.access_type === 'driver') {
                showForm = true;
            } else if (access.access_type === 'carrier') {
                showForm = true;
                isCarrier = true;
            }
        });
    }

    if (!showForm) {
        return <div />;
    }

    if (!isCarrier) {
        delete (validationObject.driver_id);
        delete (validationObject.vehicle_id);
        delete (validationObject.trailer_id);
        delete (defaultValues.driver_id);
        delete (defaultValues.vehicle_id);
        delete (defaultValues.trailer_id);
    } else if (typeof carriers[0] !== 'undefined') {
        if (carriers[0].driver_id) {
            defaultValues.driver_id = carriers[0].driver_id;
        }
        if (carriers[0].vehicle_id) {
            defaultValues.vehicle_id = carriers[0].vehicle_id;
        }
        if (carriers[0].trailer_id) {
            defaultValues.trailer_id = carriers[0].trailer_id;
        }
    }

    if (typeof carriers[0] !== 'undefined' && carriers[0].additional_info && carriers[0].additional_info.length) {
        defaultValues.additional_info = carriers[0].additional_info;
    }

    if (driversLoading || vehiclesLoading || trailersLoading || loading) {
        return <div className="PassingCarrierForm-loading"><SmallLoader dark /></div>;
    }

    if (isCarrier && (drivers.length === 0 || vehicles.length === 0)) {
        return (<div className="PassingCarrierForm"><DriversFormFallback /></div>);
    }

    const validationSchema = Yup.object().shape(validationObject);
    return (
        <div className="PassingCarrierForm">
            {(driversError && typeof driversError !== 'boolean') && <Error errors={driversError} />}
            {(vehiclesError && typeof vehiclesError !== 'boolean') && <Error errors={vehiclesError} />}
            {(trailersError && typeof trailersError !== 'boolean') && <Error errors={trailersError} />}
            {(backendError && typeof backendError !== 'boolean') && <Error errors={backendError} />}
            <Formik
                initialValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <FormRow>
                            {
                                drivers.length > 0 && (
                                    <FormField
                                        name="driver_id"
                                        isSelect={drivers}
                                        isRequired
                                        label={formTranslations.driver}
                                        isAsyncSelect={(inputValue) => {
                                            setDriversError(false);
                                            cancelTokenDrivers.current = CancelToken.source();
                                            return API.get(`/users?filters[driver]=1&filter[search]=${inputValue}`, {
                                                cancelToken: cancelTokenDrivers.current.token,
                                            })
                                                .then((response) => {
                                                    if (response.data.data
                                                        && response.data.data.length) {
                                                        return parseData(response.data.data);
                                                    }
                                                    return [];
                                                })
                                                .catch((error) => {
                                                    errorHandler(error, () => {
                                                        setDriversError(error);
                                                    });
                                                });
                                        }}
                                    />
                                )
                            }
                        </FormRow>
                        <FormRow>
                            {
                                vehicles.length > 0 && (
                                    <FormField
                                        name="vehicle_id"
                                        isSelect={vehicles}
                                        isRequired
                                        label={formTranslations.vehicle}
                                        isAsyncSelect={(inputValue) => {
                                            setVehiclesError(false);
                                            cancelTokenTrucks.current = CancelToken.source();
                                            return API.get(`/vehicles?filter[search]=${inputValue}`, {
                                                cancelToken: cancelTokenTrucks.current.token,
                                            })
                                                .then((response) => {
                                                    if (response.data.data
                                                        && response.data.data.length) {
                                                        return parseData(response.data.data, 'vehicles');
                                                    }
                                                    return [];
                                                })
                                                .catch((error) => {
                                                    errorHandler(error, () => {
                                                        setVehiclesError(error);
                                                    });
                                                });
                                        }}
                                    />
                                )
                            }
                        </FormRow>
                        <FormRow>
                            {
                                trailers.length > 0 && (
                                    <FormField
                                        name="trailer_id"
                                        isSelect={trailers}
                                        label={formTranslations.trailer}
                                        isAsyncSelect={(inputValue) => {
                                            setTrailersError(false);
                                            cancelTokenTrailers.current = CancelToken.source();
                                            return API.get(`/trailers?filter[search]=${inputValue}`, {
                                                cancelToken: cancelTokenTrailers.current.token,
                                            })
                                                .then((response) => {
                                                    if (response.data.data
                                                        && response.data.data.length) {
                                                        return parseData(response.data.data, 'trailers');
                                                    }
                                                    return [];
                                                })
                                                .catch((error) => {
                                                    errorHandler(error, () => {
                                                        setTrailersError(error);
                                                    });
                                                });
                                        }}
                                    />
                                )
                            }
                        </FormRow>
                        <FormRow>
                            <FormField
                                name="additional_info"
                                type="textarea"
                                label={formTranslations.additional_info}
                            />
                        </FormRow>
                        <Button type="submit" block>{translations('front.general.save')}</Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

PassingCarrierForm.propTypes = {
    carriers: PropTypes.arrayOf(PropTypes.object).isRequired,
    accesses: PropTypes.arrayOf(PropTypes.object).isRequired,
    passingID: PropTypes.number.isRequired,
    callback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transport } = state;

    return {
        carriers: transport.transport.carriers,
        accesses: transport.transport.accesses,
        passingID: transport.transport.id,
    };
};


export default connect(mapStateToProps, null)(PassingCarrierForm);
