export const TRANSPORT_DRAFT_DOCUMENT_TO_CHANGE = 'TRANSPORT_DRAFT_DOCUMENT_TO_CHANGE';
export const TRANSPORT_DRAFT_UPDATE = 'TRANSPORT_DRAFT_UPDATE';
export const TRANSPORT_DRAFT_POINT_UPDATE = 'TRANSPORT_DRAFT_POINT_UPDATE';
export const TRANSPORT_DRAFT_SENDER = 'TRANSPORT_DRAFT_SENDER';
export const TRANSPORT_DRAFT_RECEIVER = 'TRANSPORT_DRAFT_RECEIVER';
export const TRANSPORT_DRAFT_STARTING_POINT = 'TRANSPORT_DRAFT_STARTING_POINT';
export const TRANSPORT_DRAFT_RECEIVING_POINT = 'TRANSPORT_DRAFT_RECEIVING_POINT';
export const TRANSPORT_DRAFT_LOAD = 'TRANSPORT_DRAFT_LOAD';
export const TRANSPORT_DRAFT_UNLOAD = 'TRANSPORT_DRAFT_UNLOAD';
export const TRANSPORT_DRAFT_DIFFERENT_SENDER = 'TRANSPORT_DRAFT_DIFFERENT_SENDER';
export const TRANSPORT_DRAFT_DIFFERENT_RECEIVER = 'TRANSPORT_DRAFT_DIFFERENT_RECEIVER';
export const TRANSPORT_DRAFT_CARRIER = 'TRANSPORT_DRAFT_CARRIER';
export const TRANSPORT_DRAFT_VISITED = 'TRANSPORT_DRAFT_VISITED';
export const TRANSPORT_DRAFT_VALID = 'TRANSPORT_DRAFT_VALID';
export const TRANSPORT_DRAFT_DRIVER_OPTION = 'TRANSPORT_DRIVER_OPTION';
export const TRANSPORT_DRAFT_CONTRACTOR = 'TRANSPORT_DRAFT_CONTRACTOR';
export const TRANSPORT_DRAFT_COMMENTS = 'TRANSPORT_DRAFT_COMMENTS';
export const TRANSPORT_DRAFT_DOCUMENT_PUSH = 'TRANSPORT_DRAFT_DOCUMENT_PUSH';
export const TRANSPORT_DRAFT_DOCUMENT_NAME_PUSH = 'TRANSPORT_DRAFT_DOCUMENT_NAME_PUSH';
export const TRANSPORT_DRAFT_DOCUMENT_POP = 'TRANSPORT_DRAFT_DOCUMENT_POP';
export const TRANSPORT_DRAFT_ADR = 'TRANSPORT_DRAFT_ADR';
export const TRANSPORT_DRAFT_EXTENDED = 'TRANSPORT_DRAFT_EXTENDED';
export const TRANSPORT_DRAFT_LOAD_SUMMARY = 'TRANSPORT_DRAFT_LOAD_SUMMARY';
export const TRANSPORT_DRAFT_LOAD_PUSH = 'TRANSPORT_DRAFT_LOAD_PUSH';
export const TRANSPORT_DRAFT_LOAD_POP = 'TRANSPORT_DRAFT_LOAD_POP';
export const TRANSPORT_DRAFT_RESET = 'TRANSPORT_DRAFT_RESET';
export const TRANSPORT_DRAFT_LOAD_CLEAR = 'TRANSPORT_DRAFT_LOAD_CLEAR';
