import { API, getLocale } from 'services';

import {
    COUNTRIES_REQUEST,
    COUNTRIES_LOADED,
    COUNTRIES_ERROR,
} from './constants';

export function startCountriesRequest() {
    return { type: COUNTRIES_REQUEST };
}

export function countriesSuccess(payload) {
    return { type: COUNTRIES_LOADED, payload };
}

export function countriesError(payload) {
    return { type: COUNTRIES_ERROR, payload };
}

export function getCountries() {
    return (dispatch) => {
        dispatch(startCountriesRequest());
        return API.get(`/${getLocale()}/guest/country`)
            .then((res) => {
                const countriesArray = [];
                const countriesCodes = [];
                if (res.data.data && res.data.data.length) {
                    res.data.data.forEach((elm) => {
                        countriesArray.push({
                            label: elm.name,
                            value: elm.id,
                        });
                        countriesCodes.push({
                            code: elm.iso_code,
                            value: elm.id,
                        });
                    });
                }
                return dispatch(countriesSuccess({ countriesArray, countriesCodes }));
            })
            .catch((err) => (dispatch(countriesError(err))));
    };
}
