import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import { Route, Switch } from 'react-router-dom';
import { hasPermission, translations } from 'services';
import Alert from 'components/Alert';
import SettingsMenu from './components/SettingsMenu';
import SettingsLanguage from './components/SettingsLanguage';
import SettingsAccount from './components/SettingsAccount';
import SettingsCompanyForm from './components/SettingsCompanyForm';

const Settings = ({ needToSetCompany, canUpdateCompany }) => (
    <div className="Settings">
        <SettingsMenu />
        {needToSetCompany && (
            <div className="Settings__content" style={{ marginTop: '30px' }}>
                <Alert type="danger"><big><strong>{translations('front.empty.company')}</strong></big></Alert>
            </div>
        )}
        <div className="Settings__content">
            <Switch>
                <Route path="/settings" exact component={SettingsLanguage} />
                <Route path="/settings/my-account" exact component={SettingsAccount} />
                {canUpdateCompany && <Route path="/settings/company" exact component={SettingsCompanyForm} />}
                <Route><SettingsCompanyForm /></Route>
            </Switch>
        </div>
    </div>
);

Settings.propTypes = {
    needToSetCompany: PropTypes.bool.isRequired,
    canUpdateCompany: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;
    const { permissions } = user;
    const canUpdateCompany = hasPermission('my_company_update_data', permissions);
    const needToSetCompany = canUpdateCompany && user.nip === null;

    return {
        needToSetCompany,
        canUpdateCompany,
    };
};

export default connect(mapStateToProps, null)(Settings);
