const translations = {
    loading: 'Φόρτωση',
    loading_data: 'Φόρτωση δεδομένων ...',
    validate_email: 'Αυτή δεν είναι η σωστή διεύθυνση ηλεκτρονικού ταχυδρομείου',
    validate_required: 'Αυτό το πεδίο απαιτείται',
    validate_the_same: 'Και οι δύο κωδικοί πρόσβασης πρέπει να είναι οι ίδιοι',
    sign_in: 'Συνδεθείτε',
    sign_up__invitation: 'Μην έχετε λογαριασμό;',
    sign_up: 'Κανω ΕΓΓΡΑΦΗ!',
    forgot_password: 'Μην θυμάστε τον κωδικό πρόσβασης;',
    error_occurred: 'Προέκυψε ένα σφάλμα!',
    activation_token_broken: 'Το διακριτικό ενεργοποίησης είναι εσφαλμένο.',
    return_to_login: 'Επιστροφή στη σύνδεση',
    activation_success: 'Ευχαριστώ! Ο λογαριασμός σας είναι τώρα ενεργός.',
    forgot_password_submit: 'Επαναφέρετε τον κωδικό πρόσβασης',
    password: 'Κωδικός πρόσβασης',
    password_confirmation: 'Επαναλάβετε τον κωδικό πρόσβασης',
    email: 'ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ',
    remember_me: 'Θυμήσου με',
    welcome: 'Καλως ΗΡΘΑΤΕ!',
    log_in_to_access: 'Συνδεθείτε για να ξεκινήσετε',
    password_forgot_message: 'Δεν μπορείτε να συνδεθείτε; Εισαγάγετε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας για να επαναφέρετε τον κωδικό πρόσβασής σας!',
    back_to: 'επιστρέψτε στο',
    sign_in_page: 'Σύνδεση',
    select: 'Επιλέγω...',
    select_new: 'Δημιουργώ:',
    invitation_sent: 'Η πρόσκληση στάλθηκε',
    users_custom_company_data: 'Χρησιμοποιήστε διαφορετικά στοιχεία εταιρείας',
    or_just_attachment_name: 'Ή απλά προσθέστε το όνομα του συνημμένου:',
    other_docs: 'Άλλα έγγραφα:',
    readBy: 'Διαβάστηκε από:',
};
export default translations;
