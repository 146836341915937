import {
    TRANSPORT_SINGLE_GET_SUCCESS, TRANSPORT_SINGLE_GET_REQUEST, TRANSPORT_SINGLE_GET_ERROR,
    TRANSPORT_SINGLE_DELETE_SUCCESS, TRANSPORT_SINGLE_DELETE_REQUEST, TRANSPORT_SINGLE_DELETE_ERROR,
} from './constants';

export const initialGeneralState = {
    transport: {},
    transportLoading: false,
    transportError: false,
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case TRANSPORT_SINGLE_GET_REQUEST:
        newState.transport = {};
        newState.transportLoading = true;
        newState.transportError = false;
        return newState;
    case TRANSPORT_SINGLE_GET_SUCCESS:
        newState.transportError = false;
        newState.transportLoading = false;
        newState.transport = action.payload;
        return newState;
    case TRANSPORT_SINGLE_GET_ERROR:
        newState.transport = {};
        newState.transportLoading = false;
        newState.transportError = action.payload;
        return newState;
    case TRANSPORT_SINGLE_DELETE_REQUEST:
        // newState.transport = {};
        newState.transportLoading = true;
        newState.transportError = false;
        return newState;
    case TRANSPORT_SINGLE_DELETE_SUCCESS:
        newState.transportError = false;
        newState.transportLoading = false;
        newState.transport = {};
        return newState;
    case TRANSPORT_SINGLE_DELETE_ERROR:
        // newState.transport = {};
        newState.transportLoading = false;
        newState.transportError = action.payload;
        return newState;
    default:
        return state;
    }
};
