import Moment from 'moment';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import getMomentLocale from '../getMomentLocale';

export default function (response) {
    const responseData = response.data.data;
    if (typeof responseData.translations !== 'undefined') {
        localStorage.setItem('translations', JSON.stringify(responseData.translations));
    }
    if (typeof responseData.user.language !== 'undefined') {
        localStorage.setItem('language', responseData.user.language);
        try {
            Moment.locale(getMomentLocale(responseData.user.language));
        } catch (e) {
            console.error(e);
        }
        document.documentElement.lang = 'pl';
    }
    if (typeof responseData.user.id !== 'undefined') {
        localStorage.setItem('user_id', responseData.user.id);
    }
    try {
        LogRocket.identify(responseData.user.id, {
            name: `${responseData.user.name} ${responseData.user.surname}`,
            email: responseData.user.email,

            // Add your own custom user variables here, ie:
            companyID: responseData.user.company_id,
        });
    } catch (e) {
        console.error(e);
    }
    try {
        Sentry.setUser({ email: responseData.user.email });
    } catch (e) {
        console.error(e);
    }
}
