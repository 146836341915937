import { combineReducers } from 'redux';

import user from './user/reducers';
import perPage from './perPage/reducers';
import currentChat from './currentChat/reducers';
import appVersion from './appVersion/reducers';
import languages from './languages/reducers';
// import transportAdd from './transportAdd/reducers';
import transport from './transport/reducers';
import transportsList from './transportsList/reducers';
import mobileChat from './mobileChat/reducers';
import countries from './countries/reducers';
import trailers from './trailers/reducers';
import vehicles from './vehicles/reducers';
import roles from './roles/reducers';
import confirmation from './confirmation/reducers';
import chatsList from './chatsList/reducers';
import lastDestinations from './lastDestinations/reducers';
import transportDraft from './transportDraft/reducers';
import home from './home/reducers';

export default combineReducers({
    user,
    perPage,
    currentChat,
    // transportAdd,
    transport,
    transportsList,
    mobileChat,
    countries,
    trailers,
    vehicles,
    roles,
    confirmation,
    chatsList,
    lastDestinations,
    transportDraft,
    appVersion,
    languages,
    home,
});
