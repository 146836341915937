import { API } from 'services';

import {
    CHATS_LIST_ERROR,
    CHATS_LIST_REQUEST,
    CHATS_LIST_SUCCESS,
    CHATS_LIST_OLDER_ERROR,
    CHATS_LIST_OLDER_REQUEST,
    CHATS_LIST_OLDER_SUCCESS,
    CHATS_LIST_NO_OLDER,
    CHATS_LIST_NEW_REQUEST,
    CHATS_LIST_NEW_SUCCESS,
    CHATS_LIST_NEW_ERROR,
} from './constants';

const loadOnce = 15;

export function startChatsListRequest() {
    return { type: CHATS_LIST_REQUEST };
}

export function chatsListSuccess(payload) {
    return { type: CHATS_LIST_SUCCESS, payload };
}

export function chatsListError(payload) {
    return { type: CHATS_LIST_ERROR, payload };
}

export function setChatsListNoOlder(payload) {
    return { type: CHATS_LIST_NO_OLDER, payload };
}

export function startChatsListOlderRequest() {
    return { type: CHATS_LIST_OLDER_REQUEST };
}

export function chatsListOlderSuccess(payload) {
    return { type: CHATS_LIST_OLDER_SUCCESS, payload };
}

export function chatsListOlderError(payload) {
    return { type: CHATS_LIST_OLDER_ERROR, payload };
}

export function startChatsListNewRequest() {
    return { type: CHATS_LIST_NEW_REQUEST };
}

export function chatsListNewSuccess(payload) {
    return { type: CHATS_LIST_NEW_SUCCESS, payload };
}

export function chatsListNewError(payload) {
    return { type: CHATS_LIST_NEW_ERROR, payload };
}

export function getChats() {
    return (dispatch) => {
        dispatch(startChatsListRequest());

        API.get(`/messages/chats?page[size]=${loadOnce}`)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.meta) {
                        if (response.data.meta.current_page && response.data.meta.last_page) {
                            if (response.data.meta.current_page === response.data.meta.last_page) {
                                dispatch(setChatsListNoOlder());
                            }
                        }
                    }
                    if (response.data.data.length) {
                        return dispatch(chatsListSuccess(response.data.data));
                    }
                    return dispatch(chatsListSuccess([]));
                }

                dispatch(setChatsListNoOlder());
                return dispatch(chatsListSuccess([]));
            })
            .catch((error) => dispatch(chatsListError(error)));
    };
}

export function getOlderChats(page = 1) {
    return (dispatch) => {
        dispatch(startChatsListOlderRequest());

        API.get(`/messages/chats?page[size]=${loadOnce}&page[number]=${page}`)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.data.length) {
                        return dispatch(chatsListOlderSuccess(response.data.data));
                    }
                    return dispatch(chatsListOlderSuccess([]));
                }

                dispatch(setChatsListNoOlder());
                return dispatch(chatsListOlderSuccess([]));
            })
            .catch((error) => dispatch(chatsListOlderError(error)));
    };
}

export function getNewChats() {
    return (dispatch) => {
        dispatch(startChatsListNewRequest());
        API.get('/messages/chats?page[size]=1&page[number]=1')
            .then((response) => {
                if (response.status === 200 && response.data.data.length) {
                    return dispatch(chatsListNewSuccess(response.data.data));
                }

                return dispatch(chatsListNewSuccess([]));
            })
            .catch((error) => dispatch(chatsListNewError(error)));
    };
}
