import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './styles.scss';
import Button from 'components/Button';
import classNames from 'services/classNames';
import { getMessagesList } from 'data/currentChat/actions';
import SmallLoader from 'components/SmallLoader';
import Error from 'components/Error';
import ChatMessageForm from '../ChatMessageForm';
import ChatFileForm from '../ChatFileForm';
import ChatDefinedMessages from '../ChatDefinedMessages';

const ChatFormOuter = ({
    preDefinedMessages, loading, error, get
}) => {
    const [opened, setOpened] = useState(false);
    const [bigTextarea, setBigTextarea] = useState(false);

    useEffect(() => {
        if (opened) {
            get();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opened]);

    if (opened && loading) {
        return <div className={classNames('ChatFormOuter', opened && 'opened')}><SmallLoader dark /></div>;
    }

    return (
        <div className={classNames('ChatFormOuter', opened && 'opened')}>
            {opened
            && (
                <>
                    <div className="ChatFormOuter__close">
                        <button
                            onClick={() => { setOpened(false); }}
                            className="ChatFormOuter__close--btn"
                            type="button"
                        >
                            close
                        </button>
                    </div>
                    {error && <Error errors={error} />}
                    <ChatDefinedMessages
                        messages={preDefinedMessages}
                    />
                </>
            )}
            {!opened && (
                <>
                    <Button
                        withPlus
                        block
                        onClick={() => { setOpened(true); }}
                        style={{ textIndent: '-5000px', overflow: 'hidden', }}
                    >
                        open
                    </Button>
                    <div className={classNames('ChatFormOuter__forms', bigTextarea && 'show-message-only')}>
                        <ChatFileForm />
                        <ChatFileForm version="image" />
                        <ChatMessageForm
                            focusInHandler={() => { setBigTextarea(true); }}
                            focusOutHandler={() => { setBigTextarea(false); }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

ChatFormOuter.propTypes = {
    preDefinedMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]).isRequired,
    get: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { currentChat } = state;

    return {
        preDefinedMessages: currentChat.list,
        loading: currentChat.listLoading,
        error: currentChat.listError,
    };
};


const mapDispatchToProps = (dispatch) => ({
    get: () => dispatch(getMessagesList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatFormOuter);
