import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'services/classNames';

import './styles.scss';

const FormRow = ({ children, className }) => (
    <div className={classNames('form-row', className ?? className)}>
        {children}
    </div>
);

FormRow.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};


FormRow.defaultProps = {
    className: '',
};

export default FormRow;
