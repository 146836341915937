import {
    CONFIRMATION_HIDE,
    CONFIRMATION_SHOW,
} from './constants';

export const initialGeneralState = {
    title: '',
    description: '',
    callback: () => {},
    show: false,
    isError: false,
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case CONFIRMATION_SHOW:
        newState.title = action.payload && action.payload.title && action.payload.title.length ? action.payload.title : '';
        newState.description = action.payload && action.payload.description && action.payload.description.length ? action.payload.description : '';
        if (action.payload && typeof action.payload.callback !== 'undefined') {
            newState.callback = action.payload.callback;
        } else {
            newState.callback = () => {};
        }
        newState.show = true;
        newState.isError = action.payload.isError;
        return newState;
    case CONFIRMATION_HIDE:
        newState.title = '';
        newState.description = '';
        newState.callback = () => {};
        newState.show = false;
        newState.isError = false;
        return newState;
    default:
        return state;
    }
};
