const translations = {
    loading: 'Chargement',
    loading_data: 'Chargement de données ...',
    validate_email: "Ce n'est pas la bonne adresse e-mail",
    validate_required: 'Ce champ est obligatoire',
    validate_the_same: 'Les deux mots de passe doivent être les mêmes',
    sign_in: "S'identifier",
    sign_up__invitation: "Vous n'avez pas de compte?",
    sign_up: "S'inscrire!",
    forgot_password: 'Vous ne vous souvenez pas du mot de passe?',
    error_occurred: "Une erreur s'est produite!",
    activation_token_broken: "Jeton d'activation est incorrect.",
    return_to_login: 'Retourner à la connexion',
    activation_success: 'Merci! Votre compte est maintenant actif.',
    forgot_password_submit: 'Réinitialiser le mot de passe',
    password: 'Mot de passe',
    password_confirmation: 'Répéter le mot de passe',
    email: 'E-mail',
    remember_me: 'Souviens-toi de moi',
    welcome: 'Bienvenue!',
    log_in_to_access: 'Connectez-vous pour commencer',
    password_forgot_message: 'Vous ne pouvez pas vous connecter? Saisissez votre adresse email pour réinitialisez votre mot de passe!',
    back_to: 'retourner à',
    sign_in_page: 'connexion',
    select: 'Choisir...',
    select_new: 'Créer:',
    invitation_sent: 'Invitation envoyée',
    users_custom_company_data: 'Utilisez d\'autres données d\'entreprise',
    or_just_attachment_name: 'Ou ajoutez simplement le nom de la pièce jointe:',
    other_docs: 'Autres documents:',
    readBy: 'Lu par:',
};
export default translations;
