const getCustomCompanyValues = (data = {}) => {
    const emptyReturn = {
        use_different_company: false,
        custom_company_name: '',
        custom_company_nip: '',
        custom_company_address_postcode: '',
        custom_company_address_city: '',
        custom_company_address_street: '',
        custom_company_address_housenumber: '',
        custom_company_address_country_id: '',
    };

    if (typeof data.custom_company_data !== 'undefined' && data.custom_company_data !== false && data.custom_company_data) {
        const {
            name,
            nip,
            address_country_id: countryId,
            address_postcode: postCode,
            address_housenumber: houseNumber,
            address_city: city,
            address_street: street,
        } = data.custom_company_data;

        return {
            use_different_company: true,
            custom_company_name: name || '',
            custom_company_nip: nip || '',
            custom_company_address_postcode: postCode || '',
            custom_company_address_city: city || '',
            custom_company_address_street: street || '',
            custom_company_address_housenumber: houseNumber || '',
            custom_company_address_country_id: countryId || '',
        };
    }

    return emptyReturn;
};

export default getCustomCompanyValues;
