import { API } from 'services';

import { LAST_DESTINATIONS_REQUEST, LAST_DESTINATIONS_LOADED, LAST_DESTINATIONS_ERROR, } from './constants';

export function startLastDestinationsRequest() {
    return { type: LAST_DESTINATIONS_REQUEST };
}

export function lastDestinationsSuccess(payload) {
    return { type: LAST_DESTINATIONS_LOADED, payload };
}

export function lastDestinationsError(payload) {
    return { type: LAST_DESTINATIONS_ERROR, payload };
}


export function getLastDestinations() {
    return (dispatch) => {
        dispatch(startLastDestinationsRequest());

        return API.get('/last/destination-addresses')
            .then((response) => {
                if (response.data.data && response.data.data.length) {
                    return dispatch(lastDestinationsSuccess(response.data.data));
                }
                return dispatch(lastDestinationsSuccess([]));
            })
            .catch((error) => dispatch(lastDestinationsError(error)));
    };
}
