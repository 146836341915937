import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { errorHandler, getStatuses, translations } from 'services';
import { NavLink } from 'react-router-dom';
import { CancelToken } from 'axios';
import { useDispatch, connect } from 'react-redux';
import Loader from 'components/Loader';
import API from 'services/API';
import Error from 'components/Error';
import {
    homePassingsNewRequestError,
    homePassingsNewRequestSuccess,
    homePassingsRequestError,
    homePassingsRequestSuccess,
    homeSetActivePassing, startHomePassingsNewRequest,
    startHomePassingsRequest
} from 'data/home/actions';
import SmallLoader from 'components/SmallLoader';
import HomePassing from './components/HomePassing';
import style from './HomePassings.module.scss';

const HomePassings = ({
    loading, error, passings, currentPage, totalPages, newLoading, newError,
}) => {
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();

    const loadPassings = useCallback((page = 1) => {
        dispatch(page === 1 ? startHomePassingsRequest() : startHomePassingsNewRequest());
        const source = CancelToken.source();
        const acceptedStatuses = getStatuses().filter((elm) => elm !== 'finished');
        const statuses = acceptedStatuses.join(',');
        const url = new URLSearchParams();
        url.append('filter[is_working_copy]', false);
        url.append('filter[status_id]', statuses);
        url.append('page[size]', `${10}`);
        url.append('page[number]', `${page}`);
        url.append('include', 'startingAddress,destinationAddress,sender,receiver,carriers');
        url.append('sort', '-updated_at');
        API.get(`/passings?${url.toString()}`, {
            cancelToken: source.token,
        })
            .then((response) => {
                const { data: axiosData } = response;
                const { data, meta } = axiosData;
                const currentPageFromApi = meta?.current_page || page; // eslint-disable-line camelcase,max-len
                const totalPagesFromApi = meta?.last_page || page; // eslint-disable-line camelcase
                if (data?.length && typeof data[0].id !== 'undefined') {
                    const active = data[0].id;
                    const positions = [];
                    data.forEach((passing) => {
                        if (!passing.status_id || ![
                            'in_way_for_load',
                            'ready_for_load',
                            'in_way_for_unload',
                            'ready_for_unload',
                            'pause',
                            'damage',
                        ].includes(passing.status_id)) {
                            return;
                        }
                        if (passing.current_passing_carrier?.driver?.lat
                            && passing.current_passing_carrier?.driver?.lng) {
                            const { lat, lng } = passing.current_passing_carrier.driver;
                            if (Number.isNaN(parseFloat(lat)) || Number.isNaN(parseFloat(lng))) {
                                return;
                            }
                            positions.push({
                                id: passing.id,
                                lat: parseFloat(lat),
                                lng: parseFloat(lng),
                                status: passing.status_id,
                            });
                        }
                    });
                    const newData = {
                        passings: data,
                        activePassing: active,
                        positions,
                        currentPage: currentPageFromApi,
                        totalPages: totalPagesFromApi,
                    };
                    dispatch(page === 1
                        ? homePassingsRequestSuccess(newData)
                        : homePassingsNewRequestSuccess(newData));
                    return;
                }
                const newData = {
                    passings: [],
                    activePassing: -1,
                    positions: [],
                    currentPage: 1,
                    totalPages: 1,
                };
                dispatch(page === 1
                    ? homePassingsRequestSuccess(newData)
                    : homePassingsNewRequestSuccess(newData));
            })
            .catch((err) => {
                errorHandler(err, () => {
                    dispatch(page === 1
                        ? homePassingsRequestError(err)
                        : homePassingsNewRequestError(err));
                });
            });

        return () => {
            source.cancel();
        };
    }, [dispatch]);

    const checkForMorePassings = useCallback(() => {
        if (!wrapperRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
        if (scrollTop + clientHeight >= scrollHeight
            && currentPage < totalPages
            && !newError
            && !newLoading) {
            loadPassings(currentPage + 1);
        }
    }, [currentPage, loadPassings, newError, newLoading, totalPages]);

    useEffect(() => {
        loadPassings();
    }, [loadPassings]);

    useEffect(() => {
        if (totalPages > 1) {
            checkForMorePassings();
        }
    }, [checkForMorePassings, totalPages]);

    useEffect(() => {
        const wrapper = document.getElementById('auth-wrapped-content');
        wrapperRef.current = wrapper;
        wrapper.addEventListener('scroll', checkForMorePassings);

        return () => {
            wrapper.removeEventListener('scroll', checkForMorePassings);
        };
    }, [currentPage, totalPages, loadPassings, newLoading, checkForMorePassings]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Error errors={error} />;
    }

    return (
        <>
            {passings.length > 0 ? (
                <div className={style.HomePassings}>
                    {passings.map((passing) => (
                        <HomePassing
                            key={passing.id}
                            passing={passing}
                            changePassing={() => {
                                dispatch(homeSetActivePassing(passing.id));
                            }}
                        />
                    ))}
                    {newLoading && <div className={style.LoadingNew}><SmallLoader dark /></div>}
                    {newError && <Error errors={newError} />}
                </div>
            ) : (
                <div className={style.Empty}>
                    <div className="empty-layout-content">
                        <div className="empty-layout--copy">{translations('front.empty.passings_list')}</div>
                        <NavLink to="/transports/add" className="empty-layout--add" exact>
                            <span className="empty-layout--add-inner">
                                {translations('route.api.passing.store')}
                            </span>
                        </NavLink>
                    </div>
                </div>
            )}
        </>
    );
};


HomePassings.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    passings: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    newLoading: PropTypes.bool.isRequired,
    newError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

const mapStateToProps = (state) => {
    const { home } = state;
    const {
        loading,
        error,
        passings,
        currentPage,
        totalPages,
        newLoading,
        newError,
    } = home;

    return {
        loading,
        error,
        passings,
        currentPage,
        totalPages,
        newLoading,
        newError,
    };
};

export default connect(mapStateToProps, null)(HomePassings);
