import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { confirmationHide } from 'data/confirmation/actions';
import './styles.scss';
import { classNames, translations } from 'services';

const Confirmation = ({
    title, description, callback, show, hide, isError,
}) => {
    const confirm = () => {
        callback();
        hide();
    };

    if (!show) {
        return <></>;
    }

    return (
        <div className="Confirmation">
            <div className="Confirmation__content">
                <div className={classNames('Confirmation__title', isError && 'is-danger')}>{title}</div>
                <div style={{ padding: '0 20px 20px' }}>
                    <div className="Confirmation__description">{description}</div>
                    <div className="Confirmation__btns">
                        <button
                            className="Confirmation__btns--btn"
                            type="button"
                            onClick={() => { hide(); }}
                        >
                            {translations('front.general.cancel')}
                        </button>
                        <button
                            className={classNames('Confirmation__btns--btn', isError && 'is-danger')}
                            type="button"
                            onClick={() => { confirm(); }}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

Confirmation.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    isError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const {
        confirmation
    } = state;

    const {
        title, description, callback, show, isError
    } = confirmation;

    return {
        title, description, callback, show, isError,
    };
};

const mapDispatchToProps = (dispatch) => ({
    hide: () => dispatch(confirmationHide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
