import { useEffect, useState } from 'react';
import { API, errorHandler } from 'services';
import querystring from 'querystring';
import {
    TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_ADD
} from '../submissionStatuses';

const useTransportAddLoad = (status, loads, transport = false) => {
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (status === TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_ADD
            && loads.length) {
            loads.forEach((load) => {
                let shouldAdd = false;
                // eslint-disable-next-line camelcase
                if (transport?.passing_loads?.length > 0) {
                    if (transport.passing_loads.findIndex((elm) => elm.id === load.id) === -1) {
                        shouldAdd = true;
                    }
                } else {
                    shouldAdd = true;
                }

                if (shouldAdd) {
                    setIsLoading((v) => v + 1);
                    API.post(`/passings/${transport.id}/loads`, querystring.stringify(load))
                        .then(() => {
                            setIsLoading((v) => v - 1);
                        })
                        .catch((err) => {
                            errorHandler(err, () => {
                                setIsLoading((v) => v - 1);
                                setError(err);
                            });
                        });
                }
            });
        }
    }, [status, loads, transport]);

    return { isLoading: isLoading > 0, error };
};

export default useTransportAddLoad;
