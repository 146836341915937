const translations = {
    loading: 'nalaganje',
    loading_data: 'Nalaganje podatkov ...',
    validate_email: 'To ni pravi e-poštni naslov',
    validate_required: 'to polje je obvezno',
    validate_the_same: 'Oba gesla morata biti enaka',
    sign_in: 'Prijaviti se',
    sign_up__invitation: 'Ali nimate računa?',
    sign_up: 'Registriraj se!',
    forgot_password: 'Ali se ne spomnite gesla?',
    error_occurred: 'Prišlo je do napake!',
    activation_token_broken: 'Aktiviranje žetonov je napačen.',
    return_to_login: 'Vrnite se na prijavo',
    activation_success: 'Hvala vam! Vaš račun je zdaj aktiven.',
    forgot_password_submit: 'Ponastavite geslo',
    password: 'Geslo',
    password_confirmation: 'Ponovite geslo',
    email: 'E-naslov',
    remember_me: 'Zapomni si me',
    welcome: 'Dobrodošli!',
    log_in_to_access: 'Prijavite se za začetek',
    password_forgot_message: 'Ali se ne prijavite? Vnesite svoj e-poštni naslov, da ponastavite geslo!',
    back_to: 'Nazaj na',
    sign_in_page: 'Vpiši se',
    select: 'Izberite ...',
    select_new: 'Ustvarite:',
    invitation_sent: 'Povabilo poslano',
    users_custom_company_data: 'Uporabite druge podatke o podjetju',
    or_just_attachment_name: 'Ali samo dodajte ime priloge:',
    other_docs: 'Drugi dokumenti:',
    readBy: 'Branje od:',
};
export default translations;
