import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getChat } from 'data/currentChat/actions';
import { NavLink, useLocation } from 'react-router-dom';
import ChatAvatar from '../ChatAvatar';

import './styles.scss';

const ChatListElement = ({ click, data }) => {
    let lastUser = null;
    const userID = !Number.isNaN(parseInt(localStorage.getItem('user_id'), 10))
        ? parseInt(localStorage.getItem('user_id'), 10)
        : 0;
    const location = useLocation();
    let isFullScreen = false;
    if (location.pathname && location.pathname.startsWith('/messages')) {
        isFullScreen = true;
    }
    let isRead = false;

    if (userID === data.lastMessageUser) {
        isRead = true;
    }
    if (data.lastMessage && data.lastMessage.user?.id > 0) {
        lastUser = data.lastMessage.user.id;
    }
    try {
        if (!isRead && data?.receivers?.length > 0) {
            data.receivers.forEach((receiver) => {
                try {
                    if (receiver.user?.id === userID
                        && receiver.last_seen_message_at >= data.lastMessageAt) {
                        isRead = true;
                    }
                } catch (e) {
                    console.error(e);
                }
            });
        }
    } catch (e) {
        console.error(e);
        isRead = true;
    }

    const clickHandler = (e) => {
        e.preventDefault();
        if (e.target) {
            if (e.target.className.includes('Avatar') || e.target.closest('.Avatar')) {
                return;
            }
        }
        click(data.id);
    };

    if (isFullScreen) {
        return (
            <NavLink to={`/messages/${data.id}`} className="ChatListElement">
                <ChatAvatar receivers={data.receivers} withLink lastUser={lastUser} />
                <div className="ChatListElement__title" style={!isRead ? { fontWeight: 'bold' } : {}}>
                    {data.title}
                    <div className="ChatListElement__msg" style={!isRead ? { fontWeight: 'bold' } : {}}>{data.message}</div>
                </div>
            </NavLink>
        );
    }

    return (
        <a href={`/messages/${data.id}`} className="ChatListElement" onClick={clickHandler}>
            <ChatAvatar receivers={data.receivers} withLink lastUser={lastUser} />
            <div className="ChatListElement__title" style={!isRead ? { fontWeight: 'bold' } : {}}>
                {data.title}
                <div className="ChatListElement__msg" style={!isRead ? { fontWeight: 'bold' } : {}}>{data.message}</div>
            </div>
        </a>
    );
};

ChatListElement.propTypes = {
    click: PropTypes.func.isRequired,
    data: PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.number,
        receivers: PropTypes.arrayOf(PropTypes.shape({
            chat_id: PropTypes.number,
            company_id: PropTypes.number,
            created_at: PropTypes.string,
            email: PropTypes.string,
            id: PropTypes.number,
            is_you: PropTypes.bool,
            last_seen_message_at: PropTypes.string,
            updated_at: PropTypes.string,
            avatar: PropTypes.object,
            user: PropTypes.object,
        })),
        message: PropTypes.string,
        lastMessageUser: PropTypes.number,
        lastMessageAt: PropTypes.string,
        lastMessage: PropTypes.shape({
            id: PropTypes.number,
            user: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                surname: PropTypes.string,
                email: PropTypes.string,
                phone: PropTypes.string,
                role_id: PropTypes.number,
                company_id: PropTypes.number,
                is_active: PropTypes.number,
                email_verified_at: PropTypes.string,
                language: PropTypes.string,
                created_at: PropTypes.string,
                updated_at: PropTypes.string,
                lat: PropTypes.string,
                lng: PropTypes.string,
                avatar_id: PropTypes.number,
            })
        }),
    }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    click: (id) => dispatch(getChat(id)),
});

export default connect(null, mapDispatchToProps)(ChatListElement);
