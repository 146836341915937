import React, { useMemo } from 'react';
import Yup from 'services/translatedYup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import FormRow from 'components/FormRow';
import FormField from 'components/FormField';

import translations from 'services/translations';
import FormWrapper from '../FormWrapper';


const TrucksForm = ({
    onSubmit, formValues, isLoading, typesOfVehicles,
}) => {
    const validationSchema = useMemo(() => Yup.object().shape({
        brand: Yup.string().required().min(2),
        model: Yup.string().required().min(2),
        registration_number: Yup.string().required().min(2),
        deadweight_load: Yup.number().positive(),
        gross_vehicle_mass: Yup.number().positive(),
        type_idstr: Yup.string().required(),
    }), []);

    const typesTouse = typesOfVehicles;
    if (formValues.type_idstr && formValues.type_idstr.length) {
        if (typesTouse.length) {
            if (!typesTouse.find((elm) => elm.value === formValues.type_idstr)) {
                typesTouse.push({
                    value: formValues.type_idstr,
                    label: formValues.type_idstr,
                });
            }
        } else {
            typesTouse.push({
                value: formValues.type_idstr,
                label: formValues.type_idstr,
            });
        }
    }

    return (
        <FormWrapper
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
            isLoading={isLoading}
            isTruck
        >
            {() => (
                <>
                    <FormRow>
                        <FormField
                            name="brand"
                            type="text"
                            label={translations('front.fleet.truck.brand')}
                            isRequired
                        />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="model"
                            type="text"
                            label={translations('front.fleet.truck.model')}
                            isRequired
                        />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="registration_number"
                            type="text"
                            label={translations('front.fleet.truck.registration_number')}
                            isRequired
                        />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="deadweight_load"
                            type="number"
                            label={translations('front.fleet.truck.deadweight_load')}
                            min={0}
                            step={0.1}
                        />
                        <FormField
                            name="gross_vehicle_mass"
                            type="number"
                            label={translations('front.fleet.truck.gross_vehicle_mass')}
                            min={0}
                            step={0.1}
                        />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="type_idstr"
                            type="select"
                            label={translations('front.fleet.trailer.type')}
                            isSelect={typesTouse}
                            isRequired
                            isCreatable
                        />
                    </FormRow>
                </>
            )}
        </FormWrapper>
    );
};

TrucksForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    isLoading: PropTypes.bool.isRequired,
    formValues: PropTypes.shape({
        brand: PropTypes.string,
        model: PropTypes.string,
        registration_number: PropTypes.string,
        weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        dmc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type_idstr: PropTypes.string,
    }).isRequired,
    typesOfVehicles: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string, PropTypes.number
        ]),
        label: PropTypes.string,
    })).isRequired,
};

const mapStateToProps = (state) => {
    const { vehicles } = state;

    return {
        typesOfVehicles: vehicles.vehicles && vehicles.vehicles.length ? vehicles.vehicles : [],
    };
};

export default connect(mapStateToProps, null)(TrucksForm);
