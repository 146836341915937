import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import './styles.scss';
import Avatar from 'components/Avatar';
import classNames from 'services/classNames';
import { hasPermission } from 'services';

const UsersListElement = ({ userData, isAdd, canManageTeam }) => {
    const history = useHistory();
    const { page, user } = useParams();

    const nameWithSurname = `${userData.name} ${userData.surname}`;


    const handleClick = () => {
        if (canManageTeam) {
            const pageNumber = page || 1;
            window.scrollTo(0, 0);
            history.push(`/users/list/${pageNumber}/${userData.id}`);
        }
    };

    return (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div
            role="button"
            className={classNames('UsersListElement', (user || isAdd) && 'opened', !canManageTeam && 'no-link')}
            onClick={handleClick}
        >
            <Avatar
                width={32}
                height={32}
                image={userData.avatar && userData.avatar.download_url ? userData.avatar.download_url : ''}
            />
            <div className="UsersListElement__elm">
                <span className="UsersListElement__elm--name UsersListElement--text">{nameWithSurname}</span>
            </div>
            <div className="UsersListElement__elm">
                <span className="UsersListElement__elm--email">
                    <a href={`mailto:${userData.email}`} className="UsersListElement--text">{userData.email}</a>
                </span>
            </div>
            <div className="UsersListElement__elm"><span className="UsersListElement__elm--role UsersListElement--text">{userData?.role?.label}</span></div>
            <div className="UsersListElement__elm"><span className="UsersListElement__elm--phone"><a href={`tel:${userData.phone}`} className="UsersListElement--text">{(userData.phone && userData.phone.length > 0) ? userData.phone : <>&nbsp;</>}</a></span></div>
            <div className="UsersListElement__open" />
        </div>
    );
};

UsersListElement.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    userData: PropTypes.object.isRequired,
    isAdd: PropTypes.bool,
    canManageTeam: PropTypes.bool.isRequired,
};

UsersListElement.defaultProps = {
    isAdd: false,
};

const mapStateToProps = (state) => {
    const { user } = state;
    const { permissions } = user;
    const canManageTeam = hasPermission('team_manage', permissions);

    return {
        canManageTeam,
    };
};

export default connect(mapStateToProps, null)(UsersListElement);
