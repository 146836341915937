import React, { useMemo } from 'react';
import Yup from 'services/translatedYup';
import PropTypes from 'prop-types';


import FormRow from 'components/FormRow';
import FormField from 'components/FormField';

import defaultTranslation from 'services/defaultTranslations';
import { NavLink } from 'react-router-dom';
import FormWrapper from '../FormWrapper';

const PasswordForm = ({ onSubmit, isLoading }) => {
    const lang = localStorage.getItem('language') ?? 'en';
    const trans = {
        back_to: defaultTranslation('back_to', lang),
        sign_in_page: defaultTranslation('sign_in_page', lang),
    };

    const validationSchema = useMemo(() => {
        const language = localStorage.getItem('language') ?? 'en';

        const translations = {
            validate_email: defaultTranslation('validate_email', language),
            validate_required: defaultTranslation('validate_required', language),
        };

        return Yup.object().shape({
            email: Yup.string().email(translations.validate_email)
                .required(translations.validate_required),
        });
    }, []);

    return (
        <>
            <FormWrapper
                initialValues={{
                    email: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => onSubmit(values)}
                isLoading={isLoading}
            >
                {({ isSubmitting }) => (
                    <>
                        <FormRow>
                            <FormField
                                label="E-mail"
                                name="email"
                                type="email"
                                icon="email"
                            />
                        </FormRow>
                    </>
                )}
            </FormWrapper>
            <p className="Login__register">
                {trans.back_to}
                {' '}
                <NavLink to="/" exact>{trans.sign_in_page}</NavLink>
            </p>
        </>
    );
};

PasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default PasswordForm;
