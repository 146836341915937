const isImage = (msg) => {
    if (msg.attachment_file) {
        if (msg.attachment_file.mime_type && msg.attachment_file.mime_type.startsWith('image')) {
            return true;
        }
    }

    return false;
};

export default isImage;
