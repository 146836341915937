export default (languageCode) => {
    const languages = {
        bg: {
            label: 'български',
            isoName: 'Bulgarian',
        },
        cs: {
            label: 'český',
            isoName: 'Czech',
        },
        da: {
            label: 'dansk',
            isoName: 'Danish',
        },
        de: {
            label: 'Deutsch',
            isoName: 'German',
        },
        el: {
            label: 'Ελληνικά',
            isoName: 'Greek',
        },
        en: {
            label: 'English',
            isoName: 'English',
        },
        es: {
            label: 'español',
            isoName: 'Spanish',
        },
        et: {
            label: 'eesti',
            isoName: 'Estonian',
        },
        fi: {
            label: 'suomen',
            isoName: 'Finnish',
        },
        fr: {
            label: 'français',
            isoName: 'French',
        },
        hr: {
            label: 'hrvatski',
            isoName: 'Croatian',
        },
        hu: {
            label: 'magyar',
            isoName: 'Hungarian',
        },
        it: {
            label: 'italiano',
            isoName: 'Italian',
        },
        lb: {
            label: 'Lëtzebuergesch',
            isoName: 'Luxembourgish',
        },
        lt: {
            label: 'lietuvių',
            isoName: 'Lithuanian',
        },
        lv: {
            label: 'Latviešu',
            isoName: 'Latvian',
        },
        nl: {
            label: 'Nederlands',
            isoName: 'Dutch',
        },
        pl: {
            label: 'polski',
            isoName: 'Polish',
        },
        pt: {
            label: 'português',
            isoName: 'Portuguese',
        },
        ro: {
            label: 'română',
            isoName: 'Romanian',
        },
        ru: {
            label: 'русский',
            isoName: 'Russian',
        },
        sk: {
            label: 'slovenský',
            isoName: 'Slovak',
        },
        sl: {
            label: 'slovenščina',
            isoName: 'Slovenian',
        },
        sv: {
            label: 'svenska',
            isoName: 'Swedish',
        },
        tr: {
            label: 'Türkçe',
            isoName: 'Turkish',
        },
        uk: {
            label: 'Українська',
            isoName: 'Ukrainian',
        },
    };

    if (Object.prototype.hasOwnProperty.call(languages, languageCode)) {
        return languages[languageCode];
    }

    return languages.en;
};
