const parseCustomCompanyData = (data = {}) => {
    const notEmpty = (v) => {
        if (typeof v === 'undefined') {
            return false;
        }

        if (typeof v === 'string' && v.length > 0) {
            return true;
        }

        if (Number.isNaN(parseInt(v, 10))) {
            return false;
        }

        return true;
    };

    if (typeof data.use_different_company !== 'undefined' && data.use_different_company === true) {
        /* eslint-disable max-len */
        const toReturn = {
            ...(notEmpty(data.custom_company_name) ? { name: data.custom_company_name } : {}),
            ...(notEmpty(data.custom_company_nip) ? { nip: data.custom_company_nip } : {}),
            ...(notEmpty(data.custom_company_address_postcode) ? { address_postcode: data.custom_company_address_postcode } : {}),
            ...(notEmpty(data.custom_company_address_city) ? { address_city: data.custom_company_address_city } : {}),
            ...(notEmpty(data.custom_company_address_street) ? { address_street: data.custom_company_address_street } : {}),
            ...(notEmpty(data.custom_company_address_housenumber) ? { address_housenumber: data.custom_company_address_housenumber } : {}),
            ...(notEmpty(data.custom_company_address_country_id) ? { address_country_id: data.custom_company_address_country_id } : {}),
        };
        /* eslint-enable max-len */

        if (Object.keys(toReturn).length > 0) {
            return { custom_company_data: toReturn };
        }
    }

    return { custom_company_data: null };
};

export default parseCustomCompanyData;
