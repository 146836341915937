import {
    VEHICLES_ERROR, VEHICLES_LOADED, VEHICLES_REQUEST
} from './constants';

export const initialGeneralState = {
    loading: false,
    error: false,
    vehicles: [],
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };

    switch (action.type) {
    case VEHICLES_REQUEST:
        newState.vehicles = [];
        newState.loading = true;
        newState.error = false;
        return newState;
    case VEHICLES_LOADED:
        newState.vehicles = action.payload;
        newState.loading = false;
        newState.error = false;
        return newState;
    case VEHICLES_ERROR:
        newState.vehicles = [];
        newState.loading = false;
        newState.error = action.payload;
        return newState;
    default:
        return state;
    }
};
