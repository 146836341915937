import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneValidator = (value = '', acceptNull = false) => {
    if (!value || value.length <= 4) {
        return acceptNull;
    }

    const valueToUse = value.startsWith('+') ? value : `+${value}`;

    try {
        const phoneNumber = PhoneNumberUtil.getInstance().parse(valueToUse);
        return PhoneNumberUtil.getInstance().isPossibleNumber(phoneNumber);
    } catch (e) {
        console.error(e);
        return false;
    }
};

export default phoneValidator;
