import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import errorHandler from 'services/errorHandler';
import Loader from 'components/Loader';
import Error from 'components/Error';
import GetCmr from 'components/GetCmr';
import Pagination from 'components/Pagination';
import { CancelToken } from 'axios';
import {
    classNames, API, setPaginationVariables, translations
} from 'services';
import { connect } from 'react-redux';

import './styles.scss';
import { NavLink, Redirect, useParams } from 'react-router-dom';

const Documents = ({ perPage }) => {
    const { page } = useParams();
    const pageNumber = typeof page !== 'undefined' && page ? parseInt(page, 10) : 1;
    const [lastPage, setLastPage] = useState(pageNumber);
    const [redirectTo, setRedirectTo] = useState(0);
    const [files, setFiles] = useState([]);
    const [formError, setFormError] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const source = CancelToken.source();
        setLoading(true);
        API.get(`/passings?filter[is_working_copy]=false&include=additionalDocs&page[size]=${perPage}&page[number]=${pageNumber}`, {
            cancelToken: source.token,
        })
            .then((response) => {
                const paginationVariables = setPaginationVariables(response);
                setRedirectTo(paginationVariables.redirectTo);
                setLastPage(paginationVariables.lastPage);
                if (response.data.data && response.data.data.length) {
                    setFiles(response.data.data);
                } else {
                    setFiles([]);
                }
                setLoading(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setFiles([]);
                    setFormError(error);
                    setLoading(false);
                });
            });

        return () => {
            source.cancel();
        };
    }, [pageNumber, perPage]);

    if (redirectTo > 0) {
        return <Redirect to={`/documents/${redirectTo}`} />;
    }

    if (loading) {
        return <Loader hideText />;
    }

    const filesMap = files.map((elm) => {
        const docs = elm.additional_docs
        && elm.additional_docs.length ? elm.additional_docs : [];
        return (
            <div className={classNames('Documents__single', (elm.status_id === 'finished') && 'blank')} key={elm.id}>
                <GetCmr
                    downloadUrl={elm.cmr_url.download_url}
                    passingID={elm.id}
                    title={elm.cmr_url.name}
                    date={elm.updated_at}
                    docs={docs}
                />
            </div>
        );
    });

    const filesLayout = files.length ? filesMap : '';

    return (
        <>
            <div className={classNames('Documents', files.length === 0 && 'empty-layout')}>
                {formError ? <Error errors={formError} /> : (filesLayout)}
                {files.length === 0 && (
                    <div className="empty-layout-content">
                        <div className="empty-layout--copy">{translations('front.empty.files_list')}</div>
                        <NavLink to="/transports/add" className="empty-layout--add" exact>
                            <span className="empty-layout--add-inner">
                                {translations('route.api.passing.store')}
                            </span>
                        </NavLink>
                    </div>
                )}
            </div>
            <Pagination keyName="docs" urlPrefix="/documents/" lastPage={lastPage} currentPage={pageNumber} />
        </>
    );
};

Documents.propTypes = {
    perPage: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    const { perPage } = state;
    return {
        perPage: perPage.docs,
    };
};

export default connect(mapStateToProps, null)(Documents);
