import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import SmallLoader from 'components/SmallLoader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { API, errorHandler } from 'services';
import { CancelToken } from 'axios';
import Error from 'components/Error';
import Pagination from 'components/Pagination';
import TransportShipmentList from './components/TransportShipmentList';

const TransportShipment = ({
    contractor, perPage,
}) => {
    const cancelToken = useRef(null);
    const [contractorsSearch, setContractorsSearch] = useState('');
    const [isLoadingContractors, setIsLoadingContractors] = useState(true);
    const [contractorsError, setContractorsError] = useState(false);
    const [contractors, setContractors] = useState([]);
    const cancelTokenCompanies = useRef(null);
    const [isLoadingCompanies, setIsLoadingCompanies] = useState(true);
    const [companiesError, setCompaniesError] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [reload, setReload] = useState(0);
    const location = useLocation();
    const pageNumber = !Number.isNaN(parseInt(location.hash.replace('#', ''), 10))
        ? parseInt(location.hash.replace('#', ''), 10) : 1;
    const [lastPage, setLastPage] = useState(pageNumber);
    const [redirectTo, setRedirectTo] = useState(0);
    const history = useHistory();
    const onPage = perPage || 10;

    const paginationUpdate = (meta) => {
        let redirectToSet = 0;
        if (meta) {
            if (meta.last_page) {
                setLastPage(meta.last_page);
            }
            if (meta.current_page
                && meta.last_page
                && meta.current_page > meta.last_page) {
                redirectToSet = meta.last_page;
            }
        }
        if (redirectToSet !== 0) {
            setRedirectTo(redirectToSet);
        }
    };

    useEffect(() => {
        setIsLoadingContractors(true);
        setIsLoadingCompanies(true);
        cancelToken.current = CancelToken.source();
        cancelTokenCompanies.current = CancelToken.source();
        API.get(`/contractors?page[size]=${perPage}&page[number]=${pageNumber}&include=existingCompany${contractorsSearch.length ? `&filter[search]=${contractorsSearch}` : ''}`, {
            cancelToken: cancelToken.current.token,
        })
            .then((response) => {
                if (response.data.data) {
                    setContractors(response.data.data);
                }
                setIsLoadingContractors(false);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setIsLoadingContractors(false);
                    setContractorsError(err);
                });
            });
        API.get(`/passing/available-companies?page[size]=${onPage}&page[number]=${pageNumber}&include=existingCompany${contractorsSearch.length ? `&filter[search]=${contractorsSearch}` : ''}`, {
            cancelToken: cancelTokenCompanies.current.token,
        })
            .then((response) => {
                paginationUpdate(response.data.meta);
                if (response.data.data) {
                    setCompanies(response.data.data);
                }
                setIsLoadingCompanies(false);
            })
            .catch((err) => {
                errorHandler(err, () => {
                    setIsLoadingCompanies(false);
                    setCompaniesError(err);
                });
            });

        return () => {
            if (cancelToken.current) {
                cancelToken.current.cancel();
            }
            if (cancelTokenCompanies.current) {
                cancelTokenCompanies.current.cancel();
            }
        };
    }, [contractorsSearch, reload, perPage, pageNumber, onPage]);

    useEffect(() => {
        if (redirectTo > 0) {
            history.push(`${location.pathname}#${redirectTo}`);
        }
    }, [history, location.pathname, redirectTo]);


    if (isLoadingContractors || isLoadingCompanies) {
        return <div className="loading-wrapper"><SmallLoader dark /></div>;
    }

    return (
        <div className="TransportShipment">
            {contractorsError && <Error errors={contractorsError} />}
            {(contractor?.email && contractor.email.length) && (
                <TransportShipmentList
                    contractors={[contractor]}
                    search={false}
                    reload={() => null}
                    isSelected
                />
            )}
            {!contractorsError && (
                <TransportShipmentList
                    contractors={contractors}
                    search={{
                        value: contractorsSearch,
                        update: (v) => {
                            setContractorsSearch(v);
                        }
                    }}
                    reload={() => { setReload((v) => v + 1); }}
                />
            )}
            {companiesError && <Error errors={companiesError} />}
            {!companiesError && (
                <TransportShipmentList
                    contractors={companies}
                    search={(!contractorsError
                        && (!contractors || contractors?.length <= 0) ? {
                            value: contractorsSearch,
                            update: (v) => {
                                setContractorsSearch(v);
                            }
                        } : false)}
                    forceShowSearch={!contractorsError
                    && (!contractors || contractors?.length <= 0)}
                    reload={() => { setReload((v) => v + 1); }}
                />
            )}
            <Pagination keyName="carriers" urlPrefix={`${location.pathname}#`} lastPage={lastPage} currentPage={pageNumber} />
        </div>
    );
};

TransportShipment.propTypes = {
    perPage: PropTypes.number.isRequired,
    contractor: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
        avatar: PropTypes.string,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const { contractor } = transportDraft;
    const { perPage } = state;

    return {
        contractor,
        perPage: perPage.carriers,
    };
};
export default connect(mapStateToProps, null)(TransportShipment);
