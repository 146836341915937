import React, { useEffect, useState } from 'react';
import SimplePassing from 'components/SimplePassing';
import { CancelToken } from 'axios';
import { errorHandler, API, translations } from 'services';

const PassingList = () => {
    const [passings, setPassings] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [passingsLoad, setPassingsLoad] = useState(false);

    useEffect(() => {
        setPassingsLoad(true);
        const source = CancelToken.source();
        API.get('/passings?include=startingAddress,destinationAddress,sender,receiver,carriers&page[size]=3&page[number]=1&filter[is_working_copy]=false',
            {
                cancelToken: source.token,
            })
            .then((response) => {
                if (response.data.data && response.data.data.length) {
                    setPassings(response.data.data);
                } else {
                    setPassings([]);
                }
                setPassingsLoad(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setPassings([]);
                    setPassingsLoad(false);
                });
            });

        return () => {
            source.cancel();
        };
    }, []);

    const passingsMap = passings.length ? passings.map((elm) => (
        <SimplePassing passing={elm} key={elm.id} />
    )) : <></>;
    const passingsLayout = passings.length ? passingsMap : '';

    return (
        <div className="PassingsListSingle">
            {passings.length > 0 && (<div className="PassingsListSingle__header">{translations('front.passing.current_transports')}</div>)}
            {passingsLayout}
        </div>
    );
};

export default PassingList;
