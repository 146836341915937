import React from 'react';
import PropTypes from 'prop-types';
import defaultTranslation from 'services/defaultTranslations';

const PassingCarrierCompany = ({ company, email, isInvitation }) => {
    let logoUrl = '/images/logo-company-default.svg';
    let companyName = '';
    const lang = localStorage.getItem('language') || 'en';

    if (company) {
        if (company.logo
            && company.logo.download_url) {
            logoUrl = company.logo.download_url;
        }
        if (company.name && company.name.length) {
            companyName = company.name;
        }
    }

    return (
        <div className="PassingCarrier__company">
            {isInvitation && <div className="PassingCarrier__company--status">{defaultTranslation('invitation_sent', lang)}</div>}
            <div className="PassingCarrier__company--logo" style={{ backgroundImage: `url(${logoUrl})` }} />
            <div className="PassingCarrier__company--info">
                {companyName.length > 0 && (<div className="PassingCarrier__company--info-h">{companyName}</div>)}
                <div className="PassingCarrier__company--info-v">{email}</div>
            </div>
        </div>
    );
};

PassingCarrierCompany.propTypes = {
    company: PropTypes.shape({
        logo: PropTypes.object,
        name: PropTypes.string,
    }),
    email: PropTypes.string,
    isInvitation: PropTypes.bool,
};

PassingCarrierCompany.defaultProps = {
    company: null,
    email: '',
    isInvitation: false,
};

export default PassingCarrierCompany;
