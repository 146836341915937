export default (point) => {
    let addressString = '';

    if (!point) {
        return addressString;
    }

    if (point.address_street) {
        addressString += addressString.length > 0 ? ` ${point.address_street}` : point.address_street;
    }

    if (point.address_housenumber) {
        addressString += addressString.length > 0 ? ` ${point.address_housenumber}` : point.address_housenumber;
    }

    if (point.address_postcode) {
        addressString += addressString.length > 0 ? `, ${point.address_postcode}` : point.address_postcode;
    }

    if (point.address_city) {
        addressString += addressString.length > 0 ? ` ${point.address_city}` : point.address_city;
    }

    if (point.address_country) {
        addressString += addressString.length > 0 ? ` ${point.address_country}` : point.address_country;
    }

    return addressString;
};
