import up from './up.svg';
import password from './lock.svg';
import user from './user.svg';
import logout from './logout.svg';
import search from './search.svg';
import bell from './bell.svg';
// eslint-disable-next-line camelcase
import bell_active from './bell_active.svg';
import chat from './chat.svg';
import close from './close.svg';

const icons = {
    up,
    password,
    user,
    logout,
    search,
    bell,
    bell_active,
    chat,
    close,
};

export default icons;
