import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Moment from 'moment';

const ChatAutoMessage = ({ data }) => {
    const time = Moment(data.created_at).format('LT');
    const timeFull = Moment(data.created_at).format('LLL');
    let { text } = data;

    if (data.form_params_translation && data.form_params_translation.length) {
        text = data.form_params_translation.map((translation, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${index}-${translation.translated}-${translation.name}`}>{translation.translated}</div>
        ));
    }
    return (
        <div className="ChatAutoMessage" title={timeFull}>
            {text}
            <div className="ChatAutoMessage__time">{time}</div>
        </div>
    );
};

ChatAutoMessage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

export default ChatAutoMessage;
