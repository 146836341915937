import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { API, classNames } from 'services';
import { connect } from 'react-redux';
import querystring from 'querystring';
import { useParams } from 'react-router-dom';
import { transportDraftContractor, transportDraftVisited } from 'data/transportDraft/actions';

const TransportShipmentContractor = ({
    contractor, push, driverVisited, visitedDriver,
    isFavourite, reload, isSelected,
}) => {
    const [isActive, setIsActive] = useState(false);
    const { id } = useParams();
    const edit = !!id;
    const withStar = !isSelected;
    const withDelete = !withStar;

    let imageSrc = '/images/logo-company-default.svg';

    try {
        // eslint-disable-next-line camelcase
        if (contractor?.existing_company?.logo?.download_url) {
            imageSrc = contractor.existing_company.logo.download_url;
        // eslint-disable-next-line camelcase
        } else if (contractor?.logo?.download_url) {
            imageSrc = contractor.logo.download_url;
        } else if (contractor?.avatar) {
            imageSrc = contractor.avatar;
        }
    } catch (e) {
        console.error(e);
    }

    const handleContractorSelect = () => {
        const { name, email } = contractor;
        push({ name, email, avatar: imageSrc }, edit);
        setIsActive(false);

        if (!driverVisited) {
            visitedDriver();
        }
    };

    const handleContractorDelete = () => {
        push({ name: '', email: '', avatar: '' }, edit);
    };

    const handleStar = () => {
        const { email, name } = contractor;
        if (!isFavourite) {
            API.post('/contractors', querystring.stringify({
                email, name
            }))
                .then(() => {
                    reload();
                })
                .catch(() => {
                    reload();
                });
        } else {
            API.delete(`/contractors/${contractor.id}`)
                .then(() => {
                    reload();
                })
                .catch(() => {
                    reload();
                });
        }
    };

    return (
        <div className={classNames('TransportShipmentContractor__outer', isActive && 'contractor-active', withStar && 'with-star')}>
            <button className={classNames('TransportShipmentContractor', withStar && 'with-star')} type="button" onClick={() => { setIsActive((prev) => !prev); }}>
                <img src={imageSrc} className="TransportShipmentContractor__image" alt="" />
                <div className="TransportShipmentContractor__name" title={contractor.name}>{contractor.name}</div>
                <div className="TransportShipmentContractor__email" title={contractor.email}>{contractor.email}</div>
                <div className="TransportShipmentContractor__actions">actions</div>
            </button>
            {!withDelete && <button className="TransportShipmentContractor__add" type="button" onClick={() => { handleContractorSelect(); }} />}
            {withStar && <button className={classNames('TransportShipmentContractor__star', isFavourite && 'favourite')} type="button" onClick={() => { handleStar(); }} />}
            {withDelete && <button className="TransportShipmentContractor__remove" type="button" onClick={() => { handleContractorDelete(); }} />}
        </div>
    );
};

TransportShipmentContractor.propTypes = {
    contractor: PropTypes.shape({
        company_id: PropTypes.number,
        created_at: PropTypes.string,
        email: PropTypes.string,
        existing_company: PropTypes.object,
        existing_contractor_company_id: PropTypes.number,
        id: PropTypes.number,
        name: PropTypes.string,
        updated_at: PropTypes.string,
        isCarrier: PropTypes.bool,
        logo: PropTypes.object,
        avatar: PropTypes.string,
    }).isRequired,
    push: PropTypes.func.isRequired,
    driverVisited: PropTypes.bool.isRequired,
    visitedDriver: PropTypes.func.isRequired,
    isFavourite: PropTypes.bool,
    reload: PropTypes.func,
    isSelected: PropTypes.bool,
};

TransportShipmentContractor.defaultProps = {
    isFavourite: false,
    reload: () => {},
    isSelected: false,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const { driverVisited } = transportDraft;
    // console.log(transportAdd, 'kamil<3');

    return {
        driverVisited,
    };
};

const mapDispatchToProps = (dispatch) => ({
    push: (vals, edit = false) => dispatch(transportDraftContractor(vals, edit)),
    visitedDriver: () => dispatch(transportDraftVisited(true, 'driver')),
});
export default connect(mapStateToProps, mapDispatchToProps)(TransportShipmentContractor);
