import React, { useCallback, useEffect, useRef } from 'react';
import './styles.scss';
import { translations } from 'services';

const SvgCreator = () => {
    const svgWidth = 900;
    const svgHeight = 500;
    const strokeWidth = 2;
    const elmID = 'svg-drawing';
    const path = useRef(null);
    const buffer = useRef([]);
    const bufferSize = useRef(1);
    const strPath = useRef('');

    const appendToBuffer = (pt) => {
        if (buffer.current && bufferSize.current) {
            buffer.current.push(pt);
            while (buffer.current.length > bufferSize.current) {
                buffer.current.shift();
            }
        }
    };

    const getMousePosition = (event) => {
        const $elm = document.getElementById(elmID);
        if ($elm) {
            const $rect = $elm.getBoundingClientRect();

            return {
                x: (event.pageX - $rect.left) / ($rect.width / svgWidth),
                y: (event.pageY - $rect.top) / ($rect.height / svgHeight),
            };
        }
        return {
            x: 0,
            y: 0,
        };
    };

    const getAveragePoint = (offset) => {
        const len = buffer.current.length;
        if (len % 2 === 1 || len >= bufferSize.current) {
            let totalX = 0;
            let totalY = 0;
            let pt;
            let
                i;
            let count = 0;
            for (i = offset; i < len; i += 1) {
                count += 1;
                pt = buffer.current[i];
                totalX += pt.x;
                totalY += pt.y;
            }
            return {
                x: Math.floor(totalX / count),
                y: Math.floor(totalY / count),
            };
        }
        return null;
    };

    const updateSvgPath = () => {
        let pt = getAveragePoint(0);

        if (pt) {
            strPath.current += ` L${pt.x} ${pt.y}`;

            let tmpPath = '';
            for (let offset = 2; offset < buffer.current.length; offset += 2) {
                pt = getAveragePoint(offset);
                tmpPath += ` L${pt.x} ${pt.y}`;
            }

            path.current.setAttribute('d', strPath.current + tmpPath);
        }
    };

    const handleClear = () => {
        const $elm = document.getElementById(elmID);
        if ($elm) {
            const $paths = $elm.querySelectorAll('path');
            if ($paths && $paths.length) {
                for (let i = 0; i < $paths.length; i += 1) {
                    $paths[i].remove();
                }
            }
            path.current = null;
            buffer.current = [];
            bufferSize.current = 1;
            strPath.current = '';
        }
    };

    const handleMouseDown = useCallback((event) => {
        const $elm = document.getElementById(elmID);
        if ($elm) {
            bufferSize.current = 1;
            path.current = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            path.current.setAttribute('fill', 'none');
            path.current.setAttribute('stroke', '#000');
            path.current.setAttribute('stroke-width', strokeWidth);
            buffer.current = [];
            const pt = getMousePosition(event);
            appendToBuffer(pt);
            strPath.current = `M${pt.x} ${pt.y}`;
            path.current.setAttribute('d', strPath.current);
            $elm.appendChild(path.current);
        }
    }, []);

    const handleMouseMove = useCallback((event) => {
        if (path.current) {
            appendToBuffer(getMousePosition(event));
            updateSvgPath();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMouseUp = useCallback(() => {
        if (path.current) {
            path.current = null;
        }
    }, []);

    useEffect(() => {
        const $elm = document.getElementById(elmID);

        if ($elm) {
            $elm.addEventListener('mousedown', handleMouseDown);
            $elm.addEventListener('mousemove', handleMouseMove);
            $elm.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            if ($elm) {
                $elm.removeEventListener('mousedown', handleMouseDown);
                $elm.removeEventListener('mousemove', handleMouseMove);
                $elm.removeEventListener('mouseup', handleMouseUp);
            }
        };
    }, [handleMouseDown, handleMouseMove, handleMouseUp]);

    return (
        <div className="SvgCreator">
            <svg
                width={`${svgWidth}px`}
                height={`${svgHeight}px`}
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                id={elmID}
            />
            <div className="SvgCreator__clear-outer">
                <button type="button" className="SvgCreator__clear" onClick={() => { handleClear(); }}>{translations('front.general.clear')}</button>
            </div>
        </div>
    );
};

export default SvgCreator;
