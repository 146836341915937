import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { classNames } from 'services';

const Alert = ({ type, ...props }) => {
    const { children } = props;

    return <div className={classNames('Alert', `Alert--${type}`)}>{children}</div>;
};

Alert.propTypes = {
    children: PropTypes.node,
    type: PropTypes.string,
};

Alert.defaultProps = {
    children: <></>,
    type: 'warning',
};

export default Alert;
