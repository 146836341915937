import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from 'services';
import SmallLoader from 'components/SmallLoader';

import './styles.scss';

const Button = ({
    theme,
    type,
    onClick,
    children,
    large,
    block,
    shadow,
    withPlus,
    withLoader,
    loading,
    small,
    medium,
    close,
    className,
    ...props
}) => (
    <button type={type} //eslint-disable-line
        onClick={onClick}
        className={classNames(
            'button',
            `button--${theme}`,
            large && 'button--large',
            shadow && 'button--shadow',
            block && 'button--block',
            loading && 'button--loading',
            withPlus && 'button--plus',
            small && 'button--small',
            medium && 'button--medium',
            close && 'button--close',
            className.length > 0 ? className : '',
        )}
        {...props}
    >
        <span className="button-inner">{children}</span>
        {withLoader && <SmallLoader />}
    </button>
);

Button.propTypes = {
    theme: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    type: PropTypes.string,
    large: PropTypes.bool,
    block: PropTypes.bool,
    withLoader: PropTypes.bool,
    withPlus: PropTypes.bool,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    loading: PropTypes.bool,
    shadow: PropTypes.bool,
    close: PropTypes.bool,
    className: PropTypes.string,
};

Button.defaultProps = {
    theme: 'primary',
    type: 'button',
    large: false,
    block: false,
    shadow: false,
    withLoader: false,
    withPlus: false,
    small: false,
    medium: false,
    loading: false,
    close: false,
    className: '',
    onClick: () => null,
};

export default Button;
