import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

import './styles.scss';
import { translations } from 'services';

const Search = ({ value, callback, placeholder }) => {
    const [initialSearch, setInitialSearch] = useState(value);
    const placeholderToUse = placeholder.length ? placeholder : translations('front.general.search');

    return (
        <div className="Search">
            <input
                type="search"
                placeholder={placeholderToUse}
                value={initialSearch}
                onKeyUp={(e) => {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                        callback(e.target.value);
                    }
                }}
                onChange={(e) => {
                    setInitialSearch(e.target.value);
                }}
                className="Search-input"
            />
            {(initialSearch && initialSearch.length > 0) ? <button type="button" className="Search-remove" onClick={() => { setInitialSearch(''); callback(''); }}><Icon icon="close" /></button>
                : <button type="button" className="Search-button" onClick={() => { callback(initialSearch); }}><Icon icon="search" /></button>}
        </div>
    );
};

Search.propTypes = {
    value: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

Search.defaultProps = {
    placeholder: '',
};

export default Search;
