const getDriverName = (passing) => {
    let name = '\u00A0';

    try {
        if (passing.carriers && passing.carriers[0] && passing.carriers[0].driver) {
            const { driver } = passing.carriers[0];
            name = `${driver.name} ${driver.surname}`;
        }
    } catch (e) {
        console.error(e);
    }

    return name;
};

export default getDriverName;
