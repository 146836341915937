const getStatuses = () => ([
    'new',
    'accepted',
    'rejected',
    'in_way_for_load',
    'ready_for_load',
    'loaded',
    'in_way_for_unload',
    'ready_for_unload',
    'unloaded',
    'damage',
    'pause',
    'finished',
    'waiting_for_accept',
]);

export default getStatuses;
