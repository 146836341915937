import React from 'react';
import { Field, useFormikContext } from 'formik';
import { getStatuses, translations } from 'services';
import FormField from 'components/FormField';
import './styles.scss';

const TransportsFiltersForm = () => {
    const statuses = getStatuses();

    const { submitForm, setFieldValue } = useFormikContext();

    const handleReset = async () => {
        setFieldValue('status', []);
        setFieldValue('sorting', '-updated_at');
        await submitForm();
    };

    return (
        <>
            <div className="TransportsFiltersForm__group">
                <div className="TransportsFiltersForm__header">{translations('front.transports_filters.status')}</div>
                <div className="TransportsFiltersForm__checkboxes">
                    {statuses.map((status) => (
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        <label key={status} style={{ display: 'block', cursor: 'pointer' }}>
                            <Field type="checkbox" name="status" value={status} className="TransportsFiltersForm__checkbox" />
                            <span className="TransportsFiltersForm__checkbox-span">
                                {`${translations(`passing.status.${status}`)}`}
                            </span>
                        </label>
                    ))}
                </div>
            </div>
            <div className="TransportsFiltersForm__group">
                <div className="TransportsFiltersForm__header">{translations('front.general.sort')}</div>
                <FormField
                    name="sorting"
                    isSelect={[
                        {
                            value: 'created_at',
                            label: translations('front.general.sorting.created_at_asc'),
                        },
                        {
                            value: '-created_at',
                            label: translations('front.general.sorting.created_at_desc'),
                        },
                        {
                            value: 'updated_at',
                            label: translations('front.general.sorting.updated_at_asc'),
                        },
                        {
                            value: '-updated_at',
                            label: translations('front.general.sorting.updated_at_desc'),
                        },
                        {
                            value: 'passing_idstr',
                            label: translations('front.general.sorting.id_asc'),
                        },
                        {
                            value: '-passing_idstr',
                            label: translations('front.general.sorting.id_desc'),
                        },
                    ]}
                />
            </div>
            <div>
                <div className="d-flex">
                    <button type="submit" className="TransportsFiltersForm__submit">{translations('front.general.filters_submit')}</button>
                </div>
                <div className="d-flex">
                    <button type="button" onClick={handleReset} className="TransportsFiltersForm__cancel">{translations('front.general.clear')}</button>
                </div>
            </div>
        </>
    );
};

export default TransportsFiltersForm;
