import { API } from 'services';

import {
    ROLES_REQUEST, ROLES_LOADED, ROLES_ERROR
} from './constants';

export function startRolesRequest() {
    return { type: ROLES_REQUEST };
}

export function rolesSuccess(payload) {
    return { type: ROLES_LOADED, payload };
}

export function rolesError(payload) {
    return { type: ROLES_ERROR, payload };
}

export function getRoles() {
    return (dispatch) => {
        dispatch(startRolesRequest());
        return API.get('/roles')
            .then((response) => {
                if (response.status === 200) {
                    return dispatch(rolesSuccess(response.data.data));
                }
                return dispatch(rolesSuccess([]));
            })
            .catch((err) => (dispatch(rolesError(err))));
    };
}
