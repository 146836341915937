export default function draftValidation(state) {
    const {
        senderValid, senderVisited, receiverValid, receiverVisited,
        driverVisited, carrier, contractor,
    } = state;
    const driverValid = !!((carrier !== false)
        || (contractor !== false && contractor?.email?.length));

    let senderStatus = '';
    if (senderValid) {
        senderStatus = 'success';
    } else if (senderVisited) {
        senderStatus = 'error';
    }

    let receiverStatus = '';
    if (receiverValid) {
        receiverStatus = 'success';
    } else if (receiverVisited) {
        receiverStatus = 'error';
    }

    let driverStatus = '';
    if (driverVisited) {
        if (!driverValid) {
            driverStatus = 'error';
        } else if (driverValid) {
            driverStatus = 'success';
        }
    } else if (driverValid) {
        driverStatus = 'success';
    }

    return {
        sender: {
            validation: senderValid,
            status: senderStatus,
        },
        receiver: {
            validation: receiverValid,
            status: receiverStatus,
        },
        driver: {
            validation: driverValid,
            status: driverStatus,
        },
    };
}
