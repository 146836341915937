import bg from './bg/index';
import cs from './cs/index';
import da from './da/index';
import de from './de/index';
import en from './en/index';
import el from './el/index';
import es from './es/index';
import et from './et/index';
import fi from './fi/index';
import fr from './fr/index';
import hr from './hr/index';
import hu from './hu/index';
import it from './it/index';
import lb from './lb/index';
import lt from './lt/index';
import lv from './lv/index';
import nl from './nl/index';
import pl from './pl/index';
import pt from './pt/index';
import ro from './ro/index';
import ru from './ru/index';
import sk from './sk/index';
import sl from './sl/index';
import sv from './sv/index';
import tr from './tr/index';
import uk from './uk/index';

const defaultTranslation = (keyName, lang = 'en') => {
    let translationsToUse = en;
    switch (lang) {
    case 'pl':
        translationsToUse = pl;
        break;
    case 'bg':
        translationsToUse = bg;
        break;
    case 'cs':
        translationsToUse = cs;
        break;
    case 'da':
        translationsToUse = da;
        break;
    case 'de':
        translationsToUse = de;
        break;
    case 'el':
        translationsToUse = el;
        break;
    case 'es':
        translationsToUse = es;
        break;
    case 'et':
        translationsToUse = et;
        break;
    case 'fi':
        translationsToUse = fi;
        break;
    case 'fr':
        translationsToUse = fr;
        break;
    case 'hr':
        translationsToUse = hr;
        break;
    case 'hu':
        translationsToUse = hu;
        break;
    case 'it':
        translationsToUse = it;
        break;
    case 'lb':
        translationsToUse = lb;
        break;
    case 'lt':
        translationsToUse = lt;
        break;
    case 'lv':
        translationsToUse = lv;
        break;
    case 'nl':
        translationsToUse = nl;
        break;
    case 'pt':
        translationsToUse = pt;
        break;
    case 'ro':
        translationsToUse = ro;
        break;
    case 'ru':
        translationsToUse = ru;
        break;
    case 'sk':
        translationsToUse = sk;
        break;
    case 'sl':
        translationsToUse = sl;
        break;
    case 'tr':
        translationsToUse = tr;
        break;
    case 'sv':
        translationsToUse = sv;
        break;
    case 'uk':
        translationsToUse = uk;
        break;
    default:
        break;
    }

    const getTranslation = (k) => {
        if (typeof translationsToUse[k] !== 'undefined') {
            return translationsToUse[k];
        }
        return '';
    };

    if (typeof keyName === 'undefined') {
        return '';
    }

    if (!lang) {
        return getTranslation(keyName);
    }

    return getTranslation(keyName);
};

export default defaultTranslation;
