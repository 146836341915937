import { API } from 'services';

import {
    TRAILERS_LOADED, TRAILERS_ERROR, TRAILERS_REQUEST
} from './constants';

export function startTrailersRequest() {
    return { type: TRAILERS_REQUEST };
}

export function trailersSuccess(payload) {
    return { type: TRAILERS_LOADED, payload };
}

export function trailersError(payload) {
    return { type: TRAILERS_ERROR, payload };
}

export function getTrailers() {
    return (dispatch) => {
        dispatch(startTrailersRequest());
        return API.get('/trailers/types')
            .then((res) => {
                const trailersArray = [];
                if (res.data.data && res.data.data.length) {
                    res.data.data.forEach((elm) => {
                        trailersArray.push({
                            label: elm.name,
                            value: elm.idstr,
                        });
                    });
                }
                return dispatch(trailersSuccess(trailersArray));
            })
            .catch((err) => (dispatch(trailersError(err))));
    };
}
