import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import translations from 'services/translations';

import Button from 'components/Button';

import './style.scss';

const FormWrapper = ({
    children,
    initialValues,
    validationSchema,
    onSubmit,
    isLoading,
    isTruck,
}) => {
    const isEdit = !!((initialValues.brand
        && initialValues.registration_number
        && initialValues.model));

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            // validateOnChange={false}
        >
            {(formikBag) => (
                <Form className="TrucksList-form">
                    {children(formikBag)}
                    <div className="TrucksList-form-title">{isEdit ? translations(`front.fleet.edit.${isTruck ? 'truck' : 'trailer'}`) : translations(`front.fleet.add.${isTruck ? 'truck' : 'trailer'}`)}</div>
                    <div className="TrucksList-form__button-wrapper">
                        <Button block shadow type="submit" disabled={isLoading || !(formikBag.isValid)}>{translations('front.general.save')}</Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

FormWrapper.propTypes = {
    children: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired, //eslint-disable-line
    validationSchema: PropTypes.any.isRequired, //eslint-disable-line
    isLoading: PropTypes.bool.isRequired,
    isTruck: PropTypes.bool,
};

FormWrapper.defaultProps = {
    isTruck: false,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        isLoggedIn: user.isLoggedIn,
    };
};

export default connect(mapStateToProps)(FormWrapper);
