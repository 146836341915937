import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'services/classNames';
import translations from 'services/translations';
import './styles.scss';
import { confirm } from 'data/confirmation/actions';

const TrucksListElement = ({
    truck, edit, del, hideForm, confirmation,
}) => {
    const [optionsVisible, setOptionsVisible] = useState(false);

    /**
     * Hook that alerts clicks outside of the passed ref
     */
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOptionsVisible(false);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className={classNames('TrucksListElement', 'list-element')}>
            <div className="TrucksListElement--text TrucksListElement__brand">{truck.brand}</div>
            <div className="TrucksListElement--text TrucksListElement__model">{truck.model}</div>
            <div className="TrucksListElement--text TrucksListElement__type">{truck.type}</div>
            <div className="TrucksListElement--text TrucksListElement__registration_number">{truck.registration_number}</div>
            <div className="TrucksListElement--text TrucksListElement__weight">{truck.deadweight_load ? `${truck.deadweight_load}kg` : ''}</div>
            <div className="TrucksListElement--text TrucksListElement__gvwr">{truck.gross_vehicle_mass ? `${truck.gross_vehicle_mass}kg` : ''}</div>
            <div
                className="TrucksListElement__actions"
                ref={wrapperRef}
            >
                <button
                    type="button"
                    onClick={() => { hideForm(); setOptionsVisible(!optionsVisible); }}
                    className="TrucksListElement__action option-button"
                >
                    actions
                </button>
                <div
                    className="TrucksListElement__buttons"
                    style={{ display: optionsVisible ? 'block' : 'none' }}
                >
                    <button
                        type="button"
                        onClick={() => { edit(truck); }}
                        className="TrucksListElement__edit"
                    >
                        {translations('front.general.edit')}
                    </button>
                    <button
                        type="button"
                        className="TrucksListElement__delete"
                        onClick={() => {
                            confirmation(translations('front.general.attention'), translations('front.fleet.trucks_delete_confirmation'), del);
                        }}
                    >
                        {translations('front.general.delete')}
                    </button>
                </div>
            </div>
        </div>
    );
};

TrucksListElement.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    truck: PropTypes.object.isRequired,
    edit: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    hideForm: PropTypes.func.isRequired,
    confirmation: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    confirmation: (t, d, c) => dispatch(confirm(t, d, c)),
});

export default connect(null, mapDispatchToProps)(TrucksListElement);
