import React from 'react';
import { Form, Formik } from 'formik';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import { translations } from 'services';
import Yup from 'services/translatedYup';
import './styles.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { transportDraftContractor } from 'data/transportDraft/actions';

const TransportShipmentInvite = ({ push, closeHandler }) => {
    const { id } = useParams();
    const edit = !!id;
    const defaultsToUse = {
        email: '',
    };
    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required(),
    });
    const emailLabel = translations('passing.email');
    const handleSubmit = (email) => {
        const scrollElement = document.querySelector('.AuthenticatedWrapper-content');
        if (scrollElement) {
            scrollElement.scrollTo(0, 0);
        }
        push({
            name: '',
            email,
            avatar: '',
        }, edit);
        closeHandler();
    };

    return (
        <div className="TransportShipmentInvite">
            <button className="TransportShipmentInvite__close" onClick={() => { closeHandler(); }} type="button">close</button>
            <div className="TransportShipmentInvite__copy">{translations('invite.message')}</div>
            <Formik
                initialValues={defaultsToUse}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleSubmit(values.email);
                }}
            >
                {() => (
                    <Form className="TransportShipmentInvite__form">
                        <FormRow>
                            <FormField
                                label={emailLabel}
                                name="email"
                                type="email"
                                isRequired
                            />
                        </FormRow>
                        <button type="submit" className="TransportShipmentInvite__submit">
                            <span className="TransportShipmentInvite__submit--copy">{translations('invite_carrier.header')}</span>
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

TransportShipmentInvite.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    push: (vals, edit = false) => dispatch(transportDraftContractor(vals, edit)),
});

export default connect(null, mapDispatchToProps)(TransportShipmentInvite);
