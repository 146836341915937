import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'services/classNames';


import './styles.scss';

const Block = ({ header, isLoading, ...props }) => {
    const { children } = props;
    return (
        <div className={classNames('Block', isLoading && 'loading')}>
            <div className="Block--header">
                <div className="Block--header-inner">{header}</div>
            </div>
            <div className="Block--content">
                {children}
            </div>
        </div>
    );
};

Block.propTypes = {
    header: PropTypes.node.isRequired,
    isLoading: PropTypes.bool,
    children: PropTypes.node
};

Block.defaultProps = {
    children: <></>,
    isLoading: false,
};

export default Block;
