import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Yup from 'services/translatedYup';
import {
    translations, API, errorHandler, useTransportDrivers, transformLastDriverToSelect
} from 'services';
import { Form, Formik } from 'formik';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import Error from 'components/Error';
import Button from 'components/Button';
import './styles.scss';
import { CancelToken } from 'axios';
import DriversFormFallback from 'components/DriversFormFallback';
import SmallLoader from 'components/SmallLoader';
import {
    transportDraftCarrier, transportDraftDriverOption, transportDraftValid, transportDraftVisited
} from 'data/transportDraft/actions';


import TransportDriverFormObserver from './components/TransportDriverFormObserver';

const TransportDriverForm = ({
    edit, push, notValid, goBack, carrier,
    visitedDriver,
}) => {
    const cancelTokenDriversSearch = useRef(null);
    const cancelTokenTrucks = useRef(null);
    const cancelTokenTrailers = useRef(null);
    const reference = useRef(null);
    const [driversSearchError, setDriversSearchError] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [vehiclesError, setVehiclesError] = useState(false);
    const [vehiclesLoading, setVehiclesLoading] = useState(false);
    const [trailers, setTrailers] = useState([]);
    const [trailersError, setTrailersError] = useState(false);
    const [trailersLoading, setTrailersLoading] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const validationObject = {
        driver_id: Yup.string().required().test('number', 'Must be a valid driver', (value) => {
            if ((!Number.isNaN(value) || !Number.isNaN(parseInt(value, 10))) && value > 0) {
                return true;
            }
            return new RegExp('\\d+-\\d+-\\d+').test(value);
        }),
        vehicle_id: Yup.number().required().positive().integer(),
        trailer_id: Yup.number().positive().integer(),
        additional_info: Yup.string().max(250).nullable(),
    };
    const validationSchema = Yup.object().shape(validationObject);
    const formTranslations = {
        driver: translations('front.passing.add.driver'),
        trailer: translations('front.passing.add.trailer'),
        vehicle: translations('front.passing.add.vehicle'),
        additional_info: translations('front.passing.single.load.carrier_info'),
    };
    const defaultsToUse = carrier ? {
        driver_id: carrier.driver_id ? carrier.driver_id : '',
        trailer_id: carrier.trailer_id ? carrier.trailer_id : '',
        vehicle_id: carrier.vehicle_id ? carrier.vehicle_id : '',
        additional_info: carrier.additional_info && carrier.additional_info.length ? carrier.additional_info : '',
    } : {
        driver_id: '',
        trailer_id: '',
        vehicle_id: '',
        additional_info: '',
    };
    const {
        drivers, driversError, driversLoading, lastDrivers
    } = useTransportDrivers(carrier && carrier.driver
        ? carrier.driver : null);

    const parseDriversData = (data) => {
        let array = [];
        data.forEach((single) => {
            const lastDriversToJoin = lastDrivers.filter(
                (d) => d.driver_id === parseInt(single.id, 10)
            ).map((elm) => transformLastDriverToSelect(elm));
            array = [...array, ...lastDriversToJoin, {
                value: parseInt(single.id, 10),
                label: `${single.name} ${single.surname}`,
            }];
        });
        return array;
    };

    const parseData = (data) => {
        const array = [];
        data.forEach((single) => {
            array.push({
                value: parseInt(single.id, 10),
                label: `${single.brand} ${single.model} ${single.registration_number}`,
            });
        });
        return array;
    };

    const goBackToSwitcher = () => {
        setIsCancel(true);
    };

    useEffect(() => {
        if (isCancel) {
            goBack(edit);
        }
    }, [edit, goBack, isCancel]);

    useEffect(() => {
        const source2 = CancelToken.source();
        const source3 = CancelToken.source();
        setVehiclesLoading(true);
        API.get('/vehicles?page[size]=100', {
            cancelToken: source2.token,
        })
            .then((response) => {
                if (response.data.data && response.data.data.length) {
                    const arrayToSet = response.data.data;
                    if (carrier.vehicle && carrier.vehicle.id) {
                        if (!response.data.data.find((elm) => elm.id === carrier.vehicle.id)) {
                            arrayToSet.push(carrier.vehicle);
                        }
                    }
                    setVehicles(parseData(arrayToSet, 'vehicles'));
                }
                setVehiclesLoading(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setVehiclesError(error);
                    setVehiclesLoading(false);
                });
            });
        setTrailersLoading(true);
        API.get('/trailers?page[size]=100', {
            cancelToken: source3.token,
        })
            .then((response) => {
                if (response.data.data && response.data.data.length) {
                    const arrayToSet = response.data.data;
                    if (carrier.trailer && carrier.trailer.id) {
                        if (!response.data.data.find((elm) => elm.id === carrier.trailer.id)) {
                            arrayToSet.push(carrier.trailer);
                        }
                    }
                    setTrailers(parseData(arrayToSet, 'trailers'));
                }
                setTrailersLoading(false);
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setTrailersError(error);
                    setTrailersLoading(false);
                });
            });

        return () => {
            source2.cancel();
            source3.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => () => {
        if (cancelTokenDriversSearch.current) {
            cancelTokenDriversSearch.current.cancel();
        }
        if (cancelTokenTrucks.current) {
            cancelTokenTrucks.current.cancel();
        }
        if (cancelTokenTrailers.current) {
            cancelTokenTrailers.current.cancel();
        }
    }, []);


    useEffect(() => () => {
        if (reference.current) {
            reference.current.validateForm()
                .then((e) => {
                    if (Object.keys(e).length > 0) {
                        notValid(edit);
                        visitedDriver();
                    }
                });
            reference.current.submitForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (driversLoading || vehiclesLoading || trailersLoading) {
        return (<div className="loading-wrapper"><SmallLoader dark /></div>);
    }


    if (drivers.length === 0 || vehicles.length === 0) {
        return (
            <div className="TransportAdd__card">
                <DriversFormFallback />
                <Button theme="outline" block type="button" role="button" onClick={() => { goBackToSwitcher(); }}>{translations('front.general.cancel')}</Button>
            </div>
        );
    }

    return (
        <div className="TransportAdd__card">
            <div className="TransportDriverForm">
                {(driversError && typeof driversError !== 'boolean') && <Error errors={driversError} />}
                {(driversSearchError && typeof driversSearchError !== 'boolean') && <Error errors={driversSearchError} />}
                {(vehiclesError && typeof vehiclesError !== 'boolean') && <Error errors={vehiclesError} />}
                {(trailersError && typeof trailersError !== 'boolean') && <Error errors={trailersError} />}
                <Formik
                    enableReinitialize
                    innerRef={reference}
                    initialValues={defaultsToUse}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (!isCancel) {
                            push(values, edit);
                        }
                        visitedDriver();
                    }}
                >
                    {({ values }) => (
                        <Form className="TransportDriverForm__form">
                            <TransportDriverFormObserver />
                            <FormRow>
                                {
                                    drivers.length > 0 && (
                                        <FormField
                                            name="driver_id"
                                            isSelect={drivers}
                                            isRequired
                                            label={formTranslations.driver}
                                            withValue
                                            isAsyncSelect={(inputValue) => {
                                                setDriversSearchError(false);
                                                // eslint-disable-next-line max-len
                                                cancelTokenDriversSearch.current = CancelToken.source();
                                                return API.get(`/users?filters[driver]=1&filter[search]=${inputValue}`, {
                                                    cancelToken:
                                                    cancelTokenDriversSearch.current.token,
                                                })
                                                    .then((response) => {
                                                        if (response.data.data
                                                            && response.data.data.length) {
                                                            // eslint-disable-next-line max-len
                                                            return parseDriversData(response.data.data);
                                                        }
                                                        return [];
                                                    })
                                                    .catch((error) => {
                                                        errorHandler(error, () => {
                                                            setDriversSearchError(error);
                                                        });
                                                    });
                                            }}
                                        />
                                    )
                                }
                            </FormRow>
                            <FormRow>
                                {
                                    vehicles.length > 0 && (
                                        <FormField
                                            name="vehicle_id"
                                            isSelect={vehicles}
                                            isRequired
                                            label={formTranslations.vehicle}
                                            forceValue
                                            isAsyncSelect={(inputValue) => {
                                                setVehiclesError(false);
                                                cancelTokenTrucks.current = CancelToken.source();
                                                return API.get(`/vehicles?filter[search]=${inputValue}`, {
                                                    cancelToken: cancelTokenTrucks.current.token,
                                                })
                                                    .then((response) => {
                                                        if (response.data.data
                                                            && response.data.data.length) {
                                                            return parseData(response.data.data, 'vehicles');
                                                        }
                                                        return [];
                                                    })
                                                    .catch((error) => {
                                                        errorHandler(error, () => {
                                                            setVehiclesError(error);
                                                        });
                                                    });
                                            }}
                                        />
                                    )
                                }
                            </FormRow>
                            <FormRow>
                                {
                                    trailers.length > 0 && (
                                        <FormField
                                            name="trailer_id"
                                            isSelect={trailers}
                                            label={formTranslations.trailer}
                                            forceValue
                                            isAsyncSelect={(inputValue) => {
                                                setTrailersError(false);
                                                cancelTokenTrailers.current = CancelToken.source();
                                                return API.get(`/trailers?filter[search]=${inputValue}`, {
                                                    cancelToken: cancelTokenTrailers.current.token,
                                                })
                                                    .then((response) => {
                                                        if (response.data.data
                                                            && response.data.data.length) {
                                                            return parseData(response.data.data, 'trailers');
                                                        }
                                                        return [];
                                                    })
                                                    .catch((error) => {
                                                        errorHandler(error, () => {
                                                            setTrailersError(error);
                                                        });
                                                    });
                                            }}
                                        />
                                    )
                                }
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="additional_info"
                                    id="driver_additional_info"
                                    type="textarea"
                                    label={formTranslations.additional_info}
                                />
                            </FormRow>
                            <div className="TransportDriverForm__buttons">
                                <Button theme="outline" block type="button" role="button" onClick={() => { goBackToSwitcher(); }}>{translations('front.general.cancel')}</Button>
                                <Button
                                    theme="blue"
                                    block
                                    type="submit"
                                >
                                    {translations('front.general.add')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

TransportDriverForm.propTypes = {
    edit: PropTypes.bool,
    push: PropTypes.func.isRequired,
    notValid: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    carrier: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            additional_info: PropTypes.string,
            company_id: PropTypes.number,
            created_at: PropTypes.string,
            driver: PropTypes.object,
            driver_id: PropTypes.number,
            id: PropTypes.number,
            is_current: PropTypes.number,
            passing_id: PropTypes.number,
            position: PropTypes.number,
            trailer: PropTypes.object,
            trailer_id: PropTypes.number,
            updated_at: PropTypes.string,
            vehicle: PropTypes.object,
            vehicle_id: PropTypes.number,
        }),
        PropTypes.shape({
            driver_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            trailer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            vehicle_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            additional_info: PropTypes.string,
        })
    ]).isRequired,
    visitedDriver: PropTypes.func.isRequired,
};

TransportDriverForm.defaultProps = {
    edit: false,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const { carrier } = transportDraft;

    return {
        carrier,
    };
};


const mapDispatchToProps = (dispatch) => ({
    push: (values, edit = false) => dispatch(transportDraftCarrier(values, edit)),
    notValid: (edit = false) => dispatch(transportDraftValid(false, 'driver', edit)),
    goBack: (edit = false) => {
        dispatch(transportDraftCarrier(false, edit));
        return dispatch(transportDraftDriverOption());
    },
    visitedDriver: () => dispatch(transportDraftVisited(true, 'driver')),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportDriverForm);
