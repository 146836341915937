import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import classNames from 'services/classNames';
import Moment from 'moment';
import translations from 'services/translations';

const PassingPoint = ({
    type, data, timeStart, timeEnd
}) => {
    let header = '';
    switch (type) {
    case 'sender':
        header = translations('front.passing.single.sender');
        break;
    case 'receiver':
        header = translations('front.passing.single.receiver');
        break;
    case 'starting_address':
        header = translations('front.passing.single.starting_address');
        break;
    case 'destination_address':
        header = translations('front.passing.single.destination_address');
        break;
    default:
        break;
    }

    let timeLayout = '';

    if (timeStart || timeEnd) {
        if (timeStart && timeEnd) {
            if (Moment(timeStart).format('YYYYMMDD') === Moment(timeEnd).format('YYYYMMDD')) {
                timeLayout = `${Moment(timeStart).format('lll')} - ${Moment(timeEnd).format('LT')}`;
            } else {
                timeLayout = `${Moment(timeStart).format('lll')} - ${Moment(timeEnd).format('lll')}`;
            }
        } else if (timeStart) {
            timeLayout = Moment(timeStart).format('lll');
        } else {
            timeLayout = Moment(timeEnd).format('lll');
        }
    }

    const emails = [];

    if (data.additional_email && data.additional_email.length > 0) {
        data.additional_email.split(',').forEach((email) => {
            if (email.length > 0 && !emails.includes(email)) {
                emails.push(email);
            }
        });
    }

    return (
        <div className="PassingPoint" data-km-passing-point={type === 'sender' || type === 'receiver' ? 1 : 2}>
            <div className="PassingPoint__header">
                {header}
            </div>
            <div className={classNames('PassingPoint__data', type)}>
                <div className="PassingPoint__data--title">{data.title}</div>
                <div className="PassingPoint__data--address">
                    {data.address_street}
                    {' '}
                    {data.address_housenumber}
                    {' '}
                    {data.address_apartment_number}
                    {' '}
                    <br />
                    {data.address_postcode}
                    {' '}
                    {data.address_city}
                    {' '}
                    <br />
                    {data.address_country}
                    <br />
                    {' '}
                    <br />
                    {data.email ? <a href={`mailto:${data.email}`}>{data.email}</a> : ' '}
                    {emails.map((email) => <div key={`em-${email}`}><a href={`mailto:${data.email}`}>{email}</a></div>)}
                </div>
                {timeLayout ? (
                    <div className="PassingPoint__data--time">
                        <br />
                        {timeLayout}
                    </div>
                ) : ''}
                {((type === 'starting_address'
                    || type === 'destination_address')
                    && data.additional_info)
                    ? <div className="PassingPoint__data--info">{data.additional_info}</div>
                    : ''}
            </div>
        </div>
    );
};

PassingPoint.propTypes = {
    type: PropTypes.string.isRequired,
    timeStart: PropTypes.string,
    timeEnd: PropTypes.string,
    data: PropTypes.shape({
        title: PropTypes.string,
        address_city: PropTypes.string,
        address_country: PropTypes.string,
        address_housenumber: PropTypes.string,
        address_postcode: PropTypes.string,
        address_street: PropTypes.string,
        address_apartment_number: PropTypes.string,
        email: PropTypes.string,
        additional_email: PropTypes.string,
        additional_info: PropTypes.string,
    }).isRequired,
};

PassingPoint.defaultProps = {
    timeStart: '',
    timeEnd: '',
};

export default PassingPoint;
