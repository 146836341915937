import React from 'react';
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import { classNames, defaultTranslations, translations } from 'services';
import style from './UserCustomCompany.module.scss';

const UserCustomCompany = ({ countries }) => {
    const formik = useFormikContext();
    const { values } = formik;
    const lang = localStorage.getItem('language') || 'en';

    return (
        <div>
            <div className={style.Checkbox}>
                <Field
                    type="checkbox"
                    name="use_different_company"
                    className=""
                    id="use_different_company_checkbox"
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="use_different_company_checkbox" className={style.Label}>
                    {defaultTranslations('users_custom_company_data', lang)}
                </label>
            </div>
            {values.use_different_company === true && (
                <div>
                    <FormRow>
                        <FormField
                            name="custom_company_name"
                            label={translations('passing.name')}
                            isRequired
                        />
                    </FormRow>
                    <FormRow>
                        <FormField name="custom_company_nip" isRequired label={translations('front.settings.company.nip')} />
                    </FormRow>
                    <FormRow>
                        <FormField
                            name="custom_company_address_country_id"
                            isSelect={countries}
                            label={translations('passing.country')}
                            isRequired
                            withValue
                        />
                    </FormRow>
                    <div className={style.Group}>
                        <FormRow className={classNames(style.Half, style.WithPostCode)}>
                            <FormField
                                name="custom_company_address_postcode"
                                label={translations('passing.postcode')}
                                isRequired
                            />
                            <FormField
                                name="custom_company_address_city"
                                label={translations('passing.city')}
                                isRequired
                            />
                        </FormRow>
                        <FormRow className={style.Half}>
                            <FormField
                                name="custom_company_address_street"
                                label={translations('passing.street')}
                                isRequired
                            />
                            <FormField
                                name="custom_company_address_housenumber"
                                label={translations('front.passing.add.house')}
                                isRequired
                            />
                        </FormRow>
                    </div>
                </div>
            )}
        </div>
    );
};

UserCustomCompany.propTypes = {
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
};


const mapStateToProps = (state) => {
    const { countries } = state;

    return {
        countries: countries.countries,
    };
};

export default connect(mapStateToProps, null)(UserCustomCompany);
