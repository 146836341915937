import React from 'react';
import {
    BrowserRouter as Router, NavLink, Route, Switch
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { classNames } from 'services';
import { connect } from 'react-redux';
import { logIn } from 'data/user/actions';
import defaultTranslation from 'services/defaultTranslations';
import ActivateAccount from 'components/ActivateAccount';
import ForgotPassword from 'components/ForgotPassword';
import ResetPassword from 'components/ResetPassword';
import LangSwitcher from 'components/LangSwitcher';


import './styles.scss';
import LoginForm from './components/LoginForm';
import RouteChangeTracker from '../RouteChangeTracker';

const Login = ({ logUserIn, user }) => {
    const handleSubmit = (values) => {
        logUserIn(values.email, values.password, values.remember_me === true);
    };
    const isDev = ['localhost:3000', 'front-tests-dev.transassist.eu', 'front-tests-prod.transassist.eu', 'app-staging.transassist.eu'].includes(window.location.host);
    let errorMessage = '';
    const language = localStorage.getItem('language') ?? 'en';
    const translations = {
        welcome: defaultTranslation('welcome', language),
        log_in_to_access: defaultTranslation('log_in_to_access', language),
    };

    if (typeof user.error !== 'undefined' && user.error) {
        if (typeof user.error.response !== 'undefined' && typeof user.error.response.data !== 'undefined' && typeof user.error.response.data.message !== 'undefined') {
            if (user.error.response.data.message) {
                errorMessage = user.error.response.data.message;
            }
        }
    }

    return (
        <div className="Login__outer">
            <div className="Login__sidebar">
                <span className="Login__sidebar--welcome">{translations.welcome}</span>
                <span className="Login__sidebar--company">Trans Assist</span>
                <span className="Login__sidebar--text">{translations.log_in_to_access}</span>
            </div>
            <div className={classNames('Login')}>
                <div className="Login__inner">
                    <Router>
                        {!isDev && <RouteChangeTracker />}
                        <Switch>
                            <Route path="/activate/:activateToken" component={ActivateAccount} exact />
                            <Route path="/forgot-password" component={ForgotPassword} exact />
                            <Route path="/reset-password/:token" component={ResetPassword} exact />
                            <Route path="/">
                                <div className="Login__lang">
                                    <LangSwitcher />
                                </div>
                                <NavLink to="/">
                                    <img src="/images/login_logo.svg" className="logo Login__logo" alt="" width={158} height={64} />
                                </NavLink>
                                {errorMessage.length > 0 ? <p className="api-error Login__error">{errorMessage}</p> : ''}
                                <LoginForm onSubmit={handleSubmit} />
                                <div className="Login__copy">
                                    Trans Assist © 2019 -
                                    {' '}
                                    {new Date().getFullYear()}
                                    {' '}
                                    All rights reserved
                                </div>
                            </Route>
                        </Switch>
                    </Router>
                </div>
            </div>
        </div>
    );
};

Login.propTypes = {
    logUserIn: PropTypes.func.isRequired,
    user: PropTypes.shape({
        loading: PropTypes.bool,
        user: PropTypes.object,
        error: PropTypes.any,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;

    return {
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    logUserIn: (email, password, remember = false) => dispatch(logIn(email, password, remember)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
