import React, { useEffect, useState } from 'react';
import {
    NavLink,
    Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import {
    setPaginationVariables, classNames, API, errorHandler, translations
} from 'services';
import { CancelToken } from 'axios';
import qs from 'qs';
import { transportsRemove } from 'data/transportsList/actions';
import PassingsListElement from '../PassingsListElement';

import './styles.scss';

const PassingsList = ({
    perPage, page, sorting, status, reloadTrigger, toRemoveLoading, search, clear,
}) => {
    const [passingsList, setPassingsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirectTo, setRedirectTo] = useState(0);
    const [lastPage, setLastPage] = useState(page);
    const onPage = perPage.passings ? perPage.passings : 10;
    const isLoading = loading || toRemoveLoading;
    const isFiltered = status.length > 0 || search.length > 0;

    useEffect(() => {
        const source = CancelToken.source();
        setLoading(true);

        const params = {
            include: 'startingAddress,destinationAddress,sender,receiver,carriers',
            page: {
                size: onPage,
                number: page,
            },
            filter: {
                is_working_copy: false,
            },
            sort: sorting.length > 0 ? sorting : 'default',
        };

        if (status.length > 0) {
            params.filter.status_id = status.join(',');
        }

        if (search.length > 0) {
            params.filter.search = search;
        }

        clear();

        API.get(`/passings?${qs.stringify(params)}`, {
            cancelToken: source.token,
        })
            .then((response) => {
                setLoading(false);
                const paginationVariables = setPaginationVariables(response);
                setRedirectTo(paginationVariables.redirectTo);
                setLastPage(paginationVariables.lastPage);
                if (typeof response.data !== 'undefined' && typeof response.data.data !== 'undefined') {
                    setPassingsList(response.data.data);
                } else {
                    setPassingsList([]);
                }
            })
            .catch((error) => {
                errorHandler(error, () => {
                    setPassingsList([]);
                    setLoading(false);
                });
            });

        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, onPage, sorting, status, reloadTrigger, search]);

    if (redirectTo > 0) {
        return <Redirect to={`/transports/list/${redirectTo}`} />;
    }

    const passingsListElements = passingsList.map(
        (element) => (<PassingsListElement passing={element} key={element.id} />)
    );

    return (
        <>
            <div className={classNames('PassingsList', 'list', (!isLoading && passingsList.length === 0) && 'empty-layout')}>
                {isLoading ? <Loader hideText /> : passingsListElements}
                {(!isLoading && passingsList.length === 0) && (
                    <div className="empty-layout-content">
                        <div className="empty-layout--copy">{translations(isFiltered ? 'front.transports_filters.not_found' : 'front.empty.passings_list')}</div>
                        <NavLink to="/transports/add" className="empty-layout--add" exact>
                            <span className="empty-layout--add-inner">
                                {translations('route.api.passing.store')}
                            </span>
                        </NavLink>
                    </div>
                )}
            </div>
            <Pagination keyName="passings" urlPrefix="/transports/list/" lastPage={lastPage} currentPage={page} />
        </>
    );
};

PassingsList.propTypes = {
    page: PropTypes.number.isRequired,
    reloadTrigger: PropTypes.number,
    perPage: PropTypes.shape({
        passings: PropTypes.number,
        trucks: PropTypes.number,
        users: PropTypes.number,
    }).isRequired,
    sorting: PropTypes.string.isRequired,
    status: PropTypes.arrayOf(PropTypes.string).isRequired,
    toRemoveLoading: PropTypes.bool.isRequired,
    search: PropTypes.string.isRequired,
    clear: PropTypes.func.isRequired,
};

PassingsList.defaultProps = {
    reloadTrigger: 1,
};

const mapStateToProps = (state) => {
    const { perPage, transportsList } = state;
    const {
        sorting, status, toRemoveLoading, search,
    } = transportsList;
    return {
        perPage,
        sorting,
        status,
        toRemoveLoading,
        search,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clear: () => dispatch(transportsRemove([])),
});


export default connect(mapStateToProps, mapDispatchToProps)(PassingsList);
