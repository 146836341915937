import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translations } from 'services';

const AppVersion = ({ version }) => {
    if (version.length > 0) {
        return (
            <>
                <span className="hide-md">
                    {translations('front.general.version')}
            &nbsp;
                </span>
                <span className="show-md">
                    v.&nbsp;
                </span>
                {version}
            </>
        );
    }

    return <></>;
};

AppVersion.propTypes = {
    version: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    const { appVersion } = state;
    const { version } = appVersion;
    return {
        version
    };
};

export default connect(mapStateToProps, null)(AppVersion);
