import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import { classNames } from 'services';
import { hideArrowButton } from 'data/currentChat/actions';

const ChatArrow = ({ show, hide }) => {
    const handleClick = () => {
        hide();

        if (document.getElementById('users-chat-list')) {
            document.getElementById('users-chat-list').scrollTo(0, document.getElementById('users-chat-list').scrollHeight);
        }
    };

    return (
        <button
            type="button"
            onClick={() => { handleClick(); }}
            className={classNames('ChatArrow', show && 'show')}
        >
            Arrow
        </button>
    );
};

ChatArrow.propTypes = {
    show: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { currentChat } = state;

    return {
        show: currentChat.showArrow,
    };
};

const mapDispatchToProps = (dispatch) => ({
    hide: () => dispatch(hideArrowButton()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChatArrow);
