import {
    CHAT_REQUEST,
    SET_CHAT,
    SET_MESSAGES,
    SET_DATA,
    CLOSE_CHAT,
    ADD_MESSAGE, CHAT_LOADED, CHAT_LOADING,
    OLD_MESSAGE, CHAT_NO_OLDER_MESSAGE,
    CHAT_NO_OLDER_MESSAGE_REQUEST, CHAT_NO_OLDER_MESSAGE_ERROR,
    CHAT_NEW_MESSAGE,
    REMOVE_MESSAGE,
    CHAT_NEW_MESSAGE_REQUEST,
    CHAT_HIDE_ARROW,
    CHAT_LIST_REQUEST, CHAT_LIST_ERROR, CHAT_LIST_SUCCESS, CHAT_NEW_MESSAGE_ERROR, CHAT_RECEIVERS,
} from './constants';
import { USER_LOGOUT } from '../user/constants';

export const initialGeneralState = {
    loading: false,
    chatID: null,
    messages: [],
    messagesIDs: [],
    data: null,
    isLoading: false,
    hasOlder: true,
    olderLoading: false,
    olderError: false,
    newLoading: false,
    showArrow: false,
    list: [],
    listLoading: false,
    listError: false,
};

export default (
    state = initialGeneralState,
    action
) => {
    const newState = { ...state };
    const ids = [];
    const messagesToAdd = [];
    const newMessagesToAdd = [];
    const newIds = [];
    let index = -1;
    let previousHeight = 0;
    let currentHeight = 0;

    switch (action.type) {
    case CHAT_REQUEST:
        newState.loading = true;
        return newState;
    case SET_CHAT:
        newState.chatID = action.payload;
        newState.hasOlder = true;
        newState.showArrow = false;
        return newState;
    case SET_MESSAGES:
        if (action.payload.length) {
            action.payload.forEach((elm) => {
                ids.push(elm.id);
            });
        }
        newState.messagesIDs = ids;
        newState.messages = action.payload;
        return newState;
    case SET_DATA:
        newState.data = action.payload;
        return newState;
    case CHAT_LOADING:
        newState.isLoading = true;
        return newState;
    case CHAT_LOADED:
        newState.isLoading = false;
        return newState;
    case CLOSE_CHAT:
        newState.chatID = null;
        newState.messages = [];
        newState.data = null;
        return newState;
    case ADD_MESSAGE:
        newState.messages = [
            {
                chat_id: newState.chatID,
                // created_at: Moment().format('x'),
                id: action.payload.id,
                text: action.payload.msg,
                isLoading: true,
            },
            ...newState.messages
        ];
        return newState;
    case REMOVE_MESSAGE:
        index = state.messages.findIndex((elm) => (elm.id === action.payload));
        if (index > -1) {
            newState.messages = [
                ...state.messages.slice(0, index),
                ...state.messages.slice(index + 1),
            ];
        }
        return newState;
    case CHAT_NEW_MESSAGE_REQUEST:
        newState.newLoading = true;
        return newState;
    case CHAT_RECEIVERS:
        try {
            if (newState.data && typeof newState.data.receivers !== 'undefined' && newState.data.receivers.length > 0 && action.payload.length > 0) {
                newState.data.receivers = newState.data.receivers.map((receiver) => {
                    const payloadItem = action.payload.find((item) => item.id === receiver.id);
                    if (payloadItem) {
                        return {
                            ...receiver,
                            last_seen_message_at: payloadItem.last_seen_message_at
                        };
                    }
                    return receiver;
                });
            }
        } catch (e) {
            console.error(e);
        }
        return newState;
    case CHAT_NEW_MESSAGE:
        if (action.payload.length) {
            action.payload.forEach((elm) => {
                if (!state.messagesIDs.includes(elm.id)) {
                    newMessagesToAdd.push(elm);
                    newIds.push(elm.id);
                }
            });
            if (newIds.length) {
                newState.messagesIDs = newIds.concat(state.messagesIDs);
                newState.messages = newMessagesToAdd.concat(state.messages);
                if (typeof newMessagesToAdd[0] !== 'undefined' && typeof newMessagesToAdd[0].chat_id !== 'undefined') {
                    const eventToSend = new CustomEvent('ta-new-message', { detail: newMessagesToAdd[0].chat_id });
                    document.dispatchEvent(eventToSend);
                }

                if (document.getElementById('users-chat-list')) {
                    if (document.getElementById('users-chat-list').scrollHeight - document.getElementById('users-chat-list').clientHeight > document.getElementById('users-chat-list').scrollTop) {
                        newState.showArrow = true;
                    } else {
                        setTimeout(() => {
                            document.getElementById('users-chat-list').scrollTo(0, document.getElementById('users-chat-list').scrollHeight);
                        }, 50);
                    }
                }
            }
        }
        newState.newLoading = false;
        return newState;
    case CHAT_NEW_MESSAGE_ERROR:
        if (newState.messages.length) {
            newState.messages = newState.messages.map((message) => (message.id === action.payload
                ? { ...message, error: true, isLoading: false }
                : message));
        }
        return newState;
    case OLD_MESSAGE:
        if (action.payload.length) {
            action.payload.forEach((elm) => {
                if (!state.messagesIDs.includes(elm.id)) {
                    messagesToAdd.push(elm);
                    ids.push(elm.id);
                }
            });
            newState.messagesIDs = state.messagesIDs.concat(ids);
            newState.messages = state.messages.concat(messagesToAdd);

            if (document.getElementById('users-chat-list')) {
                previousHeight = document.getElementById('users-chat-list').scrollHeight;

                setTimeout(() => {
                    currentHeight = document.getElementById('users-chat-list').scrollHeight;
                    if (currentHeight > previousHeight) {
                        document.getElementById('users-chat-list').scrollTo(0, document.getElementById('users-chat-list').scrollTop + (currentHeight - previousHeight));
                    }
                }, 30);
            }
        }
        newState.olderLoading = false;
        newState.olderError = false;
        return newState;
    case CHAT_NO_OLDER_MESSAGE:
        newState.hasOlder = false;
        return newState;
    case CHAT_NO_OLDER_MESSAGE_REQUEST:
        newState.olderLoading = true;
        newState.olderError = false;
        return newState;
    case CHAT_NO_OLDER_MESSAGE_ERROR:
        newState.olderLoading = false;
        newState.olderError = action.payload;
        return newState;
    case CHAT_HIDE_ARROW:
        newState.showArrow = false;
        return newState;
    case CHAT_LIST_SUCCESS:
        newState.list = action.payload;
        newState.listLoading = false;
        newState.listError = false;
        return newState;
    case CHAT_LIST_REQUEST:
        // newState.list = ;
        newState.listLoading = true;
        newState.listError = false;
        return newState;
    case CHAT_LIST_ERROR:
        newState.list = [];
        newState.listLoading = false;
        newState.listError = action.payload;
        return newState;
    case USER_LOGOUT:
        return { ...initialGeneralState };
    default:
        return state;
    }
};
