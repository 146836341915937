import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FilterButton from 'components/FilterButton';
import { Form, Formik } from 'formik';
import './styles.scss';
import { transportsFiltersSet } from 'data/transportsList/actions';
import TransportsFiltersForm from './components/TransportsFiltersForm';

const TransportsFilters = ({ sorting, status, submit }) => {
    const [formVisible, setFormVisible] = useState(false);
    const defaultValues = {
        sorting, status,
    };
    const onSubmit = (values) => {
        submit(values);
        setFormVisible(false);
    };

    return (
        <div className="TransportsFilters">
            <FilterButton onClick={() => { setFormVisible(true); }} />
            {(formVisible) && (
                <Formik initialValues={defaultValues} onSubmit={onSubmit}>
                    {() => (
                        <Form className="TransportsFilters__form">
                            <TransportsFiltersForm />
                            <button
                                className="TransportsFilters__close"
                                type="button"
                                onClick={() => { setFormVisible(false); }}
                            >
                                close
                            </button>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

TransportsFilters.propTypes = {
    sorting: PropTypes.string.isRequired,
    status: PropTypes.arrayOf(PropTypes.string).isRequired,
    submit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transportsList } = state;
    const { sorting, status, } = transportsList;

    return {
        sorting, status,
    };
};

const mapDispatchToProps = (dispatch) => ({
    submit: (values) => dispatch(transportsFiltersSet(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportsFilters);
