import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { connect } from 'react-redux';

import './styles.scss';
import SmallLoader from 'components/SmallLoader';
import classNames from 'services/classNames';
import { translations } from 'services';
import checkIsImage from 'services/isImage';
import { getChats, getOlderChats } from 'data/chatsList/actions';
import ChatListElement from './components/ChatListElement';
import ChatSingle from './components/ChatSingle';
import Error from '../Error';

const Chat = ({
    chatID, visible, get, isLoading, chatsList,
    getOlder, hasOlder, isOlderLoading, error, errorOlder,
}) => {
    const perPage = 15;
    const location = useLocation();
    let isFullScreen = false;
    if (location.pathname && location.pathname.startsWith('/messages')) {
        isFullScreen = true;
    }

    const older = useCallback(() => {
        getOlder(Math.floor(chatsList.length / perPage) + 1);
    }, [chatsList, getOlder]);

    const handleScroll = useCallback(() => {
        if (hasOlder && !isOlderLoading) {
            const $elm = document.getElementById('users-chats-list');
            if ($elm.scrollHeight - $elm.clientHeight <= $elm.scrollTop) {
                older();
            }
        }
    }, [hasOlder, isOlderLoading, older]);

    useEffect(() => {
        if (chatID === null && hasOlder) {
            const $elm = document.getElementById('users-chats-list');
            if ($elm) {
                $elm.addEventListener('scroll', handleScroll);
                return () => {
                    $elm.removeEventListener('scroll', handleScroll);
                };
            }
        }

        return () => {

        };
    }, [handleScroll, chatID, isFullScreen, isLoading, hasOlder]);

    useEffect(() => {
        if (chatID === null && hasOlder && !isLoading && !isOlderLoading) {
            const $elm = document.getElementById('users-chats-list');
            if ($elm) {
                if ($elm.scrollHeight === $elm.clientHeight && $elm.scrollTop === 0) {
                    older();
                }
            }
        }
    }, [chatID, isFullScreen, isLoading, hasOlder, isOlderLoading, older]);

    useEffect(() => {
        if (chatID === null || isFullScreen) {
            get();
        }
    }, [chatID, get, isFullScreen]);

    if (isLoading && !isFullScreen) {
        return (
            <div className={classNames('Chat', visible && 'visible')}>
                <div className="Chat__loader">
                    <SmallLoader dark />
                </div>
            </div>
        );
    }

    if (chatID !== null && !isFullScreen) {
        return <div className={classNames('Chat', visible && 'visible')}><ChatSingle /></div>;
    }

    const mappedList = chatsList && chatsList.length ? chatsList.map((elm) => {
        let elmTitle = '';
        let elmMsg = '';
        let lastUser = null;

        if (elm.passing
            && elm.passing.id
            && elm.passing.passing_idstr
            && elm.passing.passing_idstr.length) {
            elmTitle = elm.passing.passing_idstr;
        } else {
            elm.receivers.forEach((receiver) => {
                if (!receiver.is_you) {
                    elmTitle = receiver.user ? `${receiver.user.name} ${receiver.user.surname}` : receiver.email;
                }
            });
        }

        if (elm.last_message) {
            const isFile = !!elm.last_message.attachment_file_id;
            const isImage = isFile && checkIsImage(elm.last_message);

            if (elm.last_message.from_user_id) {
                lastUser = elm.last_message.from_user_id;
            }

            if (isFile) {
                if (isImage) {
                    elmMsg = translations('front.chat.sent_image');
                } else {
                    elmMsg = translations('front.chat.sent_file');
                }
            } else {
                if (elm.last_message.form_params_translation
                    && elm.last_message.form_params_translation.length) {
                    elm.last_message.form_params_translation.forEach((translation) => {
                        if (translation.translated && translation.translated.length) {
                            elmMsg += `${translation.translated} `;
                        }
                    });
                }
                if (elmMsg.length === 0 && elm.last_message.text && elm.last_message.text.length) {
                    elmMsg = elm.last_message.text;
                }
            }
        }

        const elmData = {
            receivers: elm.receivers,
            title: elmTitle,
            id: elm.id,
            message: elmMsg,
            lastMessageUser: lastUser,
            lastMessageAt: elm.last_message_at,
            lastMessage: elm.last_message,
            currentReceiver: typeof elm.current_receiver !== 'undefined' ? elm.current_receiver : null,
        };

        return (
            <ChatListElement data={elmData} key={elm.id} />
        );
    }) : '';

    if (error) {
        return (
            <div className={classNames('Chat', 'empty-chat', visible && 'visible')}>
                <div className="Chat-wrapper" style={{ width: '100%' }}>
                    {Error && <Error errors={error} />}
                </div>
            </div>
        );
    }

    if (!chatsList || chatsList.length === 0) {
        if (isFullScreen) {
            return (
                <div className={classNames('Chat', 'empty-chat', visible && 'visible')}>
                    <div className="Chat__empty">
                        {translations('front.empty.full_chats_list')}
                    </div>
                </div>
            );
        }
        return (
            <div className={classNames('Chat', 'empty-chat', visible && 'visible')}>
                <div className="Chat__empty">
                    <img src="/images/chat-empty.svg" width={48} height={48} className="Chat__empty--image" alt="" />
                    {translations('front.chat.empty')}
                </div>
            </div>
        );
    }

    return (
        <div className={classNames('Chat', visible && 'visible', 'is-list')} id="users-chats-list" style={{ overflow: 'auto' }}>
            <div className="Chat-wrapper">
                {isOlderLoading && (
                    <div className="Chat__older-loading">
                        <SmallLoader dark />
                    </div>
                )}
                {mappedList}
                {errorOlder && <Error errors={errorOlder} />}
            </div>
        </div>
    );
};

Chat.propTypes = {
    chatID: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]),
    visible: PropTypes.bool.isRequired,
    get: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    chatsList: PropTypes.arrayOf(PropTypes.object).isRequired,
    getOlder: PropTypes.func.isRequired,
    hasOlder: PropTypes.bool.isRequired,
    isOlderLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]).isRequired,
    errorOlder: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]).isRequired,
};

Chat.defaultProps = {
    chatID: null,
};

const mapStateToProps = (state) => {
    const { currentChat, mobileChat, chatsList } = state;

    return {
        chatID: currentChat.chatID,
        visible: mobileChat.visible,
        isLoading: chatsList.loading,
        chatsList: chatsList.chats,
        hasOlder: chatsList.hasOlder,
        isOlderLoading: chatsList.loadingOlder,
        error: chatsList.error,
        errorOlder: chatsList.errorOlder,
    };
};

const mapDispatchToProps = (dispatch) => ({
    get: () => (dispatch(getChats())),
    getOlder: (p) => (dispatch(getOlderChats(p))),
});


export default connect(mapStateToProps, mapDispatchToProps)(Chat);
