import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    GoogleMap, DirectionsService, DirectionsRenderer, Marker
} from '@react-google-maps/api';
import getMapStyles from 'services/mapStyles';

const RouteMap = ({
    from, to, height, driverPosition
}) => {
    const travelMode = 'DRIVING';
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [showPoints, setShowPoints] = useState(false);
    const count = useRef(0);
    const mapRef = useRef(null);

    useEffect(() => {
        count.current = 0;
    }, [from, to]);

    const containerStyle = {
        width: '100%',
        height,
    };

    const styles = getMapStyles();

    const center = {
        lat: 51.9324513,
        lng: 16.8922582
    };

    const options = {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles
    };

    const directionsCallback = (response) => {
        if (response !== null) {
            if (response.status !== 'OK' && count.current === 0) {
                if (typeof from.lat !== 'undefined' && typeof from.lng !== 'undefined' && typeof to.lat !== 'undefined' && typeof to.lng !== 'undefined') {
                    setShowPoints(true);
                }
            }
            if (response.status === 'OK' && count.current === 0) {
                count.current += 1;
                setDirectionsResponse(response);
            } else {
                console.error('response: ', response);
            }
        }
    };

    if (showPoints) {
        try {
            if (mapRef.current) {
                const bounds = new window.google.maps.LatLngBounds();
                bounds.extend(new window.google.maps.LatLng(from.lat, from.lng));
                bounds.extend(new window.google.maps.LatLng(to.lat, to.lng));
                mapRef.current.fitBounds(bounds);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            options={options}
            onLoad={(map) => {
                try {
                    mapRef.current = map;
                } catch (e) {
                    console.error(e);
                }
            }}
        >
            { /* Child components, such as markers, info windows, etc. */ }
            <DirectionsService
                options={{
                    origin: from,
                    destination: to,
                    travelMode,
                }}
                callback={directionsCallback}
            />
            {directionsResponse !== null && (
                <>
                    {from && (
                        <Marker
                            position={{
                                lat: from.lat,
                                lng: from.lng,
                            }}
                            icon={{
                                url: 'https://www.transassist.eu/_inc/images/from.png',
                                anchor: { x: 23, y: 40 },
                                size: { width: 46, height: 50 },
                            }}
                        />
                    )}
                    {to && (
                        <Marker
                            position={{
                                lat: to.lat,
                                lng: to.lng,
                            }}
                            icon={{
                                url: 'https://www.transassist.eu/_inc/images/to.png',
                                anchor: { x: 23, y: 40 },
                                size: { width: 46, height: 50 },
                            }}
                        />
                    )}
                    <DirectionsRenderer
                        options={{
                            directions: directionsResponse,
                            suppressMarkers: true,
                        }}
                    />
                </>
            )}
            {driverPosition !== null && (
                <Marker
                    position={{
                        lat: driverPosition.lat,
                        lng: driverPosition.lng,
                    }}
                    icon={{
                        url: 'https://www.transassist.eu/logo-marker.png',
                        size: { width: 42, height: 41 },
                    }}
                />
            )}
            {showPoints && (
                <>
                    <Marker
                        position={{
                            lat: from.lat,
                            lng: from.lng,
                        }}
                        icon={{
                            url: 'https://www.transassist.eu/main-marker.png',
                            anchor: { x: 15, y: 15 },
                            // scaledSize: { width: 18, height: 18 },
                            size: { width: 30, height: 30 },
                        }}
                    />
                    <Marker
                        position={{
                            lat: to.lat,
                            lng: to.lng,
                        }}
                        icon={{
                            url: 'https://www.transassist.eu/main-marker.png',
                            anchor: { x: 15, y: 15 },
                            // scaledSize: { width: 18, height: 18 },
                            size: { width: 30, height: 30 },
                        }}
                    />
                </>
            )}
        </GoogleMap>
    );
};

RouteMap.propTypes = {
    from: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    })]).isRequired,
    to: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    })]).isRequired,
    height: PropTypes.string,
    driverPosition: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    })
};

RouteMap.defaultProps = {
    height: '296px',
    driverPosition: null,
};

export default RouteMap;
