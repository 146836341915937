import React from 'react';
import './styles.scss';
import HomeMap from './components/HomeMap';
import HomePassings from './components/HomePassings';

const Home = () => (
    <div className="Home">
        <div className="Home__left">
            <HomeMap />
        </div>
        <div className="Home__right">
            <HomePassings />
        </div>
    </div>
);

export default Home;
