import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import { translations } from 'services';
import './styles.scss';
import { useParams } from 'react-router-dom';
import { transportDraftComments } from 'data/transportDraft/actions';

const TransportLoadComments = ({
    specialProvisions,
    senderInstructions,
    set,
}) => {
    const { id } = useParams();
    const edit = !!id;
    const reference = useRef(null);
    const defaultValues = {
        specialProvisions,
        senderInstructions,
    };
    const handleSubmit = (values) => {
        set(values, edit);
    };

    useEffect(() => () => {
        reference.current.submitForm();
    }, []);

    return (
        <div className="TransportLoadComments">
            <div className="TransportAdd__card">
                <Formik
                    innerRef={reference}
                    initialValues={defaultValues}
                    onSubmit={handleSubmit}
                >
                    {() => (
                        <Form>
                            <FormRow>
                                <FormField
                                    name="senderInstructions"
                                    type="textarea"
                                    label={translations('front.passing.single.load.sender_instructions')}
                                />
                            </FormRow>
                            <FormRow>
                                <FormField
                                    name="specialProvisions"
                                    type="textarea"
                                    label={translations('passing.special_provisions')}
                                />
                            </FormRow>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

TransportLoadComments.propTypes = {
    specialProvisions: PropTypes.string.isRequired,
    senderInstructions: PropTypes.string.isRequired,
    set: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const { specialProvisions, senderInstructions } = transportDraft;

    return {
        specialProvisions, senderInstructions
    };
};

const mapDispatchToProps = (dispatch) => ({
    set: (values, isEdit = false) => dispatch(transportDraftComments(values, isEdit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportLoadComments);
