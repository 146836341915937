import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Formik } from 'formik';

import { classNames, defaultTranslations, translations } from 'services';
import Document from 'components/Document';
import { useParams } from 'react-router-dom';
import { transportDraftPopDocument, transportDraftPushDocument, transportDraftSetDocumentToChange } from 'data/transportDraft/actions';
import TransportFileFormRename from './components/TransportFileFormRename';
import TransportFileJustNames from './components/TransportFileJustNames';

import style from './TransportFileForm.module.scss';


const TransportFileForm = ({
    documents, push, pop, setEdit,
}) => {
    const lang = localStorage.getItem('language') || 'en';
    const { id } = useParams();
    const edit = !!id;
    const docsToDownload = documents.filter((doc) => (doc.download_url
            && doc.download_url.length > 0)
        || (doc.objectURL && doc.objectURL.length > 0));
    const otherDocs = documents.filter((doc) => (!doc.download_url || doc.download_url.length === 0)
        && (!doc.objectURL || doc.objectURL.length === 0));

    const onSubmit = (values) => {
        if (values.files && values.files.length) {
            for (let i = 0; i < values.files.length; i += 1) {
                const objectURL = URL.createObjectURL(values.files[i]);
                let extension = 'extension';
                let name = 'file';
                let size = 0;
                let modified = 0;
                let fileID = -1;
                try {
                    if (values.files[i].name) {
                        name = values.files[i].name;
                        extension = name.split('.').pop();
                    }
                    size = values.files[i].size;
                    modified = values.files[i].lastModified;
                    fileID = `${size}-${modified}`;
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
                const mbSize = (size / (1024 * 1024));
                if (mbSize >= 25) {
                    const eventToSend = new CustomEvent('ta-show-modal', { detail: 'file-error-20' });
                    document.dispatchEvent(eventToSend);
                } else {
                    push({
                        name,
                        extension,
                        objectURL,
                        size,
                        modified,
                        id: fileID,
                    }, edit);
                }
            }
        }
    };

    const handleDelete = (doc) => {
        pop(doc, edit);
    };

    const passingDocs = docsToDownload?.length > 0
        ? (
            <div className={style.Docs}>
                {docsToDownload.map((elm) => (
                    <Document
                        key={elm.id ? elm.id : elm.objectURL}
                        extension={elm.extension}
                        name={elm.newName || elm.name}
                        downloadUrl={elm.download_url || ''}
                        editFunc={typeof elm.id !== 'undefined' && elm.id ? () => { setEdit(elm.id); } : false}
                        deleteFunc={() => { handleDelete(elm.id); }}
                    />
                ))}
            </div>
        ) : '';

    const otherDocsHeader = otherDocs?.length > 0 && (docsToDownload?.length > 0)
        ? <div className={style.OtherDocsHeader}>{defaultTranslations('other_docs', lang)}</div>
        : '';


    const otherPassingDocs = otherDocs?.length > 0
        && (
            <>
                {otherDocsHeader}
                <ul className="content-list">
                    {otherDocs.map((doc) => (
                        <li key={doc.id} className={style.ListElement}>
                            <div>{doc.newName || doc.name}</div>
                            <button
                                type="button"
                                data-tooltip={translations('front.general.edit')}
                                className={classNames(style.Edit, 'km-tooltip')}
                                onClick={() => { setEdit(doc.id, edit); }}
                            >
                                {translations('front.general.edit')}
                            </button>
                            <button
                                type="button"
                                data-tooltip={translations('front.general.delete')}
                                className={classNames(style.Delete, 'delete-button', 'km-tooltip')}
                                onClick={() => { pop(doc.id, edit); }}
                            >
                                {translations('front.general.delete')}
                            </button>
                        </li>
                    ))}
                </ul>
            </>
        );

    return (
        <div className={style.TransportFileForm}>
            <Formik
                initialValues={{
                    files: [],
                }}
                onSubmit={(values) => onSubmit(values)}
            >
                {
                    ({ handleSubmit, setFieldValue, submitForm }) => (
                        <form onSubmit={handleSubmit}>
                            <Dropzone onDrop={(acceptedFiles) => { console.log('accepted', acceptedFiles); setFieldValue('files', acceptedFiles); submitForm(); }}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className={style.Uploader}>
                                            <div className={style.Uploader__Title}>{translations('passing.details.cargo.add_attachment')}</div>
                                            <div className={style.Uploader__Subtitle}>Max 25mb</div>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </form>
                    )
                }
            </Formik>
            {passingDocs}
            <TransportFileFormRename />
            {otherPassingDocs}
            <TransportFileFormRename isJustName />
            <TransportFileJustNames />
        </div>
    );
};

TransportFileForm.propTypes = {
    documents: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        objectURL: PropTypes.string,
        extension: PropTypes.string,
        size: PropTypes.number,
        modified: PropTypes.number,
    })).isRequired,
    push: PropTypes.func.isRequired,
    pop: PropTypes.func.isRequired,
    setEdit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { transportDraft } = state;
    const { documents } = transportDraft;

    return {
        documents
    };
};

const mapDispatchToProps = (dispatch) => ({
    push: (document, isEdit = false) => dispatch(transportDraftPushDocument(document, isEdit)),
    pop: (document, isEdit = false) => dispatch(transportDraftPopDocument(document, isEdit)),
    setEdit: (edit) => dispatch(transportDraftSetDocumentToChange(edit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransportFileForm);
