import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useField, useFormikContext } from 'formik';


const PhoneField = ({ ...props }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(props);
    // const lang = localStorage.getItem('language') ?? 'en';

    return (
        <PhoneInput
            country="pl"
            onChange={(phone) => {
                const valueToSet = phone.length && phone[0] !== '+' ? `+${phone}` : phone;
                setFieldTouched(field.name, true);
                setFieldValue(field.name, valueToSet);
            }}
            placeholder="XXX XXX XXX"
            value={field.value}
        />
    );
};

export default PhoneField;
