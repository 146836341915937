import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import translations from 'services/translations';
import { useLocation } from 'react-router-dom';
import classNames from 'services/classNames';

import './styles.scss';
import MenuLink from './components/MenuLink';


const Menu = ({ permissions }) => {
    const location = useLocation();

    const elements = [
        {
            icon: 'start',
            to: '/',
            translation: translations('route.api.start.main'),
        },
        {
            icon: 'transports',
            to: '/transports/list',
            translation: translations('route.api.passing.main'),
            children: [
                { translation: translations('route.api.passing.index'), to: '/transports/list' },
                { translation: translations('route.api.passing.store'), to: '/transports/add/from-company' },
            ]
        },
        {
            icon: 'fleet',
            to: '/fleet/trucks',
            translation: translations('route.api.fleet.main'),
            children: [
                { translation: translations('front.menu.cars'), to: '/fleet/trucks' },
                { translation: translations('front.menu.trailers'), to: '/fleet/trailers' },
            ]
        },
        {
            icon: 'message',
            to: '/messages',
            translation: translations('front.menu.msg'),
        },
        {
            icon: 'documents',
            to: '/documents',
            translation: translations('route.api.documents.index'),
        },
        {
            icon: 'user',
            to: '/users/list',
            translation: translations('route.api.user.main'),
            permission: 'team_list',
        },
        {
            icon: 'settings',
            to: '/settings',
            translation: translations('route.api.settings.main'),
        },
        {
            icon: 'help',
            to: '/help',
            translation: translations('route.api.help.main'),
        },
    ];

    const menuList = elements.map((elm) => {
        let childElms = '';
        const isActive = ((elm.to === '/' && location.pathname === '/')
            || (elm.to !== '/' && location.pathname.startsWith(elm.to))
            || (elm.to === '/transports/list' && location.pathname.startsWith('/transports'))
            || (elm.to === '/fleet/trucks' && location.pathname.startsWith('/fleet'))
            || (elm.to === '/users/list' && location.pathname.startsWith('/users')));
        if (typeof elm.children !== 'undefined' && elm.children.length > 0) {
            childElms = elm.children.map((child) => (
                <li className="Menu__main-sublist--element" key={child.to}>
                    <MenuLink translation={child.translation} to={child.to} />
                </li>
            ));
        }
        if (typeof elm.permission !== 'undefined' && permissions.filter((permission) => permission.name === elm.permission).length === 0) {
            return <React.Fragment key={elm.to} />;
        }

        return (
            <li className={classNames('Menu__main-list--element', (isActive && 'active'))} key={elm.to}>
                <MenuLink icon={elm.icon} translation={elm.translation} to={elm.to} isButton={typeof elm.children !== 'undefined'} />
                {(typeof elm.children !== 'undefined' && elm.children.length > 0) ? (
                    <ul className="Menu__main-sublist">
                        <li className="Menu__main-sublist-title">{elm.translation}</li>
                        {childElms}
                    </ul>
                ) : ''}
            </li>
        );
    });

    return (
        <nav className="Menu">
            <ul className="Menu__main-list">
                {menuList}
            </ul>
        </nav>
    );
};

Menu.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
    })).isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state;
    const { permissions } = user;

    return {
        permissions,
    };
};

export default connect(mapStateToProps, null)(Menu);
