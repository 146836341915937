import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Form, Formik } from 'formik';
import Yup from 'services/translatedYup';
import FormRow from 'components/FormRow';
import FormField from 'components/FormField';
import { translations } from 'services';

const PassingSignaturePassword = ({ closeHandler, reference }) => (
    <div className="PassingSignaturePassword">
        <button
            className="PassingSignaturePassword__close"
            type="button"
            onClick={() => { closeHandler(); }}
        />
        <div className="PassingSignaturePassword__msg">{translations('signatures.password_message')}</div>
        <Formik
            innerRef={reference}
            initialValues={{
                password: '',
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string().required(),
            })}
            onSubmit={(values) => {
                console.log(values);
            }}
        >
            {() => (
                <Form className="PassingSignaturePassword__form">
                    <FormRow>
                        <FormField
                            name="password"
                            label={translations('front.users.password')}
                            icon="password"
                            type="password"
                        />
                    </FormRow>
                </Form>
            )}
        </Formik>
    </div>
);

PassingSignaturePassword.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    reference: PropTypes.any.isRequired,
};

export default PassingSignaturePassword;
