import {
    TRANSPORTS_FILTERS_SET, TRANSPORTS_REMOVE_LOADING, TRANSPORTS_REMOVE_SET, TRANSPORTS_SEARCH
} from './constants';

export function transportsFiltersSet(payload) {
    return { type: TRANSPORTS_FILTERS_SET, payload };
}

export function transportsRemove(payload) {
    return { type: TRANSPORTS_REMOVE_SET, payload };
}

export function transportsRemoveLoading(payload) {
    return { type: TRANSPORTS_REMOVE_LOADING, payload };
}

export function transportsSearch(payload) {
    return { type: TRANSPORTS_SEARCH, payload };
}
