export const TRANSPORT_ADD_SUBMISSION_STATUS_INIT = 'TRANSPORT_ADD_SUBMISSION_STATUS_INIT';
export const TRANSPORT_ADD_SUBMISSION_STATUS_SENDER = 'TRANSPORT_ADD_SUBMISSION_STATUS_SENDER';
export const TRANSPORT_ADD_SUBMISSION_STATUS_STARTING = 'TRANSPORT_ADD_SUBMISSION_STATUS_STARTING';
export const TRANSPORT_ADD_SUBMISSION_STATUS_RECEIVER = 'TRANSPORT_ADD_SUBMISSION_STATUS_RECEIVER';
export const TRANSPORT_ADD_SUBMISSION_STATUS_DESTINATION = 'TRANSPORT_ADD_SUBMISSION_STATUS_DESTINATION';
export const TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_SENDER = 'TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_SENDER';
export const TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_STARTING = 'TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_STARTING';
export const TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_RECEIVER = 'TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_RECEIVER';
export const TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_DESTINATION = 'TRANSPORT_ADD_SUBMISSION_STATUS_REMOVE_DESTINATION';
export const TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_REMOVE = 'TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_REMOVE';
export const TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_UPDATE = 'TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_UPDATE';
export const TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_ADD = 'TRANSPORT_ADD_SUBMISSION_STATUS_LOAD_ADD';
export const TRANSPORT_ADD_SUBMISSION_STATUS_ADR = 'TRANSPORT_ADD_SUBMISSION_STATUS_ADR';
export const TRANSPORT_ADD_SUBMISSION_STATUS_FILES = 'TRANSPORT_ADD_SUBMISSION_STATUS_FILES';
export const TRANSPORT_ADD_SUBMISSION_STATUS_DRIVER = 'TRANSPORT_ADD_SUBMISSION_STATUS_DRIVER';
export const TRANSPORT_ADD_SUBMISSION_STATUS_FINISH = 'TRANSPORT_ADD_SUBMISSION_STATUS_FINISH';
export const TRANSPORT_ADD_SUBMISSION_STATUS_ERROR_VALIDATION = 'TRANSPORT_ADD_SUBMISSION_STATUS_ERROR_VALIDATION';
